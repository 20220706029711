import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('PLAN_SPONSOR_VIEW', {
    LOADING: null,
    SAVING: null,

    SET_TRAKS: null,
    SET_META: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
const setSaving = actionCreator(actions.SAVING, 'saving');

const setListData = actionCreator(actions.SET_TRAKS, 'traks');
export const getTrakUsers = (criteria, pageNumber, pageSize) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    for (const k in criteria) {
        if (criteria[k]) fd.append(k, criteria[k]);
    }
    http.post(`su/trak/${pageNumber}/${pageSize}`, fd)
        .then((data) => dispatch(setListData(data)))
        .finally(() => dispatch(setLoading(false)));
};

const setMeta = actionCreator(actions.SET_META, 'meta');
export const getMeta = () => (dispatch) => {
    http.get('su/trak/meta')
        .then((data) => dispatch(setMeta(data)))
        .finally(() => dispatch(setLoading(false)));
};

export const saveTrak = (trak, cb) => (dispatch) => {
    dispatch(setSaving(true));
    http.post('su/trak/save-trak', trak).then((a) => {
        cb(a);
    }).finally(() => dispatch(setSaving(false)));
};

export const deleteTrak = (trakId, cb) => (dispatch) => {
    dispatch(setSaving(true));
    http.post(`su/trak/delete-trak/${trakId}`).then((a) => {
        cb();
    }).finally(() => setSaving(false));
};


export const restoreTrak = (trakId, cb) => (dispatch) => {
    dispatch(setSaving(true));
    http.post(`su/trak/restore-trak/${trakId}`).then((a) => {
        cb();
    }).finally(() => setSaving(false));
};
