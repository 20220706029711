import {
    Box, Button, ButtonGroup,
    CircularProgress, Grid,
    Modal, TextField,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProofingModal from 'components/ProofingModal';
import React from 'react';
import http from 'utils/http';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 480,
        height: 350,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eddd',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const ContactInfoModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, onClose, onApproved, itemName, itemCode, user
    } = props;
    const [contactInfo, setContactInfo] = React.useState({
        line1: (user ? user.customFields.name : ''),
        line2: (user ? user.customFields.phone : '')
    });
    const [previewPdf, setPreviewPdf] = React.useState(null);
    const [openProofPreview, setOpenProofPreview] = React.useState(false);
    const [generating, setGenerating] = React.useState(false);

    const classes = useStyles();
    const onFieldChange = (e) => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
    };
    const getFixedCI = () => {
        return {
            line1: contactInfo.line1 ? contactInfo.line1.replace(/|/g, ' ') : '',
            line2: contactInfo.line2 ? contactInfo.line2.replace(/|/g, ' ') : ''
        };
    }
    const generateProof = () => {
        setGenerating(true);
        const ci = getFixedCI();
        http.post(`store/contract-info-proof/${itemCode}`, ci)
            .then((previewLink) => {
                setPreviewPdf(previewLink);
                setOpenProofPreview(true);
                setGenerating(false);
            });
    };

    const approvePdf = () => {
        closeProof();
        onApproved(getFixedCI());
    };

    const closeProof = () => {
        setOpenProofPreview(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Box>
                    <Typography variant="h6">
                        Contact Information
                    </Typography>
                    <Typography variant="body1">
                        Item: {itemName}
                    </Typography>
                </Box>
                <Grid container spacing={1} style={{ marginTop: '1rem' }} className="row-item">
                    <Grid item xs={12}>
                        <TextField
                            label="Line 1"
                            name="line1"
                            fullWidth
                            value={contactInfo.line1}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            error={contactInfo.line1 && contactInfo.line1.includes('|')}
                            helperText={contactInfo && contactInfo.line1 && contactInfo.line1.includes('|') ? 'Invalid character |' : ''}
                            variant="outlined"
                            inputProps={{
                                maxLength: 45
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Line 2"
                            name="line2"
                            fullWidth
                            value={contactInfo.line2}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 45
                            }}
                            variant="outlined"
                            error={contactInfo.line2 && contactInfo.line2.includes('|')}
                            helperText={contactInfo && contactInfo.line2 && contactInfo.line2.includes('|') ? 'Invalid character |' : ''}
                        />
                    </Grid>
                </Grid>
                <ButtonGroup className="list-btn-group" style={{ marginTop: '1rem', textAlign: 'right' }}>
                    <Button
                        disabled={generating}
                        className="btn place-order-btn"
                        variant="contained"
                        onClick={generateProof}
                        color="primary"
                    >
                        {generating && (
                            <CircularProgress
                                color="secondary"
                                size={12}
                                className={classes.buttonProgress}
                            />
                        )}Generate Proof
                    </Button>
                </ButtonGroup>
                <ProofingModal
                    open={openProofPreview}
                    pdf={previewPdf}
                    onApprove={approvePdf}
                    onCancel={closeProof}
                />
            </div>
        </Modal>
    );
};
export default ContactInfoModal;
