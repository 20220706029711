import {
    Grid,
    IconButton, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core';
import { Add as AddIcon, Error as ErrorIcon, Remove as RemoveIcon } from '@material-ui/icons';
import React, { useState } from 'react';

export default function PickPackItemInfo({ orderData, onPickPackItemChange, onJobItemChange }) {
    const [pickPackItem, setPickPackItem] = useState(orderData.pickPackItem);
    const [jobItems, setJobItems] = useState(orderData.jobItems);
    const updatePickPackItem = (kv) => {
        const a = { ...pickPackItem, ...kv };
        setPickPackItem(a);
        onPickPackItemChange(a);
    };

    const addItem = () => {
        const ji = [...jobItems, { id: -1, code: '', qty: 1, cvw: new Date().getTime() }];
        setJobItems(ji);
        onJobItemChange(ji);
    };

    const removeItem = (i) => {
        jobItems.splice(i, 1);
        setJobItems([...jobItems]);
        onJobItemChange(jobItems);
    };
    const onCodeChange = (i, code) => {
        jobItems[i].code = code;
        setJobItems([...jobItems]);
        onJobItemChange(jobItems);
    };
    const onQtyChange = (i, qty) => {
        jobItems[i].qty = qty;
        setJobItems([...jobItems]);
        onJobItemChange(jobItems);
    };
    const maxItemCount = 10;

    const buildInput = (label, name, value, onChange) => {
        return (
            <TextField
                fullWidth
                margin="normal"
                label={label}
                name={name}
                value={value || ''}
                variant="outlined"
                size="small"
                onChange={((e) => onChange({ [e.target.name]: e.target.value }))}
                InputLabelProps={{
                    shrink: true
                }}
            />
        );
    };
    return (
        <Grid container spacing={2}>
            {/* <Grid item xs={6}>
                <b>{orderData.pickPackItem.sheetName}</b>
            </Grid> */}
            <Grid item xs={4}>
                {buildInput('Case Name', 'caseName', pickPackItem.caseName, updatePickPackItem)}
            </Grid>
            <Grid item xs={4}>
                {buildInput('First Name', 'firstName', pickPackItem.firstName, updatePickPackItem)}
            </Grid>
            <Grid item xs={4}>
                {buildInput('Last Name', 'lastName', pickPackItem.lastName, updatePickPackItem)}
            </Grid>
            <Grid item xs={4}>
                {buildInput('Address 1', 'address1', pickPackItem.address1, updatePickPackItem)}
            </Grid>
            <Grid item xs={4}>
                {buildInput('Address 2', 'address2', pickPackItem.address2, updatePickPackItem)}
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={3}>
                {buildInput('City', 'city', pickPackItem.city, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('State', 'state', pickPackItem.state, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Zip', 'zip', pickPackItem.zip, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Country', 'country', pickPackItem.country, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Cost Center', 'costCenter', pickPackItem.costCenter, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Market Segment', 'marketSegment', pickPackItem.marketSegment, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Req First Name', 'reqFirstName', pickPackItem.reqFirstName, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Req Last Name', 'reqLastName', pickPackItem.reqLastName, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Origin (AJEPP)', 'originAjepp', pickPackItem.originAjepp, updatePickPackItem)}
            </Grid>
            <Grid item xs={3}>
                {buildInput('Field Office', 'fieldOffice', pickPackItem.fieldOffice, updatePickPackItem)}
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Item Code</TableCell>
                                <TableCell>
                                    Qty
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobItems.map((j, i) => (
                                <TableRow key={j.cvw}>
                                    <TableCell style={{ textAlign: 'center' }}>
                                        {
                                            j.id === 0
                                                ? <span style={{ color: '#f00' }}><ErrorIcon /></span>
                                                : <span>{i + 1}</span>
                                        }
                                    </TableCell>
                                    <TableCell>

                                        <TextField
                                            margin="normal"
                                            label="Code"
                                            value={j.code}
                                            style={{ margin: '4px' }}
                                            variant="outlined"
                                            size="small"
                                            onChange={((e) => onCodeChange(i, e.target.value))}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>

                                        <TextField
                                            margin="normal"
                                            label="QTY"
                                            value={j.qty || orderData.pickPackItem.items[i].item2}
                                            style={{ margin: '4px' }}
                                            variant="outlined"
                                            size="small"
                                            type="number"
                                            onChange={((e) => onQtyChange(i, e.target.value))}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: '150px' }}>
                                        {maxItemCount > jobItems.length && (
                                            <IconButton
                                                color="primary"
                                                onClick={addItem}
                                                style={{ padding: '4px' }}
                                            >
                                                <AddIcon />
                                            </IconButton>

                                        )}
                                        {
                                            jobItems.length > 1 && (
                                                <IconButton
                                                    onClick={() => removeItem(i)}
                                                    style={{ padding: '4px' }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
