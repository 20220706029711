import {
    Box,



    Button, CircularProgress, Grid,

    Paper,

    Typography
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import PublishIcon from '@material-ui/icons/Publish';
import React, { PureComponent } from 'react';
import IMAddressSection from './IMAddressSection';
import IMTableSection from './IMTableSection';
import UploadAddressModal from './modal/UploadAddressModal';
import UploadTableModal from './modal/UploadTableModal';

export default class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showUploadAddressModal: false,
            showUploadTableModal: false,
            pageNumber: 1
        };
    }

    componentDidMount() {
        const { getIMTables, getIMAddresses, getDownloadLink } = this.props;
        const { pageNumber } = this.state;
        getIMTables();
        getIMAddresses(pageNumber);
        getDownloadLink();
    }

    openUploadAddressModal = () => {
        this.setState({ showUploadAddressModal: true });
    }

    closeUploadAddressModal = (reload) => {
        this.setState({ showUploadAddressModal: false });
        if (reload) {
            const { getIMAddresses } = this.props;
            getIMAddresses(1);
            this.setState({ pageNumber: 1 });
        }
    }

    openUploadTableModal = () => {
        this.setState({ showUploadTableModal: true });
    }

    closeUploadTableModal = (data) => {
        if (data && data.totalCount) {
            const { imTables, setIMTables } = this.props;
            setIMTables([data, ...imTables]);
        }
        this.setState({ showUploadTableModal: false });
    }

    onChangePage = (_event, pageNumber) => {
        const { getIMAddresses } = this.props;
        getIMAddresses(pageNumber + 1);
        this.setState({ pageNumber: pageNumber + 1 });
    }

    render() {
        const {
            imTables, pagedData, downloadLink, user, history, loading
        } = this.props;
        const { showUploadAddressModal, showUploadTableModal } = this.state;
        const downloadLinkTemplate = `${downloadLink}?Authorization=${user.token}`;
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">IM Batch</Typography>
                    </Breadcrumbs>
                </Box>
                <Box component={Paper} p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            {loading && <CircularProgress color="primary" />}
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ float: 'right', margin: '.5rem' }}
                                endIcon={<PublishIcon>Upload</PublishIcon>}
                                onClick={this.openUploadAddressModal}
                            >
                                Upload File
                            </Button>
                            <IMAddressSection
                                onChangePage={this.onChangePage}
                                pagedData={pagedData}
                                openUpload={this.openUploadAddressModal}
                                downloadLinkTemplate={downloadLinkTemplate}
                                history={history}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <IMTableSection imTables={imTables} openUpload={this.openUploadTableModal} />
                        </Grid>
                    </Grid>
                </Box>

                <UploadAddressModal
                    open={showUploadAddressModal && !showUploadTableModal}
                    onClose={this.closeUploadAddressModal}
                    imTables={imTables}
                    onAddTable={this.openUploadTableModal}
                    downloadLinkTemplate={downloadLinkTemplate}
                />

                <UploadTableModal
                    open={showUploadTableModal}
                    onClose={this.closeUploadTableModal}
                />

            </Box>
        );
    }
}
