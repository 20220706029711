import {
    Box,
    Breadcrumbs, Button,
    Dialog, DialogActions, DialogContent, Divider, Fab, Grid,
    Link, Paper, TextField, Typography
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { addProductToCart } from 'pages/catalog/action';
import QuickviewModal from 'pages/catalog/QuickviewModal';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfirmPDFDownload from 'components/ConfirmPDFDownload';
import {
    addToFavorites,
    removeFromFavorites,
    logThinkAgentClick
} from './action';
import RelatedProducts from './RelatedProducts';

export const ProductView = ({
    product, goBack, productMeta, addProductToCart,
    favorites, addToFavorites, removeFromFavorites, limits, relatedProducts, token, logThinkAgentClick
}) => {
    const [qty, setQty] = useState(1);
    const [openQuickView, setOpenQuickView] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');
    const fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '';
    };
    const { customFields: { materialType } } = product;
    const limitValue = limits.monthlyItemLimits[product.code.toLowerCase()] || limits.monthlyLimits[`${materialType.toLowerCase()}_${product.marketName ?? 'Default'}`];

    const addToCart = () => {
        const {
            id, isKit, code, name, thumbnail, customFields: { materialType: itemType }
        } = product;
        const { used } = productMeta;

        addProductToCart({
            id,
            isKit,
            code,
            name,
            qty,
            customFields: {
                thumbnail,
                used,
                itemType,
                limit: limits.monthlyItemLimits[product.code.toLowerCase()] || limits.monthlyLimits[`${materialType.toLowerCase()}_${product.marketName ?? 'Default'}`],
                upperLimit: limits.upperLimits[`${materialType.toLowerCase()}_${product.marketName ?? 'Default'}`] || limits.upperLimits[`${materialType.toLowerCase()}_Default`],
                whs: product.whs,
                marketName: product.marketName,
                version: product.version,
                dp: product.dp,
                year: product.year,
                type: product.type,
                hold_IND: product.hold_IND
            }
        });
    };
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);

    const contractPBP = [];
    if (productMeta) {
        for (const cp of productMeta.contractPBP) {
            contractPBP.push(<p key={cp.counties}><b>{cp.contractPBP}</b>: {cp.counties} </p>);
        }
    }

    const clickThinkAgent = () => {
        logThinkAgentClick(product.code, product.name);
        window.open('https://app.thinkagent.com', '_blank');
    };

    const confirmDownload = (e, type, link) => {
        if (type.startsWith('KT')) {
            e.preventDefault();
            setPdfDownloadUrl(link);
            setOpenConfirm(true);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Button
                        onClick={goBack}
                    >

                        {'<< Back'}
                    </Button>
                    <Typography color="textPrimary">{product && product.name}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item align="center" xs={6}>
                {
                    relatedProducts && relatedProducts.length > 0 && (
                        <>
                            <Box style={{ textAlign: 'left' }}>

                                <Typography variant="h5">
                                    Suggested Items
                                </Typography>
                            </Box>
                            <RelatedProducts token={token} products={relatedProducts} limits={limits} addProductToCart={addProductToCart} />
                        </>
                    )
                }

            </Grid>
            <Grid item xs={6}>
                {product && (
                    <>
                        <Fab style={{ float: 'right' }} aria-label="add to favorite" size="small">
                            {favorites && favorites.includes(product.code)
                                ? <FavoriteIcon onClick={() => removeFromFavorites(product.code)} style={{ color: 'red' }} />
                                : <FavoriteIcon onClick={() => addToFavorites(product.code)} />}

                        </Fab>
                        <Typography variant="h4">
                            {product.name}
                        </Typography>
                        <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />
                        {productMeta && (
                            <>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography variant="h6">
                                            Information
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            Used: {productMeta.used} of {limitValue}
                                            &nbsp;(until {lastDay.toLocaleDateString()})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="outlined" color="primary" size="small" style={{ float: 'right', marginBottom: '5px' }} title="Click here to send an Electronic Sales kit via ThinkAgent">
                                            <Link onClick={() => clickThinkAgent()}>
                                                Send a Digital Kit
                                            </Link>
                                        </Button>
                                        <Button variant="outlined" color="primary" size="small" style={{ float: 'right' }}>
                                            <Link href={`${productMeta.downloadRoot}/${product.customFields.materialType}/${product.id}?Authorization=${token}`} target="_blank" onClick={(e) => confirmDownload(e, product.customFields.materialType, `${productMeta.downloadRoot}/${product.customFields.materialType}/${product.id}?Authorization=${token}`)}>
                                                Download PDF
                                            </Link>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {contractPBP && contractPBP.length > 0 && (
                                            <>
                                                <Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
                                                    Contract PBPs:
                                                </Typography>
                                                <Box>
                                                    {contractPBP}
                                                </Box>
                                            </>
                                        )}

                                    </Grid>
                                </Grid>
                                <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />
                            </>
                        )}
                        {
                            !product.downloadOnly && (

                                <Grid
                                    container
                                    spacing={4}
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <TextField
                                            id="outlined-qty"
                                            label="Qty"
                                            size="small"
                                            type="number"
                                            min={1}
                                            style={{ width: '6rem' }}
                                            value={qty}
                                            onChange={(e) => setQty(Math.max(1, parseInt(e.target.value, 10)))}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item>

                                        <Button
                                            type="button"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            onClick={addToCart}
                                        >
                                            ADD TO CART
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </>
                )}
            </Grid>
            {openQuickView && (
                <QuickviewModal
                    open={openQuickView}
                    closeQuickview={() => setOpenQuickView(false)}
                    quickviewPDF={product.sample}
                />
            )}
            <ConfirmPDFDownload
                open={openConfirm}
                onClose={() => { setOpenConfirm(false); }}
                setOpenConfirm={setOpenConfirm}
                pdfDownloadUrl={pdfDownloadUrl}
                setPdfDownloadUrl={setPdfDownloadUrl}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    productMeta: state.brokerView.productMeta,
    limits: state.brokerView.limits,
    relatedProducts: state.brokerView.relatedProducts,
    favorites: state.brokerView.favorites
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addProductToCart,
            addToFavorites,
            removeFromFavorites,
            logThinkAgentClick
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
