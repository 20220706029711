import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrders } from './action';
import ListView from './ListView';

const mapStateToProps = ({
    appView: { user }, ordersView
}) => {
    return { ...ordersView, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
