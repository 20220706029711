import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('IM_BATCH', {
    LOADING: null,
    SET_DOWNLOAD_LINK: null,
    SET_IM_TABLES: null,
    SET_IM_ADDRESSES: null
});

export default actions;

export const setLoading = actionCreator(actions.LOADING, 'loading');
export const setIMTables = actionCreator(actions.SET_IM_TABLES, 'data');
export const getIMTables = () => (dispatch) => {
    http.get('SU/p7/im-batch-table')
        .then((data) => dispatch(setIMTables(data)));
};

const setIMAddresses = actionCreator(actions.SET_IM_ADDRESSES, 'data');
export const getIMAddresses = (pageNumber) => (dispatch) => {
    setLoading(true);
    http.get(`SU/p7/im-batch-addresses/${pageNumber}`)
        .then((data) => dispatch(setIMAddresses(data)))
        .finally(() => setLoading(false));
};

const setDownloadLink = actionCreator(actions.SET_DOWNLOAD_LINK, 'data');
export const getDownloadLink = () => (dispatch) => {
    http.get('SU/p7/download-link')
        .then((data) => dispatch(setDownloadLink(data)));
};
