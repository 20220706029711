import cartActions from 'pages/cart/action';
import telesalesActions from './action';

export default (
    state = {
        loading: false,

        selectedState: '',
        listResult: null,
        error: null
    },
    action
) => {
    switch (action.type) {
        case telesalesActions.LOADING:
            return { ...state, loading: action.loading };
        case telesalesActions.ERROR:
            return { ...state, error: action.error };
        case telesalesActions.SET_STATE:
            return {
                ...state,
                selectedState: action.state
            };
        case telesalesActions.SET_LIST_RESULT:
            return { ...state, listResult: action.result };
        case cartActions.EMPTY_CART:
            return { ...state, listResult: null };
        default:
            return state;
    }
};
