import {
    Box,
    CircularProgress,
    TableCell, TableRow
} from '@material-ui/core';
import React from 'react';

export default function LoadingTableRow({ loading }) {
    if (loading) {
        return (
            <TableRow>
                <TableCell colSpan={20} style={{ textAlign: 'center' }}>
                    <Box m={2}>
                        <CircularProgress color="primary" />
                    </Box>
                </TableCell>
            </TableRow>
        );
    }
    return null;
}
