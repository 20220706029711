import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    categoryLink: {
        textDecoration: 'none'
    }
}));

function BuildListItem(currentCode, openId, toggleOpen, classes, items, level = 0, user) {
    const sortedItem = [...items];

    let hit = false;
    
    if(user !== null && user && user.customFields && user.customFields.p2_Catalog && user.customFields.p2_Catalog.length > 0 ) {
        if (sortedItem && sortedItem.length > 0) {

            const sortByName = sortedItem.every(x => x.displayOrder === sortedItem[0].displayOrder);

            sortedItem.sort((l, r) => {
                if(!sortByName) {
                    if (l.displayOrder < r.displayOrder) return -1;
                }
                else {
                    if (l.code < r.code) return -1;
                }
                return 1;
            });
        }   
    } else {
        if (sortedItem && sortedItem[0] && sortedItem[0].name !== 'Enrollment Kits for Paper Submission') {
            sortedItem.sort((l, r) => {
                if (l.name === 'Enrollment Kits for Paper Submission') {
                    hit = true;
                    return -10;
                }
                if (l.displayOrder < r.displayOrder) return -1;
                if (l.code < r.code) return -1;
                return 1;
            });
        }
    }
    
    const ss = [...sortedItem];
    return (
        <>
            {ss.map((c) => (
                <React.Fragment key={c.code}>
                    <ListItem button selected={currentCode === c.code} onClick={() => toggleOpen(c.id || c.code)} style={{ paddingLeft: `${level * 2 + 0.5}rem` }}>
                        <Link
                            to={`/catalog/${c.code}`}
                            underline="none"
                            color="primary"
                            className={classes.categoryLink}
                        >
                            <ListItemText
                                color="primary"
                                primary={<Typography type="body2" style={{ color: '#7d3f98' }}>{c.name}</Typography>}
                            />
                        </Link>
                    </ListItem>
                    {
                        ((c.children && c.children.length > 0) || (c.inverseParent && c.inverseParent.length > 0)) && (
                            <Collapse in={openId.includes(c.id || c.code)} timeout="auto" unmountOnExit>
                                {BuildListItem(currentCode, openId, toggleOpen, classes, c.children || c.inverseParent, level + 1, user)}
                            </Collapse>
                        )
                    }
                </React.Fragment>
            ))}
        </>
    );
}

export default function Category(props) {
    const [openId, setOpenId] = React.useState([]);
    const { categoryTree, categoryCode: currentCode, user } = props;
    const classes = useStyles();

    const toggleOpen = (id) => {
        let newId;
        if (openId.includes(id)) {
            newId = openId.filter((o) => o !== id);
        } else {
            newId = [...openId, id];
        }

        setOpenId(newId);
    };

    return (
        <List
            component="nav"
            dense
            aria-labelledby="nested-list-subheader"
            style={{ maxHeight: '45rem', overflow: 'auto' }}
            subheader={(
                <ListSubheader component="div" id="nested-list-subheader">
                    Category
                </ListSubheader>
            )}
            className={classes.root}
        >
            {BuildListItem(currentCode, openId, toggleOpen, classes, categoryTree, 0, user)}
        </List>
    );
}
