import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import actionCreator from 'utils/actionCreator';

export const actions = keyMirror('BOM_VIEW', {
    LOADING: null,
    ERROR: null,
    SET_LIST_RESULT: null,
    SET_STATE: null
});

export default actions;

export const setLoading = actionCreator(actions.LOADING, 'loading');
export const setError = actionCreator(actions.ERROR, 'error');
export const setState = actionCreator(actions.SET_STATE, 'state');

export const setListResult = actionCreator(actions.SET_LIST_RESULT, 'result');
export const loadMaterials = (state, pageNumber) => (dispatch, getState) => {
    dispatch(setLoading(true));
    return http.get(`smallgroup/materials/${state}/${pageNumber}/24`).then((data) => {
        dispatch(setListResult(data));
        dispatch(setLoading(false));
    });
};
