import React, { useState } from 'react';
import {
    Paper, Fab
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FallbackSrc from 'utils/fallbackSrc';
import QuickviewModal from '../QuickviewModal';

export default function MaterialMedia({ newThumbnail, productDetail, user }) {
    const [open, setOpen] = useState(false);
    const openQuickViewClick = () => {
        setOpen(true);
    };

    const closeQuickView = () => {
        setOpen(false);
    };
    if (!productDetail) return null;

    let bigThumb = productDetail.sample;
    if (bigThumb) {
        bigThumb += `?Authorization=${user.token}`;
    }

    return (
        <Paper style={{ overflow: 'hidden', position: 'relative' }}>
            {newThumbnail || productDetail.thumbnail
                ? (
                    <img
                        src={newThumbnail || productDetail.thumbnail}
                        onError={FallbackSrc}
                        style={{ width: (productDetail && productDetail.code && productDetail.code.startsWith('customizable_banner_ad') ? 'auto' : '100%') }}
                        alt={productDetail.name}
                    />
                )
                : <img src="https://cdndev.memberdoc.com/f/img_no_thumb.jpg" alt={productDetail.name} />}

            <div style={{ position: 'absolute', right: '0', top: '0' }}>

                {productDetail.sample
                    && (
                        <div style={{ margin: '.5rem' }}>
                            <Fab color="primary" style={{ float: 'right' }} aria-label="add" size="small">
                                <VisibilityIcon onClick={openQuickViewClick} />
                            </Fab>
                        </div>
                    )}
            </div>

            <QuickviewModal
                open={open}
                closeQuickview={closeQuickView}
                quickviewPDF={bigThumb}
            />
        </Paper>
    );
}
