import orderActions from './action';

export default (
    state = {
        loading: false,
        orders: null,
        saves: null,
        templates: null,
        criteria: {
            orderNumber: '',
            soNumber: '',
            itemCode: '',
            recipientFirstName: '',
            recipientMiddleName: '',
            recipientLastName: '',
            customerName: '',
            program: '',
            jobType: '',
            userId: '',
            npn: '',
            startDate: null,
            endDate: null,
            shipByDate: null
        },
        pageNumber: 1,
        savedCriteria: {
            orderNumber: '',
            recipientFirstName: '',
            recipientMiddleName: '',
            recipientLastName: '',
            fileNumber: '',
            customerName: '',
            itemCode: '',
            startDate: null,
            endDate: null
        },
        savedPageNumber: 1
    },
    action
) => {
    switch (action.type) {
        case orderActions.LOADING:
            return { ...state, loading: action.loading };

        case orderActions.SET_ORDERS:
            return { ...state, orders: action.orders };

        case orderActions.SET_SAVES:
            return { ...state, saves: action.saves };

        case orderActions.SET_PAGE_NUMBER:
            return { ...state, pageNumber: action.pageNumber };

        case orderActions.SET_CRITERIA:
            return { ...state, criteria: { ...state.criteria, [action.name]: action.value } };

        case orderActions.SET_SAVED_PAGE_NUMBER:
            return { ...state, savedPageNumber: action.pageNumber };

        case orderActions.SET_SAVED_CRITERIA:
            return { ...state, savedCriteria: { ...state.savedCriteria, [action.name]: action.value } };

        case orderActions.SET_TEMPLATES:
            return { ...state, templates: action.templates };

        default:
            return state;
    }
};
