import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteTrak, getMeta, getTrakUsers, restoreTrak, saveTrak } from './action';
import ListView from './ListView';

const mapStateToProps = ({ trakView, appView: { user } }) => {
    return { ...trakView, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            restoreTrak, deleteTrak, getTrakUsers, saveTrak, getMeta
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
