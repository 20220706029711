import {

    Link, Paper,
    Table, TableBody, TableCell, TableContainer, TableFooter,
    TableHead, TablePagination, TableRow, Tooltip,

    Typography
} from '@material-ui/core';
import React from 'react';

export default function IMAddressSection({
    onChangePage,
    openUpload, downloadLinkTemplate, history,
    pagedData
}) {
    const searchOrder = (fileNumber) => {
        history.push({
            pathname: '/order-management',
            state: { fileNumber }
        });
    };
    return (
        <>

            <Typography variant="h6">IM Batch Address Files</Typography>
            <TableContainer component={Paper} square>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>File Number</TableCell>
                            <TableCell>File Name</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Uploaded On</TableCell>
                            <TableCell>Warning File</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(pagedData && !pagedData.results.length) && (
                            <TableRow>
                                <TableCell colSpan={20}>
                                    <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}>
                                        No IM Batch Address uploaded yet
                                    </span>
                                </TableCell>
                            </TableRow>
                        )}
                        {pagedData && pagedData.results.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {row.reportId
                                        ? (
                                            <Tooltip title="Search orders">
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => searchOrder(row.id)}
                                                >
                                                    {row.id}
                                                </Link>
                                            </Tooltip>
                                        )
                                        : row.id}

                                </TableCell>
                                <TableCell>{row.fileName}</TableCell>
                                <TableCell>{row.totalCount}</TableCell>
                                <TableCell>{row.createdOn}</TableCell>
                                <TableCell>{row.reportId
                                    ? (
                                        <a target="_blank" href={downloadLinkTemplate.replace('{id}', row.reportId)}>IM Warning File</a>
                                    )
                                    : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={20}>
                                {pagedData && (
                                    <TablePagination
                                        component="div"
                                        count={pagedData.totalCount}
                                        page={pagedData.pageNumber - 1}
                                        rowsPerPage={pagedData.pageSize}
                                        rowsPerPageOptions={[pagedData.pageSize]}
                                        onChangePage={onChangePage}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}
