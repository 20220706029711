import passwordActions from './action';

export default (
    state = {
        loading: false,
        settings: null,
        message: null,
        userGroups: null
    },
    action
) => {
    switch (action.type) {
        case passwordActions.LOADING:
            return { ...state, loading: action.loading };

        case passwordActions.SET_MESSAGE:
            return { ...state, message: action.message };

        case passwordActions.SET_SETTINGS:
            return { ...state, settings: action.settings };

        case passwordActions.SET_USER_GROUPS:
            return { ...state, userGroups: action.userGroups };

        default:
            return state;
    }
};
