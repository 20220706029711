import {
    Box, Breadcrumbs,
    Grid,
    Link, Paper,
    Typography
} from '@material-ui/core';
import React from 'react';

export default function AccountContainer(props) {
    const { name, children } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">{name}</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>
            <Grid item xs={12} component={Paper}>
                {children}
            </Grid>

        </Grid>
    );
}
