const actionCreator = (type, ...argNames) => {
    return (...args) => {
        const action = { type };
        argNames.forEach((arg, i) => {
            action[argNames[i]] = args[i];
        });

        return action;
    };
};

export default actionCreator;
