const keyMirror = (prefix, obj) => {
    const mirror = {};
    let key;
    if (!(obj instanceof Object && !Array.isArray(obj))) {
        throw new Error('keyMirror(...): Argument must be an object.');
    }
    for (key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            mirror[key] = `${prefix}_${key}`;
        }
    }
    return mirror;
};

export default keyMirror;
