import { addProductToCart } from 'components/basket/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import ListView from './ListView';

const mapStateToProps = ({
    smallGroupView,
    appView: { settings },
    cartView: { basketItems }

}) => ({
    ...smallGroupView, settings, basketItems
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions, addProductToCart
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
