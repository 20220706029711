import {
    Box, Checkbox, Grid, Paper, Typography
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import CountyBar from './CountyBar';
import BrokerSelect from './EditBrokerSelect';
import FilterBar from './FilterBar';
import MessageBoard from './MessageBoard';
import TableView from './ProductTableView';
import ProductDetailView from './ProductView';
import SelectionBar from './SelectionBar';

export default class BrokerView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            product: null
        };
    }

    componentDidMount() {
        const {
            setSelectionOpen, selection, filters, getFilters, favorites, getFavorites, limits, getLimits
        } = this.props;
        if (!selection) {
            setSelectionOpen(true);
        }
        if (!filters) {
            getFilters();
        }
        if (!favorites) {
            getFavorites();
        }
        if (!limits) {
            getLimits();
        }
    }

    onSelectBarChange = () => {
        const {
            setSelectionOpen: openSelection
        } = this.props;
        openSelection(true);
    }

    onToggleFilter = (filterCode) => {
        const { setFilters: saveFilters, filters, loadProducts } = this.props;
        const changed = filters.find((f) => f.code === filterCode);
        changed.selected = !changed.selected;
        saveFilters([...filters]);
        loadProducts();
    }

    onToggleCounties = (county) => {
        const { loadProducts, countyList, setCounties } = this.props;
        // console.log('BrokerView-onToggleCounties: ', county);
        // console.log('BrokerView-onToggleCounties: ', countyList);
        if (county === '') {
            countyList.map((c) => {
                c.selected = c.name === county;
                return c;
            });
        } else {
            const changed = countyList.find((f) => f.name === county);
            const countyListAll = countyList.find((f) => f.name === '');
            changed.selected = !changed.selected;
            countyListAll.selected = false;
        }
        setCounties(countyList);
        loadProducts();
    }

    setSelectedCounty = (county) => {
        const { countyList, setCounties } = this.props;
        // console.log('selected county: ', county);
        if (countyList) {
            countyList.forEach((c) => {
                const changed = c;
                changed.selected = (changed.name === county);
            });
            // console.log('setSelectedCounty: ', countyList);
            setCounties(countyList);
        }
    }

    onSelectionChanged = (selection) => {
        const { saveSelection: save, loadProducts } = this.props;
        save(selection);
        loadProducts();
    }

    openProduct = (product) => {
        this.setState({ product });
        const { getProductMeta, getRelatedProducts, selection } = this.props;
        getProductMeta(product.code, product.version, product.whs);
        getRelatedProducts(product.id, product.itemType, selection.state, selection.language);
    }

    closeProduct = () => {
        this.setState({ product: null });
    }

    toggleFavoritesOnly = () => {
        const { setFavoritesOnly: toggle, favoritesOnly, loadProducts } = this.props;
        toggle(!favoritesOnly);
        loadProducts();
    }

    render() {
        const { product } = this.state;
        const {
            editSelection, broker, selection, filters, settings, favoritesOnly, setCounties, countyList
        } = this.props;
        if (product) {
            return <ProductDetailView product={product} goBack={this.closeProduct} token={broker.token} />;
        }

        let brokerMessage;
        if (settings) {
            if (settings.messageBoard && settings.messageBoard.length) {
                [brokerMessage] = settings.messageBoard;
            } else if (settings.useDefaultMsg) {
                brokerMessage = 'Welcome to our new Broker Ordering Module (BOM)! Our new ordering portal is designed to be easier and more efficient to use. You can learn the new features of our ordering portal by downloading our User Guide from the Need Help page by clicking the question mark icon.';
            }
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Box m={2} component={Paper}>
                        {!editSelection && filters && <FilterBar filters={filters} onToggle={this.onToggleFilter} />}
                    </Box>
                    <Box m={2} component={Paper} style={{ maxHeight: '400px', overflow: 'auto' }}>
                        {!editSelection && countyList && <CountyBar counties={countyList} onToggle={this.onToggleCounties} />}
                    </Box>
                    {!editSelection && (
                        <Box m={2} p={2} component={Paper}>
                            <Box>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    checked={favoritesOnly}
                                    color="primary"
                                    onChange={this.toggleFavoritesOnly}
                                />
                                Favorites
                            </Box>
                        </Box>
                    )}
                    <Box m={2} p={2} component={Paper}>
                        {brokerMessage && <MessageBoard content={brokerMessage} />}
                    </Box>
                    <Box m={2} p={2} component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            <BuildIcon /> Don&apos;t Forget!
                        </Typography>
                        <Typography variant="body1">
                            Click on the Need Help icon to download our new User Guide.
                        </Typography>
                    </Box>
                    <Box m={2} p={2} component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            <ShoppingBasketIcon /> Order Status
                        </Typography>
                        <Typography variant="body1">
                            Here are your saved or pending orders awaiting completion:
                        </Typography>
                        <Box align="center">
                            <Link
                                to="/orders"
                                underline="none"
                                color="primary"
                            >
                                Order History
                            </Link>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={12}>
                            {broker && editSelection && broker.customFields.brokerMeta && (
                                <BrokerSelect
                                    meta={broker.customFields.brokerMeta}
                                    stateLang={broker.customFields.stateLang}
                                    saveBrokerSelection={this.onSelectionChanged}
                                    selection={selection}
                                    setCounties={setCounties}
                                    countyList={countyList}
                                    selectCounty={this.setSelectedCounty}
                                />
                            )}
                            {broker && !editSelection && selection && (
                                <SelectionBar
                                    selection={selection}
                                    onChangeClick={this.onSelectBarChange}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            {!editSelection && selection && <TableView openProduct={this.openProduct} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
