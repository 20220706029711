import React, { PureComponent } from 'react';

export default class Passthrough extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { passthrough, match: { params: { salt, token } } } = this.props;

        if (token && token !== 'undefined') {
            passthrough(salt, token);
        }
    }

    render() {
        const { error } = this.props;
        const createMarkup = (cf) => { return { __html: cf }; };

        let msg = 'LOADING...';
        if (error) {
            msg = 'ACCESS DENIED';
        }
        return (
            <div dangerouslySetInnerHTML={createMarkup(msg)} />
        );
    }
}
