import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import { setKitItems, addKitItems } from 'pages/direct-order/action';

export const actions = keyMirror('CART_VIEW', {
    LOADING: null,
    SET_BASKET_ITEMS: null,
    UPDATE_ITEM_QTY: null,
    REMOVE_ITEM: null,
    SET_USED: null,
    SET_PRICING: null,
    SET_REFRESHED: false,
    EMPTY_CART: null
});

export default actions;

export const setRefreshed = actionCreator(actions.SET_REFRESHED, 'refreshed');
const setLoading = actionCreator(actions.LOADING, 'loading');

const setPricing = actionCreator(actions.SET_PRICING, 'pricing');
export const getPricing = () => (dispatch, getState) => {
    const current = getState().cartView.pricing;
    if (current) return;

    http.get('store/get-pricing')
        .then((pricing) => {
            dispatch(setPricing(pricing));
        });
};

export const setBasketItems = actionCreator(actions.SET_BASKET_ITEMS, 'basketItems');

export const getBasketItems = () => (dispatch) => {
    dispatch(setLoading(true));
    http.get('store/get-basket-items')
        .then(({ basketItems, usage }) => {
            for (const bi of basketItems) {
                if (bi.customFields && typeof bi.customFields === 'string') {
                    bi.customFields = JSON.parse(bi.customFields);
                }
            }
            dispatch(setBasketItems(basketItems));
            const u = {};
            if (usage) {
                for (const k in usage) {
                    u[k.toLowerCase()] = usage[k];
                }
            }
            dispatch(setUsed(u));
            dispatch(setRefreshed(true));
        }).catch(() => dispatch(setBasketItems([]))).finally(() => dispatch(setLoading(false)));
};

const setUsed = actionCreator(actions.SET_USED, 'used');

const setBasketItemQty = actionCreator(actions.UPDATE_ITEM_QTY, 'lineId', 'qty');
export const updateBasketItemQty = (lineId, qty) => (dispatch) => {
    dispatch(setBasketItemQty(lineId, qty));
    dispatch(setLoading(true));
    http.get(`catalog/update-qty/${lineId}/${qty}`)
        .then(() => {
            // dispatch(setBasketItemQty(lineId, qty));
        }).finally(() => dispatch(setLoading(false)));
};

const setBasketSubItemQty = actionCreator(actions.UPDATE_SUB_ITEM_QTY, 'lineId', 'itemId', 'qty');

const deleteBasketItem = actionCreator(actions.REMOVE_ITEM, 'lineIds');
export const removeBasketItem = (lineIds) => (dispatch) => {
    dispatch(setLoading(true));
    http.post('catalog/remove-basket-item', lineIds)
        .then(() => {
            dispatch(deleteBasketItem(lineIds));
            dispatch(setRefreshed(true));
        }).finally(() => dispatch(setLoading(false)));
};

export const emptyCart = actionCreator(actions.EMPTY_CART);

export const saveItemsForLater = (savedName, lineIds) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    fd.append('name', savedName);
    fd.append('lineIds', lineIds);
    http.post('catalog/save-items-for-later', fd)
        .then(() => {
            dispatch(getBasketItems());
        }).finally(() => dispatch(setLoading(false)));
};

export const saveBasketItemCustomFields = (lineId, customFields, gotoCart) => (dispatch) => {
    http.post(`catalog/edit-basket-item/${lineId}`, customFields)
        .then(() => {
            dispatch(getBasketItems());
            if (gotoCart) history.push('/cart');
        });
};

export const duplicateBasketItem = (lineId) => (dispatch) => {
    http.get(`catalog/duplicate-basket-item/${lineId}`)
        .then(() => {
            dispatch(getBasketItems());
        });
};

export const validateBasketItems = () => (dispatch) => {
    http.get('catalog/validate-basket-items')
        .then(() => {
            dispatch(getBasketItems());
        });
};

export const setItems = (items) => (dispatch) => {
    dispatch(setKitItems(items));
};

// export const getSmallGroupItems = (lineId, brand, planId, state, jobItems) => (dispatch) => {
//     let newItems = jobItems ? jobItems.filter((x) => x.lineId !== lineId) : [];
//     const formData = new FormData();
//     formData.append('brand', brand);
//     formData.append('planId', planId);
//     formData.append('state', state);

//     http.post('catalog/search-small-group-items', formData)
//         .then((ii) => {
//             let items = ii;
//             for (const item of items) {
//                 item.lineId = lineId;
//                 item.selected = true;
//                 item.requiredCode = 'R';
//                 item.quantity = 1;
//             }
//             newItems = newItems.concat(items);
//             dispatch(setKitItems(newItems));
//             // setItems(newItems);
//         });
// };

export const getSmallGroupItems = (itemPlans, basketPlanDocs) => async (dispatch) => {
    let cartItems = [];
    for (let i = 0; i < itemPlans.length; i += 1) {
        const { lineId, brand, plans, state } = itemPlans[i];

        for (let j = 0; j < plans.length; j += 1) {
            const formData = new FormData();
            formData.append('brand', brand);
            formData.append('planId', plans[j]);
            formData.append('state', state);

            await http.post('catalog/search-small-group-items', formData)
                .then((ii) => {
                    const items = ii;
                    for (let k = 0; k < items.length; k += 1) {
                        if (!cartItems.find(x => x.cvw === items[k].cvw) && !(basketPlanDocs.find(x => x.code === items[k].code && x.version === items[k].version))) {
                            items[k].lineId = lineId;
                            items[k].selected = true;
                            items[k].requiredCode = 'R';
                            items[k].quantity = 1;

                            cartItems.push(items[k]);
                        }
                    }
                });
        }
    }

    dispatch(setKitItems(cartItems));
};