import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('BANNER_MESSAGE_VIEW', {
    LOADING: null,
    SAVING: null,
    SET_PAGED_DATA: null,
    SET_OPEN_EDITOR: null,
    SET_EDITING_ITEM: null,
    SET_DELETING_ID: null,
    SET_CRITERIA: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
const setSaving = actionCreator(actions.SAVING, 'saving');
export const setOpenEditor = actionCreator(actions.SET_OPEN_EDITOR, 'openEditor');
export const setEditingItem = actionCreator(actions.SET_EDITING_ITEM, 'editingItem');
export const setCriteria = actionCreator(actions.SET_CRITERIA, 'criteria');
export const setDeletingId = actionCreator(actions.SET_DELETING_ID, 'deletingId');

const setPagedData = actionCreator(actions.SET_PAGED_DATA, 'pagedData');

export const loadData = (pageNumber) => (dispatch, getState) => {
    const { criteria } = getState().bannerMessageView;
    dispatch(setLoading(true));
    http.post(`su/banner-message/${pageNumber}/10`, criteria)
        .then((data) => dispatch(setPagedData(data)))
        .finally(() => dispatch(setLoading(false)));
};

export const saveMessage = (bannerMessage) => (dispatch, getState) => {
    dispatch(setSaving(true));
    http.post('su/banner-message/save', bannerMessage)
        .then((data) => {
            dispatch(setOpenEditor(false));
            dispatch(setEditingItem(null));
            dispatch(loadData(1));
        })
        .finally(() => dispatch(setSaving(false)));
};

export const deleteMsg = (id) => (dispatch, getState) => {
    dispatch(setSaving(true));
    http.post(`su/banner-message/delete/${id}`)
        .then((data) => {
            dispatch(setDeletingId(null));
            dispatch(loadData(1));
        })
        .finally(() => dispatch(setSaving(false)));
};
