import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateCustomizeComponent from 'components/customize/CreateCustomizeComponent';
import React, { Suspense } from 'react';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: 720,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eddd',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const EditModal = ({
    customizeCode, crosswalk, open, onClose, lineId, saveEditCrosswalk, outputType
}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    const CustomizeComponent = CreateCustomizeComponent(customizeCode);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Suspense fallback={<div>Loading...</div>}>
                    <CustomizeComponent
                        crosswalk={crosswalk}
                        open
                        onClose={onClose}
                        customizeCode={customizeCode}
                        lineId={lineId}
                        saveCrosswalk={saveEditCrosswalk}
                        setGenerating={() => { }}
                        outputType={outputType}
                    />
                </Suspense>
            </div>
        </Modal>
    );
};
export default EditModal;
