import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAddresses, saveAddress } from './action';
import ListView from './ListView';

const mapStateToProps = ({ addressesView: { addresses } }) => {
    return { addresses };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAddresses, saveAddress
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
