import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('ORDER_MGMT', {
    LOADING: null,
    SET_SAVES: null,
    SET_CRITERIA: null,
    SET_PAGE_NUMBER: null,
    SET_SAVED_CRITERIA: null,
    SET_SAVED_PAGE_NUMBER: null,
    SET_ORDERS: null,
    SET_TEMPLATES: null
});

export default actions;

export const setCriteria = actionCreator(actions.SET_CRITERIA, 'name', 'value');
export const setPageNumber = actionCreator(actions.SET_PAGE_NUMBER, 'pageNumber');
export const setSavedCriteria = actionCreator(actions.SET_SAVED_CRITERIA, 'name', 'value');
export const setSavedPageNumber = actionCreator(actions.SET_SAVED_PAGE_NUMBER, 'pageNumber');
const setLoading = actionCreator(actions.LOADING, 'loading');

const setOrders = actionCreator(actions.SET_ORDERS, 'orders');
export const getOrders = (pageNumber, pageSize, criteria) => (dispatch) => {
    const fixed = criteria = {
        ...criteria,
        startDate: criteria.startDate ? criteria.startDate.toLocaleDateString() : '',
        endDate: criteria.endDate ? criteria.endDate.toLocaleDateString() : '',
        shipByDate: criteria.shipByDate ? criteria.shipByDate.toLocaleDateString() : ''
    };
    dispatch(setLoading(true));
    dispatch(setPageNumber(pageNumber));
    const fd = new FormData();
    if (criteria) {
        for (const k in fixed) {
            fd.append(k, (fixed[k] || '').trim());
        }
    }
    http.post(`SU/orders/${pageNumber}/${pageSize}`, fd)
        .then((data) => dispatch(setOrders(data)))
        .finally(() => dispatch(setLoading(false)));
};

const setSaves = actionCreator(actions.SET_SAVES, 'saves');
export const getSaves = (pageNumber, pageSize, criteria) => (dispatch) => {
    const fixed = criteria = {
        ...criteria,
        startDate: criteria.startDate ? criteria.startDate.toLocaleDateString() : '',
        endDate: criteria.endDate ? criteria.endDate.toLocaleDateString() : '',
        shipByDate: criteria.shipByDate ? criteria.shipByDate.toLocaleDateString() : '',
        isTemplate: 'false'
    };
    console.log("savedorders fixed criteria: ", fixed);
    dispatch(setLoading(true));
    dispatch(setSavedPageNumber(pageNumber));
    const fd = new FormData();
    if (criteria) {
        for (const k in fixed) {
            fd.append(k, (fixed[k] || '').trim());
        }
    }
    http.post(`SU/saves/${pageNumber}/${pageSize}`, fd)
        .then((data) => dispatch(setSaves(data)))
        .finally(() => dispatch(setLoading(false)));
};

const setTemplates = actionCreator(actions.SET_TEMPLATES, 'templates');
export const getTemplates = (pageNumber, pageSize, criteria) => (dispatch) => {
    const fixed = criteria = {
        ...criteria,
        startDate: criteria.startDate ? criteria.startDate.toLocaleDateString() : '',
        endDate: criteria.endDate ? criteria.endDate.toLocaleDateString() : '',
        shipByDate: criteria.shipByDate ? criteria.shipByDate.toLocaleDateString() : '',
        isTemplate: 'true'
    };
    console.log("savedorders fixed criteria: ", fixed);
    dispatch(setLoading(true));
    dispatch(setSavedPageNumber(pageNumber));
    const fd = new FormData();
    if (criteria) {
        for (const k in fixed) {
            fd.append(k, (fixed[k] || '').trim());
        }
    }
    http.post(`SU/saves/${pageNumber}/${pageSize}`, fd)
        .then((data) => dispatch(setTemplates(data)))
        .finally(() => dispatch(setLoading(false)));
};
