import { Box, Grid } from '@material-ui/core';
import Category from 'components/Category';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCategoryTree } from './action';
import DetailView from './detail';
import ListView from './ListView';

class CatalogView extends PureComponent {
    componentDidMount() {
        const {
            categoryTree,
            getCategoryTree: loadTree,
            match: {
                params: {
                    product_code: productCode,
                    category_code: categoryCode
                }
            }
        } = this.props;
        if (!productCode && (!categoryCode || categoryCode === 'dynamic-oe-support-materials')) { // && !categoryTree
            loadTree();
        }
    }

    render() {
        const {
            categoryTree,
            history,
            products,
            user,
            match: {
                params: {
                    category_code: category,
                    product_code: productCode,
                    p2_id, product_type,
                    lineId
                }
            }
        } = this.props;
        const prodData = productCode && products && products.results && products.results.length > 0
            ? products.results.find((p) => p.code === productCode)
            : null;

        return (
            <>
                <Box display={productCode ? 'block' : 'none'}>
                    <Grid container spacing={2}>
                        {productCode && (
                            <DetailView
                                history={history}
                                category={category}
                                product={prodData}
                                productCode={productCode}
                                p2_id={p2_id}
                                product_type={product_type}
                                lineId={lineId}
                            />
                        )}
                    </Grid>
                </Box>
                <Box display={!productCode ? 'block' : 'none'}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Category categoryTree={categoryTree || []} categoryCode={category} user={user} />
                        </Grid>
                        <Grid item xs={9} style={{ padding: '.5rem' }}>
                            <ListView categoryCode={category} />
                        </Grid>
                    </Grid>

                </Box>
            </>
        );
    }
}

const mapStateToProps = ({ catalogView: { categoryTree, products }, appView: { user } }) => ({
    categoryTree, products, user
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getCategoryTree
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogView);
