import React from 'react';

const JobInfo = React.lazy(() => import('./JobInfo'));
const SelectCustomer = React.lazy(() => import('./SelectCustomer'));
const SelectItems = React.lazy(() => import('./SelectItems'));
const SelectScreen = React.lazy(() => import('./SelectScreen'));
const SelectShippingAddress = React.lazy(() => import('./SelectShippingAddress'));

const EmptyComp = () => {
    return (<div />);
};

export default function BuildSummary(stepName, active) {
    if (active) return EmptyComp;
    switch (stepName) {
        case 'Select Customer':
            return SelectCustomer;
        case 'Shipping Address':
            return SelectShippingAddress;
        case 'Select Attributes':
            return SelectScreen;
        case 'Select Items':
            return SelectItems;
        case 'Job Information':
            return JobInfo;
        default:
            return EmptyComp;
    }
}
