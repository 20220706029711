import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('SAVED_JOB_VIEW', {
    LOADING: null,
    SET_CRITERIA: null,

    SET_SAVED_JOB: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

export const saveCriteria = actionCreator(actions.SET_CRITERIA, 'criteria');

const setSavedJobs = actionCreator(actions.SET_SAVED_JOB, 'savedJobs');
export const getSavedJobs = (pageNumber, pageSize, orderId, fileNumber, firstName, lastName) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    if (orderId) {
        fd.append('orderId', orderId);
    }
    if (fileNumber) {
        fd.append('fileNumber', fileNumber);
    }
    if (firstName) {
        fd.append('firstName', firstName);
    }
    if (lastName) {
        fd.append('lastName', lastName);
    }
    http.post(`customer/saved-jobs/${pageNumber}/${pageSize}`, fd)
        .then((data) => {
            for (const job of data.results) {
                if (job.customFields && job.customFields.includes("{")) {
                    job.customFields = JSON.parse(job.customFields);
                }
            }

            dispatch(setSavedJobs(data));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const getSavedTemplates = (pageNumber, pageSize, orderId, fileNumber, firstName, lastName) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    if (orderId) {
        fd.append('orderId', orderId);
    }
    if (fileNumber) {
        fd.append('fileNumber', fileNumber);
    }
    if (firstName) {
        fd.append('firstName', firstName);
    }
    if (lastName) {
        fd.append('lastName', lastName);
    }
    http.post(`customer/saved-templates/${pageNumber}/${pageSize}`, fd)
        .then((data) => {
            for (const job of data.results) {
                if (job.customFields && job.customFields.includes("{")) {
                    job.customFields = JSON.parse(job.customFields);
                }
            }

            dispatch(setSavedJobs(data));
        })
        .finally(() => dispatch(setLoading(false)));
};
