import {
    Box, Button,
    Grid, IconButton, InputAdornment,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import LoadingTableRow from 'components/LoadingTableRow';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';
import Shipping from './Shipping';

export default function ShippingAddress({
    orderId, jobType, dateReq, so, lob
}) {
    const [loading, setLoading] = useState(false);
    const [fullPageCount, setFullPagedCount] = useState(null);
    const [pagedData, setPagedData] = useState(null);
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [soNumber, setSONumber] = useState(so || '');
    const [shippingData, setShippingData] = useState(null);
    const [showShipping, setShowShipping] = useState(false);

    const onChangePage = (_event, pn) => {
        setLoading(true);
        http.post(`store/order-shipping/${orderId}/${pn}`, { name, company, soNumber }).then((pd) => {
            setPagedData(pd);
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        const loadShippings = () => {
            setLoading(true);
            http.post(`store/order-shipping/${orderId}/0`, { soNumber }).then((pd) => {
                setPagedData(pd);
                console.log('#ODS# pd:', pd);
                if (fullPageCount === null) {
                    setFullPagedCount(pd.pageCount);
                }
            }).finally(() => setLoading(false));
        };

        loadShippings();
        return () => { };
    }, [company, orderId]);

    const handleSearch = () => {
        onChangePage(null, 1);
    };

    const openShipping = (d) => {
        setShippingData(d);
        setShowShipping(true);
    };

    const getDeliveryDate = (lob, jobType, a) => {
        if (a.deliveryDueDate) {
            if (lob === 'p1a') {
                // if (!(jobType === 'FJE' || jobType === 'IM') && a.orderShippingItem[0].navShippingDate) {
                //     return new Date(a.deliveryDueDate).toLocaleDateString();
                // }
                return '';
            }
            else if (lob === 'p1b') {
                if (!(jobType === 'FJE' || jobType === 'IM')) {
                    return new Date(a.deliveryDueDate).toLocaleDateString();
                }
            }
            else {
                return new Date(a.deliveryDueDate).toLocaleDateString();
            }
        }
        return '';
    }

    let maxDDD = null;
    if (!(jobType === 'FJE' || jobType === 'IM') && pagedData && pagedData.results.length > 0) {
        for (const a of pagedData.results) {
            if (a.deliveryDueDate && (maxDDD === null || new Date(a.deliveryDueDate) > new Date(maxDDD))) {
                maxDDD = a.deliveryDueDate;
            }
        }
    }
    if (maxDDD) {
        maxDDD = new Date(maxDDD).toLocaleDateString();
    } else {
        maxDDD = '';
    }

    const soPagedData = [];
    if (pagedData && pagedData.results && pagedData.results.length > 0) {
        for (const os of pagedData.results) {
            for (const osi of os.orderShippingItem) {
                if (osi.navOrderNumber && !soPagedData.some(spd => spd.navOrderNumber === osi.navOrderNumber)) {
                    soPagedData.push({ ...os, navOrderNumber: osi.navOrderNumber });
                }
            }
        }
    }

    if (soPagedData.length > 0) {
        soPagedData.forEach((s) => s.orderShippingItem.sort((a, b) => {
            return new Date(a.navShippingDate) - new Date(b.navShippingDate);
        }));
    }

    const addrData = soPagedData.length > 0
        ? soPagedData
        : (!pagedData ? [] : pagedData.results);

    return (
        <Box mb={2}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h6" color="primary">
                        Shipping Address
                        <span style={{ fontSize: '1rem', marginLeft: '1rem', color: '#111' }}>Shipping Method: {pagedData && pagedData.results.length > 0 && pagedData.results[0].shippingMethodCode}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {pagedData && pagedData.pageCount > 1 && (
                        <Pagination
                            component="div"
                            count={pagedData.pageCount}
                            page={pagedData.pageNumber}
                            onChange={onChangePage}
                            style={{ float: 'right' }}
                        />
                    )}
                    <span style={{ float: 'right', marginLeft: '1rem' }}>Total Addresses: {pagedData && pagedData.totalCount}</span>
                </Grid>
            </Grid>

            <TableContainer style={{ maxHeight: '400px' }} component={Paper}>
                <Table stickyHeader ize="small" aria-label="address table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: '.5rem' }}>
                                {
                                    pagedData && fullPageCount > 1
                                        ? (
                                            <TextField
                                                label="Name"
                                                autoFocus
                                                value={name}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                                                onChange={(e) => setName(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="search" onClick={handleSearch}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                        ) : 'Name1'
                                }
                            </TableCell>
                            <TableCell style={{ padding: '.5rem' }}>
                                {
                                    pagedData && fullPageCount > 1
                                        ? (
                                            <TextField
                                                label="Company"
                                                autoFocus
                                                value={company}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                                                onChange={(e) => setCompany(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="search" onClick={handleSearch}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                        ) : 'Company'
                                }
                            </TableCell>
                            <TableCell style={{ padding: '.5rem' }}>
                                {
                                    pagedData && fullPageCount > 1
                                        ? (
                                            <TextField
                                                label="SO #"
                                                autoFocus
                                                value={soNumber}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                                                onChange={(e) => setSONumber(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="search" onClick={handleSearch}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                        ) : 'SO #'
                                }
                            </TableCell>
                            <TableCell style={{ padding: '.5rem' }}>
                                Address
                            </TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Phone</TableCell>
                            {(jobType === 'AJEKT' || jobType === 'IM')
                                && (
                                    <TableCell style={{ padding: '.5rem' }}>QTY</TableCell>
                                )}
                            <TableCell style={{ padding: '.5rem' }}>Ship Date</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Actual Ship Date</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Delivery Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingTableRow loading={loading} />
                        {!loading && pagedData && pagedData.results.length > 0 && addrData.map((a, i) => (
                            <TableRow key={a.id}>
                                <TableCell style={{ padding: '.5rem' }}>
                                    <Box>
                                        <Button color="primary" onClick={() => openShipping(a)}>
                                            {a.firstName} {a.middleName} {a.lastName}
                                        </Button>
                                    </Box>
                                    <Box>{a.firstName2} {a.middleName2} {a.lastName2}</Box>
                                </TableCell>
                                <TableCell style={{ padding: '.5rem' }}>{a.company}</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>{a.navOrderNumber || a.orderShippingItem[0].navOrderNumber}</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>
                                    {a.address} {a.address2} {a.address3}, {a.city}, {a.state} {a.zip} {a.country}
                                </TableCell>
                                <TableCell style={{ padding: '.5rem' }}>{a.phone}</TableCell>
                                {(jobType === 'AJEKT' || jobType === 'IM')
                                    && (
                                        <TableCell style={{ padding: '.5rem' }}>{a.orderShippingItem[0].quantity}</TableCell>
                                    )}
                                <TableCell style={{ padding: '.5rem' }}>
                                    {(a.shipByDate && !(lob === 'p1a' && (jobType === 'FJE' || jobType === 'AJEPP')) ? new Date(a.shipByDate).toLocaleDateString() : (dateReq && !(jobType === 'FJE' || jobType === 'AJEPP') ? new Date(dateReq).toLocaleDateString() : ''))}
                                </TableCell>
                                <TableCell style={{ padding: '.5rem' }}>
                                    {a.orderShippingItem[0].navShippingDate}
                                </TableCell>
                                <TableCell style={{ padding: '.5rem' }}>
                                    {
                                        getDeliveryDate(lob, jobType, a)
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {shippingData && (
                <Shipping
                    open={showShipping}
                    onClose={() => setShowShipping(false)}
                    data={shippingData}
                />
            )}
        </Box>
    );
}
