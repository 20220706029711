import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import fallbackSrc from 'utils/fallbackSrc';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1024,
        height: 760,
        minHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const QuickviewModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, closeQuickview,
        quickviewPDF
    } = props;

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={closeQuickview}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                {quickviewPDF.includes('p2-img')
                    ? (
                        <img
                            src={quickviewPDF}
                            style={{ width: '100%' }}
                            onError={fallbackSrc}
                        />
)
                    : <iframe style={{ width: '100%', height: '100%' }} title={quickviewPDF} src={`${quickviewPDF}#toolbar=0&navpanes=0`} />}
            </div>
        </Modal>
    );
};
export default QuickviewModal;
