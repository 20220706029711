import {
    Box, CircularProgress, Input, Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { PureComponent } from 'react';
import http from 'utils/http';

export default class UploadAddressModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
            fileName: '',
            fileData: null,
            data: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open) {
            this.setState({
                uploading: false,
                fileName: '',
                fileData: null,
                data: null
            });
        }
    }


    onFileChange = (e) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            this.getBase64(file).then(async (fileData) => {
                const fd = new FormData();
                fd.append('fileName', file.name);
                fd.append('fileData', fileData);
                this.setState({
                    fileName: file.name,
                    fileData
                });
            });
        } else {
            this.setState({
                fileName: '',
                fileData: null
            });
        }
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    submit = () => {
        this.setState({ uploading: true });
        const { fileName, fileData } = this.state;
        const { onClose } = this.props;
        const fd = new FormData();
        fd.append('fileName', fileName);
        fd.append('fileData', fileData);
        http.post('su/p7/upload-im-table', fd).then((data) => {
            this.setState({ data });
            onClose(data);
        }).finally(() => {
            this.setState({ uploading: false });
        });
    }

    getIsNextReady = () => {
        const { uploading, fileName } = this.state;
        return !(uploading || !fileName);
    }

    render() {
        const {
            open,
            onClose
        } = this.props;
        const { uploading } = this.state;
        const isNextReady = this.getIsNextReady();
        return (
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
            >
                <MuiDialogTitle disableTypography>
                    <Typography variant="h6">Upload IM Batch Table</Typography>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Box mt={2} pl={3} pr={3}>
                        <Input
                            style={{ width: '100%' }}
                            placeholder="Select IM Batch Address file"
                            inputProps={{ type: 'file', accept: '.xlsx' }}
                            onChange={this.onFileChange}
                        />
                    </Box>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        disabled={!isNextReady}
                        onClick={this.submit}
                        variant="contained"
                        color="primary"
                    >
                        {uploading && (
                            <CircularProgress
                                color="secondary"
                                size={12}
                            />
                        )}
                        Upload
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}
