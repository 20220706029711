import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import history from 'utils/history';
import http from 'utils/http';
import IMBatchItemInfo from './IMBatchItemInfo';
import PickPackItemInfo from './PickPackItemInfo';

export default function ContinueOrderDialog({
    open, onClose, savedJobId
}) {
    const [loading, setLoading] = useState(false);
    const [unsaved, setUnsaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [error, setError] = useState(null);
    const [changedAddress, setChangedAddress] = useState(null);
    const [pickPackItem, setPickPackItem] = useState(null);
    const [jobItems, setJobItems] = useState(null);
    useEffect(() => {
        async function getSavedOrderData() {
            setLoading(true);
            http.get(`su/p7/saved-order/${savedJobId}`)
                .then((d) => setOrderData(d))
                .finally(() => setLoading(false));
        }

        if (open) {
            getSavedOrderData();
        }
        return () => {
        };
    }, [open, savedJobId]);

    const submitOrder = () => {
        setError(null);
        setLoading(true);
        http.post(`su/p7/saved-order/${savedJobId}`)
            .then((d) => {
                if (d && d.orderId) {
                    history.push(`/order/${d.orderId}/c`);
                } else if (typeof d === 'string' && d !== '1') {
                    setError(d);
                } else {
                    onClose(true);
                }
            })
            .finally(() => setLoading(false));
    };

    const updateAddress = (a) => {
        setError(null);
        setChangedAddress(a);
        setUnsaved(true);
    };

    const onPickPackItemChange = (a) => {
        setError(null);
        setPickPackItem(a);
        setUnsaved(true);
    };

    const onJobItemChange = (a) => {
        setError(null);
        setJobItems(a);
        setUnsaved(true);
    };

    const saveChange = (a) => {
        setSaving(true);
        const postUrl = orderData.pickPackItem
            ? `su/p7/edit-saved-pick-pack-item/${savedJobId}`
            : `su/p7/edit-saved-batch-item/${savedJobId}`;
        const postData = orderData.pickPackItem
            ? {
                pickPackItem, jobItems
            }
            : changedAddress;
        http.post(postUrl, postData)
            .then(() => {
                setLoading(true);
                http.get(`su/p7/saved-order/${savedJobId}`)
                    .then((d) => {
                        setUnsaved(false);
                        setOrderData(d);
                    })
                    .finally(() => setLoading(false));
            })
            .finally(() => setSaving(false));
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Continue Order</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                {loading && <CircularProgress color="primary" />}
                {orderData && orderData.pickPackItem && <PickPackItemInfo orderData={orderData} onPickPackItemChange={onPickPackItemChange} onJobItemChange={onJobItemChange} />}
                {orderData && orderData.addressItem && <IMBatchItemInfo orderData={orderData} onAddressChanged={updateAddress} />}
            </MuiDialogContent>
            <MuiDialogActions>
                {

                    error && <span style={{ color: '#f00', marginRight: '1rem' }}>{error}</span>
                }
                <Button onClick={onClose}>
                    Close
                </Button>
                <Button
                    onClick={saveChange}
                    disabled={(!changedAddress && !pickPackItem && !jobItems) || saving || loading}
                    variant="outlined"
                    color="primary"
                >
                    {(saving) && (
                        <CircularProgress
                            color="secondary"
                            size={12}
                        />
                    )}
                    Save Change
                </Button>
                <Button
                    disabled={unsaved || loading || saving || !orderData || !orderData.jobItems || orderData.jobItems.length < 1 || orderData.jobItems.some((i) => i.id === 0)}
                    onClick={submitOrder}
                    variant="contained"
                    color="primary"
                >
                    {(loading) && (
                        <CircularProgress
                            color="secondary"
                            size={12}
                        />
                    )}
                    Submit
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
