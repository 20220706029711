import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';

export default function HelpTypeSelect({
 open, handleClose, helpTypes, handleSelect
}) {
    return (
        <Dialog onClose={handleClose} aria-labelledby="help-type-select" open={open}>
            <DialogTitle>Select Help Type</DialogTitle>
            <List>
                {helpTypes.map((ht) => (
                    <ListItem button onClick={() => handleSelect(ht.code)} key={ht.code}>
                        <ListItemText primary={ht.label} />
                    </ListItem>
            ))}
            </List>
        </Dialog>
    );
}
