import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSavedOrders, addSavedItemToCart } from './action';
import ListView from './ListView';

const mapStateToProps = ({ appView: { user }, savedOrderView: { savedOrders } }) => {
    return { user, savedOrders };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSavedOrders, addSavedItemToCart
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
