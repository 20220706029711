import {
    Box, Breadcrumbs,

    Button, Grid,

    Paper,
    Table, TableBody, TableCell, TableContainer,
    TableFooter, TableHead,
    TablePagination, TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Publish as PublishIcon } from '@material-ui/icons';
import React, { PureComponent } from 'react';
import UploadAddressModal from './modal/UploadAddressModal';

export default class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showUploadAddressModal: false,
            pageNumber: 1
        };
    }

    componentDidMount() {
        const { getPPAddresses, getDownloadLink } = this.props;
        const { pageNumber } = this.state;
        getPPAddresses(pageNumber);
        getDownloadLink();
    }

    openUploadAddressModal = () => {
        this.setState({ showUploadAddressModal: true });
    }

    closeUploadAddressModal = (reload) => {
        this.setState({ showUploadAddressModal: false });
        if (reload) {
            const { getPPAddresses } = this.props;
            getPPAddresses(1);
            this.setState({ pageNumber: 1 });
        }
    }

    searchOrder = (fileNumber) => {
        const { history } = this.props;
        history.push({
            pathname: '/order-management',
            state: { fileNumber }
        });
    }

    onChangePage = (_event, pageNumber) => {
        const { getPPAddresses } = this.props;
        getPPAddresses(pageNumber + 1);
        this.setState({ pageNumber: pageNumber + 1 });
    }

    render() {
        const {
            pagedData, downloadLink, user
        } = this.props;
        const { showUploadAddressModal, showUploadTableModal } = this.state;
        const downloadLinkTemplate = `${downloadLink}?Authorization=${user.token}`;
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Pick&Pack Batch</Typography>
                    </Breadcrumbs>
                </Box>
                <Box component={Paper} p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Pick&Pack Address Files
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{ float: 'right', margin: '.5rem' }}
                                    endIcon={<PublishIcon>Upload</PublishIcon>}
                                    onClick={this.openUploadAddressModal}
                                >
                                    Upload File
                                </Button>
                            </Typography>
                            <TableContainer component={Paper} square>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>File Number</TableCell>
                                            <TableCell>File Name</TableCell>
                                            <TableCell>Total</TableCell>
                                            <TableCell>Uploaded On</TableCell>
                                            <TableCell>Warning File</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(pagedData && !pagedData.results.length) && (
                                            <TableRow>
                                                <TableCell colSpan={20}>
                                                    <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}>
                                                        No Pick&Pack Batch Address uploaded yet
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {pagedData && pagedData.results.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    {row.reportId
                                                        ? (
                                                            <Tooltip title="Search orders">
                                                                <Link
                                                                    component="button"
                                                                    variant="body2"
                                                                    onClick={() => this.searchOrder(row.id)}
                                                                >
                                                                    {row.id}
                                                                </Link>
                                                            </Tooltip>
                                                        )
                                                        : row.id}

                                                </TableCell>
                                                <TableCell>{row.fileName}</TableCell>
                                                <TableCell>{row.totalCount}</TableCell>
                                                <TableCell>{row.createdOn}</TableCell>
                                                <TableCell>{row.reportId
                                                    ? (
                                                        <a target="_blank" href={downloadLinkTemplate.replace('{id}', row.reportId)}>Warning File</a>
                                                    )
                                                    : null}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={20}>
                                                {pagedData && (
                                                    <TablePagination
                                                        component="div"
                                                        count={pagedData.totalCount}
                                                        page={pagedData.pageNumber - 1}
                                                        rowsPerPage={pagedData.pageSize}
                                                        rowsPerPageOptions={[pagedData.pageSize]}
                                                        onChangePage={this.onChangePage}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Grid>
                </Box>

                <UploadAddressModal
                    open={showUploadAddressModal && !showUploadTableModal}
                    onClose={this.closeUploadAddressModal}
                    onAddTable={this.openUploadTableModal}
                    downloadLinkTemplate={downloadLinkTemplate}
                />

            </Box>
        );
    }
}
