import { emptyCart } from 'pages/cart/action';
import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('CHECKOUT_VIEW', {
    LOADING: null,
    SET_BASKET_ITEMS: null,
    SET_SHIPPING_METHODS: null,
    SET_SHIPPING_ADDRESSES: null,
    SET_PLAN_DOCUMENTS: null,
    SET_CONTACT_INFO: null,
    SET_BALANCE: null,
    SET_SUBMITTED: null,
    SET_MS_X_CC: null,
    SET_USED: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
export const setSubmitted = actionCreator(actions.SET_SUBMITTED, 'submitted');
const setBasketItems = actionCreator(actions.SET_BASKET_ITEMS, 'basketItems');
const setMarketSegmentXCostCenters = actionCreator(actions.SET_MS_X_CC, 'marketSegmentXCostCenters');
const setShippingMethods = actionCreator(actions.SET_SHIPPING_METHODS, 'shippingMethods');
export const setContactInfo = actionCreator(actions.SET_CONTACT_INFO, 'contactInfo');
const setShippingAddresses = actionCreator(actions.SET_SHIPPING_ADDRESSES, 'shippingAddresses');
const setPlanDocuments = actionCreator(actions.SET_PLAN_DOCUMENTS, 'planDocuments');
const setBalance = actionCreator(actions.SET_BALANCE, 'balance');
const setUsed = actionCreator(actions.SET_USED, 'used');

export const GetEstimatedDeliveryDate = (inputs) => (dispatch, getState) => {
    http.post('store/estimate-delivery-date', inputs).then((r) => {
        const { basketItems } = getState().checkoutView;
        for (const edd of r) {
            for (const bi of basketItems) {
                if (bi.lineId === edd.lineId) {
                    const s = bi.customFields.shippings[edd.index];
                    s.estimatedDeliveryDate = edd.estimatedDeliveryDate.deliveryDate;
                    s.deliveryDetail = edd.estimatedDeliveryDate;
                }
            }
        }

        dispatch(setBasketItems([...basketItems]));
    });
};

export const getCheckoutItems = () => (dispatch) => {
    dispatch(setMarketSegmentXCostCenters(null));
    dispatch(setBasketItems(null));
    dispatch(setLoading(true));
    dispatch(setSubmitted(false));
    http.get('store/get-checkout-items')
        .then(({
            basketItems, shippingMethods, shippingAddresses, balance, usage, planDocuments, contactInfo, marketSegmentXCostCenters
        }) => {
            for (const bi of basketItems) {
                bi.originalQty = bi.quantity;
                if (bi.customFields && typeof bi.customFields === 'string') {
                    bi.customFields = JSON.parse(bi.customFields);
                } else {
                    bi.customFields = {};
                }

                if (!bi.customFields.shippings) {
                    bi.customFields.shippings = [{
                        shippingAddress: shippingAddresses && shippingAddresses.length > 0
                            ? shippingAddresses[0].id
                            : '',
                        shippingMethod: shippingMethods[0].code,
                        quantity: bi.quantity
                    }];
                }
            }

            dispatch(setShippingMethods(shippingMethods));
            dispatch(setShippingAddresses(shippingAddresses));
            dispatch(setPlanDocuments(planDocuments));
            if (contactInfo) dispatch(setContactInfo(contactInfo));
            dispatch(setBalance(balance));
            var u = {};
            if (usage) {
                for (const k in usage) {
                    u[k.toLowerCase()] = usage[k];
                }
            }
            dispatch(setUsed(u));
            dispatch(setBasketItems(basketItems));
            dispatch(setMarketSegmentXCostCenters(marketSegmentXCostCenters));
        }).finally(() => dispatch(setLoading(false)));
};

export const saveAddress = (address, newAddressTarget) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const currentState = getState();
    const { checkoutView: { basketItems, shippingAddresses: old } } = currentState;
    http.post('customer/save-address', address)
        .then((shippingAddresses) => {
            dispatch(setShippingAddresses(shippingAddresses));
            const newId = shippingAddresses.filter((s) => !old.some((o) => o.id === s.id))[0].id;
            basketItems.find((b) => b.lineId === newAddressTarget.lineId).customFields.shippings[newAddressTarget.index].shippingAddress = newId;
            dispatch(setBasketItems(basketItems));
        }).finally(() => dispatch(setLoading(false)));
};

export const addShipping = (lineId) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems, shippingMethods, shippingAddresses } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    if (!item.customFields.isPlanDocument) {
        const master = item.customFields.shippings.find((s) => s.quantity > 1);
        master.quantity -= 1;
    }
    item.customFields.shippings.push({
        shippingAddress: shippingAddresses && shippingAddresses.length > 0
            ? shippingAddresses[0].id
            : '',
        shippingMethod: shippingMethods[0].code,
        quantity: 1
    });
    dispatch(setBasketItems([...basketItems]));
};

export const removeShipping = (lineId, i) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings.splice(i, 1);
    if (!item.customFields.isPlanDocument) {
        let restCount = 0;
        for (let x = 1; x < item.customFields.shippings.length; x += 1) {
            restCount += item.customFields.shippings[x].quantity;
        }

        item.customFields.shippings[0].quantity = item.originalQty - restCount;
        item.quantity = item.customFields.shippings.reduce((prev, cur) => {
            return prev + cur.quantity;
        }, 0);
    }
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingQty = (lineId, index, qty) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    if (!item.originalQty) {
        item.originalQty = item.quantity;
    }
    item.customFields.shippings[index].quantity = parseInt(qty, 10);
    let total = 0;
    for (const s of item.customFields.shippings) {
        total += s.quantity;
    }
    item.quantity = total;
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingMethod = (lineId, index, code) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingMethod = code;
    item.customFields.shippings[index].estimatedDeliveryDate = null;
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingMethodData = (lineId, index, data) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingMethodData = data;
    item.customFields.shippings[index].estimatedDeliveryDate = null;
    dispatch(setBasketItems([...basketItems]));
};

export const applyShippingAddress = (lineId, index, addressId) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingAddress = addressId;
    item.customFields.shippings[index].shippingAddressData = null;
    item.customFields.shippings[index].estimatedDeliveryDate = null;
    dispatch(setBasketItems([...basketItems]));
};

export const applySelectedShippingAddress = (lineId, index, addr) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems } } = currentState;
    const item = basketItems.find((b) => b.lineId === lineId);
    item.customFields.shippings[index].shippingAddressData = addr;
    item.customFields.shippings[index].shippingAddress = null;
    item.customFields.shippings[index].estimatedDeliveryDate = null;
    dispatch(setBasketItems([...basketItems]));
};

export const submitOrder = (status, reason, used, address, contactInfo, obo, shippingNotes, totalPrice, costCenter, marketSegment, upsShippingNotes, customerNumber, customerName) => (dispatch, getState) => {
    const currentState = getState();
    const { checkoutView: { basketItems, shippingAddresses }, appView: { user } } = currentState;
    const thumbnails = basketItems.map((b) => ({ code: b.code, thumbnail: b.customFields.thumbnail }));
    for (const b of basketItems) {
        if (address) {
            b.shippings = [{ CustomerAddress: address, quantity: b.quantity, shippingMethod: address.shippingMethodCode || 'default' }];
        } else {
            b.shippings = b.customFields.shippings.map((s) => {
                const ss = { ...s, customerAddress: shippingAddresses.find((a) => a.id == s.shippingAddress) };
                delete ss.shippingAddress;
                return ss;
            });

            delete b.createdDate;
            delete b.basket;
        }
    }

    const customFields = {
        thumbnails,
        reason,
        lob: user.customFields.userType === 'telesales'
            ? 'p1a'
            : (user.customFields.npn
                ? 'p1a'
                : (
                    user.customFields.p2_Catalog
                        ? 'p2'
                        : ''
                )),
        jobType: user.customFields.npn ? 'Broker'
            : (user.customFields.userType || ''),
        used,
        contactInfo,
        obo,
        shippingNotes,
        upsShippingNotes,
        totalPrice,
        costCenter,
        marketSegment,
        customerNumber,
        customerName
    };
    Object.keys(customFields).forEach((key) => (customFields[key] == null) && delete customFields[key]);

    const productOrderData = {
        basketItems,
        status,
        customFields
    };

    dispatch(setSubmitted(true));
    http.post('store/submit-product-order', productOrderData)
        .then((oid) => {
            dispatch(emptyCart());
            history.push(`/order/${oid}/c`);
        }).catch(() => {
            dispatch(setSubmitted(false));
        });
};
