import { API_ERROR, API_SUCCESS } from './actions/api';
import { actions as appActions } from './actions/app';
import { SET_LOADER } from './actions/ui';
import { actions as loginActions } from './pages/login/action';
import { actions as passthroughLoginActions } from './pages/passthrough-login/action';

export default (
    state = {
        isAuthUser: !!window.localStorage.getItem('user') && window.localStorage.getItem('user') !== 'undefined',
        user: (window.localStorage.getItem('user') && window.localStorage.getItem('user') !== 'undefined' ? JSON.parse(window.localStorage.getItem('user')) : {}),
        error: null,
        appMessage: null,
        showAppMessage: false,
        settings: null,
        passthroughLogin: null,
        logoutLogo: null
    },
    action
) => {
    let u;
    switch (action.type) {
        case appActions.SET_IMPERSONATE:
            u = { ...state.user, impersonate: action.login };
            window.localStorage.setItem('user', JSON.stringify(u));
            return {
                ...state, user: u
            };
        case loginActions.SET_USER_INFO:
        case passthroughLoginActions.SET_USER_INFO:
            u = { ...action.userInfo };
            if (u && u.customFields && u.customFields.npn) {
                u.customFields.productMapping = JSON.parse(u.customFields.productMapping);
                u.customFields.brokerMeta = JSON.parse(u.customFields.brokerMeta);
                u.customFields.stateLang = JSON.parse(u.customFields.stateLang);
            }

            window.localStorage.setItem('user', JSON.stringify(u));
            return {
                ...state, isAuthUser: true, user: u, token: u.token
            };
        case appActions.LOGOUT:
            let logoutLogo = undefined;
            if(!!(state.user && state.user.customFields && state.user.customFields.brandLogo)) {
                logoutLogo = state.user.customFields.brandLogo;
            }

            const user = !!window.localStorage.getItem('user') && window.localStorage.getItem('user') !== 'undefined'
                ? JSON.parse(window.localStorage.getItem('user'))
                : null;
            window.localStorage.clear();
            return {
                ...state, isAuthUser: false, user: null, passthroughLogin: (user && user.customFields && !!user.customFields.passthroughLogin), logoutLogo: logoutLogo
            };
        case loginActions.REMOVE_USER_INFO:
            window.localStorage.removeItem('user');
            return {
                ...state, isAuthUser: false, user: null, token: null
            };

        case API_SUCCESS:
            window.localStorage.setItem('user', JSON.stringify(action.payload.data.result));
            return { ...state, isAuthUser: true, user: action.payload.data.result };
        case API_ERROR:
            return { ...state, error: action.payload };

        case SET_LOADER:
            return { ...state, isLoading: action.payload };

        case appActions.SHOW_MESSAGE:
            return {
                ...state,
                appMessage: { message: action.message, severity: action.severity },
                showAppMessage: true
            };
        case appActions.HIDE_MESSAGE:
            return { ...state, appMessage: null, showAppMessage: false };
        case appActions.SET_SETTINGS:
            return { ...state, settings: action.settings };
        default:
            return state;
    }
};
