import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';

export default function LobUserGroupDialog({
    open, onClose, lob, userGroup, onSave
}) {
    const userGroupOptions = ['OE',
        'BROKER',
        'SU',
        'TELESALES',
        'TELESALES ADMINS',
        'MSO',
        'FSR',
        'SG BROKER'
    ];

    const lobOptions = [{ "text": "MEDICARE INDIVIDUAL", "value": "MEDICARE INDIVIDUAL" }, { "text": "MEDICARE GROUP", "value": "MEDICARE GROUP" }, { "text": "TRADITIONAL COMMERCIAL", "value": "TRADITIONAL COMMERCIAL" }, { "text": "DYNAMIC OE", "value": "REIMAGINATION" }, { "text": "SMALL GROUP", "value": "SMALL GROUP" }];
    const [lobValue, setLobValue] = useState(lob || '');
    const [userGroupValue, setUserGroupValue] = useState(userGroup || '');
    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Add Assignment</DialogTitle>
            <DialogContent>
                <Box>
                    <FormControl
                        fullWidth
                    >
                        <InputLabel id="lob-select-label">LOB</InputLabel>
                        <Select
                            labelId="lob-select-label"
                            id="lob-select"
                            value={lobValue}
                            onChange={(e) => setLobValue(e.target.value)}
                        >
                            <MenuItem value="">Select</MenuItem>
                            {
                                lobOptions.map((o) => <MenuItem key={o.value} value={o.value}>{o.text}</MenuItem>)
                            }
                        </Select>
                    </FormControl>

                </Box>
                <Box>
                    <FormControl
                        fullWidth
                    >
                        <InputLabel id="ug-select-label">User Group *</InputLabel>
                        <Select
                            labelId="ug-select-label"
                            id="ug-select"
                            value={userGroupValue}
                            onChange={(e) => setUserGroupValue(e.target.value)}
                        >
                            <MenuItem value="">Select</MenuItem>
                            {
                                userGroupOptions.map((o) => <MenuItem key={o} value={o}>{o}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary">
                    No
                </Button>
                <Button
                    onClick={() => onSave(lobValue, userGroupValue)}
                    color="primary"
                    disabled={!userGroupValue}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
