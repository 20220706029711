import {
    Box, CircularProgress, Grid, IconButton, Paper, TableFooter, Typography
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { PureComponent } from 'react';
import MessageBoard from '../broker/MessageBoard';
import SelectBar from './SelectBar';

export default class ListView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    UNSAFE_componentWillUpdate(nextProps) {
        const { listResult, basketItems, loadMaterials } = nextProps;
        if (listResult === null && basketItems && basketItems.length > 0) {
            loadMaterials(basketItems[0].customFields.state, 1);
        }
    }

    changeState = (e) => {
        if (e.target.value) {
            const { setState } = this.props;
            setState(e.target.value);

            const { loadMaterials } = this.props;
            loadMaterials(e.target.value, 1);
        }
    }

    handleChangePage = (_event, pageNumber) => {
        const { loadMaterials, selectedState, basketItems } = this.props;
        const stateInCart = basketItems && basketItems.length > 0 ? basketItems[0].customFields.state : null;
        loadMaterials(stateInCart || selectedState, pageNumber + 1);
    };

    toggleExpand = (id) => {
        const { listResult, setListResult } = this.props;
        const materials = listResult.results;
        const kit = materials.find((m) => m.id === id);
        kit.expanded = !kit.expanded;
        listResult.results = [...materials];
        setListResult({ ...listResult });
    }

    onAddClicked = (id, code, name, qty, items, version, warehouse, itemType, dp) => {
        const { addProductToCart, selectedState } = this.props;
        addProductToCart({
            id,
            code,
            name,
            qty,
            customFields: {
                program: 'Small Group', version, items, state: selectedState, warehouse, itemType, dp, upperLimit: 400,
            },
            isKit: itemType === 'KT'
        }, false);
    }

    render() {
        const {
            listResult, loading, settings, basketItems
        } = this.props;
        let { selectedState } = this.props;
        const stateInCart = basketItems && basketItems.length > 0 ? basketItems[0].customFields.state : null;
        if (stateInCart) {
            selectedState = stateInCart;
        }
        let msg;
        if (settings) {
            if (settings.messageBoard && settings.messageBoard.length) {
                [msg] = settings.messageBoard;
            } else if (settings.useDefaultMsg) {
                msg = '';
            }
        }

        const hasResult = !loading && selectedState && listResult;
        return (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Box m={2} p={2} component={Paper}>
                        {msg && <MessageBoard content={msg} />}
                    </Box>
                    <Box m={2} p={2} component={Paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            <BuildIcon /> Don&apos;t Forget!
                        </Typography>
                        <Typography variant="body1">
                            Click on the Need Help icon to download our new User Guide.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={3}>
                            <SelectBar selected={stateInCart || selectedState} disabled={!!stateInCart} onChange={this.changeState} />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '1rem' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Code</TableCell>
                                            <TableCell>Version</TableCell>
                                            <TableCell>Inventory</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Language</TableCell>
                                            <TableCell>Item Type</TableCell>
                                            <TableCell>WHS</TableCell>
                                            <TableCell align="right">Order</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!selectedState && (
                                            <TableRow>
                                                <TableCell style={{ padding: '2rem', textAlign: 'center' }} colSpan={20}><span>Select State to start.</span></TableCell>
                                            </TableRow>
                                        )}
                                        {loading
                                            && (
                                                <TableRow>
                                                    <TableCell style={{ padding: '2rem', textAlign: 'center' }} colSpan={20}>
                                                        <CircularProgress color="primary" />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        {hasResult && listResult.results.map((row) => {
                                            return (
                                                <>
                                                    <TableRow key={row.id}>
                                                        <TableCell>
                                                            {
                                                                row.itemType === 'KT' && (
                                                                    <IconButton onClick={() => this.toggleExpand(row.id)}>
                                                                        {row.expanded
                                                                            ? <ExpandMoreIcon />
                                                                            : <ChevronRightIcon />}

                                                                    </IconButton>
                                                                )
                                                            }
                                                        </TableCell>
                                                        <TableCell>{row.code}</TableCell>
                                                        <TableCell>{row.version}</TableCell>
                                                        <TableCell />
                                                        <TableCell component="th" scope="row">
                                                            <Typography
                                                                color="primary"
                                                                style={{ fontWeight: 'bold' }}
                                                                aria-haspopup="true"
                                                            >
                                                                {row.name}
                                                            </Typography>

                                                        </TableCell>
                                                        <TableCell>{row.language}</TableCell>
                                                        <TableCell>{row.itemType}</TableCell>
                                                        <TableCell>{row.itemType === 'KT' ? row.items && row.items.length > 0 && row.items[0].warehouse : row.warehouse}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                onClick={() => this.onAddClicked(row.id, row.code, row.name, 1, row.items, row.version,
                                                                    row.itemType === 'KT' ? row.items && row.items.length > 0 && row.items[0].warehouse : row.warehouse,
                                                                    row.itemType, row.dp)}
                                                            >
                                                                <AddShoppingCartIcon
                                                                    color="primary"
                                                                />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                    {row.expanded && row.items.length === 0 && (
                                                        <TableRow>
                                                            <TableCell style={{ textAlign: 'center', padding: '1rem' }} colSpan={16}>
                                                                <span style={{ color: '#999' }}> No item data</span>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {row.expanded && row.items.map((c) => (
                                                        <TableRow key={c.id}>
                                                            <TableCell />
                                                            <TableCell>{c.code}</TableCell>
                                                            <TableCell>{c.version}</TableCell>
                                                            <TableCell>{c.inventory}</TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {c.name}
                                                            </TableCell>
                                                            <TableCell>{c.language}</TableCell>
                                                            <TableCell>{c.itemType}</TableCell>
                                                            <TableCell>{c.warehouse}</TableCell>
                                                            <TableCell align="right" />
                                                        </TableRow>
                                                    ))}
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell colSpan={20}>
                                                {listResult && (
                                                    <TablePagination
                                                        component="div"
                                                        count={listResult.totalCount}
                                                        page={listResult.pageNumber - 1}
                                                        rowsPerPage={listResult.pageSize}
                                                        rowsPerPageOptions={[listResult.pageSize]}
                                                        onChangePage={this.handleChangePage}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
