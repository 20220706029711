import cartActions from './action';

const initialState = {
    loading: false,
    basketItems: null,
    used: null,
    pricing: null,
    refreshed: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case cartActions.LOADING:
            return { ...state, isLoading: action.loading };
        case cartActions.SET_REFRESHED:
            return { ...state, refreshed: action.refreshed };
        case cartActions.EMPTY_CART:
            return { ...state, basketItems: null };
        case cartActions.SET_PRICING:
            return { ...state, pricing: action.pricing };
        case cartActions.SET_BASKET_ITEMS:
            return { ...state, basketItems: action.basketItems };
        case cartActions.SET_USED:
            return { ...state, used: action.used };
        case cartActions.UPDATE_ITEM_QTY:
            return {
                ...state,
                basketItems: state.basketItems.map((b) => (b.lineId === action.lineId
                    ? { ...b, quantity: action.qty }
                    : b))
            };
        case cartActions.REMOVE_ITEM:
            return {
                ...state,
                basketItems: state.basketItems.filter((b) => !action.lineIds.includes(b.lineId))
            };
        default:
            return state;
    }
}
