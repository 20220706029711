import { Chip, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

export default function SelectionBar({ selection, onChangeClick }) {
    return (
        <Grid container>
            <Grid item xs={2} />
            <Grid item xs={2}>
                Year: <Chip size="small" label={selection.year} variant="outlined" />
            </Grid>
            <Grid item xs={2}>
                Product: <Chip size="small" label={selection.product} variant="outlined" />
            </Grid>
            <Grid item xs={2}>
                State: <Chip size="small" label={selection.state} variant="outlined" />
            </Grid>
            <Grid item xs={2}>
                Language: <Chip size="small" label={selection.language} variant="outlined" />
            </Grid>
            <Grid item xs={2}>
                <IconButton size="small" color="primary" style={{ float: 'right' }} onClick={onChangeClick}>
                    <EditIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
}
