import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, Divider,

    Grid,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import React from 'react';

export default function Shipping({
    open, onClose, data
}) {
    // console.log('#ODS#: addrData', data);
    const trackings = [];
    for (const item of data.orderShippingItem) {
        if (item.navTrackingNumber) {
            for (const t of item.trackings) {
                if (!trackings.some(ts => ts.trackingNumber === t.trackingNumber)) {
                    trackings.push({ ...t });
                }
            }
        }
    }
    trackings.sort((a, b) => {
        return new Date(a.navShipDate) - new Date(b.navShipDate);
    });
    // console.log('#ODS#: trackings', trackings);
    const soNumber = data.navOrderNumber || data.orderShippingItem[0].navOrderNumber;
    const soItems = data.navOrderNumber ? data.orderShippingItem.filter(i => i.navOrderNumber === data.navOrderNumber) : [];

    return (
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="md">
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                Address Shipping Details {soNumber && <span style={{ float: 'right' }}>SO #: {soNumber}</span>}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h6">
                    Shipping Address
                </Typography>
                <Grid style={{ marginTop: '.5rem' }} container spacing={1}>
                    <Grid item xs={3}>
                        <b>First Name:</b> {data.firstName}
                    </Grid>
                    <Grid item xs={3}>
                        <b>MI:</b> {data.middleName}
                    </Grid>
                    <Grid item xs={6}>
                        <b>Last Name:</b> {data.lastName}
                    </Grid>
                    {
                        data.firstName2 && (
                            <>
                                <Grid item xs={3}>
                                    <b>First Name2:</b> {data.firstName2}
                                </Grid>
                                <Grid item xs={3}>
                                    <b>MI2:</b> {data.middleName2}
                                </Grid>
                                <Grid item xs={6}>
                                    <b>Last Name2:</b> {data.lastName2}
                                </Grid>
                            </>
                        )
                    }
                    <Grid item xs={3}>
                        <b>Company:</b> {data.company}
                    </Grid>
                    <Grid item xs={9}>
                        <b>Phone:</b> {data.phone}
                    </Grid>
                    <Grid item xs={4}>
                        <b>Address:</b> {data.address}
                    </Grid>
                    <Grid item xs={4}>
                        <b>Address2:</b> {data.address2}
                    </Grid>
                    <Grid item xs={4}>
                        <b>Address3:</b> {data.address3}
                    </Grid>
                    <Grid item xs={4}>
                        <b>City:</b> {data.city}
                    </Grid>
                    <Grid item xs={4}>
                        <b>State:</b> {data.state}
                    </Grid>
                    <Grid item xs={4}>
                        <b>Zip:</b> {data.zip}
                    </Grid>
                </Grid>

                <Divider light style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />
                <Typography variant="h6">
                    Shipping Information
                </Typography>
                <TableContainer style={{ maxHeight: '400px' }} component={Paper}>
                    <Table stickyHeader ize="small" aria-label="address table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ padding: '.5rem' }}>Ship Date</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>Tracking Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {trackings.map((t) => (
                                <TableRow key={t.trackingNumber}>
                                    <TableCell style={{ padding: '.5rem' }}>{t.navShipDate}</TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        {t.trackingLink ? <a target="_blank" href={t.trackingLink}>{t.trackingNumber}</a> : <span style={{ marginRight: '.5rem' }}>{t.trackingNumber}</span>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    soItems.length > 0 && (
                        <>
                            <Divider light style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />
                            <Typography variant="h6">
                                Items
                            </Typography>
                            <TableContainer style={{ maxHeight: '400px' }} component={Paper}>
                                <Table stickyHeader ize="small" aria-label="address table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ padding: '.5rem' }}>Order Code</TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {soItems.map((t) => (
                                            <TableRow key={t.id}>
                                                <TableCell style={{ padding: '.5rem' }}>{t.code}</TableCell>
                                                <TableCell style={{ padding: '.5rem' }}>{t.shippedQTY}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )
                }

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
