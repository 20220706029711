import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';
import ProfileForm from './ProfileForm';

export default function Content({ user, setUserInfo }) {
    const [profile, setProfile] = useState(null);
    const [saving, setSaving] = useState(false);

    const allReadOnly = user.customFields.userType === 'telesales';
    useEffect(() => {
        async function getProfile() {
            http.get('customer/profile')
                .then((data) => setProfile(data));
        }
        getProfile();
    }, []);

    if (!profile) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Box mb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="#/account">
                                Account
                            </Link>
                            <Typography color="textPrimary">Account Details</Typography>
                        </Breadcrumbs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>

            </Grid>
        );
    }

    const onFieldChange = (e) => {
        if (allReadOnly) return;
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const saveProfile = () => {
        setSaving(true);
        http.post('customer/profile', profile)
            .finally(() => {
                setSaving(false);
                const nu = {
                    ...user,
                    customFields: {
                        ...user.customFields,
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        profileRequired: false
                    }
                };
                setUserInfo(nu);
            });
    };

    let readonly = false;
    if (user.customFields.passthroughLogin && !user.customFields.profileRequired) {
        readonly = true;
    }

    return (
        <Grid container component={Paper} spacing={2}>
            <Grid item xs={12}>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Account Details</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>

            {
                profile && profile.writingNumber && (
                    <>

                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <PermContactCalendarIcon /> Agent Writing Number: {profile.writingNumber}
                        </Grid>
                        <Grid item xs={3} />
                    </>

                )
            }
            {
                user && user.customFields.profileRequired && (
                    <>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <Alert severity="info">Please create the profile for the agent</Alert>
                        </Grid>
                        <Grid item xs={3} />
                    </>
                )
            }
            <Grid item xs={3} />
            <Grid item xs={6}>
                <ProfileForm onFieldChange={onFieldChange} profile={profile} readonly={readonly} allReadOnly={allReadOnly} />
            </Grid>
            <Grid item xs={3} />
            {!allReadOnly && (
                <>
                    <Grid item xs={3} />
                    <Grid item xs={9}>
                        <Button
                            className="btn submit-btn"
                            variant="contained"
                            color="primary"
                            disabled={saving}
                            onClick={saveProfile}
                        >{saving && (
                            <CircularProgress
                                size={12}
                            />
                        )}Save
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
