import { Container, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(2, 2),
        marginTop: 'auto',
        backgroundColor: theme.palette.primary.main
    },
    gridContainer: {
        height: '100%'
    },
    gridItem: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffffff'
    }
}));

const Footer = ({ user }) => {
    const classes = useStyles();
    let content = '';
    let contentFoot = '';
    if (user && user.customFields) {
        if (user.customFields.p2_Catalog) {
            content = 'Need Help Ordering? Contact the Agent Services Team: 866-272-6630.';
            contentFoot = `Hours 8 am - 5 pm CST${user.customFields.clientEmail ? " | Email: " + user.customFields.clientEmail : "."}`;
        }
    }
    return (
        <footer className={classes.footer}>
            <Container>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={4} className={classes.gridItem}>
                        Copyright © 2020 O’Neil Digital Solutions
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                        {content}
                        {contentFoot && <><br />{contentFoot}</>}
                    </Grid>
                    {/* <Grid item xs={1} className={classes.gridItem}>
                        Contact Us
                    </Grid> */}
                    <Grid item xs={1} className={classes.gridItem}>
                        <Link href="#/privacy-policy" style={{ color: '#fff' }}>Privacy Policy</Link>
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                        <Link href="#/terms-of-service" style={{ color: '#fff' }}>Terms of Service</Link>

                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

const mapStateToProps = ({ appView }) => {
    return {
        user: appView.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(
    Footer
);
