import {
    Button,

    CircularProgress,
    Dialog, DialogActions, DialogTitle,

    FormControlLabel, Grid,

    IconButton, Input, InputAdornment,

    Link, Paper, Table,
    TableBody,
    TableCell,
    TableContainer,

    TableFooter, TableHead,

    TableRow
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/Backup';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import SyncIcon from '@material-ui/icons/Sync';
import message from 'components/Message';
import ProofingModal from 'components/ProofingModal';
import React, { PureComponent } from 'react';
import getBase64 from 'utils/getBase64';
import http from 'utils/http';
import EditFormModal from '../edit-form-modal';
import ItemPreview from './ItemPreview';

export default class KitCrosswalk extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            item: null,

            codeValue: null,
            nameValue: null,
            openFind: false,
            finding: false,
            found: null,

            openProofPreview: false,
            previewPdf: null,

            openFormModal: false,
            proofingItem: null,

            confirmUpdatingItem: null,
            confirmDeletingItem: null,

            previewingItem: null,
            uploading: false
        };
    }

    startPickItem = (item) => {
        const { kit } = this.props;
        if (kit.approved) {
            this.setState({ confirmUpdatingItem: item });
        } else {
            this.setState({ item, openFind: true, found: null });
        }
    }

    cancelPdf = (approvedPdf) => {
        const fd = new FormData();
        fd.append('id', approvedPdf.split('/').pop());
        http.post('store/cancel-pdf', fd);
    }

    rejectAndPickItem = (item) => {
        const { saveJobItem, kit } = this.props;
        this.cancelPdf(kit.approvedPdf);

        kit.approved = false;
        kit.approvedPdf = null;
        saveJobItem(kit);

        this.setState({
            confirmUpdatingItem: null, item, openFind: true, found: null
        });
    }

    backToList = () => {
        this.setState({ item: null, openFind: false });
    }

    onCriteriaChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSearch = async () => {
        this.setState({ finding: true, found: null });
        const { item, codeValue, nameValue } = this.state;
        const formData = new FormData();
        formData.append('itemCode', codeValue || '');
        formData.append('itemName', nameValue || '');
        formData.append('itemType', item.itemType);
        http.post('direct-order/find-p1b-items', formData)
            .then((found) => this.setState({ found }))
            .finally(() => this.setState({ finding: false }));
    }

    selectComponent = (component) => {
        const { item } = this.state;
        const { saveJobItem, kit } = this.props;
        const { items } = kit;
        const t = items.find((o) => o.itemName === item.itemName);
        t.itemCode = component.code;
        t.version = component.version;
        t.vp = component.vp;
        t.component = component;
        if (component.vp && component.vp.length > 3) {
            t.approved = false;
            t.component.approved = true;
            t.itemId = component.id;
        }

        const tv = items.find((o) => o.itemName === `${item.itemName}_Version`);
        if (tv) {
            tv.itemCode = component.version;
        }

        kit.items = [...items];
        saveJobItem(kit);
        this.setState({ openFind: false, found: null });
    }

    deselectItem = (item) => {
        const { kit } = this.props;
        if (kit.approved) {
            this.setState({ confirmDeletingItem: item });
        } else {
            this.removeComponent(item);
        }
    }

    cloneEFData = (item, efDataSource) => {
        const { saveJobItem, kit } = this.props;
        const { items } = kit;
        const from = items.find((t) => t.itemName === efDataSource && t.itemType === 'EF');
        const to = items.find((t) => t.rn === item.rn);

        to.approved = from.approved;
        to.component = from.component;
        to.formData = from.formData;
        to.itemCode = from.itemCode;
        to.version = from.version;
        to.vp = from.vp;
        kit.items = [...items];
        kit.approved = false;
        kit.approvePdf = null;
        saveJobItem(kit);
    }

    removeComponent = (item, rejectPrevious) => {
        const { saveJobItem, kit } = this.props;
        const { items } = kit;
        const t = items.find((o) => o.itemName === item.itemName);
        t.itemCode = null;
        t.version = null;
        t.vp = null;
        t.approved = null;
        t.itemId = null;
        t.formData = null;
        t.component = null;
        kit.items = [...items];
        kit.approved = false;
        if (rejectPrevious && kit.approvedPdf) {
            this.cancelPdf(kit.approvedPdf);
            kit.approvePdf = null;
        }
        saveJobItem(kit);

        this.setState({ confirmDeletingItem: null });
    }

    handleLogoChange = () => {
        const { saveJobItem, kit } = this.props;
        kit.useCustomerLogo = !kit.useCustomerLogo;
        saveJobItem(kit);
    }

    startEditFormModal = (id, index, code, formData) => {
        const { loadFormSchema } = this.props;
        loadFormSchema(code);
        this.setState({
            proofingItem: {
                id, index, code, formData
            },
            openFormModal: true
        });
    };

    onFormApproved = (formData) => {
        const { proofingItem } = this.state;
        const { saveJobItem, kit } = this.props;
        const approvedItem = this.filterIncludeItems(kit.items)[proofingItem.index];
        approvedItem.formData = { ...formData };
        approvedItem.approved = true;
        approvedItem.component = { ...formData, approved: true };
        approvedItem.proofFileId = formData.proofFileId;
        kit.items = [...kit.items];
        saveJobItem(kit);
        this.setState({ proofingItem: null, openFormModal: false });
    }

    approveSingleItem = () => {
        const { previewingItem } = this.state;
        const { saveJobItem, kit } = this.props;
        const approvedItem = this.filterIncludeItems(kit.items).find((i) => i.itemName === previewingItem.itemName && i.itemCode === previewingItem.itemCode && i.component && i.component.cvw == previewingItem.component.cvw);
        approvedItem.component.approved = true;
        kit.items = [...kit.items];
        saveJobItem(kit);
        this.setState({ previewingItem: null });
    }

    onPreview = () => {
        const { items, kit, customer } = this.props;
        if (kit.approved) {
            this.setState({
                openProofPreview: true,
                previewPdf: {
                    preview: kit.approvedPdf
                }
            });
            return;
        }

        this.setState({ generating: true });
        const itemData = [];

        for (const o of items) {
            if (!o.itemName.endsWith('_Version')) {
                const i = {
                    name: o.itemName, value: o.itemCode
                };
                if (o.formData) {
                    i.fileId = o.formData.proofFileId;
                }
                if (o.component && o.component.preview) {
                    i.fileId = o.component.id || o.component.proofFileId;
                }

                itemData.push(i);

                const iv = items.find((t) => t.itemName === `${i.name}_Version`);
                if (iv) {
                    itemData.push({
                        name: iv.itemName, value: iv.itemCode
                    });
                }
            }
        }

        const fd = new FormData();
        fd.append('components', JSON.stringify(itemData));
        fd.append('useCustomerLogo', !!(customer.logoInd && customer.logo && kit.useCustomerLogo));
        if (kit.ci) { fd.append('ci', JSON.stringify(kit.ci)); }

        http.post(`direct-order/preview-p1b-kit/${kit.id}/${customer.id || 0}`, fd)
            .then((dl) => this.setState({ openProofPreview: true, previewPdf: dl }))
            .finally(() => this.setState({ generating: false }));
    }

    approvePdf = () => {
        const { previewPdf } = this.state;
        const { saveJobItem, kit, onSave } = this.props;
        if (!kit.approved) {
            kit.approved = true;
            kit.approvedPdf = previewPdf.preview;
            if (previewPdf.ids) {
                kit.proofFileIds = previewPdf.ids;
            } else {
                kit.proofFileId = previewPdf.id;
            }
            saveJobItem(kit);
            this.closeProof();
            onSave();
        }
    }

    closeProof = () => {
        this.setState({ openProofPreview: false, previewPdf: null });
    }

    filterIncludeItems = (items, exception) => {
        return items.filter((i) => (i.itemName === exception && (i.itemCode || i.userInputPlaceholder))
            || (!i.itemName.endsWith('_Version') && i.itemName.indexOf('TOC_Description') < 0 && (i.itemCode || i.userInputPlaceholder)));
    }

    previewItem = (previewingItem) => {
        this.setState({ previewingItem });
    }

    upload = (e, item) => {
        console.log('#ODS# ~ file: KitCrosswalk.js ~ line 274 ~ KitCrosswalk ~ item', item);
        if (!e.target.files.length) return;
        const file = e.target.files[0];
        getBase64(file).then(async (fileData) => {
            const fd = new FormData();
            fd.append('fileData', fileData);
            fd.append('fileName', file.name);
            this.setState({ uploading: true });
            http.post('store/upload-item-file', fd)
                .then((uploadData) => {
                    if (uploadData.message || uploadData.displayMessage) {
                        message.error(uploadData.message || uploadData.displayMessage);
                    } else {
                        const { saveJobItem, kit } = this.props;
                        const { items } = kit;
                        const t = items.find((o) => o.itemName === item.itemName);
                        t.itemCode = file.name;
                        t.version = '';
                        t.vp = '';
                        t.component = uploadData;
                        t.component.approved = false;
                        t.formData = uploadData;
                        const tv = items.find((o) => o.itemName === `${item.itemName}_Version`);
                        if (tv) {
                            tv.itemCode = '';
                        }

                        kit.items = [...items];
                        saveJobItem(kit);
                    }
                }).finally(() => this.setState({ uploading: false }));
        });
        e.target.value = null;
    };

    render() {
        const {
            customer, kit, items, onSave, lob, jobType, disableEF, saveJobItem
        } = this.props;
        const {
            openFind, found, codeValue, nameValue, finding, generating, openProofPreview, previewPdf, confirmDeletingItem, confirmUpdatingItem,
            previewingItem, uploading
        } = this.state;
        if (customer.logoInd && customer.logoInd === 'Y' && customer.logo && (typeof kit.useCustomerLogo === 'undefined')) {
            kit.useCustomerLogo = true;
            saveJobItem(kit);
        }
        const loading = !items;
        if (loading) {
            return (
                <div>
                    <CircularProgress />
                </div>
            );
        }

        if (openFind) {
            return (
                <>
                    <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell />
                                    <TableCell>
                                        <TextField
                                            label="Item Code"
                                            autoFocus
                                            value={codeValue}
                                            name="codeValue"
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={this.onCriteriaChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton aria-label="search" onClick={this.handleSearch}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>Version</TableCell>
                                    <TableCell>WHS</TableCell>
                                    <TableCell>
                                        <TextField
                                            label="Item Name"
                                            value={nameValue}
                                            name="nameValue"
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={this.onCriteriaChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton aria-label="search" onClick={this.handleSearch}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>DP</TableCell>
                                    <TableCell>VP</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ minHeight: '50rem' }}>
                                {finding && (
                                    <TableRow>
                                        <TableCell
                                            colSpan={20}
                                            style={{ textAlign: 'center', padding: '1rem' }}
                                        >
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!finding && found && found.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={20}>
                                            <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No Items</span>
                                        </TableCell>

                                    </TableRow>
                                )}
                                {!finding && found && found.length > 0 && found.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.id}
                                        >
                                            <TableCell scope="row">
                                                <Button onClick={() => this.selectComponent(row)}>
                                                    <AddIcon />
                                                </Button>
                                            </TableCell>
                                            <TableCell scope="row">
                                                {row.code}
                                            </TableCell>
                                            <TableCell scope="row" align="center">
                                                {row.version}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {row.whs}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {row.dp}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {row.vp}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={20}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            style={{ float: 'right', marginLeft: '1rem', marginRight: '1rem' }}
                                            onClick={this.backToList}
                                        >
                                            Back
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>

                        </Table>
                    </TableContainer>
                </>
            );
        }

        const allowUploadAttachment = lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM');
        const includedItems = this.filterIncludeItems(items, allowUploadAttachment ? 'Cover_Letter_Inside' : '');

        includedItems.forEach((e) => {
            if (!e.component && e.formData) {
                e.component = e.formData;
            }
        });

        const missingRequiredItem = includedItems.some((i) => (i.required && !i.itemCode) || (i.itemId && !i.approved) || (i.component && !i.component.approved));
        const { openFormModal, proofingItem } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {customer.logoInd && customer.logo && customer.logoInd === 'Y' && (
                        <FormControlLabel
                            control={<Switch name="use_customer_logo" />}
                            label={`Use logo ${customer.logo}`}
                            checked={kit.useCustomerLogo}
                            onChange={this.handleLogoChange}
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Button color="primary" variant="contained" style={{ float: 'right' }} onClick={onSave}>
                        Save
                    </Button>
                    <Button color="primary" variant="outlined" disabled={missingRequiredItem || generating} style={{ float: 'right', marginLeft: '1rem', marginRight: '1rem' }} onClick={this.onPreview}>
                        {generating && (
                            <CircularProgress
                                color="secondary"
                                size={12}
                            />
                        )}
                        Preview
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper} style={{ marginTop: '1rem', maxHeight: '30rem' }}>
                        <Table size="small" stickyHeader aria-label="found customers table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item Type</TableCell>
                                    <TableCell>Item</TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {includedItems.map((row, i) => {
                                    let allowUpload = false;
                                    if (allowUploadAttachment) {
                                        const uName = row.itemName.toUpperCase();
                                        allowUpload = (uName.startsWith('BENEFIT_SUMMARY') && !uName.includes('PAGE'))
                                            || uName.startsWith('COVER_LETTER_INSIDE');
                                    }

                                    let efDataSource = null;
                                    if (row.itemType === 'EF' && !row.itemCode) {
                                        efDataSource = includedItems.find((c) => c.itemType === 'EF' && c.itemCode)?.itemName;
                                    }

                                    return (
                                        <TableRow
                                            hover

                                            key={row.itemName}
                                        >
                                            <TableCell>{row.itemName} {row.required && (<span style={{ color: 'red' }}>*</span>)}</TableCell>
                                            <TableCell>
                                                {(row.userInputPlaceholder || (allowUploadAttachment && row.itemName === 'Cover_Letter_Inside')) && row.itemCode && row.component
                                                    ? (
                                                        <Link
                                                            component="button"
                                                            variant="body2"
                                                            style={{ color: (row.component.approved ? '#7d3f98' : 'red'), textDecoration: 'underline' }}
                                                            onClick={() => {
                                                                this.previewItem(row);
                                                            }}
                                                        >
                                                            {row.itemCode}
                                                        </Link>
                                                    )
                                                    : (<span> {row.itemCode}</span>)}

                                                {row.vp && row.vp.length > 3 && (
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        style={{ color: (row.approved ? 'green' : 'red'), marginLeft: '1rem' }}
                                                        onClick={() => {
                                                            this.startEditFormModal(row.itemId, i, row.vp, row.formData);
                                                        }}
                                                    >
                                                        {row.vp}
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {row.userInputPlaceholder && !(disableEF && row.itemName.startsWith('Enrollment_Form'))
                                                    ? (
                                                        <Button onClick={() => this.startPickItem(row)}>
                                                            <EditIcon />
                                                        </Button>
                                                    )
                                                    : null}
                                                {
                                                    allowUpload && (

                                                        <IconButton color="primary">
                                                            <label htmlFor={`user-address-upload-${i}`} style={{ cursor: 'pointer' }}>
                                                                {uploading
                                                                    ? <CircularProgress color="secondary" size={12} />
                                                                    : <UploadIcon />}

                                                                <Input
                                                                    type="file"
                                                                    inputProps={{ id: `user-address-upload-${i}`, accept: '.pdf' }}
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => this.upload(e, row)}
                                                                />
                                                            </label>
                                                        </IconButton>
                                                    )
                                                }
                                                {row.userInputPlaceholder && row.itemCode && <Button onClick={() => this.deselectItem(row)}><DeleteIcon /></Button>}
                                                {
                                                    efDataSource && <Button onClick={() => this.cloneEFData(row, efDataSource)}><SyncIcon /></Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>

                    <ProofingModal
                        open={openProofPreview}
                        pdf={previewPdf && previewPdf.preview}
                        approved={kit.approved}
                        onApprove={this.approvePdf}
                        onCancel={this.closeProof}
                    />
                    {!!proofingItem && openFormModal && (

                        <EditFormModal
                            open={openFormModal}
                            proofingItem={proofingItem}
                            onApproved={this.onFormApproved}
                            handleClose={() => this.setState({ openFormModal: false, proofingItem: null })}
                        />
                    )}

                </Grid>

                <Dialog
                    open={!!confirmDeletingItem}
                    onClose={() => this.setState({ confirmDeletingItem: null })}
                    aria-labelledby="Confirm deleting selected component"
                    aria-describedby="Confirm deleting selected component"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure you want to remove this item from approved kit? You will need to proof this kit again.</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmDeletingItem: null })} color="primary">
                            No
                        </Button>
                        <Button
                            onClick={() => { this.removeComponent(confirmDeletingItem, true); }}
                            color="primary"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!confirmUpdatingItem}
                    onClose={() => this.setState({ confirmUpdatingItem: null })}
                    aria-labelledby="Confirm updating selected component"
                    aria-describedby="Confirm updating selected component"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure you want to change this item from approved kit? You will need to proof this kit again.</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ confirmUpdatingItem: null })} color="primary">
                            No
                        </Button>
                        <Button
                            onClick={() => { this.rejectAndPickItem(confirmUpdatingItem); }}
                            color="primary"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                {previewingItem && (
                    <ItemPreview
                        show
                        onClose={() => this.setState({ previewingItem: null })}
                        onApproved={this.approveSingleItem}
                        item={previewingItem}
                    />
                )}
            </Grid>
        );
    }
}
