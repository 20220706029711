import bmActions from './action';

export default (
    state = {
        loading: false,
        saving: false,
        pagedData: null,
        criteria: {},

        openEditor: false,
        editingItem: null,
        deletingId: null
    },
    action
) => {
    switch (action.type) {
        case bmActions.LOADING:
            return { ...state, loading: action.loading };
        case bmActions.SAVING:
            return { ...state, loading: action.saving };
        case bmActions.SET_CRITERIA:
            return { ...state, criteria: action.criteria };
        case bmActions.SET_PAGED_DATA:
            return { ...state, pagedData: action.pagedData };
        case bmActions.SET_OPEN_EDITOR:
            return { ...state, openEditor: action.openEditor };
        case bmActions.SET_EDITING_ITEM:
            return { ...state, editingItem: action.editingItem };
        case bmActions.SET_DELETING_ID:
            return { ...state, deletingId: action.deletingId };

        default:
            return state;
    }
};
