import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import SelectCustomerStep from 'pages/direct-order/steps/SelectCustomerStep';
import React from 'react';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1024,
        minHeight: 320,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    },
    compactTable: {

        '& .MuiTableCell-sizeSmall': {
            padding: '.25rem'
        }
    }
}));

const CustomerLookupModal = ({
    open, handleClose, setCustomer
}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <SelectCustomerStep
                    setIsNextReady={() => { }}

                    onCustomerSelected={v => {
                        setCustomer(v);
                        handleClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default CustomerLookupModal;
