
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pingOneLogin, setErrorMsg } from './action';
import PingOneLogin from './PingOneLogin';

const mapStateToProps = ({ pingOneLoginView, appView }) => {
    return {
        error: pingOneLoginView.error,
        loading: pingOneLoginView.loading,
        isAuthUser: appView.isAuthUser
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            pingOneLogin, setErrorMsg
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PingOneLogin);
