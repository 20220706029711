import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export default function ConfirmDeleteDialog({ open, onClose, msg }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{msg || 'Are you sure you want to remove this item from the cart?'}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => onClose(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={() => onClose(true)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
