import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('PASSWORD_SETTINGS_VIEW', {
    LOADING: null,

    SET_MESSAGE: null,
    SET_USER_GROUPS: null,

    SET_SETTINGS: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setMessage = actionCreator(actions.SET_MESSAGE, 'message');
export const submitChange = (oldPassword, newPassword) => (dispatch, getState) => {
    const { user } = getState().appView;
    dispatch(setMessage(''));
    dispatch(setLoading(true));
    const f = new FormData();
    f.append('oldPassword', oldPassword);
    f.append('newPassword', newPassword);
    // http.post('customer/change-password', f)
    //     .then((data) => {
    //         dispatch(setMessage(data));
    //         dispatch(setUserInfo({
    //             ...user,
    //             customFields: {
    //                 ...user.customFields,
    //                 isPasswordChangeRequired: false
    //             }
    //         }));
    //     })
    //     .finally(() => dispatch(setLoading(false)));
};

const setSettings = actionCreator(actions.SET_SETTINGS, 'settings');
export const getSettings = () => (dispatch) => {
    http.get('customer/password-settings').then((data) => {
        console.log('#ODS# ~ file: action.js ~ line 42 ~ http.get ~ data', data);
        dispatch(setSettings(data));
    });
};

const setUserGroups = actionCreator(actions.SET_USER_GROUPS, 'userGroups');
export const getUserGroups = () => (dispatch) => {
    http.get('customer/user-groups').then((data) => {
        dispatch(setUserGroups(data));
    });
};
