import DateFnsUtils from '@date-io/date-fns';
import {
    Box, Button,

    Dialog, DialogActions, DialogTitle, Grid, IconButton,
    Modal,
    Paper, Select, Table, TableBody, TableCell, TableContainer, MenuItem,
    InputLabel,

    TableHead, TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import RichEditor from 'components/rich-editor';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LobUserGroupDialog from './LobUserGroupDialog';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const MessageEditor = ({
    open, onClose, data, onSave, saving
}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const [item, setItem] = useState(data);
    const [deleting, setDeleting] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [openAssignment, setOpenAssignment] = useState(false);

    useEffect(() => {
        setItem(data); setOpenDialog(open);
        return () => {

        };
    }, [data, open]);

    const classes = useStyles();
    const onFieldChange = (e) => {
        setItem({ ...item, [e.target.name]: e.target.value });
    };

    if (!item) return null;

    const addAssignment = (lob, userGroup) => {
        if (!item.lobXUserGroups.some((x) => x.lob === lob && x.userGroup === userGroup)) {
            item.lobXUserGroups = [...item.lobXUserGroups, { lob, userGroup }];
            setItem(item);
        }
        setOpenAssignment(false);
    };

    const deleteAssignment = () => {
        item.lobXUserGroups = [...item.lobXUserGroups.filter((x) => x.lob !== deleting.lob && x.userGroup !== deleting.userGroup)];
        setItem(item);
        setDeleting(null);
    };

    return (
        <Modal
            open={openDialog}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={2} className="row-item">
                        <Grid item xs={12}>
                            <RichEditor
                                initContent={item.content}
                                onChange={(v) => onFieldChange({ target: { name: 'content', value: v } })}
                            />
                        </Grid>
                        <Grid item xs={3}>

                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                fullWidth
                                format="MM/dd/yyyy"
                                id="date"
                                label="Start Date"
                                maxDate={item.endTime ? new Date(item.endTime) : null}
                                value={item.startTime ? new Date(item.startTime) : null}
                                inputVariant="outlined"
                                onChange={(d) => {
                                    onFieldChange({ target: { name: 'startTime', value: d ? d.toLocaleDateString() : '' } });
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'Start Date'
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <KeyboardDatePicker
                                disableToolbar
                                fullWidth
                                variant="inline"
                                format="MM/dd/yyyy"
                                id="date"
                                label="End Date"
                                minDate={item.startTime ? new Date(item.startTime) : null}
                                value={item.endTime ? new Date(item.endTime) : null}
                                inputVariant="outlined"
                                onChange={(d) => {
                                    onFieldChange({ target: { name: 'endTime', value: d ? d.toLocaleDateString() : '' } });
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'End Date'
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Display Location</InputLabel>
                            <Select
                                value={item.isHeader}
                                onChange={(e) => onFieldChange({ target: { name: 'isHeader', value: e.target.value } })}
                                fullWidth
                                label="Display Location"
                            >
                                <MenuItem value={false}>Home page only</MenuItem>
                                <MenuItem value>Header (all pages)</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>

                <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>LOB</TableCell>
                                <TableCell>User Group</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => {
                                            setOpenAssignment(true);
                                        }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                item.lobXUserGroups.map((d) => (
                                    <TableRow key={`${d.lob}-${d.userGroup}`}>
                                        <TableCell component="th" scope="row">
                                            {d.lob}
                                        </TableCell>
                                        <TableCell>
                                            {d.userGroup}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => setDeleting(d)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box mt={2}>
                    <Button
                        className="btn place-order-btn"
                        style={{ float: 'right' }}
                        variant="contained"
                        onClick={() => onSave(item)}
                        color="primary"
                        disabled={saving}
                    >Save
                    </Button>
                </Box>

                <Dialog
                    open={!!deleting}
                    onClose={() => setDeleting(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure you want to delete this assignment?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setDeleting(null)} color="primary">
                            No
                        </Button>
                        <Button onClick={() => deleteAssignment()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <LobUserGroupDialog
                    open={openAssignment}
                    onClose={() => setOpenAssignment(false)}
                    onSave={addAssignment}
                />
            </div>
        </Modal>
    );
};
const mapStateToProps = ({ bannerMessageView }) => {
    return {
        open: bannerMessageView.openEditor,
        saving: bannerMessageView.saving
    };
};
export default connect(
    mapStateToProps,
    {},
)(MessageEditor);
