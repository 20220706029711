import loginActions from './action';

export default (
    state = {
        loading: false,
        error: null
    },
    action
) => {
    switch (action.type) {
        case loginActions.LOADING:
            return { ...state, loading: action.loading };
        case loginActions.ERROR:
            return { ...state, error: action.errorMessage };
        default:
            return state;
    }
};
