import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React from 'react';

const filter = createFilterOptions();

export default function AutoSelect({
    options, label, name, onChange, value, disabled
}) {
    const onValueChange = (event, newValue) => {
        onChange({
            target: {
                value: newValue.map((nv) => nv.inputValue),
                name
            }
        });
    };

    return (
        <Autocomplete
            multiple
            id={`checkboxes-tags-${name}`}
            onChange={onValueChange}
            options={options}
            renderOption={(option) => option.title}
            disableCloseOnSelect
            disabled={disabled}
            value={value}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={label} />
            )}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`
                    });
                }

                return filtered;
            }}
        />
    );
}
