import React, { PureComponent } from 'react';

export default class PingOneLogin extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { pingOneLogin, match: { params: { token } } } = this.props;

        if (token && token !== 'undefined') { pingOneLogin(token); } else { setTimeout(() => window.location = 'https://www.aetnaseniorproducts.com', 2000); }
    }

    render() {
        const { error, match: { params: { token } } } = this.props;
        const createMarkup = (cf) => { return { __html: cf }; };

        let msg = 'LOADING...';
        if (error) {
            if (error === "sso-unauthorized") {
                msg = "<p>Thank you for your interest in accessing the Aetna Ordering Portal. According to our records a personal profile has not yet been created for you. To request a profile be created, please complete the <a href='https://aetnao365.sharepoint.com/:w:/r/sites/ProviderDirectories/_layouts/15/Doc.aspx?sourcedoc=%7B1D819B6D-0CF4-4855-991E-384CE78B8597%7D&file=ODS%20-%20Request%20User%20Access%20FOA_CSP%20-%208.15.2020.docx&action=default&mobileredirect=true&cid=415623e1-373f-4af9-b336-3e94a5c7e88b' target='_blank'>user access request form</a> and email it to the Aetna Fulfillment Team at AetnaFulfillmentTeam@Aetna.com. You will be notified once a profile is created, and your access is enabled (within 48 hours of your submission request).";
            }
            else {
                msg = error;
            }
        } else if (!token || token === 'undefined') {
            msg = 'You are being redirected to <b>www.aetnaseniorproducts.com</b>';
        }

        return (
            <div dangerouslySetInnerHTML={createMarkup(msg)} />
        );
    }
}
