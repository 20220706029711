import { Box, Grid, Typography } from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import React from 'react';
import fallbackSrc from 'utils/fallbackSrc';

export default function ProductOrderConfirmView({ orderData, user }) {

    const { customFields: orderContent } = orderData;
    let addresses = [];
    if (orderContent && orderContent.basketItems) {
        for (const item of orderContent.basketItems) {
            if (item.customFields && item.customFields.outputType && item.customFields.outputType === 'Digital Only') continue;

            for (const ship of item.shippings) {
                const s = ship.customerAddress ? addresses.find((a) => a.id === ship.customerAddress.id) : null;
                if (s) {
                    s.items.push({
                        code: item.code,
                        name: item.name,
                        thumbnail: orderContent.customFields.thumbnails.find((t) => t.code === item.code).thumbnail,
                        quantity: ship.quantity,
                        shippingMethod: ship.shippingMethod,
                        shippingMethodData: ship.shippingMethodData,
                        materialType: orderContent.customFields.materialType
                    });
                } else {
                    addresses.push({
                        ...ship.customerAddress,
                        status: ship.status,
                        items: [{
                            code: item.code,
                            name: item.name,
                            thumbnail: orderContent.customFields.thumbnails.find((t) => t.code === item.code).thumbnail,
                            quantity: ship.quantity,
                            shippingMethod: ship.shippingMethod,
                            shippingMethodData: ship.shippingMethodData,
                            materialType: orderContent.customFields.materialType
                        }]
                    });
                }
            }
        }
    }

    if (orderData.jobType === 'OE') {
        addresses = orderData.orderShipping.filter((s) => !!s.shippingMethodCode).map((s) => ({
            ...s,
            items: s.orderShippingItem.map((osi) => ({
                ...osi,
                ...JSON.parse(orderData.orderItem.find((oi) => oi.id === osi.orderItemId).customFields),
                quantity: osi.quantity,
                trackingLinks: osi.navTrackingNumber
                    ? osi.navTrackingNumber.split(',').map((n) => ({
                        trackingLink: (s.shippingMethodCode.startsWith('UPS'))
                            ? orderData.trackingLink.replace('$TrackingNumber$', n)
                            : null,
                        trackingNumber: n
                    }))
                    : null
            }))
        }));
    } else {
        addresses = orderData.orderShipping.map((s) => ({
            ...s,
            items: s.orderShippingItem.map((osi) => ({
                ...osi,
                ...JSON.parse(orderData.orderItem.find((oi) => oi.id === osi.orderItemId).customFields),
                trackingLinks: osi.navTrackingNumber
                    ? osi.navTrackingNumber.split(',').map((n) => ({
                        trackingLink: (orderData.jobType === 'Broker' || s.shippingMethodCode.startsWith('UPS'))
                            ? orderData.trackingLink.replace('$TrackingNumber$', n)
                            : null,
                        trackingNumber: n
                    }))
                    : null
            }))
        }));
    }

    console.log('#ODS#: ProductOrderConfirmView -> addresses', addresses);
    const shippingNotes = orderContent && orderContent.customFields && orderContent.customFields.shippingNotes;
    const upsShippingNotes = orderContent && (orderContent.upsShippingNotes || (orderContent.customFields && orderContent.customFields.upsShippingNotes));
    const isOE = orderData.jobType === 'OE';
    const toSpan = (t) => {
        if (t) return <span> | {t}</span>;
        return null;
    };
    return (
        <div className="order-confirm-view">

            {addresses.map((a, i) => (
                <Grid key={a.id} container spacing={3} style={{ marginTop: '2rem' }}>
                    <Grid item xs={2}>
                        <Typography variant="h6">
                            Address {i + 1}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <span className="table-description">
                            <span>
                                {`Ship to: ${a.firstName} ${a.lastName} `}
                            </span>
                            {a.email
                                && <span className="email">{`<${a.email}>`}</span>}
                            {toSpan(a.phone)}
                            {toSpan(a.address)}
                            {toSpan(a.address2)}
                            {toSpan(a.company)}
                            {toSpan(a.city)}
                            {toSpan(a.state)}
                            {toSpan(a.zip)}
                        </span>
                        {a.spouseFirstName ? <div>Spouse: {a.spouseFirstName} {a.spouseLastName}</div> : null}
                    </Grid>
                    <Grid item xs={2}>
                        Status: <b>{a.status || 'Processing'}</b>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid container spacing={3} style={{ borderTop: '4px #ddd solid', borderBottom: '1px #ddd solid', marginBottom: '1rem' }} className="table-title">
                                <Grid item xs={6}>
                                    Product
                                </Grid>
                                <Grid item xs={1}>
                                    Quantity
                                </Grid>
                                <Grid item xs={2}>
                                    Delivery Date
                                </Grid>
                                <Grid item xs={3}>
                                    Tracking Number
                                </Grid>
                                <Grid item xs={1} />
                            </Grid>
                            <Grid container className="table-content">
                                {
                                    a.items.map((c) => {
                                        return (
                                            <Grid key={c.code} item xs={12}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6} className="table-item">
                                                        <Grid container spacing={2}>
                                                            {isOE && (
                                                            <Grid item xs={5}>
                                                                <img
                                                                    onError={fallbackSrc}
                                                                    src={c.thumbnail || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'}
                                                                    alt={c.name}
                                                                    style={{ width: '150px' }}
                                                                />
                                                            </Grid>
                                                            )}
                                                            <Grid item xs={isOE ? 7 : 12}>
                                                                <b>{c.name}</b> <br />
                                                                {!isOE && c.code}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1} align="center" className="table-item">
                                                        {c.quantity}
                                                    </Grid>
                                                    <Grid item xs={2} className="table-item">
                                                        {a.deliveryDueDate && (orderData.jobType !== 'Broker' && orderData.jobType !== 'telesales') ? new Date(a.deliveryDueDate).toLocaleDateString() : ''}
                                                    </Grid>
                                                    <Grid item xs={3} className="table-item">
                                                        {orderData.jobType !== 'Broker' ? (<div>Ship Date: {a.shipDate}</div>) : ''}
                                                        {c.shippedDate ? (<div>Actual Ship Date: {c.shippedDate}</div>) : ''}
                                                        {
                                                            (c.trackingLinks || []).map((t, index) => (
                                                                t.trackingLink
                                                                    ? ((index + 1) % 4 === 0 ? <span><a target="_blank" style={{ marginRight: '.5rem' }} href={t.trackingLink}>{t.trackingNumber}</a><br /></span> : <span><a target="_blank" style={{ marginRight: '.5rem' }} href={t.trackingLink}>{t.trackingNumber}</a></span>)
                                                                    : <span style={{ marginRight: '.5rem' }}>{(t.trackingNumber || '')}</span>
                                                            ))
                                                        }
                                                    </Grid>
                                                    <Grid item xs={1} className="table-item" />
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            ))}
            {orderContent.totalPrintPrice && <Box style={{ float: 'right' }}>Total Print Price: <b>${orderContent.totalPrintPrice}</b></Box>}

            {shippingNotes && (
                <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Special Instruction
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <NoteIcon color="primary" /> {shippingNotes}
                    </Grid>
                </Grid>
            )}
            {upsShippingNotes && (
                <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Shipping Notes
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <NoteIcon color="primary" /> {upsShippingNotes}
                    </Grid>
                </Grid>
            )}

        </div>

    );
}
