import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSavedJobs, saveCriteria, getSavedTemplates } from './action';
import SavedJob from './SavedJob';

const mapStateToProps = ({ appView: { user }, savedJobView: { savedJobs, criteria } }) => {
    return { user, savedJobs, criteria };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getSavedJobs, saveCriteria, getSavedTemplates
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SavedJob);
