import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SearchIcon from '@material-ui/icons/Search';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Button, Grid, TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Checkbox,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { bindActionCreators } from 'redux';
import { findCustomer, setFound } from './action';
import history from '../../utils/history';
import { startImpersonate } from '../../actions/app';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        minHeight: 220,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const ImpersonateModal = (props) => {
    const [keyword, setKeyword] = useState('');
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, handleClose, found, findCustomer: searchCustomer, startImpersonate
    } = props;

    const classes = useStyles();

    const handleSearch = () => {
        searchCustomer(keyword);
    };

    const startOBO = (login) => {
        startImpersonate(login);
        handleClose();
        history.push('/catalog');
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={5}>
                    <Grid item xs={8}>
                        <TextField
                            size="small"
                            label="Email/User Name/Agent Writing Number"
                            value={keyword}
                            fullWidth
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button color="primary" size="large" variant="contained" onClick={handleSearch}>
                            <SearchIcon />  Search
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ marginTop: '2rem' }}>
                    <Table size="small" aria-label="simple table" stickyHeader style={{ maxHeight: '800px' }}>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Email / User Name</TableCell>
                                <TableCell>Agent Writing Number</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {found && found.length > 0 && found.map((row) => (
                                <TableRow key={row.id} hover>
                                    <TableCell>{row.login}</TableCell>
                                    <TableCell>{row.agentWritingNumber}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => startOBO(row.login)}>
                                            <SupervisedUserCircleIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </Modal>
    );
};

const mapStateToProps = ({ accountView: { found } }) => ({
    found
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { findCustomer, setFound, startImpersonate },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateModal);
