import { setUserInfo } from 'pages/login/action';
import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('PASSTHROUGH_VIEW', {
    REMOVE_USER_INFO: null,
    SET_USER_INFO: null,
    LOADING: null,
    ERROR: null
});
export default actions;

const setErrorMessage = actionCreator(actions.ERROR, 'errorMessage');
const setLoading = actionCreator(actions.LOADING, 'loading');

export const passthrough = (salt, token) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setErrorMsg(null));

    const formData = new FormData();
    formData.append('token', token);
    formData.append('salt', salt);

    http.post('auth/passthrough', formData)
        .then((data) => {
            const { message } = data;
            if (message) {
                dispatch(setErrorMessage(message));
                return;
            }

            dispatch(setUserInfo(data));

            history.push('/');
        }).finally(() => dispatch(setLoading(false)));
};

export const setErrorMsg = (msg) => (dispatch) => {
    dispatch(setErrorMessage(msg));
};
