import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('ORDERS_VIEW', {
    LOADING: null,
    SET_CRITERIA: null,

    SET_ORDERS: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setOrders = actionCreator(actions.SET_ORDERS, 'orders');

export const saveCriteria = actionCreator(actions.SET_CRITERIA, 'criteria');

export const getOrders = (pageNumber, pageSize, criteria) => (dispatch) => {
    dispatch(setLoading(true));
    http.post(`store/orders/${pageNumber}/${pageSize}`, criteria || {})
        .then((data) => dispatch(setOrders(data)))
        .finally(() => dispatch(setLoading(false)));
};
