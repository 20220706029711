import {
    Button,
    CircularProgress, FormControl, Grid, IconButton,
    Input, InputLabel, MenuItem, Select
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/Backup';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import { showMessage } from 'actions/app';
import InvalidAddressDialog from 'components/InvalidAddressDialog';
import message from 'components/Message';
import RecommendedAddress from 'components/RecommendedAddress';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import {
    addToAddresses, loadAddressesByPage, removeAddressAt,
    removeAllAddress, removeUploadedAddress, setAddressField,
    setPickPackFile, setValidate, updateAddressQty, uploadUserAddressFile,
    updateAddressDeliveryPoint
} from '../action';
import AddressForm from './ShippingAddress/AddressForm';
import AddressTable from './ShippingAddress/AddressTable';
import ShippingAddressModal from './ShippingAddressModal';
import UploadPickPackModal from './upload-pp';

const ShippingAddressStep = (props) => {
    const { lob, jobType, subCode } = useParams();
    const [canAdd, setCanAdd] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [validatingAddress, setValidatingAddress] = useState(false);
    const [openInvalid, setOpenInvalid] = useState(false);
    const [openRecommended, setOpenRecommended] = useState(false);
    const [recommended, setRecommended] = useState(null);
    const [isPOBox, setIsPOBox] = useState(false);
    const [showUploadPickPack, setShowUploadPickPack] = useState(false);

    const {
        addressInfo, setAddressField: setFieldValue, showMessage, totalAddressCount,
        addToAddresses, addresses, uploadUserAddressFile, updateAddressQty, removeAddressAt, uploading, addressCode, addressPagination, loadAddressesByPage, loadingAddressPage,
        setPickPackFile: savePickPackFile, removeUploadedAddress, inventoryAddresses, updateAddressDeliveryPoint,

        jumpTo
    } = props;

    const allowRemoveUploadedAddress = lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM');

    useEffect(() => {
        setCanAdd(!!addressInfo.last1
            && !!addressInfo.address
            && !!addressInfo.zip
            && !!addressInfo.country);
        const isValid = (addresses && addresses.length) || (addressPagination && addressPagination.totalCount > 0);
        props.setIsNextReady(isValid);
    }, [addressInfo, addressPagination, addresses, props]);

    const handleFieldChange = (e) => {
        if (jobType === 'INVENTORY') return;
        setFieldValue(e.target.name, e.target.value);
        setIsPOBox(false);
    };
    const clearAll = () => {
        for (const k in addressInfo) {
            setFieldValue(k, '');
        }
        setIsPOBox(false);
    };

    const handleQtyChange = (index, qty) => {
        if (qty) {
            const q = parseInt(qty, 10);
            if (q > 0) { updateAddressQty(index, q); }
        }
    };

    const onInventoryAddressSelected = (e) => {
        const selected = inventoryAddresses.find((a) => a.id == e.target.value);
        console.log('#ODS# ~ file: ShippingAddressStep.js ~ line 80 ~ onInventoryAddressSelected ~ selected', selected);

        for (const k in selected) {
            if (k !== 'id')
                setFieldValue(k, selected[k]);
        }
    };

    const handleRemoveAddr = (index) => {
        removeAddressAt(index);
    };

    const handleRemoveUploadedAddr = (id) => {
        removeUploadedAddress(id);
    };

    const upload = (e) => {
        if (!e.target.files.length) return;
        const file = e.target.files[0];
        getBase64(file).then(async (fileData) => {
            await uploadUserAddressFile(fileData, lob, jobType);
        });
        e.target.value = null;
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const hasPOBox = (s) => {
        if (s) {
            const ps = s.replace(/\./g, '').toLowerCase().trim();

            return ps.startsWith('po ');
        }
        return false;
    };

    const clearAllUploadedAddress = () => {
        const { removeAllAddress: flushAll } = props;
        flushAll();
    };

    const addAddress = () => {
        const addrs = {
            Address1: addressInfo.address,
            Address2: addressInfo.address2,
            City: addressInfo.city,
            State: addressInfo.state,
            Zipcode: addressInfo.zip,
            Country: addressInfo.country || 'United States',
            DeliveryPoint: ''
        };

        if (addressInfo.country !== 'United States' || jobType === 'INVENTORY') {
            addToAddresses(jobType === 'FJE');
            return;
        }

        if ((jobType === 'AJEKT' || jobType === 'AJEPP') && (hasPOBox(addressInfo.address) || hasPOBox(addressInfo.address2) || hasPOBox(addressInfo.address3))) {
            setIsPOBox(true);
            return;
        }

        setValidatingAddress(true);

        http.post('store/validate-address', addrs).then((r) => {
            updateAddressDeliveryPoint(r.deliveryPoint ? r.deliveryPoint : '');
            if (!r.Success) {
                if (r.StatusMessage === 'Insufficient Data') {
                    let msg = 'Your address input is insufficient.';
                    if (!addrs.City) {
                        msg += ' Please populate City';
                    } else if (!addrs.State) {
                        msg += ' Please populate State';
                    }
                    message.error(msg);
                } else {
                    // console.log(r);
                    // this.setState({ addressInfo: { ...addressInfo, deliveryPoint: r.deliveryPoint } });
                    // console.log(addressInfo);
                    setOpenInvalid(true);
                }
            } else {
                if (r.POBox && (jobType === 'AJEKT' || jobType === 'AJEPP')) {
                    setIsPOBox(true);
                    return;
                }

                const addressR = {
                    ...addressInfo,
                    address: r.Address1,
                    address2: r.Address2,
                    city: r.City,
                    state: r.State,
                    zip: r.Zipcode,
                    deliveryPoint: r.DeliveryPoint || ''
                };
                // console.log("addressR: ", addressR);
                if (addressInfo.address !== r.Address1 || addressInfo.address2 !== r.Address2 || addressInfo.city !== r.City || addressInfo.state !== r.State || addressInfo.zip !== r.Zipcode) {
                    if (!addressInfo.city && r.City) {
                        setFieldValue('city', r.City);
                    }
                    if (!addressInfo.state && r.State) {
                        setFieldValue('state', r.State);
                    }
                    setRecommended(addressR);
                    setOpenRecommended(true);
                } else {
                    addToAddresses(jobType === 'FJE');
                }
            }
        }).finally(() => setValidatingAddress(false));
    };

    const hasAddressQty = jobType === 'AJEKT';
    const allowUpload = jobType === 'AJEKT'
        || (jobType === 'IM' && (lob === 'cml' || lob === 'p1b'))
        || (jobType === 'AJEPP' && lob === 'cml');

    const supportRecipient2 = lob === 'p1b';

    return (
        <>
            <Grid container>
                <Grid item style={{ marginTop: '1rem' }} xs={12}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <AddressForm supportRecipient2={supportRecipient2} addressInfo={addressInfo} handleFieldChange={handleFieldChange} />
                        {/* {jobType === 'AJEKT' && (
                            <Grid item xs={1}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="QTY"
                                    type="text"
                                    variant="outlined"
                                    name="qty"
                                    value={addressInfo.qty || ''}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                        )} */}
                        <Grid item xs={12}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                fontWeight="fontWeightMedium"
                                disabled={!(canAdd) || validatingAddress}
                                style={{ marginRight: '1rem' }}
                                onClick={() => addAddress()}
                            >
                                <AddIcon /> Add
                            </Button>

                            <Button
                                size="large"
                                color="primary"
                                variant="outlined"
                                fontWeight="fontWeightMedium"
                                style={{ marginRight: '1rem' }}
                                onClick={() => setOpenModal(true)}
                            >
                                <SearchIcon /> Find
                            </Button>

                            <Button
                                size="large"
                                color="primary"
                                variant="outlined"
                                fontWeight="fontWeightMedium"
                                disabled={validatingAddress}
                                style={{ marginRight: '1rem' }}
                                onClick={clearAll}
                            >
                                <ClearAllIcon /> Clear
                            </Button>
                            {allowUpload && (
                                <IconButton color="primary">
                                    <label htmlFor="user-address-upload" style={{ cursor: 'pointer' }}>
                                        {uploading
                                            ? <CircularProgress color="secondary" size={12} />
                                            : <UploadIcon />}

                                        <Input type="file" inputProps={{ id: 'user-address-upload', accept: '.xlsx, .xls' }} style={{ display: 'none' }} onChange={upload} />
                                    </label>
                                </IconButton>
                            )}
                            {isPOBox && <span style={{ color: 'red' }}>PO Box address is not allowed.</span>}
                            {
                                jobType === 'INVENTORY' && inventoryAddresses && inventoryAddresses.length > 0 && (
                                    <FormControl size="small" style={{ width: '10rem' }} variant="outlined">
                                        <InputLabel id="user-type-simple-select-outlined-label">ODS Address</InputLabel>
                                        <Select
                                            labelId="user-type-simple-select-outlined-label"
                                            id="user-type-simple-select-outlined"
                                            onChange={onInventoryAddressSelected}
                                            label="ODS Address"
                                            value=""
                                        >
                                            {inventoryAddresses.map((g) => (
                                                <MenuItem
                                                    key={g.id}
                                                    value={g.id}
                                                >
                                                    {g.whs}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            }
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>

            <AddressTable
                addresses={addresses}
                handleQtyChange={handleQtyChange}
                onRemove={handleRemoveAddr}
                removeUploadedAddr={handleRemoveUploadedAddr}
                hasAddressQty={hasAddressQty}
                totalAddressCount={totalAddressCount}
                loadAddressesByPage={loadAddressesByPage}
                addressCode={addressCode}
                addressPagination={addressPagination}
                loadingAddressPage={loadingAddressPage}
                clearAll={clearAllUploadedAddress}
                allowRemoveUploadedAddress={allowRemoveUploadedAddress}
                supportRecipient2={supportRecipient2}
            />
            <ShippingAddressModal open={openModal} handleClose={() => setOpenModal(false)} />

            {
                openRecommended && (
                    <RecommendedAddress
                        open={openRecommended}
                        onClose={() => { setRecommended(null); setOpenRecommended(false); }}
                        recommendedAddress={recommended}
                        originalAddress={addressInfo}
                        onSave={(a) => {
                            setOpenInvalid(false);
                            setRecommended(null);
                            setOpenRecommended(false);
                            addToAddresses(jobType === 'FJE', a);
                        }}
                    />
                )
            }
            {
                openInvalid && (
                    <InvalidAddressDialog
                        open={openInvalid}
                        onClose={() => setOpenInvalid(false)}
                        onSave={() => {
                            addToAddresses(jobType === 'FJE');
                            setOpenInvalid(false);
                        }}
                    />
                )
            }
            {
                showUploadPickPack && (
                    <UploadPickPackModal
                        open={showUploadPickPack}
                        jobType={jobType}
                        subCode={subCode}
                        onClose={(a, data) => {
                            setShowUploadPickPack(false);

                            if (data && data.totalCount) {
                                savePickPackFile(data);
                                jumpTo(jobType === 'AJEPP' ? 'Select Attributes' : 'Job Information');
                            }
                        }}
                    />
                )
            }
        </>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            addressInfo, addresses, totalAddressCount, uploading, addressCode, addressPagination, loadingAddressPage, inventoryAddresses
        }
    }
}) => ({
    addressInfo, addresses, totalAddressCount, uploading, addressCode, addressPagination, loadingAddressPage, removeUploadedAddress, inventoryAddresses
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            showMessage,
            setAddressField,
            setValidate,
            addToAddresses,
            uploadUserAddressFile,
            updateAddressQty,
            removeAddressAt,
            removeUploadedAddress,
            loadAddressesByPage,
            setPickPackFile,
            removeAllAddress,
            updateAddressDeliveryPoint
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressStep);
