import { loadAppSettings } from 'actions/app';
import { getBasketItems } from 'pages/cart/action';
import { setUserInfo } from 'pages/login/action';
import actionCreator from '../../utils/actionCreator';
import history from '../../utils/history';
import http from '../../utils/http';
import keyMirror from '../../utils/keyMirror';

export const actions = keyMirror('PINGONE_LOGIN_VIEW', {
    REMOVE_USER_INFO: null,
    LOADING: null,
    ERROR: null
});
export default actions;

const setErrorMessage = actionCreator(actions.ERROR, 'errorMessage');
const setLoading = actionCreator(actions.LOADING, 'loading');

export const pingOneLogin = (token) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setErrorMsg(null));

    http.get(`pingone/${token}`)
        .then((data) => {
            const { message } = data;
            if (message) {
                dispatch(setErrorMessage(message));
                return;
            }

            window.localStorage.setItem('user', JSON.stringify(data));
            dispatch(loadAppSettings());
            dispatch(getBasketItems());

            dispatch(setUserInfo(data));

            if (data.customFields.p2_Catalog) {
                history.push('/catalog');
            } else if (data.customFields.npn) {
                history.push('/broker');
            } else if (data.customFields.userType === 'small_group_broker') {
                history.push('/small-group');
            } else if (data.customFields.userType === 'telesales') {
                history.push('/telesales');
            } else {
                history.push('/');
            }
        }).finally(() => dispatch(setLoading(false)));
};

export const setErrorMsg = (msg) => (dispatch) => {
    dispatch(setErrorMessage(msg));
};
