import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import actionCreator from 'utils/actionCreator';

export const actions = keyMirror('TELESALES_VIEW', {
    LOADING: null,
    ERROR: null,
    SET_LIST_RESULT: null,
    SET_STATE: null
});

export default actions;

export const setLoading = actionCreator(actions.LOADING, 'loading');
export const setError = actionCreator(actions.ERROR, 'error');

export const setListResult = actionCreator(actions.SET_LIST_RESULT, 'result');
export const searchMaterials = (criteria, pageNumber) => (dispatch, getState) => {
    dispatch(setLoading(true));
    return http.post(`catalog/search-p1a-materials/${pageNumber}/24`, criteria).then((data) => {
        dispatch(setListResult(data));
        dispatch(setLoading(false));
    });
};
