
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import EditFormModal from './EditFormModal';


const mapStateToProps = ({
    directOrderView: {
        editFormView: {
            formSchema, generating, formProofPreview, openFormProofPreview
        }
    }
}) => ({
    formSchema, generating, formProofPreview, openFormProofPreview
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { ...actions },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFormModal);
