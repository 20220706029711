import React from 'react';

const pnpComponent = React.lazy(() => import('components/customize/PlugNPlay'));
const onlineBannerComponent = React.lazy(() => import('components/customize/OnlineBanner'));
const micrositePostcardComponent = React.lazy(() => import('components/customize/MicrositePostcard'));
const planComparisonComponent = React.lazy(() => import('components/customize/PlanComparison'));

const emptyReadyComponent = React.lazy(() => import('components/customize/Empty'));

export default function CreateCustomizeComponent(code) {
    switch (code) {
        case 'PlugNPlay':
        case 'IntlPlugNPlay':
        case 'BehavioralPlugNPlay':
        case 'CmlPlugNPlay':
        case 'A1APlugNPlay':
            return pnpComponent;
        case 'OnlineBanner':
        case 'OnlineBannerNoImg':
            return onlineBannerComponent;
        case 'MicrositePostcard':
            return micrositePostcardComponent;
        case 'PlanComparison':
            return planComparisonComponent;
        default:
            return emptyReadyComponent;
    }
}
