import { IconButton, Link, Dialog, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import React, { useState } from 'react';
import ConfirmPDFDownload from 'components/ConfirmPDFDownload';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);
export default function Relatedproducts({
    token, products, limits, addProductToCart
}) {
    const [openConfirm, setOpenConfirm] = useState(false);
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');
    const onAddClicked = (id, isKit, code, name, quantity, customFields) => {
        customFields.limit = limits.monthlyItemLimits[code.toLowerCase()] || limits.monthlyLimits[`${customFields.itemType.toLowerCase()}_${customFields.marketName ?? 'Default'}`];
        customFields.upperLimit = limits.upperLimits[`${customFields.itemType.toLowerCase()}_${customFields.marketName ?? 'Default'}`] || limits.upperLimits[`${customFields.itemType.toLowerCase()}_Default`];
        addProductToCart({
            id, isKit, code, name, qty: quantity, customFields
        }, false);
    };

    const fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '100%';
    };
    // const openProduct = (r) => {
    // };

    const confirmDownload = (e, type, link) => {
        if (type.startsWith('KT')) {
            e.preventDefault();
            setPdfDownloadUrl(link);
            setOpenConfirm(true);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Item Description</StyledTableCell>
                        <StyledTableCell>Item Code</StyledTableCell>
                        <StyledTableCell align="right">Order</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((row) => {
                        return (
                            <TableRow key={row.id + row.code}>
                                <StyledTableCell component="th" scope="row">
                                    <Typography
                                        color="primary"
                                        style={{ fontWeight: 'bold' }}
                                        aria-haspopup="true"
                                    >
                                        {row.name}
                                    </Typography>
                                    {/* <br />
                                    <Button
                                        onClick={() => openProduct(row)}
                                    >View Counties and Contract/PBPs
                                    </Button> */}
                                </StyledTableCell>
                                <StyledTableCell>{row.code}</StyledTableCell>
                                <StyledTableCell align="right">
                                    {
                                        row.downloadOnly
                                            ? (
                                                <Link href={`${row.downloadRoot}/${row.itemType}/${row.id}?Authorization=${token}`} onClick={(e) => confirmDownload(e, row.itemType, `${row.downloadRoot}/${row.itemType}/${row.id}?Authorization=${token}`)} target="_blank">
                                                    <CloudDownloadIcon color="primary" />
                                                </Link>
                                            )
                                            : (row.download ? (
                                                <div style={{ display: 'flex' }}>
                                                    <IconButton href={`${row.downloadRoot}/${row.itemType}/${row.id}?Authorization=${token}`} onClick={(e) => confirmDownload(e, row.itemType, `${row.downloadRoot}/${row.itemType}/${row.id}?Authorization=${token}`)} target="_blank">
                                                        <CloudDownloadIcon color="primary" />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => onAddClicked(row.id, row.isKit, row.code, row.name, 1,
                                                            {
                                                                whs: row.whs, version: row.version, itemType: row.customFields.materialType, thumbnail: row.thumbnail, marketName: row.marketName,
                                                                dp: row.dp,
                                                                type: row.type,
                                                                relatedProduct: true,
                                                                year: row.year,
                                                                hold_IND: row.hold_IND
                                                            })}
                                                    >
                                                        <AddShoppingCartIcon
                                                            color="primary"
                                                        />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <IconButton
                                                    onClick={() => onAddClicked(row.id, row.isKit, row.code, row.name, 1,
                                                        {
                                                            whs: row.whs, version: row.version, itemType: row.customFields.materialType, thumbnail: row.thumbnail, marketName: row.marketName,
                                                            dp: row.dp,
                                                            type: row.type,
                                                            relatedProduct: true,
                                                            year: row.year,
                                                            hold_IND: row.hold_IND
                                                        })}
                                                >
                                                    <AddShoppingCartIcon
                                                        color="primary"
                                                    />
                                                </IconButton>
                                            ))
                                    }
                                </StyledTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <ConfirmPDFDownload
                open={openConfirm}
                onClose={() => { setOpenConfirm(false); }}
                setOpenConfirm={setOpenConfirm}
                pdfDownloadUrl={pdfDownloadUrl}
                setPdfDownloadUrl={setPdfDownloadUrl}
            />
        </TableContainer>
    );
}
