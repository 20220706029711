import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToolBar from './ToolBar';

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);
