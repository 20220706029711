import formActions from './action';

const initialState = {
    loading: false,
    formSchema: null,
    generating: false,
    formProofPreview: null,
    openFormProofPreview: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case formActions.SET_FORM_SCHEMA:
            return { ...state, formSchema: action.schema };
        case formActions.GENERATING_FORM_PREVIEW:
            return { ...state, generating: action.generating };

        case formActions.SET_FORM_PROOF_PREVIEW:
            return { ...state, formProofPreview: action.preview, generating: false };

        case formActions.SET_FORM_PROOF_PREVIEW_OPEN:
            return { ...state, openFormProofPreview: action.open, generating: false };

        default:
            return state;
    }
};
