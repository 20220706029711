import {
    Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, Switch, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import http from 'utils/http';
import AutoSelect from './AutoSelect';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const FormModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, onClose, trak, onSave, meta: { costCenters, marketSegments, userGroups }, savedResult
    } = props;
    const ug = trak && trak.userGroupId
        ? userGroups.find((g) => g.id === trak.userGroupId).name
        : '';
    const isBroker = ug === 'Broker';

    const t = trak || {};
    const fv = t.micrositeRole === 'User' ? {
        ...t,
        na: t.micrositeMarketSegments.includes('National Accounts'),
        mm: t.micrositeMarketSegments.includes('Middle Market'),
        pl: t.micrositeMarketSegments.includes('Public and Labor'),
        ai: t.micrositeMarketSegments.includes('Large Group,Small Medium Enterprise,Canada,Government,Health Services'),
        payflex: t.payFlexEntry === 'Y'
    } : (
        t.micrositeRole === 'AdminItnl' ?
            {
                ...t,
                ai: t.micrositeMarketSegments.includes('Large Group,Small Medium Enterprise,Canada,Government,Health Services')
            }
            : t
    );

    const [formData, setFormData] = React.useState(fv);
    const [errorMessage, setErrorMessage] = useState('');

    const classes = useStyles();

    const onFieldChange = (e) => {
        const value = Array.isArray(e.target.value) ? [...new Set(e.target.value.filter((v) => !!v))].join(',') : e.target.value;
        setErrorMessage('');
        setFormData({ ...formData, [e.target.name]: value });
        if (e.target.name === 'userGroupId') {
            if (value) {
                const newUserGroup = userGroups.find((g) => g.id === value).name;
                const jobEntries = {
                    orderManagement: 'N',
                    oeJobEntry: 'N',
                    fastJobEntry: 'N',
                    kitJobEntry: 'N',
                    ppJobEntry: 'N',
                    imJobEntry: 'N',
                    sgJobEntry: 'N',
                    payFlexEntry: 'N'
                };
                switch (newUserGroup) {
                    case 'SU':
                        for (const k in jobEntries) {
                            jobEntries[k] = 'Y';
                        }
                        jobEntries.payFlexEntry = 'N';
                        break;
                    case 'FSR':
                        jobEntries.oeJobEntry = 'Y';
                        jobEntries.kitJobEntry = 'Y';
                        jobEntries.ppJobEntry = 'Y';
                        jobEntries.imJobEntry = 'Y';
                        break;
                    case 'OE':
                        jobEntries.oeJobEntry = 'Y';
                        break;
                    case 'SG Broker':
                        jobEntries.sgJobEntry = 'Y';
                        break;
                    default:
                        break;
                }

                setFormData({
                    ...formData,
                    [e.target.name]: value,
                    ...jobEntries
                });
            }
        }
    };

    const saveChange = () => {
        setErrorMessage('');
        if (userGroups.find((g) => g.id === formData.userGroupId).name === 'FSR'
            && formData.oeJobEntry === 'Y'
            && formData.marketSegments.split(',').every((m) => !['KEY', 'SEL', 'NAT', 'SGB'].includes(m))
        ) {
            setErrorMessage('When OE Job Entry is checked market segments must contains one of these: KEY, SEL, NAT, SGB');
            return;
        }

        if (formData.userGroupId && (
            ['SU', 'OE'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)
            || (userGroups.find((g) => g.id == formData.userGroupId).name === 'FSR' && formData.oeJobEntry === 'Y')
        ) && formData.micrositeRole === 'User' && !(formData.na || formData.mm || formData.pl || formData.payflex || formData.ai)
        ) {
            setErrorMessage('Microsite segment is required for microsite user');
            return;
        }

        if (formData.userGroupId && userGroups.find((g) => g.id == formData.userGroupId).name.startsWith('Telesales') && !formData.vendorGroupNumber
        ) {
            setErrorMessage('Vendor Group Number is required for telesales user');
            return;
        }

        if (userGroups.find((g) => g.id === formData.userGroupId).name === 'FSR' && formData.oeJobEntry !== 'Y') {
            formData.micrositeRole = null;
        }

        if (formData.micrositeRole === 'User') {
            const mss = [];
            if (formData.payflex) {
                formData.micrositeMarketSegments = '';
                formData.oeJobEntry = 'Y';
                formData.payFlexEntry = 'Y';
            } else if (formData.ai) {
                formData.payFlexEntry = 'N';
                formData.micrositeMarketSegments = 'Large Group,Small Medium Enterprise,Canada,Government,Health Services';
            } else {
                if (formData.na) {
                    mss.push('National Accounts');
                }
                if (formData.mm) {
                    mss.push('Middle Market');
                }
                if (formData.pl) {
                    mss.push('Public and Labor');
                }

                formData.payFlexEntry = 'N';
                formData.micrositeMarketSegments = mss.join(',');
            }
        } else if (!formData.micrositeRole && formData.payflex === true) {
            formData.micrositeRole = 'User';
            formData.payFlexEntry = 'Y';
            formData.micrositeMarketSegments = '';
        } else if (formData.micrositeRole === 'AdminIntl') {
            formData.payFlexEntry = 'N';
            formData.micrositeMarketSegments = 'Large Group,Small Medium Enterprise,Canada,Government,Health Services';
        }

        onSave(formData);
    };

    const [confirming, setConfirming] = useState(false);
    const confirmReset = () => {
        setSuccessMsg('');
        setErrorMsg('');
        setConfirming(true);
    };

    const [resetting, setResetting] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const resetPassword = () => {
        if (successMsg) {
            setSuccessMsg('');
            setErrorMsg('');
            setConfirming(false);
            return;
        }

        setResetting(true);

        const currentUserGroup = userGroups.find((g) => g.id === formData.userGroupId).name;
        const fd = new FormData();
        fd.append('id', formData.id);
        fd.append('userGroup', currentUserGroup);
        http.post('su/reset', fd).then((sent) => {
            if (sent) {
                setSuccessMsg(currentUserGroup === 'MSO' || currentUserGroup === 'Broker' ? 'Password has been reset to default.' : 'Reset password email has been sent.');
                setTimeout(() => {
                    setConfirming(false);
                    setResetting(false);
                }, 1500);
            } else {
                setErrorMsg('Fail to reset password.');
                setResetting(false);
            }
        }).catch((eee) => {
            setErrorMsg('Fail to reset password.');
            setResetting(false);
        });
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={1} className="row-item">
                    <Grid item xs={5}>
                        <TextField
                            label="User ID"
                            name="userName"
                            required
                            fullWidth
                            value={formData.userName || ''}
                            disabled={isBroker}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            label="Email"
                            name="email"
                            fullWidth
                            required={!formData.id}
                            value={formData.email || ''}
                            disabled={isBroker}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            display: !formData.id || trak.status !== 'Active' ? 'none' : 'block'
                        }}
                    >
                        <Button color="primary" disabled={!formData.userGroupId || (!formData.email && userGroups.find((g) => g.id === formData.userGroupId).name !== 'MSO')} onClick={confirmReset}>
                            Reset Password
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formData.firstName || ''}
                            onChange={onFieldChange}
                            disabled={isBroker}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Middle Initial"
                            name="middleInitial"
                            value={formData.middleInitial || ''}
                            onChange={onFieldChange}
                            disabled={isBroker}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formData.lastName || ''}
                            onChange={onFieldChange}
                            disabled={isBroker}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel id="user-type-simple-select-outlined-label">User Group</InputLabel>
                            <Select
                                labelId="user-type-simple-select-outlined-label"
                                id="user-type-simple-select-outlined"
                                value={formData.userGroupId}
                                onChange={onFieldChange}
                                name="userGroupId"
                                label="User Group"
                                disabled={
                                    isBroker
                                    || (formData.id > 0 && formData.userGroupId && userGroups.find((g) => g.id === formData.userGroupId).name === 'MSO')
                                    || (formData.id > 0 && formData.userGroupId && userGroups.find((g) => g.id === formData.userGroupId).name.startsWith('Telesales'))
                                }
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                {userGroups.map((g) => (
                                    <MenuItem
                                        key={g.id}
                                        value={g.id}
                                        disabled={g.name === 'Broker'
                                            || (formData.id > 0 && (g.name === 'MSO' && (!formData.userGroupId || userGroups.find((g) => g.id === formData.userGroupId).name !== 'MSO')))
                                            || (formData.id > 0 && (g.name.startsWith('Telesales') && (!formData.userGroupId || !userGroups.find((g) => g.id === formData.userGroupId).name.startsWith('Telesales'))))}
                                    >
                                        {g.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        style={{
                            display: formData.userGroupId && userGroups.find((g) => g.id == formData.userGroupId).name.startsWith('Telesales')
                                ? 'block' : 'none'
                        }}
                    >

                        <TextField
                            label="Vendor Group Number"
                            name="vendorGroupNumber"
                            required
                            fullWidth
                            value={formData.vendorGroupNumber || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: formData.userGroupId && (userGroups.find((g) => g.id == formData.userGroupId).name.startsWith('Telesales') || userGroups.find((g) => g.id == formData.userGroupId).name.startsWith('Inventory'))
                                ? 'none' : 'block'
                        }}
                    >
                        <AutoSelect
                            options={marketSegments.map((cc) => ({ inputValue: cc.value, title: cc.value }))}
                            label="Market Segments"
                            name="marketSegments"
                            disabled={isBroker}
                            value={(formData.marketSegments || '').split(',').filter((s) => !!s).map((s) => ({ inputValue: s, title: s }))}
                            onChange={onFieldChange}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        style={{
                            display: formData.userGroupId && (userGroups.find((g) => g.id == formData.userGroupId).name.startsWith('Telesales') || userGroups.find((g) => g.id == formData.userGroupId).name.startsWith('Inventory'))
                                ? 'none' : 'block'
                        }}
                    >
                        <AutoSelect
                            options={costCenters.map((cc) => ({ inputValue: cc.value, title: cc.value }))}
                            label="Cost Center"
                            name="costCenters"
                            disabled={isBroker}
                            value={(formData.costCenters || '').split(',').filter((s) => !!s).map((s) => ({ inputValue: s, title: s }))}
                            onChange={onFieldChange}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: formData.userGroupId && ['FSR'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)
                                ? 'block' : 'none'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.oeJobEntry === 'Y'}
                                            onChange={(e) => {
                                                const v = e.target.checked ? 'Y' : 'N';
                                                onFieldChange({ target: { name: 'oeJobEntry', value: v } });
                                            }}
                                            name="oeJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="OE Job Entry"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.sgJobEntry === 'Y'}
                                            disabled={formData.userGroupId && ['SU'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)}
                                            onChange={(e) => onFieldChange({ target: { name: 'sgJobEntry', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="sgJobEntry"
                                            color="primary"
                                        />
                                    )}
                                    label="Small Group Entry"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    display: formData.userGroupId && ['SU'].includes(userGroups.find((g) => g.id == formData.userGroupId).name)
                                        ? 'block' : 'none'
                                }}
                            >
                                <FormControlLabel
                                    control={(
                                        <Switch
                                            checked={formData.orderManagement === 'Y'}
                                            onChange={(e) => onFieldChange({ target: { name: 'orderManagement', value: e.target.checked ? 'Y' : 'N' } })}
                                            name="orderManagement"
                                            color="primary"
                                        />
                                    )}
                                    label="Order Management"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: formData.userGroupId && (['SU', 'OE'].includes(userGroups.find((g) => g.id == formData.userGroupId).name) || (userGroups.find((g) => g.id == formData.userGroupId).name === 'FSR' && formData.oeJobEntry === 'Y'))
                                ? 'block' : 'none'
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="user-type-simple-select-outlined-label">Microsite Role</InputLabel>
                                    <Select
                                        labelId="user-type-simple-select-outlined-label"
                                        id="user-type-simple-select-outlined"
                                        value={formData.payflex === true ? 'User' : formData.micrositeRole || ''}
                                        disabled={formData.payflex === true}
                                        onChange={onFieldChange}
                                        name="micrositeRole"
                                        label="Microsite Role"
                                    >
                                        <MenuItem value="">
                                            <em>Select</em>
                                        </MenuItem>
                                        <MenuItem value="User">
                                            User
                                        </MenuItem>
                                        <MenuItem value="Admin">
                                            Admin
                                        </MenuItem>
                                        <MenuItem value="AdminIntl">
                                            Admin Intl
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={9}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={formData.micrositeRole === 'AdminIntl' ? false : (formData.micrositeRole === 'Admin' || formData.na || false)}
                                            disabled={formData.micrositeRole === 'Admin' || formData.micrositeRole === 'AdminIntl' || formData.payflex === true || formData.ai === true}
                                            onChange={(e) => {
                                                setFormData({ ...formData, na: e.target.checked });
                                            }}
                                            name="na"
                                            color="primary"
                                        />
                                    )}
                                    label="National Accounts"
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={formData.micrositeRole === 'AdminIntl' ? false : (formData.micrositeRole === 'Admin' || formData.mm || false)}
                                            disabled={formData.micrositeRole === 'Admin' || formData.micrositeRole === 'AdminIntl' || formData.payflex === true || formData.ai === true}
                                            onChange={(e) => {
                                                setFormData({ ...formData, mm: e.target.checked });
                                            }}
                                            name="mm"
                                            color="primary"
                                        />
                                    )}
                                    label="Middle Market"
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={formData.micrositeRole === 'AdminIntl' ? false : (formData.micrositeRole === 'Admin' || formData.pl || false)}
                                            disabled={formData.micrositeRole === 'Admin' || formData.micrositeRole === 'AdminIntl' || formData.payflex === true || formData.ai === true}
                                            onChange={(e) => {
                                                setFormData({ ...formData, pl: e.target.checked });
                                            }}
                                            name="pl"
                                            color="primary"
                                        />
                                    )}
                                    label="Public and Labor"
                                />
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            // checked={formData.micrositeRole === 'Admin' || formData.micrositeRole === 'AdminIntl' || formData.ai || false}
                                            checked={formData.micrositeRole === 'AdminIntl' || formData.ai || false}
                                            disabled={formData.micrositeRole === 'Admin' || formData.na === true || formData.mm === true || formData.pl === true || formData.payflex === true}
                                            onChange={(e) => {
                                                setFormData({ ...formData, ai: e.target.checked });
                                            }}
                                            name="ai"
                                            color="primary"
                                        />
                                    )}
                                    label="Aetna International"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={2} mb={1}>
                    {!formData.isDeleted && (
                        <Button
                            className="btn place-order-btn"
                            disabled={!(formData.userName) || (!formData.userGroupId) || (!formData.id && (!formData.email)) || isBroker}
                            variant="contained"
                            onClick={saveChange}
                            color="primary"
                        >Save
                        </Button>
                    )}
                    <Button
                        className="btn"
                        variant="contained"
                        onClick={onClose}
                        style={{ float: 'right' }}
                    >Close
                    </Button>
                </Box>
                {errorMessage && <span style={{ color: 'red', paddingLeft: '1rem' }}>{errorMessage}</span>}
                {savedResult && savedResult.error && (
                    <Alert severity="error">
                        <AlertTitle>{savedResult.error}</AlertTitle>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>User Id: <b>{savedResult.userName}</b></Grid>
                            <Grid item xs={6}>Email: <b>{savedResult.email}</b></Grid>
                            <Grid item xs={6}>Name: <b>{savedResult.firstName} {savedResult.middleName} {savedResult.lastName}</b></Grid>
                            <Grid item xs={6}>User Group: <b>{savedResult.userGroup}</b></Grid>
                        </Grid>
                    </Alert>
                )}

                <Dialog
                    open={confirming}
                    onClose={() => setConfirming(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{'Are you sure you want to reset password of this account? '}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You must save your change before reset if you have edited the password email.
                            {successMsg !== '' && <Alert severity="success">{successMsg}</Alert>}
                            {errorMsg !== '' && <Alert severity="error">{errorMsg}</Alert>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={resetting} onClick={() => setConfirming(false)} color="primary">
                            No
                        </Button>
                        <Button disabled={resetting} onClick={() => resetPassword()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Modal>
    );
};

export default FormModal;
