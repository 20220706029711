import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RadioSelectCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            errorMessage: '',
            pickList: props.args.options || props.pickList || []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });
        }
        if (nextProps.pickList !== this.props.pickList && nextProps.pickList) {
            this.setState({ pickList: nextProps.pickList });
        }
    }

    onChange = (e) => {
        const { value } = e.target;
        this.setState({ value });
        const { row, col, updateRowData } = this.props;
        updateRowData && updateRowData(row, col, value);
    }

    render() {
        const { isReadOnly, prefix, col } = this.props;
        const { value, pickList } = this.state;

        return (
            <Box component="div" className={`${prefix}-radio-field`}>
                <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>{col.title}</InputLabel>
                    <Select
                        value={value}
                        onChange={this.onChange}
                        label={col.title}
                        disabled={isReadOnly}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {pickList && pickList.map((item, index) => {
                            return <MenuItem key={index} value={item[col.valueField]}>{item[col.textField]}</MenuItem>;
                        })}
                    </Select>
                    {
                        value === 'I wish to receive an order status' && (
                            <FormHelperText>Please click <Link to="/orders" color="primary" style={{ textDecoration: 'underline' }}>here</Link> to check your order history</FormHelperText>
                        )
                    }
                </FormControl>
            </Box>
        );
    }
}
RadioSelectCell.defaultProps = {
    prefix: 'editable-cell'
};
export default RadioSelectCell;
