import {
    Button,
    FormControl, InputLabel, MenuItem, Select,
    Table, TableCell, TableHead, TableRow, TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';

export default function SearchBar({ submitSearch, meta }) {
    const [criteria, setCriteria] = useState({
        userName: '', email: '', firstName: '', lastName: '', userGroupId: '', status: ''
    });

    const userGroups = meta ? meta.userGroups : [];

    return (
        <Table size="small" aria-label="order table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: '10rem' }}>
                        <TextField
                            label="User ID"
                            autoFocus
                            value={criteria.userName}
                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(criteria); } }}
                            onChange={(e) => setCriteria({ ...criteria, userName: e.target.value })}
                        />
                    </TableCell>
                    <TableCell style={{ width: '10rem' }}>
                        <TextField
                            label="Email"
                            autoFocus
                            value={criteria.email}
                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(criteria); } }}
                            onChange={(e) => setCriteria({ ...criteria, email: e.target.value })}
                        />
                    </TableCell>
                    <TableCell style={{ width: '10rem' }}>
                        <TextField
                            label="First Name"
                            autoFocus
                            value={criteria.firstName}
                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(criteria); } }}
                            onChange={(e) => setCriteria({ ...criteria, firstName: e.target.value })}
                        />
                    </TableCell>
                    <TableCell style={{ width: '10rem' }}>
                        <TextField
                            label="Last Name"
                            autoFocus
                            value={criteria.lastName}
                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(criteria); } }}
                            onChange={(e) => setCriteria({ ...criteria, lastName: e.target.value })}
                        />
                    </TableCell>
                    {meta && (
                        <TableCell>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="user-type-simple-select-outlined-label">User Group</InputLabel>
                                <Select
                                    labelId="user-type-simple-select-outlined-label"
                                    id="user-type-simple-select-outlined"
                                    value={criteria.userGroupId}
                                    onChange={(e) => setCriteria({ ...criteria, userGroupId: e.target.value })}
                                    name="userGroupId"
                                    label="User Group"
                                >
                                    <MenuItem value="">
                                        <em>Select</em>
                                    </MenuItem>
                                    {userGroups.map((g) => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </TableCell>
                    )}
                    <TableCell>
                        <FormControl fullWidth size="small" variant="outlined">
                            <InputLabel id="user-type-simple-select-outlined-label">Status</InputLabel>
                            <Select
                                labelId="user-type-simple-select-outlined-label"
                                id="user-type-simple-select-outlined"
                                value={criteria.status}
                                onChange={(e) => setCriteria({ ...criteria, status: e.target.value })}
                                name="status"
                                label="Status"
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Deleted">Deleted</MenuItem>
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <Button color="primary" onClick={() => submitSearch(criteria)}>
                            <SearchIcon /> Search
                        </Button>
                    </TableCell>
                </TableRow>
            </TableHead>
        </Table>
    );
}
