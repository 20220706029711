import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import ReactDOM from 'react-dom';

const useStyles = makeStyles(() => ({
    center: {
        top: 'calc(50vh - 50px)'
    },
    top: {
        top: '-50px'
    },
    bottom: {
        top: 'calc(100vh - 150px)'
    }
}));

const NotifyMessage = (props) => {
    const classes = useStyles();
    const { type = 'success', message = '', place = 'center' } = props;
    return (
        <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={place === 'center' ? classes.center : place === 'top' ? classes.top : classes.bottom}
        >
            <Alert severity={type}>{message}</Alert>
        </Snackbar>
    );
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toast = (props) => {
    const message = props.message || '';
    const toastProps = {
        show: true,
        type: props.type || 'success',
        place: props.place || 'center',
        message
    };

    const component = NotifyMessage;
    const div = document.createElement('div');
    document.body.appendChild(div);

    function setProps(partialProps) {
        const t = React.createElement(component, { ...toastProps, ...partialProps });
        ReactDOM.render(t, div);
    }

    function close() {
        setProps({ show: false });
        destroy();
    }

    function destroy() {
        ReactDOM.unmountComponentAtNode(div);
    }

    ReactDOM.render(React.createElement(component, toastProps), div);

    setTimeout(() => {
        close();
    }, props.duration || 3000);

    return {
        close,
        destroy
    };
};

const info = (msg, place) => {
    return Toast({ type: 'info', message: msg, place });
};

const error = (msg, place) => {
    return Toast({ type: 'error', message: msg, place });
};

const warning = (msg, place) => {
    return Toast({ type: 'warning', message: msg, place });
};

const success = (msg, place) => {
    return Toast({ type: 'success', message: msg, place });
};

const message = {
    info, error, warning, success
};

export default message;
