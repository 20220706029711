import { addProductToCart as addToCart } from 'components/basket/action';
import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('CATALOG_VIEW', {
    LOADING: null,
    SET_CATEGORY_TREE: null,
    SET_PRODUCTS: null,
    SET_PRODUCT_DETAIL: null,
    SET_FOUND: null,
    SET_NEW_THUMBNAIL: null,
    SET_NOT_READY_FOR_CART: null,
    SET_CROSSWALK: null,
    SET_SEARCH_RESULT: null,
    SET_PRICING: null,
    SET_EDITING_CUSTOM_FIELDS: null,
    SET_SEARCHING: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
const setSearching = actionCreator(actions.SET_SEARCHING, 'searching');

const setCategoryTree = actionCreator(actions.SET_CATEGORY_TREE, 'categoryTree');
export const getCategoryTree = () => (dispatch) => {
    dispatch(setLoading(true));
    http.get('catalog/category')
        .then((data) => {
            dispatch(setCategoryTree(data));
            if (data && data.length > 0) { history.push(`/catalog/${data[0].code}`); }
        })
        .finally(() => dispatch(setLoading(false)));
};
const setProducts = actionCreator(actions.SET_PRODUCTS, 'products');
export const getProducts = (code, pageNumber, pageSize) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSearching(false));
    dispatch(setSearchResult(null));
    http.get(`catalog/products/${code}/${pageNumber}/${pageSize}`)
        .then((data) => dispatch(setProducts(data)))
        .finally(() => dispatch(setLoading(false)));
};

const setPricing = actionCreator(actions.SET_PRICING, 'code', 'pricing');
export const setProduct = actionCreator(actions.SET_PRODUCT_DETAIL, 'productDetail');
export const getProduct = (code, category, id, productType) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const currentState = getState();
    const s = currentState.appView.user;
    const api = s.customFields.p2_Catalog ? `catalog/p2/${productType}/${id}` : `catalog/product-detail/${code}`;
    http.get(api)
        .then((data) => {
            dispatch(setNewThumbnail(null));
            dispatch(setCrosswalk(null));
            dispatch(setProduct(data));
            if (data.customFields.pricing && !currentState.catalogView.pricing.code) {
                dispatch(setPricing(code, data.customFields.pricing));
            }
        })
        .finally(() => dispatch(setLoading(false)));
};

export const setEditingCustomFields = actionCreator(actions.SET_EDITING_CUSTOM_FIELDS, 'editingCustomFields');
export const getCrosswalk = (lineId) => (dispatch) => {
    http.get(`store/get-crosswalk/${lineId}`)
        .then((data) => {
            dispatch(setEditingCustomFields(data));
        });
};

export const addProductToCart = (cartItem, gotoCart = true) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(addToCart(cartItem, gotoCart));
    dispatch(setLoading(false));
};

export const setFound = actionCreator(actions.SET_FOUND, 'found');
export const findItem = (code, name) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    fd.append('code', code);
    fd.append('name', name);
    http.post('catalog/find-p2-items', fd)
        .then((found) => dispatch(setFound(found)))
        .finally(() => dispatch(setLoading(false)));
};

export const setNewThumbnail = actionCreator(actions.SET_NEW_THUMBNAIL, 'newThumbnail');
export const setNotReadyForCart = actionCreator(actions.SET_NOT_READY_FOR_CART, 'notReadyForCart');
export const setCrosswalk = actionCreator(actions.SET_CROSSWALK, 'crosswalk');

export const setSearchResult = actionCreator(actions.SET_SEARCH_RESULT, 'searchResult');
export const searchP2 = (itemCode, stateCode, pageNumber, pageSize) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSearching(true));
    const fd = new FormData();
    fd.append('itemCode', itemCode);
    fd.append('stateCode', stateCode);
    fd.append('pageNumber', pageNumber);
    fd.append('pageSize', pageSize);
    http.post('catalog/search-p2-items', fd)
        .then((found) => dispatch(setSearchResult(found)))
        .finally(() => dispatch(setLoading(false)));
};
