import {
    Button, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';

export default function IMTableSection({ imTables, openUpload }) {
    return (
        <>
            <Typography variant="h6">
                IM Batch Table Files
                <Button
                    variant="outlined"
                    color="primary"
                    style={{ float: 'right', margin: '.5rem' }}
                    endIcon={<PublishIcon>Upload</PublishIcon>}
                    onClick={openUpload}
                >
                    Upload File
                </Button>
            </Typography>

            <TableContainer component={Paper} square>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>File Name</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Uploaded On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(!imTables || !imTables.length) && (
                            <TableRow>
                                <TableCell colSpan={20}>
                                    <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}>
                                        No IM Batch Table uploaded yet
                                    </span>
                                </TableCell>
                            </TableRow>
                        )}
                        {(imTables || []).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.fileName}</TableCell>
                                <TableCell>{row.totalCount}</TableCell>
                                <TableCell>{row.createdOn}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
