import {
    Box, Grid, Paper, Typography
} from '@material-ui/core';
import React from 'react';

export default function Comments({ comments }) {
    return (
        <>
            <Grid item xs={6}>
                <Typography variant="h6" color="primary">
                    Comments
                </Typography>
            </Grid>
            <Box mt={1} component={Paper} p={2}>
                {
                    comments.map((c) => (
                        <Grid key={c.id} container spacing={2}>
                            <Grid item xs={3}>
                                {new Date(c.createdOn).toLocaleString()} <b>{c.createdBy}</b>:
                        </Grid>
                            <Grid item xs={9}>
                                {c.content}
                            </Grid>
                        </Grid>
                    ))
                }

            </Box>
        </>
    );
}
