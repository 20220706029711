import { loadAppSettings } from 'actions/app';
import actionCreator from 'utils/actionCreator';
import history from 'utils/history';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('LOGIN_VIEW', {
    REMOVE_USER_INFO: null,
    SET_USER_INFO: null,
    LOADING: null,
    ERROR: null
});
export default actions;

const setErrorMessage = actionCreator(actions.ERROR, 'errorMessage');

export const setUserInfo = actionCreator(actions.SET_USER_INFO, 'userInfo');
const setLoading = actionCreator(actions.LOADING, 'loading');

export const login = (userName, password) => (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setErrorMsg(null));

    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('password', password);
    http.post('auth/login', formData)
        .then((data) => {
            const { message } = data;
            if (message) {
                dispatch(setErrorMessage(message));
                return;
            }

            if (data.customFields && data.customFields.passwordExpired) {
                data.customFields.isPasswordChangeRequired = true;
            }

            dispatch(setUserInfo(data));
            dispatch(loadAppSettings());
            if (data.customFields.p2_Catalog) {
                history.push('/catalog');
            } else if (data.customFields.npn) {
                history.push('/broker');
            } else if (data.customFields.userType === 'small_group_broker') {
                history.push('/small-group');
            } else if (data.customFields.userType === 'telesales') {
                history.push('/telesales');
            } else if (data.customFields.isPasswordChangeRequired) {
                history.push('/password');
            } else {
                history.push('/');
            }
        }).finally(() => dispatch(setLoading(false)));
};

export const setErrorMsg = (msg) => (dispatch) => {
    dispatch(setErrorMessage(msg));
};
