import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export default function SelectBar({ selected, onChange, disabled }) {
    const states = ['AK',
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'VT',
        'WA',
        'WI',
        'WV',
        'WY'];
    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                    fullWidth
                    disabled={disabled}
                    value={selected}
                    onChange={onChange}
                >
                    <MenuItem value="">SELECT</MenuItem>
                    {states.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    );
}
