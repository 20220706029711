import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getFavorites, getFilters, getLimits, getProductMeta, getRelatedProducts, loadProducts, saveSelection, setFavoritesOnly, setFilters, setSelectionOpen, setSelection, setCounties
} from './action';
import BrokerView from './BrokerView';

const mapStateToProps = ({
    brokerView: {
        editSelection, selection, filters, favorites, favoritesOnly, limits, countyList
    },
    appView: { user: broker, settings }
}) => ({
    editSelection, selection, broker, filters, favorites, favoritesOnly, limits, settings, countyList
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setSelectionOpen,
            saveSelection,
            getFilters,
            setFilters,
            loadProducts,
            getProductMeta,
            getFavorites,
            setFavoritesOnly,
            getLimits,
            getRelatedProducts,
            setSelection,
            setCounties
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrokerView);
