import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    }
}));


export default function FilterBar({ filters, onToggle }) {
    const classes = useStyles();

    return (
        <List
            subheader={<ListSubheader>Filter by Material Type</ListSubheader>}
            className={classes.root}
            dense
        >
            {filters.map((f) => {
                const labelId = `checkbox-list-label-${f.code}`;

                return (
                    <ListItem key={f.code} dense button>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={f.selected}
                                color="primary"
                                onChange={(e) => onToggle(f.code)}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={f.name} onClick={(e) => onToggle(f.code)} />
                    </ListItem>
                );
            })}
        </List>
    );
}
