import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import PasswordView from './PasswordView';

const mapStateToProps = ({ passwordView, appView: { user } }) => {
    return { ...passwordView, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PasswordView);
