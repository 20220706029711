import savedOrderActions from './action';

export default (
    state = {
        loading: false,
        savedOrders: null
    },
    action
) => {
    switch (action.type) {
        case savedOrderActions.LOADING:
            return { ...state, loading: action.loading };

        case savedOrderActions.SET_SAVED_ORDER:
            return { ...state, savedOrders: action.savedOrders };

        default:
            return state;
    }
};
