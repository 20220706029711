import {
    Box, Breadcrumbs,
    Button, Grid,
    IconButton, Link, Paper,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,
    Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import React, { PureComponent } from 'react';
import FormModal from './FormModal';
import SearchBar from './SearchBar';

const pageSize = 10;
const styles = theme => ({
    table: {
        '& .MuiTableCell-root': { padding: '.5rem ' },
        '& .MuiTableCell-root:last-child': { minWidth: '6rem ' },
        '& .MuiIconButton-root': { padding: '.5rem ' },
        th: { padding: '.5rem ' }
    }
});


class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            open: false,
            trak: null,

            criteria: {
                userName: '', email: '', firstName: '', lastName: '', status: ''
            },

            deleteConfirming: false,
            restoreConfirming: false,
            trakId: null,
            savedResult: null
        };
    }

    componentDidMount() {
        const { pageNumber, criteria } = this.state;
        const {
            getTrakUsers, getMeta, user, history
        } = this.props;

        if (user && !(user.customFields && user.customFields.userGroups && user.customFields.userGroups.length > 0 && user.customFields.userType === 'su')) {
            history.push('/');
        }

        getMeta();
        getTrakUsers(criteria, pageNumber, pageSize);
    }

    onSubmitSearch = (criteria) => {
        this.setState({ criteria });
        this.handleChangePage(null, 0, criteria);
    }

    handleChangePage = (_event, pageNumber = 0, newCriteria = null) => {
        const { criteria } = this.state;
        const { getTrakUsers } = this.props;
        getTrakUsers(newCriteria || criteria, pageNumber + 1, pageSize);
    }

    closeModal = () => {
        this.setState({ open: false, savedResult: null });
    }

    saveTrakUser = (formData) => {
        this.setState({ savedResult: null });
        const { saveTrak, getTrakUsers } = this.props;
        const {
            pageNumber, criteria
        } = this.state;
        saveTrak(formData, (savedResult) => {
            if (savedResult && savedResult.error) {
                this.setState({ savedResult });
            } else {
                getTrakUsers(criteria, pageNumber, pageSize);
                this.setState({ open: false });
            }
        });
    }

    openModal = (id) => {
        const { traks } = this.props;
        this.setState({
            open: true,
            trak: id ? traks.results.find((a) => a.id === id) : {
                id: 0,
                userName: '',
                userGroupId: '',
                firstName: '',
                lastName: '',
                fastJobEntry: 'N',
                kitJobEntry: 'N',
                ppJobEntry: 'N',
                imJobEntry: 'N',
                costCenters: '',
                marketSegments: '',
                isDeleted: false,
                oeJobEntry: 'N',
                orderManagement: 'N',
                sgJobEntry: 'N'
            }
        });
    }

    confirmDelete = (trakId) => {
        this.setState({ deleteConfirming: true, trakId });
    }

    delete = () => {
        const {
            criteria, pageNumber, trakId
        } = this.state;
        const { deleteTrak, getTrakUsers } = this.props;
        deleteTrak(trakId, () => {
            getTrakUsers(criteria, pageNumber, pageSize);
            this.setState({ deleteConfirming: false, trakId: null });
        });
    }

    confirmRestore = (trakId) => {
        this.setState({ restoreConfirming: true, trakId });
    }

    restore = () => {
        const {
            criteria, pageNumber, trakId
        } = this.state;
        const { restoreTrak, getTrakUsers } = this.props;
        restoreTrak(trakId, () => {
            getTrakUsers(criteria, pageNumber, pageSize);
            this.setState({ restoreConfirming: false, trakId: null });
        });
    }

    getUserGroupName = (row) => {
        if (!row.userGroupId) return '';
        const { meta } = this.props;
        if (!meta) return '';
        const sg = meta.userGroups.find((g) => g.id === row.userGroupId);
        return sg ? sg.name : '';
    }

    render() {
        const { traks, showMessage, meta, classes } = this.props;
        const {
            open, trak, savedResult
        } = this.state;
        const {
            deleteConfirming, restoreConfirming
        } = this.state;

        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box m1={2}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="#/account">
                                    Account
                                </Link>
                                <Typography color="textPrimary">Users Management</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer className={classes.table} component={Paper} style={{ fontSize: '0.875rem' }}>
                            <SearchBar
                                submitSearch={this.onSubmitSearch}
                                meta={meta}
                            />
                            <Table aria-label="order table">
                                <TableHead>
                                    <TableRow style={{ fontWeight: 'bold' }}>
                                        <TableCell>User ID / Email</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>User Group</TableCell>
                                        <TableCell>Cost Centers</TableCell>
                                        <TableCell>Market Segments</TableCell>
                                        <TableCell>Fast Job </TableCell>
                                        <TableCell>Kit Job </TableCell>
                                        <TableCell>P&P Job </TableCell>
                                        <TableCell>IM Job</TableCell>
                                        <TableCell>Small Group</TableCell>
                                        <TableCell>OE Job</TableCell>
                                        <TableCell>Order MGMT</TableCell>
                                        <TableCell>Microsite Role</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Updated On</TableCell>
                                        <TableCell>Updated By</TableCell>
                                        <TableCell>
                                            <IconButton color="secondary" onClick={() => this.openModal()}>
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {traks && traks.results.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {row.userName} {row.email && ` / ${row.email}`}
                                            </TableCell>
                                            <TableCell> {row.firstName} {row.lastName}</TableCell>
                                            <TableCell> {meta ? meta.userGroups.find((g) => g.id === row.userGroupId).name : ''}</TableCell>
                                            <TableCell style={{ maxWidth: '150px', wordBreak: 'break-word' }}> {row.costCenters}</TableCell>
                                            <TableCell>
                                                <Box style={{
                                                    maxWidth: '150px', wordBreak: 'break-word', maxHeight: '100px', overflow: 'auto'
                                                }}
                                                >
                                                    {row.marketSegments}
                                                </Box>
                                            </TableCell>
                                            <TableCell> <DoneIcon style={{ display: meta.userGroups.find((g) => g.id === row.userGroupId).name === 'MSO' || row.fastJobEntry === 'Y' ? 'block' : 'none' }} /></TableCell>
                                            <TableCell>
                                                <DoneIcon style={{
                                                    display:

                                                        row.kitJobEntry === 'Y'
                                                            && (meta.userGroups.find((g) => g.id === row.userGroupId).name !== 'FSR' || row.marketSegments.includes('MGRP') || row.marketSegments.includes('AVP') || row.marketSegments.includes('FEDS'))
                                                            ? 'block'
                                                            : 'none'
                                                }}
                                                />
                                            </TableCell>
                                            <TableCell> <DoneIcon style={{ display: row.ppJobEntry === 'Y' ? 'block' : 'none' }} /></TableCell>
                                            <TableCell> <DoneIcon style={{ display: row.imJobEntry === 'Y' ? 'block' : 'none' }} /></TableCell>
                                            <TableCell> <DoneIcon style={{ display: row.sgJobEntry === 'Y' ? 'block' : 'none' }} /></TableCell>
                                            <TableCell> <DoneIcon style={{ display: row.oeJobEntry === 'Y' ? 'block' : 'none' }} /></TableCell>
                                            <TableCell> <DoneIcon style={{ display: row.orderManagement === 'Y' ? 'block' : 'none' }} /></TableCell>
                                            <TableCell> {row.micrositeRole}</TableCell>
                                            <TableCell> {row.status}</TableCell>
                                            <TableCell> {row.updatedOn}</TableCell>
                                            <TableCell> {row.updatedBy}</TableCell>
                                            <TableCell>
                                                {meta && (
                                                    <>
                                                        {!row.isDeleted && (
                                                            <IconButton color="primary" onClick={() => this.openModal(row.id)}>
                                                                <EditIcon />
                                                            </IconButton>

                                                        )}
                                                        {row.status === 'Active'
                                                            ? (
                                                                <IconButton color="primary" onClick={() => this.confirmDelete(row.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            )
                                                            : (
                                                                <IconButton color="primary" onClick={() => this.confirmRestore(row.id)}>
                                                                    <SettingsBackupRestoreIcon />
                                                                </IconButton>
                                                            )}
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={20}>
                                            {traks
                                                && (
                                                    <TablePagination
                                                        component="div"
                                                        count={traks.totalCount}
                                                        page={traks.pageNumber - 1}
                                                        rowsPerPage={traks.pageSize}
                                                        rowsPerPageOptions={[traks.pageSize]}
                                                        onChangePage={this.handleChangePage}
                                                    />
                                                )}

                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        {open && (
                            <FormModal
                                open={open}
                                trak={trak}
                                meta={meta}
                                savedResult={savedResult}
                                onClose={this.closeModal}
                                onSave={this.saveTrakUser}
                                showMessage={showMessage}
                            />
                        )}
                    </Grid>

                </Grid>

                <Dialog
                    open={deleteConfirming}
                    onClose={() => this.setState({ deleteConfirming: false, trakId: null })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure you want to remove this user?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ deleteConfirming: false, trakId: null })} color="primary">
                            No
                        </Button>
                        <Button onClick={() => this.delete()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={restoreConfirming}
                    onClose={() => this.setState({ restoreConfirming: false, trakId: null })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure you want to activate this user?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({ restoreConfirming: false, trakId: null })} color="primary">
                            No
                        </Button>
                        <Button onClick={() => this.restore()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ListView);

