import http from '../../utils/http';
import keyMirror from '../../utils/keyMirror';
import actionCreator from '../../utils/actionCreator';

export const actions = keyMirror('CATALOG_VIEW', {
    LOADING: null,
    SET_FOUND: null
});

export default actions;


const setLoading = actionCreator(actions.LOADING, 'loading');
export const setFound = actionCreator(actions.SET_FOUND, 'found');
export const findCustomer = (login) => (dispatch) => {
    dispatch(setLoading(true));
    const fd = new FormData();
    fd.append('login', login);
    http.post('customer/find-p2-customer', fd)
        .then((found) => dispatch(setFound(found)))
        .finally(() => dispatch(setLoading(false)));
};
