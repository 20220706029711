import axios from 'axios';
import React, { useEffect } from 'react';
import { showMessage } from '../actions/app';
import history from '../utils/history';

const checkRequests = (Wrapped) => {
    function CheckRequests(props) {
        useEffect(() => {
            axios.interceptors.response.use((response) => {
                // Do something with response data
                return response;
            }, (error) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            window.localStorage.clear();
                            history.push('/login'); // we will redirect user into 503 page
                            break;
                        case 500:
                            showMessage(error.response.data.responseException.exceptionMessage, 'error');
                            break;
                        default:
                            break;
                    }
                } else {
                    // TODO: show error msg
                    // eslint-disable-next-line no-alert
                    showMessage(error.message || 'Unknown network error');
                }

                // Do something with response error
                return Promise.reject(error);
            });
        });

        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Wrapped {...props} />
        );
    }

    return CheckRequests;
};

export default checkRequests;
