import {
    Box, Grid, InputAdornment, Paper,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField,
    Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmDeleteDialog from 'pages/cart/ConfirmDeleteDialog';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import ContinueOrderDialog from './ContinueOrderDialog';

const pageSize = 12;

class SavedJob extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            openDeleteConfirm: false,
            deletingId: null,
            savedJobId: null
        };
    }

    loadData = (pageNumber, pageSize, orderId, fileNumber, firstName, lastName) => {
        const {
            getSavedJobs, getSavedTemplates, isTemplate
        } = this.props;

        if (isTemplate) {
            getSavedTemplates(pageNumber, pageSize, orderId, fileNumber, firstName, lastName);
        } else {
            getSavedJobs(pageNumber, pageSize, orderId, fileNumber, firstName, lastName);
        }
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const {
            criteria: {
                orderId, fileNumber, firstName, lastName
            }          
        } = this.props;
        this.loadData(pageNumber, pageSize, orderId, fileNumber, firstName, lastName);
    }

    handleChangePage = (_event, pageNumber) => {
        const {
            criteria: {
                orderId, fileNumber, firstName, lastName
            }            
        } = this.props;
        this.loadData(pageNumber + 1, pageSize, orderId, fileNumber, firstName, lastName);
        this.setState({ pageNumber });
    }

    addSavedItemToCart = (listId, itemId) => {
        const { addSavedItemToCart: addItemToCart } = this.props;
        addItemToCart(listId, itemId);
    }

    setCriteria = (name, value) => {
        const { saveCriteria: save, criteria } = this.props;
        save({ ...criteria, [name]: value });
    };

    searchOrder = () => {
        this.setState({ pageNumber: 1 });
        const {
            criteria: {
                orderId, fileNumber, firstName, lastName
            }
        } = this.props;
        this.loadData(1, pageSize, orderId, fileNumber, firstName, lastName);
    }

    continueWork = (savedName, savedId, savedOrderNumber, savedJob) => {
        const { history } = this.props;
        history.push({
            pathname: savedJob.hash.replace('#', ''),
            state: { savedJob: { ...savedJob, savedId, savedOrderNumber, savedName } }
        });
    }

    continueOrder = (savedJobId) => {
        this.setState({ savedJobId });
    }

    closeOrderDialog = (t) => {
        if (t === true) {
            const {
                pageNumber
            } = this.state;
            const {
                criteria: {
                    orderId, fileNumber, firstName, lastName
                }
            } = this.props;
            this.loadData(pageNumber, pageSize, orderId, fileNumber, firstName, lastName);
        }

        this.setState({ savedJobId: null });
    }

    deleteWork = (deletingId) => {
        this.setState({ deletingId, openDeleteConfirm: true });
    }

    onConfirmClose = (t) => {
        if (t) {
            const { deletingId } = this.state;
            http.get(`customer/delete-job/${deletingId}`).then(() => {
                this.setState({ deletingId: null, openDeleteConfirm: false, pageNumber: 1 });
                this.loadData(1, pageSize);
            });
        } else {
            this.setState({ deletingId: null, openDeleteConfirm: false });
        }
    }

    render() {
        const {
            savedJobs, user, criteria: {
                orderId, fileNumber, firstName, lastName
            }
        } = this.props;
        const {
            openDeleteConfirm, savedJobId
        } = this.state;
        if (!savedJobs) {
            return (
                <Box>
                    <Typography variant="h6" gutterBottom component="p">
                        loading...
                    </Typography>
                </Box>
            );
        }

        const containsBatchSaved = savedJobs && savedJobs.results && savedJobs.results.some((s) => s.fileNumber);
        return (
            <Grid container spacing={1}>
                <Grid item container>
                    <Grid item container>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                label="Saved Order #"
                                                value={orderId}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                                onChange={(e) => this.setCriteria('orderId', e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="search" onClick={this.searchOrder}>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>Saved On</TableCell>
                                        {

                                            containsBatchSaved && (
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        label="Batch File #"
                                                        value={fileNumber}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                                        onChange={(e) => this.setCriteria('fileNumber', e.target.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="search" onClick={this.searchOrder}>
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </TableCell>
                                            )
                                        }
                                        <TableCell />
                                    </TableRow>
                                    {(
                                        (user.customFields && user.customFields.marketSegments && user.customFields.marketSegments.includes('FEDS'))
                                        || (user.customFields && user.customFields.userType === 'su')
                                    ) && (
                                            <TableRow>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        label="First Name"
                                                        value={firstName}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                                        onChange={(e) => this.setCriteria('firstName', e.target.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="search" onClick={this.searchOrder}>
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        fullWidth
                                                        label="Last Name"
                                                        value={lastName}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                                        onChange={(e) => this.setCriteria('lastName', e.target.value)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton aria-label="search" onClick={this.searchOrder}>
                                                                        <SearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        )}
                                </TableHead>
                                <TableBody>
                                    {savedJobs.results.map((j) => (
                                        <TableRow key={j.id}>
                                            <TableCell>{j.name}</TableCell>
                                            <TableCell>{j.orderNumber || j.orderId}</TableCell>
                                            <TableCell>{new Date(j.createdOn).toLocaleDateString()}</TableCell>
                                            {
                                                containsBatchSaved
                                                && (<TableCell>{j.fileNumber}</TableCell>)
                                            }
                                            <TableCell>
                                                <IconButton onClick={() => (j.fileNumber
                                                    ? this.continueOrder(j.id)
                                                    : this.continueWork(j.name, j.id, j.orderNumber || j.orderId, j.customFields))}
                                                >
                                                    <RestoreIcon
                                                        color="primary"
                                                    />
                                                </IconButton>
                                                <IconButton onClick={() => this.deleteWork(j.id)}>
                                                    <DeleteIcon
                                                        color="primary"
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell colSpan={20}>
                                            <TablePagination
                                                component="div"
                                                count={savedJobs.totalCount}
                                                page={savedJobs.pageNumber - 1}
                                                rowsPerPage={savedJobs.pageSize}
                                                rowsPerPageOptions={[savedJobs.pageSize]}
                                                onChangePage={this.handleChangePage}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        <ConfirmDeleteDialog open={openDeleteConfirm} onClose={this.onConfirmClose} msg="Are you sure you want to remove this saved item?" />
                        {!!savedJobId && <ContinueOrderDialog open={savedJobId} onClose={this.closeOrderDialog} savedJobId={savedJobId} />}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default SavedJob;
