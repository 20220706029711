import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    }
}));


export default function CountyBar({ counties, onToggle }) {
    const classes = useStyles();
    // const selectedArr = selected.split(',').map((value) => value.trim());
    // console.log('County-Bar-Selected: ', selected);
    return (
        <List
            subheader={<ListSubheader>Counties</ListSubheader>}
            className={classes.root}
            dense
        >
            {counties.map((c) => {
                const labelId = `checkbox-list-label-${c.name}`;

                return (
                    <ListItem key={c.name} dense button>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={c.selected}
                                color="primary"
                                onChange={() => onToggle(c.name)}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={c.name === '' ? 'All' : c.name} onClick={() => onToggle(c.name)} />
                    </ListItem>
                );
            })}
        </List>
    );
}
