import http from 'utils/http';
import { actionCreator, keyMirror } from '../../exportUtil';

export const actions = keyMirror('DEMO_VIEW', {
    PRE_LOAD: null,
    LOAD_SUCCESS: null,
    ERROR: null,
    SET_ORDER_DATA: null,
    SET_HELP_INFO: null,
    UPDATE_HELP_INFO: null,
    RESET_HELP_INFO: null
});
export default actions;

export const error = actionCreator(actions.ERROR, 'errorMessages');

export const setHelpInfo = actionCreator(actions.SET_HELP_INFO, 'data');
export const loadHelpInfo = (helpType) => (dispatch, getState) => {
    let { userType } = getState().appView.user.customFields;
    userType = userType.toLowerCase();

    let userGuideLink;
    if (helpType === 'oe') {
        userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitCommercialDynamicOEUserGuide.pdf';
    } else if (helpType === 'p2') {
        userGuideLink = 'https://cdndev.memberdoc.com/f/ssi/Aetna SSI Agent Quick Guide-Jul2020v1.0.pdf';
    } else if (helpType === 'p1b') {
        userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitMedicareGroupFulfillmentUserGuide.pdf';
    } else if (helpType === 'cml') {
        userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitCommercialTraditionalUserGuide.pdf';
    } else if (helpType === 'sg') {
        userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitCommercialSmallGroupUserGuide.pdf';
    } else { // p1a p1b
        switch (userType) {
            case 'broker':
            case 'brokers':
                userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitBOMUserGuide.pdf';
                break;

            case 'fsr':
                userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitMedicareIndividualFulfillmentUserGuide.pdf';
                break;

            case 'telesales':
            case 'telesales admins':
                userGuideLink = 'https://cdndev.memberdoc.com/f/ONEkitMedicareIndividualTelesalesUserGuide.pdf';
                break;

            default:
                break;
        }
    }

    const desc = helpType === 'p2' ? '' : 'Your satisfaction is important to us! Use the form below to email us your questions about products, online orders, store experiences and more.';
    const tollFreeNumber = helpType === 'p2' ? '(866) 272-6630' : (helpType === 'oe' ? '(844) 954-2968' : null);
    http.get('customer/profile')
        .then((profile) => {
            // api request
            const data = {
                title: "We're Here To Help",
                description: desc,
                downloadTitle: 'Download Users Guide',
                downloadLink: userGuideLink,
                tollFreeNumber,
                dataValue: {
                    name: profile.firstName + (profile.lastName ? ' ' : '') + (profile.lastName || ''),
                    email: profile.email || '',
                    phone: profile.phone || '',
                    state: profile.state || '',
                    support: '',
                    comments: ''
                },
                dataCell: [
                    {
                        id: 'name',
                        title: 'Your Name',
                        dType: 'string',
                        type: 'textField',
                        defaultValue: '',
                        isRequired: true
                    },
                    {
                        id: 'state',
                        title: 'Your State',
                        dType: 'string',
                        type: 'textField',
                        defaultValue: '',
                        isReadOnly: true
                    },
                    {
                        id: 'email',
                        title: 'Your Email Address',
                        dType: 'string',
                        type: 'textField',
                        defaultValue: '',
                        isRequired: true
                    },
                    {
                        id: 'phone',
                        title: 'Your Phone Number',
                        dType: 'string',
                        type: 'textField',
                        defaultValue: '',
                        isRequired: true
                    },
                    {
                        id: 'support',
                        title: 'Need for Support',
                        dType: 'string',
                        type: 'radioSelect',
                        defaultValue: '',
                        args: {
                            options: userType.startsWith('broker')
                                ? [
                                    {
                                        text: '',
                                        value: ''
                                    },
                                    {
                                        text: 'How do I place my order?',
                                        value: 'How do I place my order?'
                                    },
                                    {
                                        text: 'I need assistance placing my order',
                                        value: 'I need assistance placing my order'
                                    },
                                    {
                                        text: 'Why cant I login to the Broker Ordering Module?',
                                        value: 'Why cant I login to the Broker Ordering Module?'
                                    },
                                    {
                                        text: 'Why cant I order a specific state?',
                                        value: 'Why cant I order a specific state?'
                                    },
                                    {
                                        text: 'Damaged, or incorrect, order was received',
                                        value: 'Damaged, or incorrect, order was received'
                                    },
                                    {
                                        text: 'Technical assistance required',
                                        value: 'Technical assistance required'
                                    },
                                    {
                                        text: 'I have a question about an MAPD, PDP or Med Supp Product',
                                        value: 'I have a question about an MAPD, PDP or Med Supp Product'
                                    },
                                    {
                                        text: 'What products should I sell in my area?',
                                        value: 'What products should I sell in my area?'
                                    },
                                    {
                                        text: 'I wish to receive an order status',
                                        value: 'I wish to receive an order status'
                                    }]
                                : [{
                                    text: '',
                                    value: ''
                                },
                                {
                                    text: 'I need assistance placing my order',
                                    value: 'I need assistance placing my order'
                                },
                                {
                                    text: 'Damaged, or incorrect, order was received',
                                    value: 'Damaged, or incorrect, order was received'
                                },
                                {
                                    text: 'I have a question about the content of product',
                                    value: 'I have a question about the content of product'
                                },
                                {
                                    text: 'Technical assistance required',
                                    value: 'Technical assistance required'
                                },
                                {
                                    text: 'I need assistance with my user ID and password',
                                    value: 'I need assistance with my user ID and password'
                                },
                                {
                                    text: 'I wish to receive an order status',
                                    value: 'I wish to receive an order status'
                                },
                                {
                                    text: 'Other',
                                    value: 'Other'
                                }]
                        },
                        isRequired: true,
                        isReadOnly: false,
                        textField: 'text',
                        valueField: 'value'
                    },
                    {
                        id: 'comments',
                        title: 'Comments or Questions',
                        dType: 'string',
                        type: 'textArea',
                        isRequired: true,
                        isReadOnly: false,
                        defaultValue: ''
                    }
                ]
            };
            if (!userType.startsWith('broker')) {
                data.dataCell = data.dataCell.filter((c) => c.id !== 'state');
                data.dataValue.state = null;
            }

            dispatch(setHelpInfo(data));
        });
};

export const updateHelpInfo = actionCreator(actions.UPDATE_HELP_INFO, 'key', 'value');
export const resetEvent = actionCreator(actions.RESET_HELP_INFO);
