import {
    Button, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import React from 'react';

export default function NewVersionDialog({
    open
}) {
    return (
        <Dialog open={open} onClose={() => { window.location.reload();}} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
            <DialogTitle id="alert-dialog-title">A new and improved version of ordering portal is now available</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please click the button to continue
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { window.location.reload();}} color="primary" variant="contained" autoFocus>
                    Refresh now
                </Button>
            </DialogActions>
        </Dialog>

    );
}
