import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('BOM_VIEW', {
    SET_SELECTION: null,
    SET_EDIT_SELECTION: null,
    SET_FILTERS: null,
    LOADING: null,
    ERROR: null,
    SET_LIST_RESULT: null,
    SET_PRODUCT_META: null,
    SET_FAVORITES: null,
    SET_LIMITS: null,
    SET_RELATED_PRODUCTS: null,
    SEARCH_TOKEN: null,
    SET_FAVORITES_ONLY: null,
    SET_COUNTIES: null
});
export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');
const setSearchToken = actionCreator(actions.SEARCH_TOKEN, 'searchToken');

const setErrorMessage = actionCreator(actions.ERROR, 'errorMessage');
export const setErrorMsg = (msg) => (dispatch) => {
    dispatch(setErrorMessage(msg));
};

export const setSelection = actionCreator(actions.SET_SELECTION, 'selection');
export const saveSelection = (selection) => (dispatch) => {
    dispatch(setSelection(selection));
    dispatch(setSelectionOpen(false));
};

export const setSelectionOpen = actionCreator(actions.SET_EDIT_SELECTION, 'open');

export const setFilters = actionCreator(actions.SET_FILTERS, 'filters');
export const getFilters = () => (dispatch) => {
    return http.get('broker/facets').then((data) => {
        dispatch(setFilters(data));
    });
};
export const setLimits = actionCreator(actions.SET_LIMITS, 'limits');
export const getLimits = () => (dispatch) => {
    return http.get('broker/limits').then((data) => {
        dispatch(setLimits(data));
    });
};

const setListResult = actionCreator(actions.SET_LIST_RESULT, 'result');
export const loadProducts = (pageNumber) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const current = getState().brokerView;
    // console.log('loadProducts: ', current);
    const itemQuery = {
        ...current.listQuery,
        ...current.selection,
        favoritesOnly: current.favoritesOnly,
        pageNumber: pageNumber || current.listQuery.pageNumber,
        itemTypes: current.filters.filter((f) => f.selected).map((f) => f.code),
        countyList: current.countyList.filter((f) => f.selected).map((f) => f.name)
    };

    const token = JSON.stringify(itemQuery);
    dispatch(setSearchToken(token));

    return http.post('broker/products', itemQuery).then((data) => {
        const latestToken = getState().brokerView.searchToken;
        if (latestToken !== token) {
            return;
        }

        dispatch(setListResult(data));
        dispatch(setLoading(false));
    });
};

const setProductMeta = actionCreator(actions.SET_PRODUCT_META, 'meta');
export const getProductMeta = (code, version, whs) => (dispatch) => {
    dispatch(setProductMeta(null));
    const fd = new FormData();
    fd.append('code', code);
    fd.append('version', version);
    fd.append('whs', whs);
    return http.post('broker/product-meta', fd).then((data) => {
        dispatch(setProductMeta(data));
    });
};

const setRelatedProducts = actionCreator(actions.SET_RELATED_PRODUCTS, 'products');
export const getRelatedProducts = (id, itemType, state, language) => (dispatch) => {
    dispatch(setRelatedProducts(null));
    if (itemType === 'KT') {
        return http.get(`broker/related-products/${id}/${state}/${language}`).then((data) => {
            dispatch(setRelatedProducts(data));
        });
    }
};

export const setFavoritesOnly = actionCreator(actions.SET_FAVORITES_ONLY, 'favoritesOnly');

const setFavorites = actionCreator(actions.SET_FAVORITES, 'favorites');
export const getFavorites = () => (dispatch) => {
    return http.get('customer/favorites').then((data) => {
        dispatch(setFavorites(data));
    });
};

export const addToFavorites = (code) => (dispatch, getState) => {
    const { favorites } = getState().brokerView;
    return http.put(`customer/favorites/add/${code}`).then(() => {
        favorites.push(code);
        dispatch(setFavorites([...favorites]));
    });
};

export const removeFromFavorites = (code) => (dispatch, getState) => {
    const { favorites } = getState().brokerView;
    return http.put(`customer/favorites/remove/${code}`).then(() => {
        dispatch(setFavorites(favorites.filter((f) => f !== code)));
    });
};

export const logThinkAgentClick = (productCode, productName) => (dispatch, getState) => {
    const fd = new FormData();
    fd.append('productCode', productCode);
    fd.append('productName', productName);
    return http.post('broker/log-thinkagent', fd);
};

export const setCounties = actionCreator(actions.SET_COUNTIES, 'countyList');
