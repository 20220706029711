import { Grid, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AttachmentIcon from '@material-ui/icons/Attachment';
import React from 'react';
import FallbackSrc from 'utils/fallbackSrc';

export default function BasketItemsView({
    basketItems, noThumbnail, hasQty, showMaterialType
}) {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="basket item table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h5">
                                Materials
                            </Typography>
                        </TableCell>
                        <TableCell>
                            {hasQty && (<span>Quantity</span>)}
                        </TableCell>
                        {showMaterialType
                            && <TableCell>Material Type</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {basketItems.map((item) => (
                        <TableRow key={item.lineId}>
                            <TableCell component="th" scope="row">
                                <b>{item.customFields.alias || item.name}</b> <br />
                                {item.customFields && item.customFields.fileInfo && (<p><AttachmentIcon color="primary" />{item.customFields.fileInfo.UploadFileName}</p>)}
                            </TableCell>
                            <TableCell>
                                {hasQty && (<span>{item.quantity}</span>)}
                            </TableCell>
                            {showMaterialType
                                && (
                                    <TableCell>{
                                        item.customFields.materialType ? item.customFields.materialType.toUpperCase() : ''
                                    }
                                    </TableCell>
                                )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
