import React, { PureComponent } from 'react';
import DirectOrder from './DirectOrder';

export default class DomView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            steps: []
        };
    }

    componentDidMount() {
        this.initStateData(this.props);
        const { attachmentSettings, loadAttachmentSettings } = this.props;
        if (!attachmentSettings) {
            loadAttachmentSettings();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { match } = this.props;
        if (nextProps.match !== match) {
            this.initStateData(nextProps);
        }
    }

    componentWillUnmount() {
        const { resetInput } = this.props;
        resetInput(null);
    }

    getSteps = (lob, jobType, subCode) => {
        if (lob === 'cml') {
            switch (jobType) {
                case 'FJE':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                case 'AJEPP':
                    return subCode // FEDS
                        ? ['Select Customer', 'Job Information', 'Review & Submit']
                        : ['Select Customer', 'Shipping Address', 'Select Attributes', 'Select Items', 'Job Information', 'Review & Submit'];
                default:
                    return ['Select Customer', 'Shipping Address', 'Select Attributes', 'Select Items', 'Job Information', 'Review & Submit'];
            }
        } else if (lob === 'p1a') {
            switch (jobType) {
                case 'FJE':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                case 'INVENTORY':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                default:
                    return ['Shipping Address', 'Select Items', 'Job Information', 'Review & Submit'];
            }
        } else if (lob === 'p1b') {
            switch (jobType) {
                case 'FJE':
                    return ['Shipping Address', 'Select Items', 'Review & Submit'];
                default:
                    return ['Select Customer', 'Shipping Address', 'Select Items', 'Job Information', 'Review & Submit'];
            }
        } else {
            return ['Select Customer', 'Shipping Address', 'Select Items', 'Job Information', 'Review & Submit'];
        }
    }

    initStateData = (props) => {
        const {
            resetInput, history: { location: { state } },
            setSavedId, setSavedName, getShippingMethod, getShippingMethodSettings, validateJobItems,
            setScreenField, setCopyOrderData, loadAddressesByPage, getInventoryAddress, setSavedOrderNumber
        } = props;
        const { match: { params: { lob, jobType, subCode } } } = props;
        if (jobType === 'INVENTORY') {
            getInventoryAddress();
        } else {
            getShippingMethod(lob, jobType);
            getShippingMethodSettings(lob);
        }

        const steps = this.getSteps(lob, jobType, subCode);
        this.setState({ steps });

        let activeStep = 0;
        if (state && (state.orderData)) { // copy order
            if (state.orderData.addressCode) {
                loadAddressesByPage(1, state.orderData.addressCode, true, lob, jobType);
            }
            for (const item of state.orderData.jobItems) {
                item.selected = true;
            }
            resetInput(state.orderData);

            setScreenField('marketSegment', state.orderData.marketSegment || '');
            setScreenField('language', state.orderData.language || '');
            setScreenField('product', state.orderData.product || '');
            setScreenField('state', state.orderData.state || '');
            setScreenField('model', state.orderData.model || '');
            setCopyOrderData(state.orderData);

            validateJobItems(lob, state.orderData.jobItems);
            activeStep = steps.length - 2;
        } else {
            const savedJob = state && state.savedJob.jobData;
            const savedId = state && state.savedJob.savedId;
            const savedName = state && state.savedJob.savedName;
            const savedOrderNumber = state && state.savedJob.savedOrderNumber;
            resetInput(savedJob);
            setSavedId(savedId);
            setSavedName(savedName);
            setSavedOrderNumber(savedOrderNumber);
            if (savedJob) {
                const selected = savedJob.jobItems
                    ? (savedJob.jobItems.some((j) => j.selected === true)
                        ? savedJob.jobItems.filter((j) => j.selected === true)
                        : savedJob.jobItems
                    )
                    : [];
                if (selected.length > 0) {
                    validateJobItems(lob, selected);
                }

                if (typeof savedJob.activeStep === 'number') {
                    activeStep = savedJob.activeStep === steps.length - 1
                        ? savedJob.activeStep - 1
                        : savedJob.activeStep;
                } else {
                    activeStep = steps.length - 2;
                }
            }
        }
        this.setActiveStep(activeStep);
    }

    setActiveStep = (step) => {
        this.setState({ activeStep: step });
    }

    submitOrder = (jobOrderData) => {
        const { submitJobOrder, savedId } = this.props;
        submitJobOrder({ ...jobOrderData, savedId });
    }

    getValidates = (stepName) => {
        const { validates } = this.props;
        switch (stepName) {
            case 'Select Customer':
                return validates.selectCustomer;
            case 'Shipping Address':
                return validates.shippingAddress;
            case 'Select Attributes':
                return validates.selectScreen;
            case 'Select Items':
                return validates.selectItems;
            case 'Job Information':
                return validates.jobInformation;
            case 'Review & Submit':
                return validates.reviewSubmit;
            default:
                return true;
        }
    }

    isDisableBtn = (steps) => {
        const { activeStep } = this.state;
        const curStep = steps[activeStep];
        return !this.getValidates(curStep);
    }

    render() {
        const { user, currentJob } = this.props;
        if (!user) return null;
        const { activeStep, steps } = this.state;
        return (
            <DirectOrder
                steps={steps}
                activeStep={activeStep}
                setActiveStep={this.setActiveStep}
                isDisableBtn={this.isDisableBtn}
                currentJob={currentJob}
                submitJobOrder={this.submitOrder}
            />
        );
    }
}
