import {
    Box, Grid
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import Customer from './section/Customer';
import JobInfo from './section/JobInfo';
import JobItems from './section/JobItems';
import MarketSegment from './section/MarketSegment';
import ShippingAddress from './section/ShippingAddress';

export default function JobOrder({ orderData, so }) {

    for (const j of orderData.orderItem) {
        j.cf = typeof j.customFields === 'string'
            ? JSON.parse(j.customFields)
            : j.customFields;
    }

    const dateReq = orderData.customFields && orderData.customFields.jobInfo ? orderData.customFields.jobInfo.dateReq : '';

    return (
        <Box>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Customer customer={orderData.customFields.customer} />
                    </Grid>
                    <Grid item xs={6}>
                        <MarketSegment
                            lob={orderData.customFields.lob}
                            jobType={orderData.customFields.jobType}
                            marketSegment={orderData.customFields.marketSegment}
                            language={orderData.customFields.language}
                            product={orderData.customFields.product}
                            state={orderData.customFields.state}
                            model={orderData.customFields.model}
                        />
                    </Grid>
                </Grid>
            </Box>
            <JobInfo
                job={orderData.customFields.jobInfo}
                jobType={orderData.jobType}
            />
            <JobItems
                jobItems={orderData.orderItem}
                jobType={orderData.jobType}
                lob={orderData.customFields.lob}
                orderShipping={orderData.orderShipping}
            />
            {orderData.specialInstruction && (
                <Alert severity="info"><b>Special Instruction:</b> {orderData.specialInstruction}</Alert>
            )}
            {orderData.upsShippingNotes && (
                <Alert style={{ marginTop: '.5rem' }} severity="info"><b>Shipping Notes:</b> {orderData.upsShippingNotes}</Alert>
            )}
            <ShippingAddress
                orderId={orderData.id}
                jobType={orderData.jobType}
                dateReq={dateReq}
                so={so}
                lob={orderData.customFields.lob}
            />
        </Box>
    );
}
