import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box,
    Button, CircularProgress,
    Dialog, DialogActions, DialogTitle, Grid,

    Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TextField, Typography
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LoadingTableRow from 'components/LoadingTableRow';
import OrderRow from 'components/OrderRow';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import { getOrders, saveCriteria } from './action';

const pageSize = 10;

class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,
            copying: false,

            searchingMode: false,

            showInvalid: false,
            added: null,
            gotoCart: false
        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { getOrders: loadOrders, criteria, match: { params: orderNo } } = this.props;
        if (!!orderNo.orderNo && orderNo.orderNo.length > 0) {
            this.setCriteria('orderNumber', orderNo.orderNo);
            const newCriteria = {
                orderNumber: orderNo.orderNo,
                startDate: null,
                endDate: null,
                customerName: '',
                recipientFirstName: '',
                recipientMiddleName: '',
                recipientLastName: '',
                fileNumber: '',
                userId: '',
                npn: ''
            };
            loadOrders(pageNumber, pageSize, newCriteria);
        } else {
            loadOrders(pageNumber, pageSize, criteria);
        }
    }

    handleChangePage = (_event, pageNumber) => {
        const {
            getOrders: loadOrders,
            criteria: {
                orderNumber, recipientFirstName, recipientMiddleName,
                recipientLastName, startDate, endDate, userId, fileNumber, npn
            }
        } = this.props;
        const {
            searchingMode
        } = this.state;
        const criteria = searchingMode ? {
            orderNumber,
            recipientFirstName,
            recipientMiddleName,
            recipientLastName,
            userId,
            fileNumber,
            npn,
            startDate: (startDate ? startDate.toLocaleDateString() : null),
            endDate: (endDate ? endDate.toLocaleDateString() : null)
        } : null;
        loadOrders(pageNumber + 1, pageSize, criteria);
    }

    handleCopyOrder = (orderId) => {
        const fd = new FormData();
        fd.append('orderId', orderId);
        this.setState({ copying: true });
        http.post('store/copy-order', fd).then((data) => {
            const { history } = this.props;
            if (data.lob && data.jobType) {
                history.push({
                    pathname: `/direct-order/${data.lob}/${data.jobType}`,
                    state: { orderData: data }
                });
            } else
                if (data.invalid) {
                    this.setState({ showInvalid: true, gotoCart: data.added > 0, added: data.added });
                } else {
                    history.push('/cart');
                }
        }).finally(() => {
            this.setState({ copying: false });
        });
    }

    handleSearch = async () => {
        this.setState({ searchingMode: true });
        const {
            getOrders: loadOrders,
            criteria: {
                orderNumber,
                startDate,
                endDate,
                customerName,
                recipientFirstName,
                recipientMiddleName,
                recipientLastName,
                fileNumber,
                userId,
                npn
            }
        } = this.props;
        loadOrders(1, pageSize, {
            orderNumber,
            recipientFirstName,
            recipientMiddleName,
            recipientLastName,
            customerName,
            fileNumber,
            userId,
            npn,
            startDate: (startDate ? startDate.toLocaleDateString() : null),
            endDate: (endDate ? endDate.toLocaleDateString() : null)
        });
    };

    clearSearch = () => {
        this.setState({
            searchingMode: false
        });
        const { getOrders: loadOrders, saveCriteria: save } = this.props;
        save({
            orderNumber: '',
            startDate: null,
            endDate: null,
            customerName: '',
            recipientFirstName: '',
            recipientMiddleName: '',
            recipientLastName: '',
            fileNumber: '',
            userId: '',
            npn: ''
        });
        loadOrders(1, pageSize);
    }

    setCriteria = (name, value) => {
        const { saveCriteria: save, criteria } = this.props;
        save({ ...criteria, [name]: value });
    };

    closeInvalidDialog = () => {
        this.setState({ showInvalid: false });
        const { history } = this.props;
        const { gotoCart } = this.state;
        if (gotoCart) {
            history.push('/cart');
        }
    }

    render() {
        const {
            orders, loading, user, criteria: {
                orderNumber,
                startDate,
                endDate,
                customerName,
                recipientFirstName,
                recipientMiddleName,
                recipientLastName,
                fileNumber,
                userId,
                npn
            }
        } = this.props;
        const hasCustomerName = orders && orders.results && orders.results.some((o) => o.customerName);
        const {
            copying,
            showInvalid, added
        } = this.state;

        const showNPN = user && (user.customFields.userType === 'fsr' || user.customFields.userType === 'su');

        return (
            <div>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Order History</Typography>
                    </Breadcrumbs>
                </Box>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TableRow>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="ORDER #"
                                            autoFocus
                                            value={orderNumber}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('orderNumber', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="User Id"
                                            autoFocus
                                            value={userId}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('userId', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="First Name"
                                            autoFocus
                                            value={recipientFirstName}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('recipientFirstName', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="Last Name"
                                            autoFocus
                                            value={recipientLastName}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('recipientLastName', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="Middle Name"
                                            autoFocus
                                            value={recipientMiddleName}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('recipientMiddleName', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="Customer"
                                            autoFocus
                                            value={customerName}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('customerName', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={this.handleSearch}
                                                    style={{ float: 'left' }}
                                                >
                                                    <SearchIcon />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    style={{ float: 'left', marginLeft: '.5rem', marginRight: '.5rem' }}
                                                    onClick={this.clearSearch}
                                                >
                                                    <ClearAllIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="start_date_picker"
                                            label="Start Date"
                                            name="startDate"
                                            value={startDate}
                                            onChange={(d) => {
                                                this.setCriteria('startDate', d);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change start date'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="end_date_picker"
                                            label="End Date"
                                            name="endDate"
                                            value={endDate}
                                            onChange={(d) => {
                                                this.setCriteria('endDate', d);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end date'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{ padding: '.5rem' }}>
                                        <TextField
                                            label="Batch File #"
                                            autoFocus
                                            value={fileNumber}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                            onChange={(e) => this.setCriteria('fileNumber', e.target.value)}
                                        />
                                    </TableCell>
                                    {
                                        showNPN && (
                                            <TableCell style={{ padding: '.5rem' }}>
                                                <TextField
                                                    label="NPN"
                                                    autoFocus
                                                    value={npn}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { this.handleSearch(); } }}
                                                    onChange={(e) => this.setCriteria('npn', e.target.value)}
                                                />
                                            </TableCell>
                                        )
                                    }

                                </TableRow>
                            </MuiPickersUtilsProvider>
                        </TableHead>

                    </Table>
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ padding: '.5rem' }} />
                                <TableCell style={{ padding: '.5rem' }}>ORDER NUMBER</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>ORDERED BY</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>PROGRAM</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>JOB TYPE</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>MARKET SEGMENT</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>COST CENTER</TableCell>
                                {
                                    hasCustomerName && (<TableCell style={{ padding: '.5rem' }}>CUSTOMER</TableCell>)
                                }
                                <TableCell style={{ padding: '.5rem' }}>KIT QTY</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>ORDER STATUS</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>WHS</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>BATCH FILE #</TableCell>
                                <TableCell style={{ padding: '.5rem' }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <LoadingTableRow loading={loading} />
                            {!loading && orders && orders.results.length > 0 && orders.results.map((row) => (
                                <OrderRow
                                    key={row.id}
                                    row={row}
                                    hasCustomerName={hasCustomerName}
                                    onCopyClick={!(row.jobType === "AJEKT" && row.program.toLowerCase().indexOf("commercial") >= 0) && this.handleCopyOrder}
                                    showKitCount
                                />
                            ))}
                            {!loading && orders && orders.results.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={20} style={{ textAlign: 'center' }}>
                                        <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No order data.</span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {orders && (
                    <TablePagination
                        component="div"
                        count={orders.totalCount}
                        page={orders.pageNumber - 1}
                        rowsPerPage={orders.pageSize}
                        rowsPerPageOptions={[orders.pageSize]}
                        onChangePage={this.handleChangePage}
                    />
                )}
                <Backdrop style={{ zIndex: '1555', color: '#fff' }} open={copying}>
                    <CircularProgress color="primary" />
                </Backdrop>

                <Dialog
                    open={showInvalid}
                    onClose={this.closeInvalidDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{
                        added
                            ? 'There are some items in this order not active so only part of them been copied'
                            : 'All items in this order are inactive so none of them are copied.'
                    }
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeInvalidDialog} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({ ordersView: { orders, criteria } }) => ({
    orders, criteria
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders, saveCriteria
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
