import savedJobActions from './action';

export default (
    state = {
        loading: false,
        savedJobs: null,
        criteria: {
            orderId: '',
            fileNumber: '',
            firstName: '',
            lastName: ''
        }
    },
    action
) => {
    switch (action.type) {
        case savedJobActions.LOADING:
            return { ...state, loading: action.loading };

        case savedJobActions.SET_SAVED_JOB:
            return { ...state, savedJobs: action.savedJobs };

        case savedJobActions.SET_CRITERIA:
            return { ...state, criteria: action.criteria };

        default:
            return state;
    }
};
