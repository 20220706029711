import React from 'react';

import {
    Grid, Typography
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';


export default function MessageBoard({ content }) {
    const createMarkup = (cf) => {
        return { __html: cf };
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>

                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    <CommentIcon />  Message
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={createMarkup(content)} />

            </Grid>

        </Grid>
    );
}
