import {
    Badge, Box, Button, IconButton, Paper, Popover,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React, { PureComponent } from 'react';
import history from 'utils/history';

export default class CartIcon extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showList: false,

            anchorEl: null
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (window.location.hash === '#/cart') return;

        let { basketItems: a } = this.props;
        a = a || [];
        let { basketItems: b } = nextProps;
        b = b || [];
        const q1 = a.reduce((x, y) => {
            return x + y.quantity;
        }, 0);
        const q2 = b.reduce((x, y) => {
            return x + y.quantity;
        }, 0);

        if (a.length !== b.length || q1 !== q2) {
            this.inputElement.click();
            setTimeout(() => {
                this.setState({ showList: false, anchorEl: null });
            }, b.length ? 3000 : 1500);
        }
    }

    openList = (e) => {
        this.setState({ showList: true, anchorEl: e.currentTarget });
    }

    closeList = () => {
        this.setState({ showList: false, anchorEl: null });
    }

    goToCart = () => {
        history.push('/cart');
    }

    render() {
        const { basketItems } = this.props;
        const basketItemCount = basketItems ? basketItems.length : 0;
        const { showList, anchorEl } = this.state;

        return (
            <>
                <Badge badgeContent={basketItemCount} color="primary">
                    <IconButton
                        aria-label="ExitToAppIcon"
                        aria-describedby="logout"
                        variant="contained"
                        size="small"
                        onClick={this.openList}
                        ref={(input) => { this.inputElement = input; }}
                    >
                        <ShoppingCartIcon color="primary" />
                    </IconButton>
                </Badge>
                <Popover
                    open={showList}
                    onClose={this.closeList}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>
                                        Quantity
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    !basketItems || !basketItems.length
                                        ? (
                                            <TableRow>
                                                <TableCell colSpan={20}>
                                                    <Box p={3}>
                                                        <Typography>Your cart is empty</Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                        : basketItems.map((j) => (
                                            <TableRow key={j.lineId}>
                                                <TableCell>{j.name}</TableCell>
                                                <TableCell>{j.quantity}</TableCell>
                                            </TableRow>
                                        ))
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={20} style={{ textAlign: 'right' }}>
                                        <Button
                                            e="small"
                                            color="primary"
                                            variant="outlined"
                                            onClick={this.goToCart}
                                        >
                                            Go to Cart
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Popover>
            </>
        );
    }
}
