import { Box, Grid, Typography, ButtonGroup, Button } from '@material-ui/core';
import SelectItemStep from 'pages/direct-order/steps/SelectItemStep';
import { resetInput } from 'pages/direct-order/action';
import { getBasketItems } from 'pages/cart/action';
import message from 'components/Message';
import React, { useState } from 'react';
import http from 'utils/http';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    settings: state.appView.settings,
    planDocuments: state.directOrderView.stepView.jobItems,
    attachments: state.directOrderView.stepView.attachments,
});

const mapDispatchToProps = {
    resetInput,
    getBasketItems
};

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const {
        settings,
        showAddToCart,
        planDocuments,
        attachments,
        resetInput,
        getBasketItems
    } = props;

    const [loading, setLoading] = useState(false)

    const addToCart = () => {
        setLoading(true);
        http.post('OE/save-plan-documents', { systemPlanDocuments: planDocuments, userPlanDocuments: attachments?.map(a => ({ ...a, program: 'OE' })) })
            .then((saveResult) => {
                if (saveResult.success === false && saveResult.displayMessage) {
                    message.error(saveResult.displayMessage);
                    return;
                }
                resetInput();
                getBasketItems();
            })
            .finally(() => setLoading(false));
    }

    const hasItemAdded = (planDocuments && planDocuments.length > 0) || (attachments && attachments.length > 0);

    const hasMissingField = (attachments && attachments.length > 0) && attachments.some(a => !a.approved || !a.apnCode || !a.docType);

    return (
        <Box mt={2}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h6">
                        Add Plan Document
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }} />
                <Grid item xs={12}>
                    <SelectItemStep
                        disableChild
                        disableItemType
                        shellLimit={settings && settings.shellLimit && settings.shellLimit.oe}
                        lob="OE"
                        jobType="OEJ"
                        setIsNextReady={() => { }}
                    />
                </Grid>
                {
                    showAddToCart && hasItemAdded && (
                        <Grid item xs={12}>
                            <ButtonGroup className="list-btn-group">
                                <Button
                                    className="btn place-order-btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={addToCart}
                                    disabled={loading || hasMissingField}
                                >Add to Cart
                                </Button>
                            </ButtonGroup>

                        </Grid>
                    )
                }
            </Grid>
        </Box>
    );
});
