import {
    Card, CardActionArea, CardContent, Grid, Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BuildIcon from '@material-ui/icons/Build';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CommentIcon from '@material-ui/icons/Comment';
import FaceIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import PublishIcon from '@material-ui/icons/Publish';
import SecurityIcon from '@material-ui/icons/Security';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TuneIcon from '@material-ui/icons/Tune';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../../utils/history';
import ImpersonateModal from './ImpersonateModal';

const AccountView = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const { user } = props;

    const stopImpersonate = () => {
        props.startImpersonate(null);
        history.push('/catalog');
    };
    const showHistory = user && user.customFields.userType !== 'telesales';
    // const isMS = user && user.customFields.micrositeUser;
    const showAddresses = user && user.customFields.userType !== 'telesales';
    const isSU = user && user.customFields && user.customFields.userGroups && user.customFields.userGroups.length > 0 && user.customFields.userType === 'su';
    const isFEDS = (user.customFields && user.customFields.marketSegments && user.customFields.marketSegments.includes('FEDS'));
    const allowSaveOrder = user && user.customFields.userType !== 'telesales' && !user.customFields.p2_Catalog;
    const showPasswordChangeView = !user.customFields.passthroughLogin && user.customFields.userType !== 'Broker';

    // const jumpToExternalSite = () => {
    //     http.get('insider/get').then((d) => {
    //         window.location.href = d.link;
    //     });
    // };

    return (

        <Grid container spacing={5}>
            {showHistory && (
                <Grid item xs={3}>
                    <Card>
                        <Link to="/orders" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <AccessTimeIcon />  Order History
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>

            )}
            {showAddresses && (
                <Grid item xs={3}>
                    <Card>
                        <Link to="/addresses" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <HomeIcon /> Your Addresses
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>
            )}
            {showPasswordChangeView && (
                <Grid item xs={3}>
                    <Card>
                        <Link to="/password" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <SecurityIcon /> Change Password
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>

            )}
            <Grid item xs={3}>
                <Card>
                    <Link to="/profile" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    <AccountBoxIcon /> Account Detail
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Link>
                </Card>
            </Grid>
            {allowSaveOrder && (

                <Grid item xs={3}>
                    <Card>
                        <Link to="/saved-order" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <WatchLaterIcon /> Saved Order
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card>
                </Grid>
            )}
            {user && user.customFields && user.customFields.p2_Catalog === '*' && (
                <Grid item xs={3}>
                    <Card>
                        <CardActionArea
                            onClick={() => setOpenModal(true)}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h2">
                                    <SupervisorAccountIcon /> Impersonate {user.impersonate && (
                                        <span>({user.impersonate}) <CancelIcon onClick={() => stopImpersonate()} /> </span>
                                    )}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <ImpersonateModal open={openModal} handleClose={() => setOpenModal(false)} />
                </Grid>

            )}
            {isSU && (
                <>
                    <Grid item xs={3}>
                        <Card>
                            <Link to="/order-management" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <BuildIcon /> Order Management
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <Link to="/plan-sponsor" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <TuneIcon /> Plan Sponsors
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <Link to="/user-mgmt" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <FaceIcon /> User Management
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <Link to="/banner-message" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <CommentIcon /> Banner Message
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>

                    <Grid item xs={3}>
                        <Card>
                            <Link to="/password-settings" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <CardActionArea>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="h2">
                                            <VerifiedUserIcon /> Password Settings
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>
                </>
            )}
            {/* {
                isMS && (
                    <Grid item xs={3}>
                        <Card>
                            <CardActionArea
                                onClick={() => jumpToExternalSite()}
                            >
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        <SwapHorizontalCircleIcon /> Microsite
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            } */}
            {(isSU || isFEDS) && (
                <Grid item xs={12}>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <Card>
                                <Link to="/im-batch" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="h2">
                                                <CloudUploadOutlinedIcon />  IM Batch Order
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card>
                                <Link to="/pp-batch" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="h2">
                                                <PublishIcon />  Pick&Pack Batch Order
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

            )}
        </Grid>
    );
};

export default AccountView;
