import { getBasketItems } from 'pages/cart/action';
import http from '../../utils/http';
import keyMirror from '../../utils/keyMirror';
import actionCreator from '../../utils/actionCreator';
import history from '../../utils/history';

export const actions = keyMirror('CATALOG_VIEW', {
    ADD_TO_BASKET: null,
    REMOVE_FROM_BASKET: null,
    UPDATE_BASKET_ITEM: null
});

export default actions;

const addToBasket = actionCreator(actions.ADD_TO_BASKET, 'item');
export const addProductToCart = (item, gotoCart = true) => async (dispatch, getState) => {
    const current = getState();
    if (current.brokerView && current.brokerView.selection && current.brokerView.selection.state) {
        item.customFields.state = current.brokerView.selection.state;
    }
    http.post('catalog/add-to-cart', item)
        .then(() => {
            dispatch(addToBasket(item));
            if (gotoCart) {
                history.push('/cart');
            } else {
                dispatch(getBasketItems());
            }
        });
};

export const addProductsToCart = (items, gotoCart = true) => async (dispatch) => {
    http.post('catalog/bulk-add-to-cart', items)
        .then(() => {
            for (const item of items) {
                dispatch(addToBasket(item));
            }
            if (gotoCart) {
                history.push('/cart');
            } else {
                dispatch(getBasketItems());
            }
        });
};

export const removeFromBasket = actionCreator(actions.REMOVE_FROM_BASKET, 'lineId');
export const updateBasketItem = actionCreator(actions.UPDATE_BASKET_ITEM, 'item');
