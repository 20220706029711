import {
    Box,
    Button,

    Chip, Dialog, DialogActions, DialogTitle, IconButton,
    Table, TableBody, TableCell, TableContainer, TableFooter,

    TableHead, TableRow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import AccountContainer from 'components/AccountContainer';
import LoadingTableRow from 'components/LoadingTableRow';
import { stateFromHTML } from 'draft-js-import-html';
import React, { useEffect } from 'react';
import MessageEditor from './MessageEditor';

export default function ListView({
    loading, loadData, pagedData,
    saving, saveMessage,
    openEditor, editingItem,
    setOpenEditor, setEditingItem,
    deleteMsg, setDeletingId, deletingId
}) {
    useEffect(() => {
        loadData(1);
        return () => {

        };
    }, [loadData]);

    const edit = (row) => {
        const temp = JSON.parse(JSON.stringify(row));
        setEditingItem(temp);
        setOpenEditor(true);
    };

    const onConfirmClose = () => {
        setDeletingId(null);
    };

    const confirmDelete = (row) => {
        setDeletingId(row.id);
    };

    return (
        <AccountContainer name="Banner Message Management">

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Start Time</TableCell>
                            <TableCell>End Time</TableCell>
                            <TableCell>Assignment</TableCell>
                            <TableCell>
                                <IconButton
                                    color="secondary"
                                    onClick={() => {
                                        setEditingItem({
                                            content: '', startTime: null, endTime: null, lobXUserGroups: []
                                        });
                                        setOpenEditor(true);
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LoadingTableRow loading={loading} />
                        {
                            pagedData && pagedData.results.map((d) => (
                                <TableRow key={d.id}>
                                    <TableCell component="th" scope="row">
                                        {d.id}
                                    </TableCell>
                                    <TableCell>
                                        <Box style={{ maxWidth: '500px' }}>
                                            {stateFromHTML(d.content).getPlainText()}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{d.startTime}</TableCell>
                                    <TableCell>{d.endTime}</TableCell>
                                    <TableCell>{d.lobXUserGroups && d.lobXUserGroups.map((x) => <Chip key={x} label={`${x.lob} - ${x.userGroup}`} />)}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" onClick={() => edit(d)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color="primary" onClick={() => confirmDelete(d)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={20}>
                                {pagedData
                                    && (
                                        <Pagination
                                            component="div"
                                            style={{ float: 'right' }}
                                            count={pagedData.pageCount}
                                            page={pagedData.pageNumber}
                                            onChange={(_, p) => loadData(p)}
                                        />
                                    )}

                            </TableCell>
                        </TableRow>

                    </TableFooter>
                </Table>
            </TableContainer>
            <MessageEditor
                onClose={() => { setOpenEditor(false); setEditingItem(null); }}
                data={editingItem}
                onSave={saveMessage}
            />

            <Dialog
                open={!!deletingId}
                onClose={onConfirmClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete this message?</DialogTitle>
                <DialogActions>
                    <Button onClick={onConfirmClose} color="primary">
                        No
                    </Button>
                    <Button onClick={() => deleteMsg(deletingId)} disabled={saving} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </AccountContainer>
    );
}
