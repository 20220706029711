import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Grid, Divider, Typography
} from '@material-ui/core';
import './layout.scss';

export default class MaterialAutoLayout extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        layout: PropTypes.object,
        customFieldRender: PropTypes.func
    };

    renderLayout = (layout, level = 0, dir, isLine = false) => {
        console.group('renderLayout');
        console.log('#ODS#: renderLayout -> layout', layout);
        console.log('#ODS#: renderLayout -> level', level);
        console.log('#ODS#: renderLayout ->  dir, isLine', dir, isLine);
        console.groupEnd();
        // console.log('base', layout, level, dir, isLine);
        if (dir === 'v') {
            return (
                <Box component="div" key={level} mb={2} className="vertical-part">
                    {layout && layout.title
                        && (
                            <Box component="div" mb={1}>
                                <Typography variant="h6">{layout.title}</Typography>
                            </Box>
                        )}
                    <Box component="div">
                        {layout && layout.items && layout.items.map((item, index) => {
                            return this.renderLayout(item, index, item.dir, layout.line);
                        })}
                    </Box>
                    {isLine
                        && <Divider className="vertical-part-line" style={{ height: '3px', margin: '20px 0' }} />}
                </Box>
            );
        } else if (dir === 'h') {
            const eachGridNum = (layout.items && layout.items.length > 0 && (12 / layout.items.length)) || 12;
            const horizontalLine = layout && layout.line;
            const horizontalLineClass = horizontalLine ? ' horizontal-part-line' : '';
            return (
                <Box component="div" key={level} mb={1} className="horizontal-part">
                    {layout && layout.title
                        && (
                            <Box component="div" mb={1}>
                                <Typography variant="h6">{layout.title}</Typography>
                            </Box>
                        )}
                    <Grid container spacing={3} className="list-part">
                        {layout && layout.items && layout.items.map((item, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={eachGridNum}
                                    key={index}
                                    className={horizontalLineClass}
                                >
                                    <Box component="div">
                                        {this.renderLayout(item, index, item.dir, false)}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {isLine
                        && <Divider className="vertical-part-line" style={{ height: '3px', margin: '20px 0' }} />}
                </Box>
            );
        } else if (layout && layout.id) {
            const { customFieldRender } = this.props;
            return (
                <Box component="div" key={level} className="cell-part">
                    {customFieldRender && customFieldRender({ layout, level, dir, isLine })}
                </Box>
            );
        }
    }

    render() {
        const { layout = {} } = this.props;
        return (
            <Box component="div" className="material-auto-layout-view">
                {layout && this.renderLayout(layout, 0, layout.dir)}
            </Box>
        )
    }

}