import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AccountView from './AccountView';
import { startImpersonate } from '../../actions/app';

const mapStateToProps = ({ appView: { user } }) => {
    return { user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            startImpersonate
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountView);
