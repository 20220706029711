import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import DirectOrder from './DirectOrder';
import DomView from './DomView';
import * as actions from './action';

const mapStateToProps = ({
    appView: { user },
    directOrderView
}) => {
    return {
        user,
        ...directOrderView.stepView,
        currentJob: directOrderView.stepView
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DomView);
