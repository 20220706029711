import { createStore, compose, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import reducer from 'reducer';

import authMiddleware from 'middlewares/auth';

const createStoreWithMiddleware = applyMiddleware(
    authMiddleware
)(createStore);

// eslint-disable-next-line no-underscore-dangle
const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }) : f => f;

// eslint-disable-next-line max-len
const store = process.env.REACT_APP_SERVER_URL.includes('localhost')
    || process.env.REACT_APP_SERVER_URL.includes('//IBD')
    ? createStoreWithMiddleware(reducer, compose(applyMiddleware(thunk), devTools))
    : createStoreWithMiddleware(reducer, compose(applyMiddleware(thunk)));

export default store;
