import {
    Box,
    IconButton, List,
    ListItem,
    ListItemSecondaryAction, ListItemText, Typography
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

export default function OptionalItemPanel({
    jobType, lob, nItems, addNItem
}) {
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    {jobType === 'AJEPP' ? 'Warehouse Items' : 'Optional Items'}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <List style={{ width: '100%' }} dense>
                    <ListItem>
                        <ListItemText
                            primary={(
                                <Box width="90%" display="inline-block">
                                    <Box display="inline-block" width="15%">Item Code</Box>
                                    <Box display="inline-block" width="5%">V </Box>
                                    <Box display="inline-block" width="15%"> WHS</Box>
                                    <Box display="inline-block" width="35%">NAME</Box>
                                    <Box display="inline-block" width="5%">VP</Box>
                                    <Box display="inline-block" width="5%">DP</Box>
                                    <Box display="inline-block" width="5%">TYPE</Box>
                                    {(jobType === 'AJEKT' || jobType === 'IM') && lob !== 'p1b'
                                        && <Box display="inline-block" width="5%">Default Item</Box>}
                                </Box>
                            )}
                        />
                        <ListItemSecondaryAction />
                    </ListItem>
                    {nItems.map((item) => (
                        <ListItem
                            key={item.id}
                            ContainerComponent="li"
                            divider
                        >
                            <ListItemText
                                primary={(

                                    <Box width="90%" display="inline-block">
                                        <Box display="inline-block" width="15%">
                                            {item.code}
                                        </Box>
                                        <Box display="inline-block" width="5%">
                                            {item.version}
                                        </Box>
                                        <Box display="inline-block" width="15%">
                                            {item.whs}
                                        </Box>
                                        <Box display="inline-block" width="35%">
                                            {item.name}
                                        </Box>
                                        <Box display="inline-block" width="5%">
                                            {item.vp}
                                        </Box>
                                        <Box display="inline-block" width="5%">
                                            {item.dp}
                                        </Box>
                                        <Box display="inline-block" width="5%">
                                            {item.type}
                                        </Box>
                                        {(jobType === 'AJEKT' || jobType === 'IM') && lob !== 'p1b'
                                            && (
                                                <Box display="inline-block" width="5%">
                                                    {item.requiredCode}
                                                </Box>
                                            )}

                                    </Box>
                                )}
                            />
                            <ListItemSecondaryAction style={{ textAlign: 'right' }}>
                                <IconButton
                                    aria-label="delete"
                                    display="inline"
                                    size="small"
                                    m={1}
                                    onClick={() => addNItem(item.id)}
                                >
                                    <AddIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}
