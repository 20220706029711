import {
    Box, Button, ButtonGroup,
    Checkbox, ClickAwayListener,
    FormControlLabel, Grow, MenuItem, MenuList, Paper,
    Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { PureComponent } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

class MultiSelectTextCell extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: false,

            value: props.value || [],
            errorMessage: '',
            pickList: props.args.options || props.pickList || [],
            max: 3
        };
        this.anchorRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value ? nextProps.value : [] });
        }
        if (nextProps.pickList !== this.props.pickList) {
            if (nextProps.pickList && nextProps.pickList.length > 0) {
                this.setState({ pickList: nextProps.pickList });
            }
        }
    }

    onOptionSelectChange = (i) => {
        const { value } = this.state;
        value.splice(i, 1);
        this.setState({ value: [...value] });
        const { row, col, updateRowData } = this.props;
        updateRowData && updateRowData(row, col, value);
    }

    onOptionValueChange = (e, i) => {
        const { name } = e.target;
        const { value: text } = e.target;
        const { value } = this.state;

        value[i].value = text;
        this.setState({ value: [...value] });
        const { row, col, updateRowData } = this.props;
        updateRowData && updateRowData(row, col, [...value]);
    }

    addItem = (option) => {
        const { value } = this.state;
        this.setState({ value: [...value, { name: option, value: '' }] });
    }

    render() {
        const { isReadOnly, prefix, col } = this.props;
        const {
            value, pickList, open, max
        } = this.state;
        console.log('#ODS#: MultiSelectTextCell -> render -> value', value);

        return (
            <Box component="div" className={`${prefix}-checkbox-field`}>
                <TableContainer>
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <ButtonGroup variant="outlined" color="primary" ref={this.anchorRef} aria-label="split button">
                                        <Button
                                            onClick={() => this.setState({ open: !open })}
                                        >{col.title}
                                        </Button>
                                        <Button
                                            color="primary"
                                            size="small"
                                            aria-controls={open ? 'split-button-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-label="select"
                                            aria-haspopup="menu"
                                            onClick={() => this.setState({ open: !open })}
                                        >
                                            <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Popper open={open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
                                                        <MenuList id="split-button-menu">
                                                            {pickList.filter(p=>p.value !== 'Blank').map((option, i) => (
                                                                <MenuItem
                                                                    disabled={value.length === max}
                                                                    key={i}
                                                                    onClick={() => this.addItem(option.value)}
                                                                >
                                                                    {option.value}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value.map((o, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            <FormControlLabel
                                                style={{ fontSize: '.875rem' }}
                                                control={(
                                                    <Checkbox
                                                        checked
                                                        onChange={() => this.onOptionSelectChange(i)}
                                                        name={o.name}
                                                        color="primary"
                                                    />
                                                )}
                                                label={o.name}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: '35%' }}>
                                            <TextField
                                                style={{ fontSize: '.875rem' }}
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                label=""
                                                name={o.name}
                                                value={o.value}
                                                onChange={(e) => this.onOptionValueChange(e, i)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
}

MultiSelectTextCell.defaultProps = {
    prefix: 'editable-cell'
};

export default MultiSelectTextCell;
