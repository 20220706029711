import {
    Box, CircularProgress, FormControl, Grid, Input, InputLabel, Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React, { PureComponent } from 'react';
import http from 'utils/http';

export default class UploadAddressModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 0,
            imBatchTableId: props.imTables && props.imTables.length > 0
                ? props.imTables[0].id
                : '',
            uploading: false,
            creating: false,
            data: null,
            fileName: '',
            orderData: null,
            fileData: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open) {
            this.setState({
                activeStep: 0,
                imBatchTableId: nextProps.imTables && nextProps.imTables.length > 0
                    ? nextProps.imTables[0].id
                    : '',
                uploading: false,
                creating: false,
                data: null,
                fileName: '',
                orderData: null,
                fileData: null
            });
        }
    }

    onTableChange = (e) => {
        if (e.target.value === -1) {
            const { onAddTable } = this.props;
            onAddTable();
            this.setState({ imBatchTableId: '' });
        } else {
            this.setState({ imBatchTableId: e.target.value });
        }
    }

    onFileChange = (e) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            this.getBase64(file).then(async (fileData) => {
                const fd = new FormData();
                fd.append('fileName', file.name);
                fd.append('fileData', fileData);
                this.setState({
                    fileName: file.name,
                    fileData
                });
            });
        } else {
            this.setState({
                fileName: '',
                fileData: null
            });
        }
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    upload = () => {
        this.setState({ uploading: true });
        const { fileName, fileData } = this.state;
        const fd = new FormData();
        fd.append('fileName', fileName);
        fd.append('fileData', fileData);
        http.post('su/p7/upload-im-address', fd).then((data) => {
            this.setState({ data, activeStep: 1 });
        }).finally(() => {
            this.setState({ uploading: false });
        });
    }

    submit = () => {
        this.setState({ creating: true });
        const { data, imBatchTableId } = this.state;
        const fd = new FormData();
        fd.append('batchTableId', imBatchTableId);
        fd.append('batchAddressId', data.id);
        http.post('su/p7/create-im-batch-order', fd).then((orderData) => {
            this.setState({ orderData, activeStep: 2 });
        }).finally(() => {
            this.setState({ creating: false });
        });
    }

    goNext = () => {
        const { activeStep } = this.state;
        if (activeStep === 0) {
            this.upload();
        } else if (activeStep === 1) {
            this.submit();
        } else {
            const { onClose } = this.props;
            const { orderData } = this.state;
            onClose(!!orderData);
        }
    }

    getIsNextReady = () => {
        const {
            imBatchTableId,
            uploading,
            fileName,
            activeStep,
            creating,
            data
        } = this.state;
        if (!imBatchTableId) return false;
        if (activeStep === 0) {
            return !(uploading || !imBatchTableId || !fileName);
        } else if (activeStep === 1) {
            return !(!imBatchTableId || !data || creating);
        }

        return true;
    }

    render() {
        const {
            open,
            onClose,
            imTables,
            downloadLinkTemplate
        } = this.props;
        const {
            imBatchTableId,
            activeStep,
            data,
            orderData,
            uploading,
            creating
        } = this.state;
        const isNextReady = this.getIsNextReady();
        return (
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
            >
                <MuiDialogTitle disableTypography>
                    <Typography variant="h6">Upload IM Batch Address</Typography>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Box>
                        <Stepper activeStep={activeStep}>
                            <Step>
                                <StepLabel>Upload</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Review</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Finish</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    <Box pl={3} pr={3} pb={3}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label"> IM Batch Table: {imTables && imTables.length > 0 ? imTables[0].fileName : (<span style={{ color: 'red' }}>Please upload IM Batch Table first</span>)}</InputLabel>
                        </FormControl>
                    </Box>
                    <Box mt={2} pl={3} pr={3}>
                        {
                            activeStep === 0 && (
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="Select IM Batch Address file"
                                    inputProps={{ type: 'file', accept: '.csv' }}
                                    onChange={this.onFileChange}
                                    disabled={!imBatchTableId}
                                />
                            )
                        }
                        {
                            activeStep === 1 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}><b>Name:</b></Grid>
                                    <Grid item xs={6}>{data.fileName}</Grid>
                                    <Grid item xs={6}><b>Total Addresses:</b></Grid>
                                    <Grid item xs={6}>{data.totalCount}</Grid>
                                </Grid>
                            )
                        }
                        {
                            activeStep === 2 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={6}><b>Total Orders:</b></Grid>
                                    <Grid item xs={6}>{orderData.totalLoaded}</Grid>
                                    <Grid item xs={6}><b>Total Not Loaded:</b></Grid>
                                    <Grid item xs={6}>{orderData.totalNotLoaded}</Grid>
                                    <Grid item xs={6}><b>Download Report:</b></Grid>
                                    <Grid item xs={6}>
                                        <a target="_blank" href={downloadLinkTemplate.replace('{id}', orderData.reportId)}>IM Warning File</a>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Box>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        disabled={!isNextReady}
                        onClick={this.goNext}
                        variant="contained"
                        color="primary"
                    >
                        {(uploading || creating) && (
                            <CircularProgress
                                color="secondary"
                                size={12}
                            />
                        )}
                        {activeStep === 0 && <span>Upload</span>}
                        {activeStep === 1 && <span>Create Order</span>}
                        {activeStep === 2 && <span>Finish</span>}
                    </Button>
                </MuiDialogActions>
            </Dialog>
        );
    }
}
