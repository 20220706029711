import { getBasketItems } from 'pages/cart/action';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import actionCreator from 'utils/actionCreator';

export const actions = keyMirror('SAVED_ORDER_VIEW', {
    LOADING: null,

    SET_SAVED_ORDER: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setSavedOrders = actionCreator(actions.SET_SAVED_ORDER, 'savedOrders');
export const getSavedOrders = (pageNumber, pageSize) => (dispatch) => {
    dispatch(setLoading(true));
    http.get(`customer/saved-orders/${pageNumber}/${pageSize}`)
        .then((data) => {
            for (const list of data) {
                for (const item of list.customerSavedItem) {
                    item.customFields = JSON.parse(item.customFields);
                }
            }

            dispatch(setSavedOrders(data));
        })
        .finally(() => dispatch(setLoading(false)));
};

export const addSavedItemToCart = (listId, itemId) => (dispatch, getState) => {
    let { savedOrders } = getState().savedOrderView;
    const savedList = savedOrders.find((o) => o.id === listId);
    dispatch(setLoading(true));
    http.get(`store/add-saved-item-to-cart/${listId}/${itemId}`)
        .then((data) => {
            // refresh cart
            dispatch(getBasketItems());

            savedList.customerSavedItem = savedList.customerSavedItem.filter((i) => i.id !== itemId);
            if (savedList.customerSavedItem.length === 0) {
                savedOrders = savedOrders.filter((s) => s.id !== listId);
            }
            dispatch(setSavedOrders([...savedOrders]));
        })
        .finally(() => dispatch(setLoading(false)));
};
