import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import http from 'utils/http';

export default function SaveForLaterDialog({
    initName, open, onClose, onSaved, data, savedId, onSave, isTemplate
}) {
    const [savedName, setSavedName] = React.useState(initName);
    const [saving, setSaving] = React.useState(false);

    const save = () => {
        if (onSave) {
            onSave(savedName);
        } else {
            let sid = savedId && savedId.savedId;
            if (!sid) {
                sid = savedId || 0;
            }
            setSaving(true);
            const fd = new FormData();
            fd.append('name', savedName);
            fd.append('savedId', sid);
            const savedData = { ...data, hash: window.location.hash };
            fd.append('data', JSON.stringify(savedData));
            http.post('catalog/save-job-order', fd)
                .then((id) => onSaved(id, savedName))
                .finally(() => setSaving(false));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Save As</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Saved Name"
                    type="text"
                    value={savedName}
                    onChange={(e) => setSavedName(e.target.value)}
                    style={{ width: '25rem' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button disabled={saving || !savedName} onClick={() => save()} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
