import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getOrders, getSaves, getTemplates } from './action';
import ListView from './ListView';

const mapStateToProps = ({ orderManagementView: { orders, saves, loading, templates } }) => {
    return { orders, saves, loading, templates };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders, getSaves, getTemplates
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
