import React, { PureComponent } from 'react';
import './flipbook.scss';

export default class Flipbook extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {  match: { params: { id, brand } } } = this.props;
        let previewUrl = window.location.origin;
        if (previewUrl === 'http://localhost:3000') {
            previewUrl = 'http://localhost:47475';
        }

        return (
            <div className='flipbook'>
                {brand ? <iframe frameBorder={0} className='iframe' src={`/quickview2.html?pdf=${previewUrl}/file/flip2/${id}&a=&p=`}/>
                    : <iframe frameBorder={0} className='iframe' src={`/quickview2analytics.html?pdf=${previewUrl}/file/flip2/${id}&a=&p=`}/>
                }
            </div>
        )
    }
}
