import {
    Box,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import React from 'react';

export default function Customer({ customer }) {
    if (customer) {
        return (
            <Box>
                <Typography variant="h6" color="primary">
                    Customer
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="customer info">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{customer.customerName}</TableCell>
                                <TableCell>{customer.customerNumber}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }

    return null;
}
