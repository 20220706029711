import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { passthrough, setErrorMsg } from './action';
import Passthrough from './Passthrough';

const mapStateToProps = ({ passthroughView, appView }) => {
    return {
        error: passthroughView.error,
        loading: passthroughView.loading,
        isAuthUser: appView.isAuthUser
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            passthrough, setErrorMsg
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Passthrough);
