import {
    Button,
    Dialog, DialogActions, DialogTitle
} from '@material-ui/core';
import React from 'react';

export default function ConfirmShippingMethodChange({ open, onClose, onSave }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">UPS Ground is Aetna’s preferred shipping method. You have selected expedited shipping at a significantly higher cost. Shipping selections are monitored and tracked for reporting purposes. Do you wish to continue?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
