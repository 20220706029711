import { Box, Button, FormControlLabel, Grid, Modal, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 420,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const FormModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, onClose, sponsor, onSave
    } = props;
    const defaultFormData = {
        phone: '1-800-307-4830.',
        clientSpecificTtyTtd: 'TTY:711.',
        websiteUrl: 'AetnaRetireePlans.com.',
        operatingHours: '8 a.m. to 9 p.m. EST, Monday through Friday.'
    };
    const [formData, setFormData] = React.useState(sponsor || defaultFormData);
    const [errorMessage, setErrorMessage] = useState('');

    const classes = useStyles();

    const onFieldChange = (e) => {
        setErrorMessage('');
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const saveChange = () => {
        setErrorMessage('');
        onSave(formData);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={1} className="row-item">
                    <Grid item xs={4}>
                        <TextField
                            label="Case ID"
                            name="caseId"
                            fullWidth
                            value={formData.caseId || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Case Name"
                            name="caseName"
                            required
                            value={formData.caseName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Control Number"
                            name="controlNumber"
                            value={formData.controlNumber || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="View Status"
                            name="viewStatus"
                            value={formData.viewStatus || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Logo Ind"
                            name="logoInd"
                            value={formData.logoInd || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Logo"
                            name="logo"
                            fullWidth
                            value={formData.logo || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={(
                                <Switch
                                    checked={formData.medicareCase === 'Y'}
                                    onChange={(e) => onFieldChange({ target: { name: 'medicareCase', value: e.target.checked ? 'Y' : 'N' } })}
                                    name="medicareCase"
                                    color="primary"
                                />
                            )}
                            label="Medicare Case"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={formData.phone || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Client Specific TTY TTD"
                            name="clientSpecificTtyTtd"
                            value={formData.clientSpecificTtyTtd || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Website Url"
                            name="websiteUrl"
                            fullWidth
                            value={formData.websiteUrl || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 55
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Operating Hours"
                            name="operatingHours"
                            fullWidth
                            value={formData.operatingHours || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button
                        className="btn place-order-btn"
                        disabled={!(formData.caseName)}
                        variant="contained"
                        onClick={saveChange}
                        color="primary"
                    >Save
                    </Button>
                </Box>
                {errorMessage && <span style={{ color: 'red', paddingLeft: '1rem' }}>{errorMessage}</span>}
            </div>
        </Modal>
    );
};

export default FormModal;
