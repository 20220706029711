import {
    Box,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import React from 'react';

export default function MarketSegment({ lob, jobType, marketSegment, language, product, state, model }) {
    if (!marketSegment) return null;

    return (
        <Box >
            <Typography variant="h6" color="primary">
                Market Segment
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Market Segment</TableCell>
                            {
                                (!(lob === 'cml' && lob === 'AJEPP')) && (
                                    <>
                                        <TableCell>Language</TableCell>
                                        <TableCell>Product</TableCell>
                                        <TableCell>State/Model</TableCell>
                                    </>

                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{marketSegment}</TableCell>
                            {
                                (!(lob === 'cml' && jobType === 'AJEPP')) && (
                                    <>
                                        <TableCell>{language}</TableCell>
                                        <TableCell>{product}</TableCell>
                                        <TableCell>{state}/{model}</TableCell>
                                    </>

                                )
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

}
