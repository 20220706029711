import React from 'react';

export default function Index() {
    return (
        <div className="grid-container">
            <p><strong>Last Updated December 31,&nbsp;2019</strong></p>
            <h2>ACCEPTANCE OF PRIVACY POLICY</h2>
            <p>O’Neil Digital Solutions, LLC ("<strong>ODS</strong>", “<strong>Company</strong>”, "<strong>we</strong>", or "<strong>us</strong>") is committed to respecting and protecting the personal privacy of its clients, guests, users, and/or other persons. This Privacy Policy, including any future modifications and/or addenda (collectively, the "<strong>Privacy Policy</strong>"), applies to all information (including personal information) we may collect through y our use of <strong>ONEILDATA.com</strong> (the "<strong>Site</strong>") and any other sites, applications, software, features, events, surveys or studies, or content offered by us from time to time that link or otherwise refer to this Privacy Policy (collectively, including the Site, the "<strong>Services</strong>"), whether as a guest or a registered user.&nbsp;For purposes of this Privacy Policy, “<strong>personal information</strong>” means information that relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual or household.<br /></p>
            <p>This Privacy Policy is effective with respect to all information that we have collected from you or will collect from you in the future – the only exception is if you (a) have entered into a separate written agreement with the Company that expressly covers this subject matter, or (b) are an employee of a Company client and a separate written agreement that expressly covers this subject matter has been mutually agreed to by and between the Company and your employer (each a “<strong>Service Agreement</strong>”), in which case, the relevant Service Agreement shall control.&nbsp;Please read this Privacy Policy carefully before you start to use the Services.&nbsp;<strong>BY ACCESSING AND/OR USING THE SERVICES, YOU EXPRESSLY CONSENT AND AGREE TO BE BOUND AND ABIDE BY THIS PRIVACY POLICY AND THE TERMS OF USE AVAILABLE AT <a href="HTTPS://WWW.ONEILDATA.COM/TERMS-OF-USE">HTTPS://WWW.ONEILDATA.COM/TERMS-OF-USE</a> (THE "TERMS OF USE") INCORPORATED HEREIN BY REFERENCE AND TO COMPLY WITH ALL APPLICABLE LAWS, RULES, AND REGULATIONS (COLLECTIVELY, "APPLICABLE LAWS").</strong>&nbsp;If you do not want to agree to this Privacy Policy and the Terms of Use, you must not access or use the Services.</p>
            <p><strong>Note:</strong> If you are affected by a visual impairment that prevents you from reading the content of this Privacy Policy and/or any addenda, you may call the Company at 1-844-902-0581 for further assistance.</p>
            <h3>WHAT TYPES OF INFORMATION DOES THE COMPANY COLLECT &amp; HOW DO WE COLLECT IT?</h3>
            <p>The Company’s primary line of business is the processing of data on behalf of its clients, this includes the processing of personal information at the direction of its clients. Accordingly, most personal information that we process has been provided to us by our clients.</p>
            <h3>CATEGORIES OF PERSONAL INFORMATION</h3>
            <p>In general, the Company collects the following categories of personal information and has collected such categories of information over the twelve (12) month period preceding the date this Privacy Policy was last updated: <em><br /></em></p>
            <ul><li><strong><em>Identifiers:</em></strong> Examples might include your name, email address, phone number, IP address, device identifier, or account name; <em><br /></em></li><li><strong><em>Personal Information:</em></strong> Examples might include your financial information or business information; <em><br /></em></li><li><strong><em>Classification Characteristics:</em></strong> Examples might include your age or sex;</li><li><strong><em>Commercial Information:</em></strong> Examples might include the products or services you’ve purchased, obtained, or considered, and other purchasing or consumer histories or tendencies; <em><br /></em></li><li><strong><em>Internet Activity:</em></strong> Examples might include your browsing history, your search history, and information on your interaction with the Site and/or Services; <em><strong><br /></strong></em></li><li><strong><em>Geolocation Data:</em></strong> Examples might include your physical location or movements;<em><br /></em></li><li><strong><em>Professional or Employment-Related Information:</em></strong> Examples might include your employer, job position, salary, etc.<em><br /></em></li><li><strong><em>Inferences:</em></strong> The Company may make inferences drawn from other personal information listed above, to create a profile reflecting your preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and/or aptitudes.</li></ul>
            <h3>INFORMATION YOU PROVIDE TO US</h3>
            <p>When you setup an account, access, register, or subscribe to the Services, purchase a product, enter a sweepstakes or promotion, communicate with our support personnel (e.g., when you talk to customer service), call us, upload information, submit a request online, attend an event (e.g., an educational seminar, webinar, a live event, etc.), or otherwise communicate with us (e.g., via an online form, email, text, fax, or phone), directly with us or through a third party, we may collect and store any personal information you provide.&nbsp;Such information may include, for example, your name, email address, phone number, physical address, search terms, dates and times of access, device identifiers, login credentials (e.g., username and password), uploads/posts/other user-generated content, user experience feedback/information, payment information, and/or financial or business information. This may also include information you provide to us about other people. <br /></p>
            <p>We also store the files or other information that you may upload or provide to use with the features and functionality of the Services, for example, if you are a client of the Company, your customers’ information and any messaging content (collectively, “<strong>Client Materials</strong>”) – Client Materials are subject to any additional terms and conditions related to confidentiality and data privacy as set forth in the Services Agreement. Further, if you submit suggestions, ideas, enhancement requests, recommendations, or other feedback in connection with the Services (collectively, “<strong>Feedback</strong>”), the Company will have the unrestricted right to use or act upon such Feedback without compensation and such Feedback will not be considered confidential. By providing any of the foregoing information, you consent to such information being collected, used, disclosed, and stored by us in accordance with this Privacy Policy.</p>
            <h3>INFORMATION ABOUT YOUR USE OF THE SERVICES</h3>
            <p>We collect technical and usage information when you use the Services, including, without limitation, information obtained using various types of tracking software, such as cookies, beacons, pixels, and other tracking technologies which collect information about you when you use the Services. Information collected may include your browser type and version, IP address, operating system and platform, your internet service provider, when you open email that we send you or interact with an advertisement, and other information. <br /></p>
            <p>We may collect and store information locally on your device using mechanisms such as&nbsp;browser web storage&nbsp;and&nbsp;application data caches. In addition, if you access the Service from a mobile or other device, we may collect a unique device identifier assigned to that device, geolocation data (including your precise location), or other transactional information for that device. By visiting the Services, you consent to the tracking of your activities and your use of the Services through the technologies described in this Privacy Policy and similar technologies that may be used or developed in the future.</p>
            <h3>INFORMATION FROM OUR CLIENTS</h3>
            <p>We may obtain personal information about you from our clients. The Company generally requires its clients to be solely responsible for obtaining all required consents, opt-ins, approvals, releases, and/or any other authorizations from individuals or entities that are required by Applicable Laws and are necessary for the Company to perform any services on a client’s behalf with respect to any of your personal information (collectively “<strong>Authorizations</strong>”). Accordingly, if the Company is processing your personal information, it is very likely that you have provided an Authorization to one of our clients.</p>
            <h3>INFORMATION FROM OTHER SOURCES</h3>
            <p>We may obtain additional personal information about you from the internet, offline sources, or other public or commercial sources including, but not limited to, social media services you have provided your information to (e.g., your name, username, gender, birthday, profile picture, avatar, “likes”, etc.).&nbsp;This could include, for example, information you have provided to job-posting services (e.g., LinkedIn), social media services, or other similar services. When you access the Services through a third-party service (e.g., Facebook, Twitter, etc.), you connect the Services to a third-party service, or share our content via a third-party service, you are authorizing us to collect, store, and use such information and content in accordance with this Privacy Policy. &nbsp;</p>
            <p>We may also receive additional personal information about you from certain third parties (e.g., researchers, analysts, and/or marketers) that provide us with statistical, demographic, or other similar information. Further, we may also combine your online information with information we obtain from offline sources, including, without limitation, information that you may provide us with over the phone, via webchat, or via email. Lastly, we may obtain information about you from our business partners and affiliates.</p>
            <p><strong><em>The Company is not responsible for the privacy practices of third parties;</em></strong> when you provide information about yourself on such services, it may be publicly viewable by other users of such services and we cannot prevent further access by others. If you wish to know how social media services or other third-party service providers handle your information, please review their respective terms of use, cookie policies, and/or privacy policies; such services may permit you to modify your privacy settings. <br /></p>
            <h2>HOW DOES THE COMPANY USE YOUR INFORMATION?</h2>
            <p>We may use your information, including, any of your personal information, as follows:</p>
            <h3>TO PROVIDE &amp; IMPROVE THE SERVICES</h3>
            <p>We primarily use personal information to carry out our obligations and enforce our rights related to any Service Agreements, including, for billing and collection purposes. The Company, our service providers, clients, and other third parties use the information collected from the Services to provide the Services to you and other users/visitors.&nbsp;This includes, without limitation, maintaining, measuring, and enhancing users' experiences on our Services, providing you with information, products or services, and fulfilling offers that you request from us, developing new products and services, understanding and resolving issues with the Services (e.g., bugs in an app), serving you relevant advertisements and content on the Services and third party services, and protecting you, your data, other users, other users’ data, and the Services.&nbsp;We also process information in order to allow you to share and collaborate on content.</p>
            <p>We will share your information to allow service providers and other third parties to assist in providing and managing the Services. We use your information to provide and improve the Services, for example, improving algorithms, determining usage patterns, and to provide customer support.&nbsp; <strong>Please note that if you telephone us, or if we call you, the call may be recorded for quality, training, and/or management purposes; online interactions (including through chat or email) may be recorded for similar purposes.</strong> Financial information (such as a credit card number and expiration date) is used for billing purposes, including, without limitation, order tracking, and fraud detection/prevention. If we have trouble processing an order, this information may be used to get in touch with the user. By submitting such information, you agree that this information may be shared with third parties for purposes of facilitating the completion of any purchase transaction initiated by you or on your behalf.</p>
            <h3>TO COMMUNICATE WITH YOU</h3>
            <p>We use your personal information to send information to you about your account, products and services, and to notify you about issues with, or changes to, the Services or our policies.&nbsp; We generally communicate with users via email, web chat, and/or by telephone.&nbsp; By using the Services, you agree that the Company and/or our agents may email, call, or text you regarding the Services, including communicating with you about your account, your interactions with the Services, and/or your transactions. <strong>BY PROVIDING YOUR HOME TELEPHONE NUMBER OR MOBILE NUMBER, YOU ACKNOWLEDGE AND AGREE THAT THE COMPANY MAY MAKE CALLS AND TEXTS TO SUCH NUMBER VIA USE OF AN AUTOMATIC TELEPHONE DIALING SYSTEM (“ATDS”) OR ANOTHER SIMILAR ANNOUNCING DEVICE.</strong> However, it should be noted that consent to receive phone calls via an ATDS is not a required condition for purchasing any goods or services from the Company. You agree that we may listen to and record phone conversations you have with us for training purposes and/or to evaluate the quality of our service.</p>
            <p>We may also send you marketing or other promotional materials, including but not limited to newsletters, educational materials, and/or surveys. The Company may also communicate with you regarding special offers from the Company and/or its clients. The Company will not send you marketing messages from the Company if you have opted out.</p>
            <p><strong>However, users should note that you cannot opt-out of receiving certain administrative communications and the Company reserves the right to send such messages via email and/or by phone to facilitate agreed upon transactions or update customers about existing business relationships.</strong> Such “<strong>administrative</strong>” communications include, but are not limited to, transactional messages, business relationship messages, welcome emails, transaction receipts, billing notifications, renewal notices, notification of activation of online privileges, technical support responses, announcement updates related to the Site and/or other Services, and/or other communications required to service customers' accounts and/or customers' subscriptions with the Company or its Business Partners. Users cannot unsubscribe from these email messages, which contain important information about the Services. <br />TO AGGREGATE &amp; ANONYMIZE DATA</p>
            <p>The Company may aggregate and/or anonymize any information collected through the Services for various purposes. Once aggregated or anonymized, the information will no longer be considered “personal information” because it will no longer be able to identify you and/or any of your specific devices used to access the Services. The Company may use such information for any purpose (e.g., research and development purposes), including sharing such information with third parties. <br /></p>
            <p>TO DISPLAY &amp; TARGET ADVERTISEMENTS</p>
            <p>Your information is used to target ads and content on the Services and on third-party services. Some advertisements and other content may be delivered by third party advertising networks and advertisers that may use cookies and similar technologies and identifiers to collect and track information such as demographic information, inferred interests, aggregated information, and activity to assist them in delivering advertising that is more relevant to your interests.&nbsp; We may also provide certain customer information to service providers who will match your information to information provided by other third parties (e.g., cookies) in order to allow us to provide more tailored advertisements. <br /></p>
            <p>For more information about&nbsp;how advertisers use cookies and to understand your choices not to accept these cookies by "opting out,"&nbsp;please visit the Network Advertising Initiative's (“<strong>NAI</strong>”) website at:&nbsp;<a href="http://www.networkadvertising.org/choices/" target="_blank">http://www.networkadvertising.org/choices/</a>.&nbsp; Additionally, you may "opt-out" from having third&nbsp;party online behavioral information collected by visiting the Digital Advertising Alliance's website at:&nbsp;<a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a>&nbsp;in desktop and mobile web browsers.&nbsp;You may download the AppChoices app at:&nbsp;<a href="http://www.aboutads.info/appchoices" target="_blank">http://www.aboutads.info/appchoices</a>&nbsp;to “opt-out” in mobile apps. If you would like more information about&nbsp;the third party ad serving companies used by us or regarding your ability to "opt-out" of the use of cookies, please go to:&nbsp;<a href="http://www.google.com/privacy/ads" target="_blank">www.google.com/privacy/ads</a>;&nbsp;<a href="http://www.google.com/intl/en/privacy/privacy-policy.html" target="_blank">http://www.google.com/intl/en/privacy/privacy-policy.html</a>;&nbsp;<a href="https://www.taboola.com/privacy-policy" target="_blank">https://www.taboola.com/privacy-policy</a>; <a href="http://www.adobe.com/privacy/analytics.html" target="_blank">http://www.adobe.com/privacy/analytics.html</a>; and <a href="http://inform.com/privacy/" target="_blank">http://inform.com/privacy</a>.<br /></p>
            <h3>TO FULFILL OTHER PURPOSES THAT YOU HAVE CONSENTED TO</h3>
            <p>Your information will also be used to fulfill any other purpose for which you provide the information or otherwise provide your consent for the Company and/or other third parties to use such information.</p>
            <h2>FOR WHAT PURPOSES DOES THE COMPANY USE OR SHARE YOUR PERSONAL INFORMATION?</h2>
            <h3>CONTRACTUAL OBLIGATION PURPOSES</h3>
            <p>As stated above, the Company’s primary line of business is the processing of data on behalf of its clients, this includes the processing of personal information at the direction of its clients under a Service Agreement. Accordingly, the Company will only process personal information consistent its contractual obligations with its clients and/or as required by Applicable Laws. For any processing the Company does to fulfill a contractual obligation, you should have already provided your Authorization directly to the Company’s client in order for the Company to perform the Services contemplated by an applicable Service Agreement. <br /></p>
            <h3>ORGANIZATIONAL PURPOSES </h3>
            <p>As the Company is part of a larger group of companies, it may be necessary for the Company to transmit your personal information within the organizational group. Processing may be necessary so that data can be shared amongst our affiliates so that each entity can carry out their legal, regulatory, and/or contractual responsibilities and/or coordinate/implement business plans, logistics, and/or operations. This is especially true because the Company’s affiliated entities may perform critical services for the Company, such as services related to: accounting, human resources, information technology, legal, etc. <br /></p>
            <h3>OPERATIONAL PURPOSES</h3>
            <p>Processing your personal information may be necessary to facilitate the day-to-day operation of our business, to allow our service providers and other third-parties assist in providing and managing the Services, and to allow for business planning for strategic growth. This may include: managing our relationship with you, our employees, users, clients, vendors, and/or others; sharing intelligence with internal stakeholders; implementing training procedures; planning and allocating resources and budgets; performing data modelling; facilitating internal reporting; analyzing growth strategies; aggregating analytics; and/or processing personal information to create anonymized data (e.g., for product improvement, analytics, etc.).</p>
            <h3>LOGISTICAL PURPOSES</h3>
            <p>Processing your personal information may be necessary to enable the Company’s business operations to run more efficiently, e.g., establishing how to allocate resources or to predict future demand. Additionally, the Company will process your personal information for collaboration purposes when you choose to use the Services’ collaboration or sharing features.</p>
            <h3>RESEARCH &amp; DEVELOPMENT PURPOSES</h3>
            <p>Processing your personal information will likely be necessary for us to deliver and/or improve our products and services. This includes processing your personal information to determine whether a product or service is working as intended, monitoring usage and conduct, and identifying and troubleshooting issues.</p>
            <h3>MARKET INTELLIGENCE &amp; ANALYTICAL PURPOSES</h3>
            <p>The Company regularly needs to conduct market intelligence so that we can better promote our products and services by creating a better understanding of our users’ and/or customers’ preferences. This could include using diagnostic analytics to optimize products, services, and/or marketing campaigns by assessing/monitoring users’ usage of the products or services and/or conduct while using the Services. <br /></p>
            <p>Common metrics for evaluation could include monitoring pages and links accessed, ad performance and conversion tracking, number of posts, number of page views, patterns of navigation, time at a page, devices used, user reviews, where users are coming from, hardware used, operating system version, advertising identifiers, unique application identifiers, unique device identifiers, browser types, languages, wireless or mobile network information, etc. These metrics could be used to: personalize services and communications; determine which users should receive specialized communications based on how they use the product or service; create aggregate trend reports; determine the most effective advertising channels and messaging; and/or measure the audience for a certain communication.</p>
            <h3>PERSONALIZATION PURPOSES </h3>
            <p>We may process personal information in order to enhance and personalize the “consumer experience” we offer our current and/or prospective users/customers in our products and services.</p>
            <h3>MONITORING PURPOSES </h3>
            <p>The Company may need to process your personal information in order to identify recurring problems and/or analyze the patterns of behavior of users in order to monitor the performance of, and users’ behavior on, our Services.</p>
            <h3>DIRECT COMMUNICATION PURPOSES</h3>
            <p>Processing your personal information may be necessary for direct communication purposes to occasionally update users on the Services, including occasional communications regarding updates to our activities, products, services, and/or events.</p>
            <h3>BUSINESS-TO-BUSINESS MARKETING &amp; SALES PURPOSES</h3>
            <p>The Company may need to process personal information in the context of marketing our products and services to other businesses, e.g., processing the information of a business contact in order to market our products and/or services to the affected data subject’s employer.</p>
            <h3>DUE DILIGENCE PURPOSES</h3>
            <p>It may be necessary for the Company to process your personal information for the purposes of conducting due diligence. This could include, for example, monitoring official watch-lists, sanction lists and “do-not-do-business-with” lists published by governments and other official bodies globally. This could also include keyword searches of industry and reputable publications to determine if companies and individuals have been involved in or convicted of relevant offenses, such as fraud, bribery, and/or corruption.</p>
            <h3>FRAUD DETECTION &amp; PREVENTION PURPOSES </h3>
            <p>Processing your personal information may be necessary for the Company to help detect and prevent fraud, e.g., verifying that the registered address of the cardholder for a particular credit or debit card is the same as the cardholder’s normal place of residence or work.</p>
            <h3>UPDATING CUSTOMER DETAILS &amp; PREFERENCES PURPOSES</h3>
            <p>Processing your personal information may be necessary to verify the accuracy of your user data and to create a better understanding of our past, present, and/or prospective users. <br /></p>
            <h3>NETWORK &amp; INFORMATION SECURITY PURPOSES</h3>
            <p>Processing your personal information may be necessary for the purposes of ensuring our network and information security, e.g., monitoring users’ access to our network for the purpose of preventing cyber-attacks, inappropriate use of data, corporate espionage, hacking, system breaches, etc. This could include preventing unauthorized access to electronic communications networks and malicious code distribution and stopping “denial of service” attacks and damage to computer and electronic communication systems. <br /></p>
            <h3>BUSINESS CONTINUITY &amp; DISASTER PLANNING PURPOSES </h3>
            <p>The Company may process your personal information because it is necessary to allow for the backup and protection of your information (e.g., utilizing cloud-based services to archive/protect data) in order to ensure that such information is not improperly lost or modified. Such processing is also necessary to archive/protect data in accordance with legal, regulatory, organizational, and/or contractual obligations.</p>
            <h3>ARTIFICIAL INTELLIGENCE PURPOSES</h3>
            <p>In processing your personal information, the Company may process your data utilizing algorithms that help to streamline organizational processes, e.g., our customer service department putting in place an algorithm that helps to manage customer service requests by routing customer contacts to the most appropriate part of the Company.</p>
            <h3>COMPLIANCE WITH LAWS &amp; REGULATIONS PURPOSES</h3>
            <p>The Company is subject to binding legal or regulatory obligations and may need to process your personal information in order to comply with such laws or regulations. Examples could include: complying with reporting obligations; complying with screening obligations; responding to law enforcement requests; and/or responding to judicial/regulatory agency requests.</p>
            <h3>COMPLIANCE WITH INDUSTRY-SPECIFIC SELF-REGULATION PURPOSES</h3>
            <p>The Company is part of several industry self-regulatory organizations, including certain organizations that set standards for information security. Such organizations were formed in order to address various concerns, including: developing industry standards and best practices to protect the industry; sharing intelligence or concerns about individuals (e.g., industry-specific watch lists); sharing intelligence or concerns that may have a negative or detrimental impact on the industry; and/or ensuring that participants in the industry are following agreed-upon standards. The Company may be required to process personal information so that we may stay in compliance with these self-regulatory requirements.</p>
            <h3>REPORTING POTENTIAL THREATS TO PUBLIC SECURITY &amp; SAFETY PURPOSES</h3>
            <p>The Company may need to use personal information to report possible criminal acts or threats to public security/safety that we identify as part of our processing activities to a competent authority.</p>
            <h3>TO FULFILL OTHER PURPOSES THAT YOU HAVE CONSENTED TO</h3>
            <p>Your information will also be used to fulfill any other purpose for which you provide the information or otherwise provide your consent for the Company and/or other third parties to use such information – assuming such use is consistent with applicable laws and regulations.</p>
            <h2>WHO DOES THE COMPANY SHARE YOUR INFORMATION WITH?</h2>
            <p>The Company shares personal information as stated in this Privacy Policy, in accordance with its contractual obligations under Service Agreements, and in accordance with Applicable Laws. We do not share your personal information with others except as indicated in this Privacy Policy or when we inform you and give you an opportunity to “opt-out” of having your personal information shared. Below are categories of third-parties that we may share your information with:</p>
            <h3>SERVICE PROVIDERS </h3>
            <p>We may share your personal information with service providers who perform services on the Company’s behalf. Your information is generally shared with service providers so that the Company can fulfill, streamline, and enhance the Services. Service providers could include parties that perform research services, provide operational support, provide marketing platforms, fulfill orders, handle payments, handle shipping and delivery obligations, create content for the Services, etc. As an example, if the Services will involve delivery of tangible products, your name and address may be shared with third party shipping and delivery companies (e.g., common carriers) in order to fulfill your order(s). </p>
            <h3>CLIENTS</h3>
            <p>As stated above, the Company’s primary line of business is the processing of data on behalf of its clients. Accordingly, most personal information that we process has been provided to us by our clients – if we are processing your personal information on a client’s behalf, you will have likely provided your Authorization related to processing your personal information directly to our client. Once we have processed your personal information, it is likely that we will provide our client access to any deliverables that resulted from our processing of your information.</p>
            <h3>CORPORATE AFFILIATES</h3>
            <p>As the Company is part of a larger group of companies, it may be necessary for the Company to transmit your personal information within the organizational group.</p>
            <h3>GOVERNMENTAL ENTITIES</h3>
            <p>We may share your personal information with competent governmental authorities when required by law and/or as necessary to protect the Company, its personnel, other users, and/or the Services. <br /></p>
            <h2>HOW CAN I CHANGE MY E-MAIL COMMUNICATION PREFERENCES?</h2>
            <p>With the exception of the mandatory administrative emails mentioned above (transactional and business relationship messages), recipients have an opportunity to update their email communication preferences. Customers may choose to “opt-in” or “opt-out” from any or all of these email communications at will. Generally, you may unsubscribe from emails by clicking the "unsubscribe" link included at the bottom of any marketing email sent by the Company on the Company’s behalf. <br /></p>
            <h2>HOW CAN I CORRECT OR UPDATE MY PERSONAL INFORMATION?</h2>
            <p>When you contact us via phone, text, email, fax, chat, or submit an online request, we may include the contact information you provide to us (e.g., your address, phone number, or email address) in your customer account profile. If your personal information changes (such as your zip code), or if you no longer desire to use our Services, you may be able to correct, update, or remove your personal information provided to us by sending an email to us at: <a href="mailto:privacy@oneildata.com">privacy@oneildata.com</a>. <br /></p>
            <h2>HOW DOES THE COMPANY PROTECT MY PERSONAL INFORMATION?</h2>
            <h3>SECURITY MEASURES &amp; SAFEGUARDS</h3>
            <p>The security of personal information is very important to us. Personal information is protected by the Company in accordance with generally accepted industry standards and applicable data privacy laws, including both online and offline security measures and safeguards. The Company uses a combination of administrative, technical, personnel, and physical measures to safeguard personal information in its possession against loss, theft, and unauthorized use, disclosure, or modification. However, no one can guarantee the complete safety of your information. <br /></p>
            <p><strong>IF YOU HAVE REASON TO BELIEVE THAT YOUR INTERACTION WITH US IS NO LONGER SECURE (FOR EXAMPLE, IF YOU FEEL THAT THE SECURITY OF ANY ACCOUNT YOU MIGHT HAVE WITH US HAS BEEN COMPROMISED), PLEASE IMMEDIATELY NOTIFY US OF THE PROBLEM.</strong>&nbsp; However, please remember that no method of electronic storage or transmission over the internet is&nbsp;one-hundred percent secure and, therefore, while we strive to use commercially reasonable means to protect your personal information, and will comply with applicable data privacy laws, we cannot guarantee the absolute security of such information.</p>
            <h3>PHISHING</h3>
            <p>Phishing is a term used to describe a type of internet scam in which inauthentic communications that appear to be coming from a legitimate source are sent in an attempt to trick you into providing confidential information. These communications usually invoke a sense of urgency and often imply that your account is about to be closed unless immediate action is taken. As an example, phishing emails will often ask you to click on a link that leads you to a webpage that simulates that of the legitimate source, but it is really a page specially designed to get you to input your confidential information such as your account number, password, social security number, or credit card number. The thieves then use that information to commit fraud and identity theft.</p>
            <p>The Office of the Comptroller of Currency has set up a website providing information warning consumers about the dangers of these scams and how to protect yourself. To learn more about phishing, please click on the following link:&nbsp;<a href="https://www.occ.gov/topics/consumers-and-communities/consumer-protection/fraud-resources/phishing-attack-prevention.html" target="_blank" rel="noopener noreferrer">https://www.occ.gov/topics/consumers-and-communities/consumer-protection/fraud-resources/phishing-attack-prevention.html</a></p>
            <p>&nbsp;If you ever question the legitimacy of an email or other communication from the Company, please contact us at <a href="mailto:privacy@oneildata.com">privacy@oneildata.com</a>.&nbsp; If you believe you have been the victim of a scam, you can file a complaint with the Federal Trade Commission at:&nbsp;<a href="http://www.consumer.gov/idtheft/" target="_blank">www.consumer.gov/idtheft/</a>.</p>
            <h2>DO NOT TRACK SIGNALS </h2>
            <p>At this time, the Services do not recognize or respond to browser’s “Do Not Track” signals.</p>
            <h2>DATA RETENTION</h2>
            <p>We will retain your information for the period necessary to fulfill the purposes for their collection as outlined in this Privacy Policy, unless a longer retention period is either mandated by the Company’s contractual requirements, internal policies, or otherwise required or allowed by Applicable Laws.&nbsp; We may retain backup information related to your account on our servers for some time after cancellation/termination for fraud detection or to comply with Applicable Laws or our internal security policies.&nbsp;It is not always possible to completely remove or delete all of personal information due to technical constraints, contractual, financial, or legal requirements. Where it is not possible to remove or delete all information, the Company shall continue to safeguard your information as detailed herein.</p>
            <h2>SENSITIVE INFORMATION</h2>
            <p>We do not intentionally collect sensitive personal information, such as social security numbers, political opinions, religious or other beliefs, health information, criminal background information, sexual history or sexual orientation information, or trade union membership ("<strong>Sensitive Information</strong>") through the Services and we ask that you do not disclose it to us – unless such information is provided to us pursuant to a Services Agreement and the processing of such information is required for the Company to perform its contractual obligations.&nbsp; However, if there is not a Service Agreement in place governing the use/protection of such information, if you provide Sensitive Information to us, we will have access to such information and such information will be treated in the same way as information that is not sensitive.</p>
            <h2>PERSONAL INFORMATION OF CHILDREN</h2>
            <p>The Services are for a general audience and are not targeted to children.&nbsp; The Company and its Business Partners do not knowingly collect, solicit, or share personal information from children under the age of sixteen (16). If such a situation is discovered, we will delete that information immediately. If you believe the Company has any information from a child under the age of sixteen (16), please contact via email at <a href="mailto:privacy@oneildata.com">privacy@oneildata.com</a>. &nbsp;</p>
            <h3>CALIFORNIA PRIVACY RIGHTS</h3>
            <p>For users of the Services that are residents of the State of California, <a href="http://www.oneildata.com/ca-privacy-policy">please click here</a> to review the Company’s California Privacy Addendum (the “<strong>California Addendum</strong>”). The California Addendum details your rights under the California Consumer Privacy Act (“<strong>CCPA</strong>”), additional disclosures as required under the CCPA, and provides you with an explanation of how you may exercise your rights (including the Right to Opt-Out of the Sale of Personal Information). The California Addendum also details your rights under California’s "<strong>Shine the Light</strong>" law.</p>
            <h3>NEVADA PRIVACY RIGHTS</h3>
            <p>For users of the Services that are residents of the State of Nevada, <a href="http://www.oneildata.com/nv-privacy-policy">please click here</a> to review the Company’s Nevada Privacy Addendum (the “<strong>Nevada Addendum</strong>”). The Nevada Addendum details your rights under Nevada law and provides you with an explanation of how you may exercise your rights. </p>
            <h2>USING &amp; ACCESSING THE SERVICES FROM OUTSIDE THE UNITED STATES</h2>
            <p>The Company is a Delaware limited liability company with its headquarters located at 12655 Beatrice St., Los Angeles, California 90066, United States. Our Services are targeted at residents of the United States and our Services are governed by, and operated in accordance with, the laws of the United States. The United States is where the Services, including our servers and central database, are hosted, located, and operated. If you are accessing the Services from outside the United States, you will be voluntarily transmitting your personal information to the United States where your information will be stored and processed by the Company and/or its service providers. <br /></p>
            <p>There are obvious risks that apply when data are transferred from one jurisdiction to another (e.g., an unauthorized interception of the data, misuse, etc.) and the data protection and other laws of the United States and/or other countries might not be as comprehensive as those in your country of residence. Additionally, in certain circumstances, law enforcement or regulatory agencies, courts, or security authorities in the United States may be entitled to access your personal information.&nbsp;<strong>BY USING OUR SERVICES, YOU ACKNOWLEDGE AND AGREE THAT YOUR PERSONAL INFORMATION WILL BE TRANSFERRED TO, AND PROCESSED IN, THE UNITED STATES AT OUR FACILITIES AND BY THOSE THIRD PARTIES WITH WHOM WE SHARE IT AS DESCRIBED IN THIS PRIVACY POLICY.</strong> Please be assured that we take commercially reasonable steps to ensure that your privacy is protected. <br /></p>
            <p>For users of the Services that are residents of the European Union (“<strong>EU</strong>”) or the United Kingdom (“<strong>UK</strong>”), while the Company is an American company subject to American law, please click here <a href="https://www.oneildata.com/eu-privacy-policy" target="_blank">https://www.oneildata.com/eu-privacy-policy</a> to review the Company’s EU Data Privacy Addendum (the “<strong>EU Addendum</strong>”). The EU Addendum provides you with an explanation of how the Company will attempt to give effect to rights provided under the EU’s General Data Protection Regulation (“<strong>GDPR</strong>”) and provides you with additional disclosures. <br /></p>
            <h2>CHANGES TO OUR PRIVACY POLICY &amp; COMMUNICATING CHANGES</h2>
            <p>This Privacy Policy is subject to change at any time. All changes will be posted on the Site.&nbsp; If the changes are significant we will notify you by email (at the last email we have on file for you) or by prominently posting a notice on our Services (e.g., through a pop-up on our homepage). Changes will become effective as of the earlier of the date we post the revised privacy policy, send an email detailing any changes, or post such a notice. While we will do our best to inform you of any changes, we encourage you to review our Privacy Policy periodically to ensure that you remain informed of changes that may have occurred, including changes that relate to what information we collect, how we use it, and how we share it. <strong>BY CONTINUING TO USE THE SERVICES AFTER A CHANGE HAS TAKEN PLACE, YOU ARE AGREEING THAT YOU HAVE READ AND HAVE AGREED TO THE MOST CURRENT VERSION OF THIS PRIVACY POLICY AND EXPRESSLY CONSENT TO THE USE OF YOUR PERSONAL INFORMATION AS DETAILED HEREIN/THEREIN. </strong><br /></p>
            <h2>LEGAL DISCLAIMER</h2>
            <p>Though we make every effort to preserve user privacy, we may need to disclose your personal information to others. We may share your information to provide information to our representatives, service providers, advisors, corporate affiliates, clients, and business partners, and in the event of a sale, merger, public offering, bankruptcy or other change in control of the Company and/or its affiliates, your information may be shared with the person or business obtaining ownership and control. Your information may be used to protect the rights, including legal rights, and safety of ourselves and others.&nbsp; We may also use your information to enforce our rights arising from any contracts entered into between you and us, including, for billing and collection.&nbsp; Furthermore, disclosure of personal information may be required by law where we have a good-faith belief that such action is necessary to comply with a current judicial proceeding, a court order, or legal process.&nbsp; In such cases, we may raise or waive any legal objection or right available to us.</p>
            <h2>THIRD-PARTY SERVICES</h2>
            <p>Other websites and services to which we link, through which you access the Services, or share information from the Services and which are not owned or controlled by us are not subject to the terms of this Privacy Policy.&nbsp; <strong>WE ARE NOT RESPONSIBLE FOR THE PRIVACY PRACTICES OR THE CONTENT OF ANY OTHER WEB SITES OR SERVICES AND ANY VISIT YOU MAKE TO THOSE OTHER SERVICES IS AT YOUR OWN RISK.</strong>&nbsp; Third parties that support the Services, including by providing advertising or other services, such as analytics, may use tracking technologies to collect information about your use of the Services or other third-party services.&nbsp; We do not control these third-party technologies or services and their use is governed by the privacy policies of third parties using such technologies.&nbsp; Such third party's use of any information you share is governed by the third party's privacy policy.</p>
            <h2>INTERPRETATION</h2>
            <p>If any provision of this Privacy Policy (including any attachment) is held to be invalid, illegal, or unenforceable by a court or regulatory authority of competent jurisdiction, the rest of this Privacy Policy shall remain valid and enforceable to the fullest extent permitted under Applicable Laws. To the extent that the main body of this Privacy Policy conflicts with the terms of any addenda referenced herein, the addenda will control.</p>
            <h2>COMPANY CONTACT INFORMATION</h2>
            <p>To ask questions or comment about this Privacy Policy and/or our privacy practices, please contact the Company via email at <a href="mailto:privacy@oneildata.com">privacy@oneildata.com</a>. For users with disabilities that would prefer to speak via phone, you may reach us at 1-844-902-0581.</p>
            <br /><br />
        </div>
    );
}
