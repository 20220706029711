import {
    Box, Divider, Grid, Typography
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import {
    CheckboxFieldCell, DateFieldCell, MultiSelectCell, MultiSelectTextCell, RadioSelectCell,
    RemarkFieldCell, TextAreaCell, TextFieldCell
} from './cells-control';
import './cells-control/demo.scss';

export default function FormBuilder(props) {
    const { layout: rootLayout, dataCell, code, onFormDataChange } = props;

    // if (code === 'YMR01' || code === 'MR01') {
    //     dataCell.find(c => c.id === '715').defaultValue = 'Aetna, PO Box 14088';
    //     dataCell.push({
    //         id: '715a',
    //         title: 'Mail to 2',
    //         dType: 'string',
    //         type: 'textField',
    //         defaultValue: 'Lexington, KY 40512-4088',
    //         characterLimit: 70
    //     });

    //     rootLayout = {
    //         dir: 'v',
    //         line: true,
    //         items: [{
    //             dir: 'h',
    //             items:
    //                 [{ dir: 'v', items: [{ id: '701' }, { id: '704' }] }, { dir: 'v', items: [{ dir: 'h', title: 'Effective date', items: [{ id: '711a' }, { id: '711b' }, { id: '711c' }] }, { dir: 'h', items: [{ id: '702' }, { id: '702a' }] }, { id: '705' }] }]
    //         }, { dir: 'v', items: [{ id: '713' }, { id: '714' }, { id: '715' }, { id: '715a' }, { id: '716a' }, { id: '717a' }, { id: '718a' }] }]
    //     };
    // }
    const defaultFormData = {};
    for (const c of dataCell) {
        if (c.defaultValue) {
            defaultFormData[c.id] = c.defaultValue;
        }
    }


    if (code === 'YMR01' || code === 'MR01' || code === 'YMR04' || code === 'MR04' || code === 'YMR05' || code === 'MR05' ||
        code === 'YMR07' || code === 'MR07') {
        defaultFormData['716'] = defaultFormData['716a'] ? 'Website:' : '';
        defaultFormData['717'] = defaultFormData['717a'] ? 'Fax Number:' : '';
        defaultFormData['718'] = defaultFormData['718a'] ? 'Contact Name:' : '';
    }

    // if (code === 'YMR01' || code === 'MR01') {
    //     if (defaultFormData['716a']) {
    //         defaultFormData['716'] = 'Website';
    //     }
    //     if (defaultFormData['717a']) {
    //         defaultFormData['717'] = 'Fax Number';
    //     }
    //     if (defaultFormData['718a']) {
    //         defaultFormData['718'] = 'Contact Name';
    //     }
    // }

    const [formData, setFormData] = useState(defaultFormData);

    // set form data on initial load
    useEffect(() => {
        setFormData(defaultFormData);
        onFormDataChange(defaultFormData);
    }, []);

    // for (const d of dataCell) {
    //     if (d.id === '704' && d.title === 'HMO plan selections (max 3)') {
    //         d.type = 'multiSelectText';
    //         d.args.textIds = [
    //             '706a',
    //             '706b',
    //             '706c'
    //         ];
    //     } else if (d.id === '705' && d.title === 'PPO plan selections (max 3)') {
    //         d.type = 'multiSelectText';
    //         d.args.textIds = [
    //             '707a',
    //             '707b',
    //             '707c'
    //         ];
    //     }
    // }

    if (!rootLayout) { return <h3>Invalid form</h3>; }

    const updateFormData = (row, col, newValue) => {
        if (col.type === 'date' && newValue.includes('-')) {
            const date = new Date(`${newValue}T00:00:00`);
            const dateValue = `${(date.getMonth() > 8) ? (date.getMonth() + 1) : (`0${date.getMonth() + 1}`)
                }/${(date.getDate() > 9) ? date.getDate() : (`0${date.getDate()}`)
                }/${date.getFullYear()}`;
            formData[`${row}`] = dateValue;
        } else {
            formData[`${row}`] = newValue;
        }

        if (code === 'YMR01' || code === 'MR01' || code === 'YMR04' || code === 'MR04' || code === 'YMR05' || code === 'MR05' ||
            code === 'YMR07' || code === 'MR07') {
            formData['716'] = formData['716a'] ? 'Website:' : '';
            formData['717'] = formData['717a'] ? 'Fax Number:' : '';
            formData['718'] = formData['718a'] ? 'Contact Name:' : '';
        }

        if (col.args && col.args.groupIds && Array.isArray(newValue)) {
            for (const g of col.args.groupIds) {
                formData[g] = null;
            }
            for (let n = 0; n < newValue.length; n += 1) {
                const g = col.args.groupIds[n];
                if (g) {
                    if (col.args.textIds) {
                        formData[g] = newValue[n].name;
                        formData[col.args.textIds[n]] = newValue[n].value;
                    } else {
                        formData[g] = newValue[n];
                    }
                }
            }
        }
        if (col.args && col.args.value2Id) {
            formData[`${col.args.value2Id}`] = newValue.value2 || '';
        }

        setFormData(formData);
        props.onFormDataChange(formData);
    };

    const renderField = (key, cell, value) => {
        switch (cell.type) {
            case 'textField':
                return (
                    <TextFieldCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            case 'textArea':
                const cl = { ...cell };
                if (cl.title === 'Website Verbiage') {
                    cl.value = cl.defaultValue;
                }

                return (
                    <TextAreaCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cl}
                        updateRowData={updateFormData}
                    />
                );
            case 'multiSelect':
                return (
                    <MultiSelectCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            case 'multiSelectText':
                return (
                    <MultiSelectTextCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            case 'date':
                return (
                    <DateFieldCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            case 'radioSelect':
                return (
                    <RadioSelectCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            case 'checkbox':
                return (
                    <CheckboxFieldCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            case 'remarksField':
                return (
                    <RemarkFieldCell
                        row={key}
                        col={cell}
                        prefix="field-form"
                        value={value}
                        {...cell}
                        updateRowData={updateFormData}
                    />
                );
            default:
                return null;
        }
    };

    const renderLayout = (layout, level = 0, dir, isLine = false) => {
        // console.log('base', layout, level, dir, isLine);
        if (dir === 'v') {
            return (
                <Box component="div" key={level} mb={2} className="vertical-part">
                    {layout && layout.title
                        && (
                            <Box component="div" mb={1}>
                                <Typography variant="h6">{layout.title}</Typography>
                            </Box>
                        )}
                    <Box component="div">
                        {layout && layout.items && layout.items.map((item, index) => {
                            return renderLayout(item, index, item.dir, layout.line);
                        })}
                    </Box>
                    {isLine
                        && <Divider className="vertical-part-line" style={{ height: '3px', margin: '20px 0' }} />}
                </Box>
            );
        } else if (dir === 'h') {
            const eachGridNum = (layout.items && layout.items.length > 0 && (12 / layout.items.length)) || 12;
            const horizontalLine = layout && layout.line;
            const horizontalLineClass = horizontalLine ? ' horizontal-part-line' : '';
            return (
                <Box component="div" key={level} mb={1} className="horizontal-part">
                    {layout && layout.title
                        && (
                            <Box component="div" mb={1}>
                                <Typography variant="h6">{layout.title}</Typography>
                            </Box>
                        )}
                    <Grid container spacing={3} className="list-part">
                        {layout && layout.items && layout.items.map((item, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={eachGridNum}
                                    key={index}
                                    className={horizontalLineClass}
                                >
                                    <Box component="div">
                                        {renderLayout(item, index, item.dir, false)}
                                    </Box>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {isLine
                        && <Divider className="vertical-part-line" style={{ height: '3px', margin: '20px 0' }} />}
                </Box>
            );
        }

        if (layout && layout.id) {
            if (!dataCell) return;
            const cell = dataCell.find((x) => x.id === layout.id);

            if (cell.type === 'multiSelectText') {
                return (
                    <Box component="div" key={level} className="cell-part" fontSize={12} style={{ marginTop: '1rem' }}>
                        {renderField(layout.id, cell, formData[layout.id])}
                    </Box>
                );
            }

            return (
                <Box component="div" key={level} className="cell-part">
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >

                        <Grid item xs={12}>
                            {renderField(layout.id, cell, formData[layout.id])}
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    };

    return (
        <Box style={{ paddingTop: '.5rem' }}>
            {renderLayout(rootLayout, 0, rootLayout.dir)}
        </Box>
    );
}
