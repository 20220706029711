import { Box, Grid, Typography } from '@material-ui/core';
import SelectItemStep from 'pages/direct-order/steps/SelectItemStep';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    let o = props.onlyWHS;
    if (o.indexOf(',') > 0) {
        o = o.split(',')[1].replace(/ /g, '');
    }
    const { title = 'Add Additional Document', lob = 'cml', jobType = 'SG', disableAttachment = true } = props;
    return (
        <Box>
            <Grid container spacing={3}>
                {
                    title && (
                        <Grid item xs={6}>
                            <Typography variant="h5" component="h6">
                                {title}
                            </Typography>
                        </Grid>
                    )
                }
                <Grid item xs={12}>
                    <SelectItemStep
                        disableChild
                        disableAttachment={disableAttachment}
                        lob={lob}
                        onlyState={props.onlyState}
                        jobType={jobType}
                        onlyWhs={o}
                        setIsNextReady={() => { }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});
