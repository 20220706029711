import {
    Button,


    Dialog, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import React from 'react';
export default function ItemPreview({
    show, onClose, item, token, onApproved
}) {
    const user = window.localStorage.getItem('user') && window.localStorage.getItem('user') !== 'undefined'
        ? JSON.parse(window.localStorage.getItem('user'))
        : null;
    const pdfLink = item.component.preview
        ? `${item.component.preview}?Authorization=${user.token}`
        : `${process.env.REACT_APP_SERVER_URL.replace('/api', '/file/flip')}/quickview/p1b/${item.component.id}?Authorization=${user.token}`;
    return (
        <Dialog
            open={!!show}
            onClose={onClose}
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        {item.component.approved
                            ? <Button onClick={() => { onClose(); }}>Close</Button>
                            : (
                                <>
                                    <Button onClick={() => { onClose(); }}>Cancel</Button>
                                    <Button color="primary" onClick={() => onApproved()}>Approve</Button>
                                </>
                            )}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers style={{ textAlign: 'center', minWidth: '60rem' }}>
                <iframe
                    id="previewFrame"
                    style={{
                        width: '100%', height: '100%', minHeight: '640px'
                    }}
                    src={`${pdfLink}#toolbar=0`}
                />
            </DialogContent>
        </Dialog>
    );
}
