import orderActions from './action';

export default (
    state = {
        loading: false,
        orders: null,
        criteria: {
            orderNumber: '',
            startDate: null,
            endDate: null,
            customerName: '',
            recipientFirstName: '',
            recipientMiddleName: '',
            recipientLastName: '',
            fileNumber: '',
            userId: '',
            npn: ''
        }
    },
    action
) => {
    switch (action.type) {
        case orderActions.LOADING:
            return { ...state, loading: action.loading };

        case orderActions.SET_ORDERS:
            return { ...state, orders: action.orders };

        case orderActions.SET_CRITERIA:
            return { ...state, criteria: action.criteria };

        default:
            return state;
    }
};
