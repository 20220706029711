import React from 'react';
import {
    Button, Grid, Modal, Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 'auto',
        maxHeight: 720,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eddd',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const PreviewModal = ({
    pdf, open, onClose, user
}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    let filtered = pdf;

    if (!user) return null;
    if (filtered && !filtered.includes('Authorization')) {
        filtered = `${pdf}?Authorization=${user.token}`;
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="h6">
                            Preview
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button color="primary" onClick={onClose}>Close</Button>
                    </Grid>
                    <Grid style={{ height: '860px' }} item xs={12}>
                        <iframe style={{ width: '100%', height: '100%' }} title={filtered} src={`${filtered}#toolbar=0&navpanes=0`} />
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

const mapStateToProps = ({ appView: { user } }) => ({
    user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);
