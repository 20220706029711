
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, setErrorMsg } from './action';
import LoginForm from './LoginForm';

const mapStateToProps = ({ loginView, appView }) => {
    return {
        error: loginView.error,
        loading: loginView.loading,
        isAuthUser: appView.isAuthUser
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            login, setErrorMsg
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
