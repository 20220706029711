import {
    Box,

    Breadcrumbs, CircularProgress,
    Grid, Link,
    Tooltip, Typography
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import SpeedIcon from '@material-ui/icons/Speed';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import http from 'utils/http';
import Comments from './Comments';
import DigitalItems from './DigitalItems';
import JobView from './job';
import ProductOrderConfirmView from './ProductOrderConfirmView';

export default function OrderDetails(props) {
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState(null);
    const { match: { params: { orderId, type } }, user } = props;

    const toFullName = (s) => {
        switch (s) {
            case 'FJE':
                return 'Fast Job';
            case 'AJEPP':
                return 'Pick & Pack';
            case 'AJEKT':
                return 'Kit Job';
            case 'IM':
                return 'IM';
            case 'INVENTORY':
                return 'INVENTORY';
            case 'Broker':
                return 'Broker';
            default:
                return '';
        }
    };

    if (!orderData) {

        http.get(`store/order-data/${orderId}` + (type ? '/c' : '')).then((data) => {
            setOrderData(data);
        }).finally(() => setLoading(false));
    }
    const { location } = props;
    const fromOrderMgmt = !!(location && location.state && location.state.criteria);

    const so = location?.state?.criteria?.soNumber;

    if (loading || !orderData) {
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        {fromOrderMgmt ? (
                            <Link color="inherit" onClick={() => props.history.goBack()}>
                                Order Management
                            </Link>

                        ) : (
                            <Link color="inherit" href="#/orders">
                                Order History
                            </Link>

                        )}
                        <Typography color="textPrimary">{type ? 'Order Confirmation' : 'Order Details'}</Typography>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <Typography variant="h5" gutterBottom component="div">
                        Order Details
                    </Typography>
                </Box>
                <Box m={4} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Box>
            </Box>
        );
    }

    const orderCustomFields = JSON.parse(orderData.customFields);
    const digitalItems = orderCustomFields.basketItems ? orderCustomFields.basketItems.filter((bi) => bi.customFields && (bi.customFields.outputType === 'Digital Only' || bi.customFields.outputType === 'Digital and Print')) : [];
    for (const d of digitalItems) {
        if (d.customFields && d.customFields.crosswalk && d.customFields.customizeCode && d.customFields.customizeCode.startsWith('OnlineBanner')) {
            d.isImg = true;
            const ss = d.customFields.crosswalk.preview.split('/');
            ss[ss.length - 1] = d.customFields.crosswalk.proofFileId;
            d.downloadLink = ss.join('/').replace('file/preview/', 'file/download/');
        }
    }
    let digitalPanel = null;
    let shippingMethod = '';
    let originalOrderNumber = null;

    if (orderData && orderData.orderShipping && orderData.orderShipping.length > 0) {
        shippingMethod = orderData.orderShipping[0].shippingMethodCode;
    }

    if (digitalItems.length > 0) {
        digitalPanel = <DigitalItems digitalItems={digitalItems} user={user} />;
    }

    if (orderCustomFields && orderCustomFields.basketItems && orderCustomFields.basketItems[0].customFields && orderCustomFields.basketItems[0].customFields.originalOrderNumber) {
        originalOrderNumber = orderCustomFields.basketItems[0].customFields.originalOrderNumber;
    }

    const od = { ...orderData, customFields: JSON.parse(orderData.customFields) };
    const comments = orderData.comments;

    const toLocal = (orderedDate) => {
        const d = new Date(orderedDate);
        return `${d.toLocaleDateString().replace('202', '2')} ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}`;
    };
    return (
        <Box>

            <Box mb={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="#/account">
                        Account
                    </Link>
                    {fromOrderMgmt ? (
                        <Link color="inherit" style={{ cursor: 'pointer' }} onClick={() => props.history.goBack()}>
                            Order Management
                        </Link>

                    ) : (
                        <Link color="inherit" href="#/orders">
                            Order History
                        </Link>

                    )}
                    <Typography color="textPrimary">{type ? 'Order Confirmation' : 'Order Details'}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={9}>

                        <Typography variant="h6" gutterBottom component="div">

                            {type ? 'Order Confirmation' : 'Order Details'}
                            <Tooltip
                                placement="top"
                                title="Print order confirmation"
                                className="custom-tooltip"
                            >
                                <PrintIcon onClick={() => window.print()} className="print-icon" />
                            </Tooltip>
                        </Typography>
                        {!!type && (!orderData || !orderData.hasConfirmEmailError) && <Alert severity="info">Your order has been placed. You will receive an email confirmation soon.</Alert>}
                        {!!type && orderData && orderData.hasConfirmEmailError && <Alert severity="error">Your order has been placed. However there&apos;s an error while sending email confirmation.</Alert>}
                    </Grid>
                    <Grid item xs={3}>
                        <Box style={{ textAlign: 'right' }}>
                            <div><b>Order #: </b><span>{orderData.orderNumber}</span></div>
                            {originalOrderNumber ? <div><b>Copied From: </b><span>{originalOrderNumber}</span></div> : null}
                            <div><b>Date: </b><span>{toLocal(orderData.orderedDateInTimeZone)}</span></div>
                            <div><b>Order Status: </b><span>{orderData.orderStatus}</span></div>
                            <div><b>Job Type: </b><span>{toFullName(orderData.jobType)}</span></div>
                            {user && user.customFields && user.customFields.p2_Catalog ? <div><b>Shipping: </b><span>{shippingMethod}</span></div> : null}
                            {orderData.isRush && <div><SpeedIcon color="primary" /> Rush Order</div>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                {digitalPanel}
            </Box>
            <Box>
                {
                    od.customFields.jobItems || od.customFields.materialItems
                        ? <JobView orderData={od} so={so} />
                        : <ProductOrderConfirmView user={user} orderData={od} />
                }
            </Box>
            {comments && comments.length > 0 && (
                <Comments comments={comments} />
            )}
        </Box>
    );
}
