import catalogActions from './action';

export default (
    state = {
        loading: false,
        categoryTree: null,
        products: [],
        productDetail: null,
        found: [],
        newThumbnail: null,
        notReadyForCart: false,
        searching: false,
        crosswalk: null,
        editingCustomFields: null,
        pricing: {},
        searchResult: null
    },
    action
) => {
    switch (action.type) {
        case catalogActions.LOADING:
            return { ...state, loading: action.loading };
        case catalogActions.SET_SEARCHING:
            return { ...state, searching: action.searching };

        case catalogActions.SET_CATEGORY_TREE:
            return { ...state, categoryTree: action.categoryTree };

        case catalogActions.SET_PRODUCTS:
            return { ...state, products: action.products };

        case catalogActions.SET_SEARCH_RESULT:
            return { ...state, searchResult: action.searchResult };

        case catalogActions.SET_PRODUCT_DETAIL:
            return {
                ...state,
                productDetail: action.productDetail
            };
        case catalogActions.SET_PRICING:
            return { ...state, pricing: { ...state.pricing, [action.code]: action.pricing } };

        case catalogActions.SET_FOUND:
            return { ...state, found: action.found };

        case catalogActions.SET_NEW_THUMBNAIL:
            return { ...state, newThumbnail: action.newThumbnail };

        case catalogActions.SET_NOT_READY_FOR_CART:
            return { ...state, notReadyForCart: action.notReadyForCart };

        case catalogActions.SET_CROSSWALK:
            return { ...state, crosswalk: action.crosswalk };

        case catalogActions.SET_EDITING_CUSTOM_FIELDS:
            return { ...state, editingCustomFields: action.editingCustomFields };

        default:
            return state;
    }
};
