import {
    Box,



    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,

    Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import Pagination from '@material-ui/lab/Pagination';
import LoadingTableRow from 'components/LoadingTableRow';
import React, { useState } from 'react';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}))(Tooltip);

export default function AddressTable({
    addresses, handleQtyChange, onRemove, removeUploadedAddr, hasAddressQty, totalAddressCount, addressCode, addressPagination,
    loadAddressesByPage, loadingAddressPage, clearAll, allowRemoveUploadedAddress,
    supportRecipient2
}) {

    const [confirmClearAll, setConfirmClearAll] = useState(false);

    const total = addressCode ? (addressPagination ? addressPagination.totalCount : '') : addresses.length;

    return (
        <>

            <TableContainer style={{ maxHeight: '600px', marginTop: '2rem' }} component={Paper}>
                <Table stickyHeader ize="small" aria-label="address table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ padding: '.5rem' }}>First1</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>MI1</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Last1</TableCell>
                            {
                                supportRecipient2 && (
                                    <>
                                        <TableCell style={{ padding: '.5rem' }}>First2</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>MI2</TableCell>
                                        <TableCell style={{ padding: '.5rem' }}>Last2</TableCell>
                                    </>
                                )
                            }
                            <TableCell style={{ padding: '.5rem' }}>Company</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Address1</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Address2</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Address3</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>City</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>State</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Zip</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Country</TableCell>
                            <TableCell style={{ padding: '.5rem' }}>Phone</TableCell>
                            <TableCell style={{ padding: '.5rem' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {addressCode && loadingAddressPage
                            && (
                                <LoadingTableRow loading />
                            )}
                        {addressCode && !loadingAddressPage && addressPagination && addressPagination.results && addressPagination.results.map((a) => (
                            <TableRow key={a.id}>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.first1}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.middle1}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.last1}</TableCell>
                                {
                                    supportRecipient2 && (
                                        <>
                                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.first2}</TableCell>
                                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.middle2}</TableCell>
                                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.last2}</TableCell>
                                        </>
                                    )
                                }
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.company}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address2}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address3}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.city}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.state}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.zip}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.country}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.phoneNumber}</TableCell>
                                <TableCell style={{ padding: '.5rem' }}>
                                    {a.qty}
                                    {
                                        allowRemoveUploadedAddress && (
                                            <IconButton
                                                aria-label="delete"
                                                display="inline"
                                                m={1}
                                                style={{ padding: '5px' }}
                                                onClick={() => removeUploadedAddr(a.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )
                                    }
                                </TableCell>
                            </TableRow>
                        ))}

                        {!addressCode && addresses && addresses.length === 0 && (
                            <TableRow>
                                <TableCell style={{ textAlign: 'center', padding: '1rem' }} colSpan={16}>
                                    <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No address added yet</span>
                                </TableCell>
                            </TableRow>
                        )}
                        {!addressCode && addresses && addresses.length > 0 && addresses.map((a, i) => (
                            <TableRow key={i}>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.first1}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.middle1}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.last1}</TableCell>
                                {
                                    supportRecipient2 && (
                                        <>
                                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.first2}</TableCell>
                                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.middle2}</TableCell>
                                            <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.last2}</TableCell>
                                        </>

                                    )
                                }
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.company}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address2}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.address3}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.city}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.state}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.zip}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.country}</TableCell>
                                <TableCell style={{ padding: '.5rem', color: (a.isValid === false ? 'red' : 'black') }}>{a.phoneNumber}</TableCell>
                                <TableCell style={{ padding: '.5rem', width: '9rem', color: (a.isValid === false ? 'red' : 'black') }}>
                                    {hasAddressQty && (
                                        <input value={a.qty} type="number" style={{ width: '3rem' }} onChange={(e) => handleQtyChange(i, e.target.value)} />
                                    )}
                                    <IconButton
                                        aria-label="delete"
                                        display="inline"
                                        m={1}
                                        style={{ padding: '5px' }}
                                        onClick={() => onRemove(i)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>

                                    {
                                        a.userInput && (
                                            <HtmlTooltip
                                                title={(
                                                    <>
                                                        <Box>
                                                            Your input:
                                                    </Box>
                                                        <Box>
                                                            {a.userInput.address} {a.userInput.address2}
                                                        </Box>
                                                        <Box>
                                                            {a.userInput.city} {a.userInput.state} {a.userInput.zip} {a.userInput.country}
                                                        </Box>
                                                    </>
                                                )}
                                            >
                                                <IconButton
                                                    aria-label="delete"
                                                    display="inline"
                                                    m={1}
                                                    style={{ padding: '5px' }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </HtmlTooltip>

                                        )
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box mt={2}>
                {
                    addressCode && !loadingAddressPage && addressPagination && (
                        <Pagination
                            component="div"
                            count={addressPagination.pageCount}
                            page={addressPagination.pageNumber}
                            style={{ float: 'right' }}
                            onChange={(e, p) => {
                                loadAddressesByPage(p, addressCode);
                            }}
                        />
                    )
                }
                <Box>
                    total added addresses: {total} &nbsp;
                    {
                        total > 0 && (
                            <Button
                                size="small"
                                color="primary"
                                variant="outlined"
                                fontWeight="fontWeightMedium"
                                style={{ marginRight: '1rem' }}
                                onClick={() => setConfirmClearAll(true)}
                            >
                                <ClearAllIcon /> Clear
                            </Button>
                        )
                    }
                </Box>

            </Box>
            {
                confirmClearAll && (
                    <Dialog open={confirmClearAll} onClose={() => setConfirmClearAll(false)} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
                        <DialogTitle id="alert-dialog-title">Invalid Address</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to clear uploaded addresses?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setConfirmClearAll(false)} color="primary"
                            >
                                No
                            </Button>
                            <Button
                                onClick={() => {
                                    setConfirmClearAll(false);
                                    clearAll();
                                }} color="primary" variant="contained" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
}
