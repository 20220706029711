import {
    Box, Button, CircularProgress, Container, CssBaseline, Grid, List, ListItem, ListItemText, Paper, TextField, Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert from '@material-ui/lab/Alert';
import React, { PureComponent } from 'react';
import history from 'utils/history';
import http from 'utils/http';

export default class ResetPassword extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            new1: '',
            new2: '',
            submitting: false,
            token: null,
            isTokenValid: null,
            settings: null,
            showChanged: false,
            error: null
        };
    }

    componentDidMount() {
        const { match: { params: { token } } } = this.props;
        http.get(`auth/validate-token/${token}`).then((r) => {
            this.setState({ isTokenValid: r.isValid, settings: r.settings, token });
            if (!r.isValid) {
                this.setState({ error: 'The password reset link you followed is not valid or has expired.' });
            }
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    submitForm = () => {
        this.setState({ submitting: true, error: '' });
        const { new1, token } = this.state;
        const fd = new FormData();
        fd.append('token', token);
        fd.append('password', new1);
        http.post('/auth/reset-password', fd).then((t) => {
            if (typeof t.changeResult === 'boolean' && t.changeResult) {
                this.setState({ showChanged: true });
            }
            if (typeof t.changeResult === 'string') {
                this.setState({ error: t.changeResult });
            }
        }).finally(() => {
            this.setState({ submitting: false });
        });
    }

    isRequirementMeet = (code) => {
        const { new1 } = this.state;
        if (!new1) return false;

        switch (code) {
            case 'MinLength':
                return new1.length >= 8;
            case 'MixCase':
                return (/[a-z]/.test(new1)) && (/[A-Z]/.test(new1));
            case 'MixAlphanumeric':
                return /\d/.test(new1) && /[a-zA-Z]/.test(new1);
            case 'SpecialCharacter':
                return /(([!@#\$%\^&\*,?]))/g.test(new1);
            default:
                break;
        }
    };

    render() {
        const {
            new1, new2, submitting, isTokenValid, showChanged, error, settings
        } = this.state;
        return (
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Paper
                    variant="outlined"
                    style={{
                        marginTop: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '3rem'
                    }}
                >

                    <Typography component="h1" variant="h4">Reset Password</Typography>

                    <TextField
                        id="standard-full-width"
                        label="New Password"
                        style={{ margin: 8 }}
                        type="password"
                        placeholder="Enter new password"
                        fullWidth
                        name="new1"
                        margin="normal"
                        value={new1}
                        onChange={this.onFieldChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        id="standard-full-width"
                        label="Confirm New Password"
                        style={{ margin: 8 }}
                        type="password"
                        placeholder="Enter new password again"
                        fullWidth
                        name="new2"
                        margin="normal"
                        value={new2}
                        onChange={this.onFieldChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <Grid container>
                        {error && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    {error}
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs>
                            <Button
                                type="button"
                                style={{ marginTop: '1rem' }}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!new1 || !new2 || new1.length < 6 || new1.length !== new2.length || submitting || !isTokenValid}
                                onClick={this.submitForm}
                            >
                                {submitting && (
                                    <CircularProgress
                                        color="secondary"
                                        size={12}
                                    />
                                )} Submit
                            </Button>

                        </Grid>
                        <Grid item>
                            {settings && settings.length > 0 && (
                                <Box component={Paper} style={{ marginTop: '2rem' }}>
                                    <List dense>
                                        {settings.filter((s) =>
                                            s.code !== 'Expired'
                                            && s.code !== 'DifferentFromCurrent'
                                            && s.code !== 'NotUserId'
                                        ).map((s) => {
                                            return (
                                                <ListItem style={{ paddingRight: '0' }} key={s.id}>
                                                    <CheckCircleOutlineIcon color={this.isRequirementMeet(s.code) ? 'primary' : 'default'} />
                                                    <ListItemText primary={s.description} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
                {showChanged && (
                    <Dialog open={showChanged} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Password has been reset</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Your password has been reset. Please go to <a href="#/login">login</a> page to login with your new password.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => history.push('/login')} autoFocus color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Container>
        );
    }
}
