import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import countries from 'pages/direct-order/steps/CountryData.json';
import React from 'react';

export default function ProfileForm({
    onFieldChange, profile, readonly, agent
}) {
    return (
        <Grid container spacing={2}>
            {agent && profile.agentWritingNumber && (
                <Grid item xs={12}>
                    <h3 style={{ margin: 0, display: 'inline' }} color="primary">
                        Agent Writing Number: <span style={{ margin: 0, display: 'inline', color: '#7d3f98' }}>{profile.agentWritingNumber}</span>
                    </h3>
                </Grid>
            )}
            <Grid item xs={6}>
                <TextField
                    label={agent ? 'Agent First Name' : 'First Name'}
                    placeholder={agent ? 'Agent First Name' : 'First Name'}
                    fullWidth
                    margin="normal"
                    name="firstName"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.firstName || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: readonly
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={agent ? 'Agent Last Name' : 'Last Name'}
                    placeholder={agent ? 'Agent Last Name' : 'Last Name'}
                    fullWidth
                    margin="normal"
                    name="lastName"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.lastName || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: readonly
                    }}
                />

            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="Email Address"
                    placeholder="Email Address"
                    fullWidth
                    margin="normal"
                    name="email"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.email || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    InputProps={{
                        readOnly: readonly
                    }}
                />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: '1rem' }}>
                <TextField
                    label="Phone"
                    name="phone"
                    style={{ marginTop: '.5rem' }}
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        inputComponent: PhoneInput,
                        value: profile.phone || '',
                        onChange: (e) => onFieldChange(e),
                        readOnly: readonly
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="Address"
                    placeholder="Address"
                    fullWidth
                    margin="normal"
                    name="address"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.address || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 50
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Address 2"
                    placeholder="Address 2"
                    fullWidth
                    margin="normal"
                    name="address2"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.address2 || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 50
                    }}
                />

            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="City"
                    placeholder="City"
                    fullWidth
                    margin="normal"
                    name="city"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.city || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 30
                    }}
                />
            </Grid>
            <Grid
                item
                xs={6}
                style={{ paddingTop: '1.5rem' }}
            >
                <StateSelect
                    value={profile.state || ''}
                    onChange={onFieldChange}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    label="Zip/Postal Code"
                    placeholder="Zip"
                    fullWidth
                    margin="normal"
                    name="zip"
                    variant="outlined"
                    size="small"
                    onChange={onFieldChange}
                    value={profile.zip || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: '1.5rem' }}>
                <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                >
                    <InputLabel id="country-select-outlined-label">Country</InputLabel>
                    <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        fullWidth
                        name="country"
                        value={profile.country || 'United States'}
                        onChange={onFieldChange}
                        label="Country"
                    >
                        <MenuItem value="">Select</MenuItem>
                        {countries.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
