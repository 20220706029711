import helpActions from './action';

const initialState = {
    isLoading: false,
    errorMessages: [],
    helpInfo: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case helpActions.PRE_LOAD:
            return { ...state, isLoading: true };
        case helpActions.LOAD_SUCCESS:
            return { ...state, isLoading: false };
        case helpActions.ERROR:
            return { ...state, isLoading: false, errorMessages: action.errorMessages };

        case helpActions.SET_HELP_INFO: {
            return { ...state, helpInfo: action.data };
        }

        case helpActions.UPDATE_HELP_INFO: {
            const newData = {
                ...state.helpInfo,
                dataValue: {
                    ...state.helpInfo.dataValue,
                    [action.key]: action.value
                }
            };
            return { ...state, helpInfo: newData };
        }

        case helpActions.RESET_HELP_INFO: {
            const newData = {
                ...state.helpInfo,
                dataValue: {
                    ...state.helpInfo.dataValue,
                    support: '',
                    comments: ''
                }
            };
            return { ...state, helpInfo: newData };
        }

        default:
            return state;
    }
}
