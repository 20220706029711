import {
    Box,
    Grid, Paper, Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getOrders, setCriteria, setPageNumber, setSavedCriteria, setSavedPageNumber, getTemplates
} from './action';
import HistoricalOrders from './HistoricalOrders';
import SavedOrders from './SavedOrders';

const pageSize = 10;
class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0
        };
    }

    componentDidMount() {
        const { getOrders: loadOrders, criteria, pageNumber, setCriteria, history } = this.props;
        if (history.location.state && history.location.state.fileNumber) {
            setCriteria('fileNumber', '' + history.location.state.fileNumber);
            loadOrders(pageNumber, pageSize, { ...criteria, fileNumber: '' + history.location.state.fileNumber });
        }
        else {
            loadOrders(pageNumber, pageSize, criteria);
        }
    }

    handleChangePage = (_event, pageNumber) => {
        const { tabValue } = this.state;
        if (tabValue) {
            const { getSaves: loadOrders, savedCriteria, getTemplates } = this.props;
            if (tabValue === 2) {
                getTemplates(pageNumber, pageSize, savedCriteria);
            } else {
                loadOrders(pageNumber, pageSize, savedCriteria);
            }
            
        } else {
            const { getOrders: loadOrders, criteria } = this.props;
            loadOrders(pageNumber, pageSize, criteria);
        }
    }

    a11yProps = (index) => {
        return {
            id: `order-tab-${index}`,
            'aria-controls': `order-tabpanel-${index}`
        };
    }

    handleTabChange = (e, tabValue) => {
        this.setState({ tabValue });
        const { saves } = this.props;
        if (tabValue && !saves) {
            const { getSaves: loadOrders, criteria, getTemplates } = this.props;
            if (tabValue === 2) {
                getTemplates(1, pageSize, criteria);
            } else {
                loadOrders(1, pageSize, criteria);
            }
        }
    }

    searchOrder = () => {
        const { tabValue } = this.state;
        if (tabValue) {
            const { getSaves: loadOrders, savedCriteria } = this.props;
            loadOrders(1, pageSize, savedCriteria);
        } else {
            const { getOrders: loadOrders, criteria } = this.props;
            loadOrders(1, pageSize, criteria);
        }
    }

    searchTemplates = () => {
        const { tabValue } = this.state;
        if (tabValue) {
            const { getTemplates: loadOrders, savedCriteria } = this.props;
            loadOrders(1, pageSize, savedCriteria);
        } else {
            const { getOrders: loadOrders, criteria } = this.props;
            loadOrders(1, pageSize, criteria);
        }
    }

    continueWork = (savedName, savedId, savedJob) => {
        const { history } = this.props;
        history.push({
            pathname: savedJob.hash.replace('#', ''),
            state: { savedJob: { ...savedJob, savedId, savedName } }
        });
    }

    render() {
        const {
            orders, saves, loading, history, criteria, setCriteria,
            setSavedCriteria, savedCriteria, templates
        } = this.props;
        const { tabValue, keyword } = this.state;
        return (
            <Box>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Order Management</Typography>
                    </Breadcrumbs>
                </Box>
                <Box component={Paper} p={2}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Tabs value={tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
                                <Tab label="Historical Orders" {...this.a11yProps(0)} />
                                <Tab label="Saved Orders" {...this.a11yProps(1)} />
                                <Tab label="Saved Templates" {...this.a11yProps(2)} />
                            </Tabs>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Order Id"
                                value={keyword}
                                onKeyPress={(e) => { if (e.key === 'Enter') { this.searchOrder(); } }}
                                onChange={(e) => this.setKeyword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="search" onClick={this.searchOrder}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid> */}
                    </Grid>
                    <Box display={tabValue === 0 ? 'block' : 'none'}>
                        <HistoricalOrders
                            orders={orders}
                            setCriteria={setCriteria}
                            criteria={criteria}
                            history={history}
                            submitSearch={this.searchOrder}
                            loading={loading}
                            onChangePage={this.handleChangePage}
                        />
                    </Box>
                    <Box display={tabValue === 1 ? 'block' : 'none'}>
                        <SavedOrders
                            criteria={savedCriteria}
                            setCriteria={setSavedCriteria}
                            orders={saves}
                            submitSearch={this.searchOrder}
                            loading={loading}
                            onChangePage={this.handleChangePage}
                            onQuickView={this.continueWork}
                        />
                    </Box>
                    <Box display={tabValue === 2 ? 'block' : 'none'}>
                        <SavedOrders
                            criteria={savedCriteria}
                            setCriteria={setSavedCriteria}
                            orders={templates}
                            submitSearch={this.searchTemplates}
                            loading={loading}
                            onChangePage={this.handleChangePage}
                            onQuickView={this.continueWork}
                            isTemplate={true}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = ({
    orderManagementView: {
        orders, criteria, pageNumber, savedCriteria, savedPageNumber
    }
}) => ({
    orders, criteria, pageNumber, savedCriteria, savedPageNumber
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getOrders, setCriteria, setPageNumber, setSavedCriteria, setSavedPageNumber, getTemplates
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
