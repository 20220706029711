import {
    Box, Button, CircularProgress, Grid
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import FormBuilder from 'components/FormBuilder';
import ProofingModal from 'components/ProofingModal';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        width: '1024px',
        maxHeight: '650px',
        overflow: 'auto'
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        minHeight: 250,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

export default function EditFormModal(props) {
    const [modalStyle] = React.useState(getModalStyle);
    const { lob } = useParams();
    const {
        generating, setGeneratingForm,
        formProofPreview, openFormProofPreview,
        proofingItem, setFormProofPreviewOpen,
        formSchema, onApproved
    } = props;

    let x = {};
    if (proofingItem && proofingItem.formData) {
        x = proofingItem.formData;
    }
    const [formData, setFormData] = useState(x);
    const {
        open, handleClose
    } = props;
    const classes = useStyles();

    const viewProof = () => {
        setGeneratingForm(true);

        props.generateFormProof({ ...proofingItem, lob }, formData);
    };

    const approvePdf = () => {
        formData.proofFileId = formProofPreview.id;
        formData.preview = formProofPreview.preview;
        onApproved(formData, proofingItem);
        setFormData({});
        setFormProofPreviewOpen(false);
    };
    const closeProof = () => {
        setFormData({});
        setFormProofPreviewOpen(false);
    };

    if (proofingItem && proofingItem.formData && formSchema && formSchema.dataCell) {
        for (const k in proofingItem.formData) {
            const c = formSchema.dataCell.find((dc) => dc.id === k);
            if (c) {
                c.defaultValue = proofingItem.formData[k];
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container>
                    <Grid item xs={12} style={{ maxHeight: '550px', overflowY: 'auto' }}>
                        <Box component="div">
                            {formSchema
                                ? (
                                    <FormBuilder
                                        layout={formSchema.layout}
                                        dataCell={formSchema.dataCell}
                                        onFormDataChange={setFormData}
                                        code={(proofingItem ? proofingItem.code : '')}
                                    />
                                )
                                : <CircularProgress color="secondary" size={36} />}
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => viewProof()}
                            className={classes.button}
                            disabled={generating || !formSchema || !formSchema.layout}
                        >
                            {generating && <CircularProgress color="secondary" size={12} />}  View Proof
                        </Button>
                    </Grid>
                </Grid>
                <ProofingModal
                    open={openFormProofPreview}
                    pdf={formProofPreview && formProofPreview.preview}
                    onApprove={approvePdf}
                    onCancel={closeProof}
                />
            </div>
        </Modal>
    );
}
