import {
    Box, Link,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import React from 'react';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import http from 'utils/http';

export default function JobItems({ jobItems, lob, jobType, orderShipping }) {
    for (const item of jobItems) {
        if (typeof item.customFields === 'string') {
            item.customFields = JSON.parse(item.customFields);
        } else {
            item.customFields = {};
        }
    }

    const hasChildItem = jobItems.some((i) => i.customFields.isChild);

    const allowDownload = (lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM') || (lob === 'p1a' && jobType === 'AJEPP'))

    const getTrackingLink = (trackingNumber, trackingLink) => {
        // console.log(trackingLink);
        return trackingNumber ? trackingNumber.split(',').map(i => <span><a target="_blank" href={trackingLink.replace('$TrackingNumber$', i.trim())} style={{ marginRight: '.5rem' }}>{i.trim()}</a><br /></span>) : null;
    };

    return (
        <Box mb={2}>
            <Typography variant="h6" color="primary">
                Items
                {(jobType === 'AJEKT' || jobType === 'IM') && jobItems && (<span style={{ float: 'right', fontSize: '1rem', color: '#000' }}>Total Kits: {jobItems[0].quantity}</span>)}
                {!(jobType === 'AJEKT' || jobType === 'IM') && jobItems && (<span style={{ float: 'right', fontSize: '1rem', color: '#000' }}>Total Items: {jobItems.length}</span>)}
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {jobType !== 'AJEKT' && jobType !== 'IM' && <TableCell>Qty</TableCell>}
                            <TableCell>Item Code</TableCell>
                            <TableCell>Name</TableCell>
                            {hasChildItem && <TableCell>Child</TableCell>}
                            <TableCell>Version</TableCell>
                            <TableCell>WHS</TableCell>
                            {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell>INV</TableCell>}
                            {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell>VP</TableCell>}
                            {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell>DP</TableCell>}
                            <TableCell>TYPE</TableCell>
                            {lob === 'p1a' && <TableCell>STATUS</TableCell>}
                            {lob === 'p1a' && <TableCell>Qty Shipped</TableCell>}
                            {lob === 'p1a' && <TableCell>TRACKING #</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobItems.map((ji) => (
                            <TableRow key={ji.sequenceInGroup}>
                                {jobType !== 'AJEKT' && jobType !== 'IM' && <TableCell>{hasChildItem && ji.customFields.isChild ? ji.quantity : ji.cf.quantity}</TableCell>}
                                <TableCell>{lob !== 'cml' || !ji.customFields.requiredCode ? null : '*'}  {ji.code}</TableCell>
                                <TableCell>{
                                    allowDownload && ji.orderItemFile && ji.orderItemFile.length > 0 && !(lob === 'p1a' && jobType === 'AJEPP' && ji.isKit) ? (
                                        <Link
                                            href={http.buildUrl(`file/download/preview-of/${ji.orderItemFile[0].fileId}/${ji.name.replace(/ /g, '')}`, true)}
                                            target="_blank"
                                            color="primary"
                                            title="Click to downloadLink"
                                        >
                                            {hasChildItem && ji.customFields.isChild && <span>&nbsp;&nbsp;&nbsp;</span>} {ji.name}
                                        </Link>
                                    ) : (
                                        <span>
                                            {hasChildItem && ji.customFields.isChild && <span>&nbsp;&nbsp;&nbsp;</span>} {ji.name}
                                        </span>
                                    )
                                }

                                </TableCell>
                                {hasChildItem && <TableCell>{ji.customFields.isChild ? 'Y' : ''}</TableCell>}
                                <TableCell>{ji.version}</TableCell>
                                <TableCell>{ji.whs}</TableCell>
                                {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell>
                                    {
                                        ji.customFields.inventoryQuantity === undefined
                                            ? ''
                                            : Math.max(0, ji.customFields.inventoryQuantity)
                                    }
                                </TableCell>}
                                {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell>{ji.cf.vp}</TableCell>}
                                {!(lob === 'p1a' && jobType === 'AJEPP') && <TableCell>{ji.cf.dp}</TableCell>}
                                <TableCell>{ji.cf.type}</TableCell>
                                {lob === 'p1a' && (<TableCell>{ji.status}</TableCell>)}
                                {lob === 'p1a' && (<TableCell>{orderShipping.find((x) => x.orderShippingItem.find((y) => y.orderItemId === ji.id)).orderShippingItem.find((x) => x.orderItemId === ji.id).shippedQTY || ''}</TableCell>)}
                                {lob === 'p1a' && (<TableCell>{getTrackingLink(orderShipping.find((x) => x.orderShippingItem.find((y) => y.orderItemId === ji.id)).orderShippingItem.find((x) => x.orderItemId === ji.id).navTrackingNumber, orderShipping[0].trackingLink)}</TableCell>)}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
