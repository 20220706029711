import React, { Component } from 'react';
import { TextField, Box } from '@material-ui/core';

class DateFieldCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            errorMessage: '',
            canEdit: !props.needTrigger
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value });
        }
    }

    onChange = (e) => {
        const { value } = e.target;
        this.setState({ value });
        const { row, col, updateRowData } = this.props;
        updateRowData && updateRowData(row, col, value);
    }

    onBlur = () => {
        const {
            row, col, updateRowData, isReadOnly
        } = this.props;
        const { value, canEdit } = this.state;

        const isChangeVal = row && col && row[col.id] ? row[col.id] !== value : true;
        if (!isReadOnly && updateRowData && canEdit && isChangeVal) {
            updateRowData(row, col, value);
        }
    }

    render() {
        const { isReadOnly, prefix, col } = this.props;
        const { canEdit, value } = this.state;
        return (
            <Box component="div" className={`${prefix}-date-field`}>
                <TextField
                    fullWidth
                    size="small"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true
                    }}
                    label={col.title}
                    name={col.id}
                    disabled={isReadOnly || !canEdit}
                    value={value || ''}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                />
            </Box>
        );
    }
}

DateFieldCell.defaultProps = {
    prefix: 'editable-cell'
};

export default DateFieldCell;
