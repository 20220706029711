import DateFnsUtils from '@date-io/date-fns';
import {
    Button,
    CircularProgress, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ContinueOrderDialog from 'pages/saved-job/ContinueOrderDialog';
import React, { useState } from 'react';

export default function SavedOrders({
    loading, orders, onChangePage, onQuickView, submitSearch, criteria, setCriteria, isTemplate
}) {
    const toProgramName = (hash) => {
        if (hash) {
            if (hash.includes('p1a')) return 'Medicare Individual';
            if (hash.includes('p1b')) return 'Medicare Group';
        }

        return 'Commercial';
    };

    const toJobType = (hash, name) => {
        if (name && name.includes('Pick&Pack Batch Order')) {
            return 'Pick&Pack Job';
        }
        if (hash) {
            if (hash.includes('FJE')) return 'Fast Job';
            if (hash.includes('AJEPP')) return 'Pick&Pack Job';
            if (hash.includes('AJEKT')) return 'Kit Job';
        }
        return 'IM Job';
    };

    const getCriteria = () => {
        return {
            ...criteria,
            startDate: criteria.startDate ? criteria.startDate.toLocaleDateString() : '',
            endDate: criteria.endDate ? criteria.endDate.toLocaleDateString() : ''
        };
    };

    const toLocal = (orderedDate) => {
        const d = new Date(orderedDate);
        return `${d.toLocaleDateString().replace('202', '2')} ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}`;
    };

    const [savedJobId, setSavedJobId] = useState(null);

    return (
        <>
            <TableContainer>

                <Table size="small" aria-label="order table">
                    <TableHead>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        label="ORDER #"
                                        autoFocus
                                        value={criteria.orderNumber}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('orderNumber', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="USER ID"
                                        value={criteria.userId}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('userId', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="First Name"
                                        value={criteria.recipientFirstName}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('recipientFirstName', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Middle Name"
                                        value={criteria.recipientMiddleName}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('recipientMiddleName', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Last Name"
                                        value={criteria.recipientLastName}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('recipientLastName', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell />
                                <TableCell>
                                    <Button
                                        color="primary"
                                        onClick={() => submitSearch(getCriteria())}
                                    >
                                        <SearchIcon /> Search
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        label="Customer"
                                        autoFocus
                                        value={criteria.customerName}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('customerName', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    >
                                        <InputLabel id="country-select-outlined-label">Program</InputLabel>
                                        <Select
                                            value={criteria.program}
                                            onChange={(e) => setCriteria('program', e.target.value)}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                            fullWidth
                                            label="Program"
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            <MenuItem value="p1a">Medicare Individual</MenuItem>
                                            <MenuItem value="p1b">Medicare Group</MenuItem>
                                            <MenuItem value="cml">Traditional Commercial</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    >
                                        <InputLabel id="country-select-outlined-label">Job Type</InputLabel>
                                        <Select
                                            value={criteria.jobType}
                                            onChange={(e) => setCriteria('jobType', e.target.value)}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                            fullWidth
                                            label="Job Type"
                                        >
                                            <MenuItem value="">Select</MenuItem>
                                            <MenuItem value="FJE">Fast Job</MenuItem>
                                            <MenuItem value="AJEPP">Pick & Pack</MenuItem>
                                            <MenuItem value="AJEKT">Kit Job</MenuItem>
                                            <MenuItem value="IM">IM</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="start_date_picker"
                                        label="Start Date"
                                        name="startDate"
                                        value={criteria.startDate}
                                        onChange={(e) => setCriteria('startDate', e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change start date'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="end_date_picker"
                                        label="End Date"
                                        name="endDate"
                                        value={criteria.endDate}
                                        onChange={(e) => setCriteria('endDate', e)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change end date'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Item Code"
                                        autoFocus
                                        value={criteria.itemCode}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('itemCode', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Batch File #"
                                        autoFocus
                                        value={criteria.fileNumber}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        onChange={(e) => setCriteria('fileNumber', e.target.value)}
                                    />
                                </TableCell>
                            </TableRow>
                        </MuiPickersUtilsProvider>
                    </TableHead>
                </Table>
                <Table aria-label="order table">
                    <TableHead>
                        <TableRow>
                            {isTemplate && <TableCell>Name</TableCell>}
                            <TableCell>ORDER #</TableCell>
                            <TableCell>Creator</TableCell>
                            <TableCell>Program</TableCell>
                            <TableCell>Job Type</TableCell>
                            <TableCell>SAVED DATE</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell colSpan={20} style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading && orders && orders.results.map((row) => (
                            <TableRow key={row.id}>
                                {isTemplate && <TableCell>{row.name}</TableCell>}
                                <TableCell component="th" scope="row">
                                    {row.orderNumber}
                                </TableCell>
                                <TableCell>{row.customerName}</TableCell>
                                <TableCell>{toProgramName(row.data.hash)}</TableCell>
                                <TableCell>{toJobType(row.data.hash, row.name)}</TableCell>
                                <TableCell>{toLocal(row.orderedDate)}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={() => {
                                            row.batchFileNumber
                                                ? setSavedJobId(row.id)
                                                : onQuickView(row.name, row.id, row.data);
                                        }}
                                        color="primary"
                                    >
                                        <RestoreIcon /> View
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={20}>
                                {orders
                                    && (
                                        <Pagination
                                            component="div"
                                            count={orders.pageCount}
                                            page={orders.pageNumber}
                                            onChange={onChangePage}
                                        />
                                    )}

                            </TableCell>
                        </TableRow>

                    </TableFooter>
                </Table>
            </TableContainer>
            {!!savedJobId && (
                <ContinueOrderDialog
                    open={savedJobId}
                    onClose={() => {
                        setSavedJobId(null);
                    }}
                    savedJobId={savedJobId}
                />
            )}
        </>
    );
}
