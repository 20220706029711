import React, { useState } from 'react';
import {
    Button, Box, Grid, TextField, ButtonGroup, Modal, Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 480,
        height: 240,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #eddd',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const RenameModal = ({
    name, open, onClose, onSave
}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    const [newName, setNewName] = React.useState(name);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Box>
                    <Typography variant="h6">
                        Rename
                    </Typography>
                </Box>
                <Grid container spacing={1} style={{ marginTop: '1rem' }} className="row-item">
                    <Grid item xs={12}>
                        <TextField
                            label="New Name"
                            autoFocus
                            fullWidth
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>

                </Grid>
                <ButtonGroup className="list-btn-group" style={{ marginTop: '1rem', textAlign: 'right' }}>
                    <Button
                        className="btn place-order-btn"
                        variant="contained"
                        onClick={() => { onSave(newName); onClose(); }}
                        color="primary"
                    >Save
                    </Button>
                </ButtonGroup>
            </div>
        </Modal>
    );
};
export default RenameModal;
