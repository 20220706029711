import { keyMirror, actionCreator } from '../../exportUtil';

export const actions = keyMirror('DEMO_VIEW', {
    PRE_LOAD: null,
    LOAD_SUCCESS: null,
    ERROR: null,
    SET_ORDER_DATA: null
});

export const error = actionCreator(actions.ERROR, 'errorMessages');

const initialState = {
    isLoading: false,
    errorMessages: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.PRE_LOAD:
            return { ...state, isLoading: true };
        case actions.LOAD_SUCCESS:
            return { ...state, isLoading: false };
        case actions.ERROR:
            return { ...state, isLoading: false, errorMessages: action.errorMessages };

        default:
            return state;
    }
}
