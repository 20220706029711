import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import HelpView from './HelpView';

const mapStateToProps = (state) => {
    return {
        isLoading: state.helpView.isLoading,
        errorMessages: state.helpView.errorMessages,
        helpInfo: state.helpView.helpInfo,
        user: state.appView.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HelpView);
