import {
    Box, Button, ButtonGroup, Checkbox, Container, CssBaseline, Divider, Grid, IconButton, TextField, Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import message from 'components/Message';
import React, { useEffect, useState } from 'react';
import fallbackSrc from 'utils/fallbackSrc';
import http from 'utils/http';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import OEPlanDocumentPanel from './OEPlanDocumentPanel';
import OETools from './OETools';
import './shop-cart-view.scss';

export default function OECartView({
    basketItems, removeItem, changeItemQty, changePPQty, hidePlanDoc,
    goBack, goCheckout, renameItem, duplicateItem, planDocuments, attachments, saveItemsForLater, pricing, resetInput
}) {
    const [loading, setLoading] = useState(false);
    const [deletingLineId, setDeletingLineId] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selected, setSelected] = useState([]);
    const [openPlanDoc, setOpenPlanDoc] = useState(false);
    const [openSaveOrder, setOpenSaveOrder] = useState(false);
    const [savedOrderName, setSavedOrderName] = useState('');

    const [itemQtys, setItemQtys] = useState(basketItems.map((b) => ({ lineId: b.lineId, quantity: b.quantity })));
    useEffect(() => {
        if (itemQtys.length !== basketItems.length) {
            const ii = basketItems.map((b) => ({ lineId: b.lineId, quantity: b.quantity }));
            setItemQtys([...ii]);
        }
    }, [basketItems, itemQtys]);

    let newer = basketItems.map((b) => ({ lineId: b.lineId, quantity: b.quantity }));
    if (newer.length !== itemQtys.length) {
        setItemQtys([...newer]);
    } else {
        newer = null;
    }

    const onToggle = (lineId) => {
        if (selected.includes(lineId)) {
            setSelected(selected.filter((s) => s !== lineId));
        } else {
            setSelected([...selected, lineId]);
        }
    };

    const onCheckOutClick = () => {
        if ((planDocuments && planDocuments.length > 0) || (attachments && attachments.length > 0)) {
            setLoading(true);
            http.post('OE/save-plan-documents', { systemPlanDocuments: planDocuments, userPlanDocuments: attachments?.map(a => ({ ...a, program: 'OE' })) })
                .then((saveResult) => {
                    if (saveResult.success === false && saveResult.displayMessage) {
                        message.error(saveResult.displayMessage);
                        return;
                    }

                    resetInput();
                    goCheckout();
                })
                .finally(() => setLoading(false));
        } else {
            goCheckout();
        }
    };

    const saveOrderForLater = () => {
        saveItemsForLater(savedOrderName, selected);
        setOpenSaveOrder(false);
        setSelected([]);
    };

    const onQtyChange = (lineId, value, minQty) => {
        const newQty = value ? parseInt(value, 10) : minQty;
        const min = minQty ? parseInt(minQty, 10) : 1;
        if (newQty >= min) {
            changeItemQty(lineId, newQty);
        }
    };

    const confirmDelete = (lineId) => {
        setOpenConfirm(true);
        setDeletingLineId(lineId);
    };

    const onConfirmClose = (yes) => {
        if (yes) {
            if (selected.includes(deletingLineId)) {
                removeItem(selected);
            } else {
                removeItem([deletingLineId]);
            }
        }
        setOpenConfirm(false);
        setDeletingLineId(null);
    };

    const getPrice = (item) => {
        if (!pricing || !item.customFields.outputType || !item.customFields.outputType.includes('Print')) return null;
        const itemPricing = pricing.filter((p) => p.description.includes(item.code));
        let prices = [];
        for (const ip of itemPricing) {
            const qtyRange = ip.quantity.indexOf('+') > 0
                ? [parseInt(ip.quantity.replace('+', ''), 10), 99999999999]
                : ip.quantity.split('–').map((q) => parseInt(q, 10));

            if (item.quantity >= qtyRange[0] && item.quantity <= qtyRange[1]) {
                const p = item.quantity * ip.printPricePerUnit;
                prices = [`$${p.toFixed(2)} ${(ip.spec ? ` (${ip.spec})` : '')}`];
                // pricesss.push(`$${p.toFixed(2)} ${(ip.spec ? ` (${ip.spec})` : '')}`);
            }
        }
        if (prices.length) {
            return prices.map((p) => <p key={p}>{p}</p>);
        }

        return null;
    };
    const hasPendingItem = (planDocuments && planDocuments.some((i) => i.vp && i.vp.length > 3 && !i.approved))
        || (attachments && attachments.some((a) => !a.approved || !a.apnCode || !a.docType))
        || (
            (!basketItems || !basketItems.length)
            && (!planDocuments || !planDocuments.length)
            && (!attachments || !attachments.length)
        );
    return (
        <Container fixed className="shop-cart-view">
            <CssBaseline />
            <Box component="div" mb={4}>
                <Typography variant="h3">
                    Shopping Cart Contents
                </Typography>
            </Box>
            <Box component="div">
                <Divider style={{ height: '3px' }} />
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                    style={{ padding: '20px 0' }}
                >
                    <Grid container item xs={1}>
                        Action
                    </Grid>
                    <Grid container item xs={5}>
                        Product
                    </Grid>
                    <Grid container item xs={1}>
                        Quantity
                    </Grid>
                    <Grid container item xs={4} />
                    <Grid container item xs={1} />
                </Grid>
                <Divider />
                {
                    basketItems && basketItems.length > 0 && basketItems.map((item) => {
                        return (
                            <Grid
                                key={item.lineId}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={1}
                                style={{ padding: '20px 0' }}
                            >
                                <Grid
                                    item
                                    xs={1}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Checkbox
                                            edge="start"
                                            tabIndex={-1}
                                            disableRipple
                                            size="small"
                                            checked={selected.includes(item.lineId)}
                                            color="primary"
                                            onChange={() => onToggle(item.lineId)}
                                        />

                                        <IconButton
                                            aria-label="DeleteIcon"
                                            aria-describedby="remove"
                                            variant="contained"
                                            size="small"
                                            onClick={() => confirmDelete(item.lineId)}
                                        >
                                            <DeleteIcon className="action-btn del-icon" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={5}>
                                            <img
                                                onError={fallbackSrc}
                                                src={item.customFields.thumbnail || '//cdndev.memberdoc.com/f/img_no_thumb.jpg'}
                                                alt={item.name}
                                                style={{ width: '150px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <b>{item.customFields.alias || item.name}</b>
                                            {
                                                !item.isPlanDocument && (
                                                    <OETools
                                                        item={item}
                                                        onRenameItem={(n) => renameItem(item.lineId, n)}
                                                        duplicateItem={duplicateItem}
                                                        crosswalk={item.customFields.crosswalk}
                                                        lineId={item.lineId}
                                                        customizeCode={item.customFields.customizeCode}
                                                    />
                                                )
                                            }
                                            <p>{item.customFields.outputType}</p>
                                            <div>{getPrice(item)}</div>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    {item.customFields.outputType === 'Digital Only'
                                        ? (<b>-</b>)
                                        : (
                                            <TextField
                                                label="Qty"
                                                size="small"
                                                type="number"
                                                min={item.customFields.minQty || 1}
                                                inputProps={{
                                                    min: item.customFields.minQty || 1
                                                }}
                                                style={{ width: '6rem' }}
                                                value={((newer || itemQtys).find((i) => i.lineId === item.lineId)).quantity}
                                                onChange={(e) => setItemQtys((newer || itemQtys).map((i) => ({ lineId: i.lineId, quantity: i.lineId === item.lineId ? e.target.value : i.quantity })))}
                                                error={item.customFields.minQty && parseInt(((newer || itemQtys).find((i) => i.lineId === item.lineId)).quantity, 10) < item.customFields.minQty}
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                helperText={item.customFields.minQty ? `Min Qty:${item.customFields.minQty}` : null}
                                                onBlur={(e) => onQtyChange(item.lineId, e.target.value, item.customFields.minQty)}
                                            />
                                        )}
                                </Grid>
                                <Grid item xs={5} />
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Box>

            {!hidePlanDoc && <OEPlanDocumentPanel open={openPlanDoc} onClose={() => setOpenPlanDoc(false)} />}

            <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                <Grid item xs={6}>
                    <Button variant="outlined" disabled={selected.length === 0} color="primary" onClick={() => setOpenSaveOrder(true)}>
                        Save Order
                    </Button>
                    <Dialog open={openSaveOrder} onClose={() => setOpenSaveOrder(false)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Save Order</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="Name"
                                type="text"
                                fullWidth
                                required
                                value={savedOrderName}
                                onChange={(e) => setSavedOrderName(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenSaveOrder(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={saveOrderForLater} disabled={!savedOrderName} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
                <Grid item xs={6}>
                    <ButtonGroup className="list-btn-group">
                        <Button
                            className="btn back-btn"
                            style={{ marginRight: '1rem' }}
                            variant="contained"
                            onClick={goBack}
                        >Go Back
                        </Button>
                        <Button
                            className="btn place-order-btn"
                            variant="contained"
                            disabled={loading || hasPendingItem}
                            color="primary"
                            onClick={onCheckOutClick}
                        >Check Out
                        </Button>
                    </ButtonGroup>

                </Grid>

            </Grid>

            <ConfirmDeleteDialog open={openConfirm} onClose={onConfirmClose} />
        </Container>
    );
}
