import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { Component } from 'react';

class CheckboxFieldCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || false,
            errorMessage: '',
            canEdit: !props.needTrigger
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value || false });
        }
    }

    onChange = (e) => {
        const value = e.target.checked ? 1 : 0;
        this.setState({ value });

        const {
            row, col, updateRowData, isReadOnly
        } = this.props;
        const { canEdit } = this.state;

        const isChangeVal = row && col && row[col.name] ? row[col.name] !== value : true;
        if (!isReadOnly && updateRowData && canEdit && isChangeVal) {
            updateRowData && updateRowData(row, col, value);
        }
    }

    render() {
        const { isReadOnly, prefix, col, showTitle = false } = this.props;
        const { value, canEdit } = this.state;

        return (
            <Box component="div" className={`${prefix}-checkbox-field`}>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={"checkbox-control"}
                            checked={value == true}
                            onChange={this.onChange}
                            disabled={isReadOnly || !canEdit}
                            color="primary"
                        />
                    }
                    label={col.title}
                />
                {/* <Checkbox
                    className={"checkbox-control"}
                    checked={value == true}
                    onChange={this.onChange}
                    disabled={isReadOnly || !canEdit}
                    color="primary"
                />
                {showTitle && <Box component="span">{col.title}</Box>} */}
            </Box>
        );
    }
}

CheckboxFieldCell.defaultProps = {
    prefix: 'editable-cell'
};
export default CheckboxFieldCell;
