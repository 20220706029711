import {
    Button, Dialog, DialogActions, DialogContent
} from '@material-ui/core';
import React from 'react';

export default function ConfirmPDFDownload({
    open, onClose, setOpenConfirm, pdfDownloadUrl, setPdfDownloadUrl
}) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" disableBackdropClick>
            <DialogContent>Please note that the downloadable kits are intended for internal/broker use only.  They are not remediated and are not compliant to be emailed to prospects. To send a kit digitally, please use the ekits that are available through Think Agent.  For enrollment forms, please use the standalone enrollment form that is available for order/download.  Do not print the enrollment form from this downloadable kit.  Thank you!</DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpenConfirm(false);
                    setPdfDownloadUrl('');
                }}
                >
                    Cancel
                </Button>
                <Button onClick={() => {
                    window.open(pdfDownloadUrl, '_blank');
                    setOpenConfirm(false);
                    setPdfDownloadUrl('');
                }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>

    );
}
