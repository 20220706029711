import { Box, TextField } from '@material-ui/core';
import React, { Component } from 'react';

class TextAreaCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            errorMessage: '',
            hasDiff: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value || '' });
        }
    }

    onChangeTextArea = (e) => {
        const { value } = e.target;
        this.setState({ value });

        const { row, col, updateRowData } = this.props;
        updateRowData && updateRowData(row, col, value);
    }

    onBlurTextArea = () => {
        const {
            row, col, updateRowData, isReadOnly
        } = this.props;
        const { value } = this.state;

        const isChangeVal = row && col && row[col.id] ? row[col.id] !== value : true;
        if (!isReadOnly && updateRowData && isChangeVal) {
            updateRowData(row, col, value);
        }
    }

    render() {
        const { isReadOnly, prefix, col, characterLimit } = this.props;
        const { value } = this.state;

        return (
            <Box component="div" className={`${prefix}-text-area`}>
                <TextField
                    fullWidth
                    multiline
                    size="small"
                    rows={4}
                    variant="outlined"
                    label={col.title}
                    name={col.id}
                    value={value}
                    disabled={isReadOnly}
                    onChange={this.onChangeTextArea}
                    onBlur={this.onBlurTextArea}
                    inputProps={{
                        maxLength: characterLimit || 2500
                    }}
                />
            </Box>
        );
    }
}

TextAreaCell.defaultProps = {
    prefix: 'editable-cell'
};

export default TextAreaCell;
