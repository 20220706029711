import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useEffect, useState } from 'react';

export default function PasswordView({
    message, loading, user, getSettings, submitChange, settings
}) {
    const [current, setCurrent] = useState('');
    const [new1, setNew1] = useState('');
    const [new2, setNew2] = useState('');
    const [innerError, setInnerError] = useState('');

    useEffect(() => {
        getSettings(user.customFields.userGroups[0].userGroupId);
    }, [getSettings, user]);

    const isRequirementMeet = (code) => {
        if (!new1) return false;

        switch (code) {
            case 'MinLength':
                return new1.length >= 8;
            case 'MixCase':
                return (/[a-z]/.test(new1)) && (/[A-Z]/.test(new1));
            case 'MixAlphanumeric':
                return /\d/.test(new1) && /[a-zA-Z]/.test(new1);
            case 'NotUserId':
                return user.userName !== new1;
            case 'DifferentFromCurrent':
                return current !== new1;
            case 'SpecialCharacter':
                return /(([!@#\$%\^&\*,?]))/g.test(new1);
            default:
                break;
        }
    };

    const onSubmit = () => {
        if (!current) {
            setInnerError('Please enter current password'); return;
        }
        if (!new1) {
            setInnerError('Please enter new password'); return;
        }
        if (!new2) {
            setInnerError('Please enter confirm new password'); return;
        }
        if (new1 !== new2) {
            setInnerError("Confirm new password doesn't match new password"); return;
        }

        if (settings.filter((s) => s.code !== 'Expired').some((s) => !isRequirementMeet(s.code))) {
            setInnerError('Please make sure your password meet the requirement.'); return;
        }

        setInnerError('');
        submitChange(current, new1);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Change Password</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Grid container>
                    <Grid item xs={11}>
                        {user && user.customFields && user.customFields.isPasswordChangeRequired && (
                            <Box color="warning.main">
                                <b>You must change your password before you can use the site.</b>
                            </Box>
                        )}
                        {message && (
                            <Box color="success.main">
                                <b>{message}</b>
                            </Box>
                        )}
                        {innerError && (
                            <Box color="error.main">
                                <b>{innerError}</b>
                            </Box>
                        )}
                        <TextField
                            id="current"
                            label="Current Password"
                            style={{ margin: 8 }}
                            type="password"
                            placeholder="Enter current password"
                            fullWidth
                            margin="normal"
                            value={current}
                            onChange={(e) => setCurrent(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                    </Grid>

                    <Grid item xs={11}>
                        <TextField
                            id="new1"
                            label="New Password"
                            style={{ margin: 8 }}
                            type="password"
                            placeholder="Enter new password"
                            fullWidth
                            margin="normal"
                            value={new1}
                            onChange={(e) => setNew1(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                    </Grid>

                    <Grid item xs={11}>
                        <TextField
                            id="new2"
                            label="Confirm New Password"
                            style={{ margin: 8 }}
                            type="password"
                            placeholder="Enter new password again"
                            fullWidth
                            margin="normal"
                            value={new2}
                            onChange={(e) => setNew2(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />

                    </Grid>

                    <Grid item xs={11}>
                        <Button
                            className="btn submit-btn"
                            variant="contained"
                            onClick={onSubmit}
                            disabled={loading}
                            color="primary"
                        >Submit
                        </Button>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                {settings && settings.length > 0 && (
                    <Box component={Paper}>
                        <List dense>
                            {settings.filter((s) => s.code !== 'Expired').map((s) => {
                                return (
                                    <ListItem key={s.id}>
                                        <CheckCircleOutlineIcon color={isRequirementMeet(s.code) ? 'primary' : 'default'} />
                                        <ListItemText primary={s.description} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                )}
            </Grid>

        </Grid>
    );
}
