import http from 'utils/http';
import keyMirror from 'utils/keyMirror';
import actionCreator from 'utils/actionCreator';

export const actions = keyMirror('APP_VIEW', {
    SHOW_MESSAGE: null,
    HIDE_MESSAGE: null,
    LOGOUT: null,
    SET_SETTINGS: null,
    SET_IMPERSONATE: null
});
export default actions;

const setMessage = actionCreator(actions.SHOW_MESSAGE, 'message', 'severity');
export const showMessage = (message, severity = 'info') => (dispatch) => {
    dispatch(setMessage(message, severity));
};

const clearMessage = actionCreator(actions.HIDE_MESSAGE);
export const hideMessage = () => (dispatch) => {
    dispatch(clearMessage());
};

const clearUser = actionCreator(actions.LOGOUT);
export const logout = () => (dispatch) => {
    dispatch(clearUser());
};

const setImpersonate = actionCreator(actions.SET_IMPERSONATE, 'login');
export const startImpersonate = (login) => (dispatch) => {
    dispatch(setImpersonate(login));
};

const setAppSettings = actionCreator(actions.SET_SETTINGS, 'settings');
export const loadAppSettings = () => (dispatch) => {
    http.get('store/sync-up')
        .then((data) => dispatch(setAppSettings(data)));
};
