import {
    Checkbox, DialogActions, FormControlLabel, Grid, IconButton, Link, TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import message from 'components/Message';
import React from 'react';
import { connect } from 'react-redux';
import http from 'utils/http';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        minWidth: 800,
        minHeight: 640,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    }
}));

const ProofingModal = ({
    open, onCancel, pdf, wh, user,
    doubleApproval, onApprove, approved,
    allowAddToMS, fileId, marketSegment, fundingType, brand, lock
}) => {
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const $wh = wh || {};
    const classes = useStyles();
    const [confirmApproval, setConfirmApproval] = React.useState(false);
    const [agreed, setAgreed] = React.useState(false);
    const [addToMS, setAddToMS] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [name, setName] = React.useState('');

    if (!pdf) return null;
    let $pdf = pdf;
    // http://localhost:5000/file/preview/406
    let downloadLink = pdf.replace('file/preview', 'file/download');
    if (pdf && !pdf.includes('Authorization') && user) {
        $pdf = `${pdf}?Authorization=${user.token}&ro=${(lock ? '1' : '0')}`;
        downloadLink = `${downloadLink}?Authorization=${user.token}&ro=${(lock ? '1' : '0')}`;
    }

    const onApproveClicked = () => {
        if (doubleApproval) {
            setConfirmApproval(true);
        } else {
            onApprove();
        }
    };

    const closeConfirm = () => {
        setConfirmApproval(false);
        setAgreed(false);
    };
    const printPdf = () => {
        http.post('track/save', {
            action: 'PrintFile',
            data: {
                link: pdf
            }
        });
        window.document.querySelector('#previewFrame').contentWindow.print();
    };
    const cancelPdf = () => {
        const fd = new FormData();
        fd.append('id', pdf.split('/').pop().split('?')[0]);
        http.post('store/cancel-pdf', fd);
    };

    const saveToMicrosite = async () => {
        setLoading(true);
        const fd = new FormData();
        fd.append('fileId', fileId);
        fd.append('name', name);
        fd.append('brand', brand);
        fd.append('marketSegment', marketSegment);
        fd.append('fundingType', fundingType);
        return http.post('OE/add-to-ms', fd).then((saveResponse) => {
            if (saveResponse && saveResponse.saved) {
                message.success(`PnP PDF [${name}] has been added to microsite.`);
                return true;
            } else {
                message.error("Fail to add PnP PDF to microsite, please try again later.");
                return false;
            }
        }).catch((ex) => {
            message.error("Fail to add PnP PDF to microsite, please try again later.");
            return false;
        }).finally(() => {
            setLoading(false);
        });
    }

    const onYes = async () => {
        if (addToMS) {
            var added = await saveToMicrosite();
            if (!added) return;
        }

        onApprove();
    }

    return (
        <>

            <Dialog
                maxWidth="lg"
                fullWidth
                open={open}
                onClose={() => { cancelPdf(); onCancel(); }}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <Grid container>
                        <Grid item xs={6}>
                            Preview
                            <IconButton style={{ width: '3rem', height: '3rem' }}>
                                <Link href={downloadLink} target="_blank" color="primary">
                                    <CloudDownloadIcon size="small" />
                                </Link>
                            </IconButton>
                            <IconButton color="primary" onClick={printPdf}>
                                <PrintIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            {approved
                                ? <Button onClick={() => { onCancel(); }}>Close</Button>
                                : (
                                    <>
                                        <Button onClick={() => { cancelPdf(); onCancel(); }}>Cancel</Button>
                                        <Button color="primary" onClick={onApproveClicked}>Approve</Button>
                                    </>
                                )}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers style={{ textAlign: 'center' }}>
                    <iframe
                        id="previewFrame"
                        style={{
                            width: '100%', height: '100%', minHeight: '640px', ...$wh
                        }}
                        title={$pdf}
                        src={`${$pdf}#toolbar=0`}
                    />
                </DialogContent>
                {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClose} color="primary">
                    Subscribe
                </Button>
            </DialogActions> */}

            </Dialog>

            <Dialog
                open={confirmApproval}
                onClose={closeConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">By checking the box below, I agree to all customizations made to this product have been reviewed for content, spelling and grammar and have been approved by the appropriate parties. I understand my document will print exactly as it appears and I cannot make changes once my order has been placed.</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={agreed}
                                    autoFocus
                                    onChange={(e) => setAgreed(e.target.checked)}
                                    name="agreed"
                                    color="primary"
                                />
                            )}
                            label="I have read the above statement and approve."
                        />
                        {
                            allowAddToMS && (
                                <Grid container style={{ height: '4rem' }}>
                                    <Grid item xs={12}><FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={addToMS}
                                                autoFocus
                                                onChange={(e) => setAddToMS(e.target.checked)}
                                                name="addToMS"
                                                color="primary"
                                            />
                                        )}
                                        label="Add to Microsite"
                                    />
                                    </Grid>
                                    <Grid item xs={12}>

                                        {
                                            addToMS && (
                                                <TextField
                                                    label="Plug N Play Name"
                                                    size="small"
                                                    style={{ margin: 0 }}
                                                    required
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    className="form-input"
                                                    value={name}
                                                    autoFocus
                                                    onChange={(e) => setName(e.target.value)}
                                                    inputProps={{
                                                        maxLength: 200
                                                    }}
                                                />
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirm} disabled={loading} color="primary">
                        No
                    </Button>
                    <Button onClick={onYes} disabled={!agreed || loading || (addToMS && !name)} color="primary">

                        {
                            loading
                                ? 'Saving...'
                                : 'Yes'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = ({ appView: { user } }) => ({
    user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ProofingModal);
