import profileActions from './action';

export default (
    state = {
        loading: false,
        profile: null
    },
    action
) => {
    switch (action.type) {
        case profileActions.LOADING:
            return { ...state, loading: action.loading };

        case profileActions.SET_PROFILE:
            return { ...state, profile: action.profile };

        default:
            return state;
    }
};
