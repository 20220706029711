import { connect } from 'react-redux';
import OrderDetails from './OrderDetails';

const mapStateToProps = (state) => ({
    user: state.appView.user
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
