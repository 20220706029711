import {
    Box,
    Button, Dialog,
    DialogContent, DialogTitle, Grid, Paper,
    Typography
} from '@material-ui/core';
import React from 'react';

export default function RecommendedAddress({
    open, onClose,
    originalAddress, recommendedAddress, onSave
}) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="form-dialog-title">Verify shipping address</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={{ marginBottom: '1.5rem' }}>
                    <Grid item xs={6} component={Paper}>
                        <Box>
                            <Typography component="h4" style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                                Original Address
                            </Typography>
                        </Box>
                        <Box>
                            {originalAddress.address}
                        </Box>
                        <Box>
                            {originalAddress.address2}
                        </Box>
                        <Box>
                            {originalAddress.city}, {originalAddress.state} {originalAddress.zip}
                        </Box>
                        <Box style={{ marginTop: '1.5rem' }}>
                            <Button color="secondary" variant="contained" onClick={() => { onSave(originalAddress) }}>
                                Use this address
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} component={Paper}>
                        <Box>
                            <Typography component="h4" style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                                Recommended Address
                            </Typography>
                        </Box>
                        <Box>
                            {recommendedAddress.address}
                        </Box>
                        <Box>
                            {recommendedAddress.address2}
                        </Box>
                        <Box>
                            {recommendedAddress.city}, {recommendedAddress.state} {recommendedAddress.zip}
                        </Box>
                        <Box style={{ marginTop: '1.5rem' }}>
                            <Button color="primary" variant="contained" onClick={() => { onSave(recommendedAddress) }}>
                                Use this address
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

    );
}
