import jobActions from './action';

const initialState = {
    loading: false,
    uploading: false,
    isNextDomStepReady: false,
    validates: {
        shippingAddress: false,
        selectItems: true,
        reviewSubmit: true,
        selectCustomer: true,
        selectScreen: false,
        jobInformation: false
    },

    addressFileIds: [],
    totalAddressCount: 0,
    addresses: [],
    addressCode: null,
    addressPagination: null,
    loadingAddressPage: false,
    addressInfo: {
        first1: '',
        middle1: '',
        last1: '',
        first2: '',
        middle2: '',
        last2: '',
        companyName: '',
        address: '',
        address2: '',
        address3: '',
        zip: '',
        city: '',
        state: '',
        country: 'United States',
        phoneNumber: '',
        ext: '',
        qty: 1,
        deliveryPoint: ''
    },

    // DOM: customer input
    customers: null,
    customer: null,

    // DOM: cost center input
    screenData: {
        marketSegment: '',
        costCenter: '',
        language: '',
        product: '',
        state: '',
        model: ''
    },

    products: null,
    costCenters: null,
    stateModels: null,

    // DOM: select item
    jobItems: null,
    pickPackFile: null,

    jobInfo: {
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        ext: '',
        email: '',
        dateReq: '',
        costCenter: '',
        officeLocation: '',
        jobReason: '',
        quantity: 1,
        email1: '',
        email2: '',
        shippingMethodId: null,
        earliest: null
    },

    deliveryDate: null,
    attachments: [],
    attachmentSettings: null,
    attachmentError: null,
    shippingNotes: '',
    upsShippingNotes: '',
    itemTypes: null,
    whs: null,

    savedId: null,
    savedName: null,
    savedOrderNumber: null,

    submitting: false,
    spmLoaded: false,
    shippingMethod: null,

    marketSegmentXCostCenters: null,
    imInfo: null,
    shippingMethodSettings: null,
    submitError: null,

    copyOrderData: null,
    inventoryAddresses: null
};

export default (state = initialState, action) => {
    let addrs;
    let dates;
    let earliest;
    switch (action.type) {
        case jobActions.SET_MARKET_SEGMENT_COST_CENTERS:
            const ms = Object.keys(action.marketSegmentXCostCenters);
            return {
                ...state,
                marketSegmentXCostCenters: action.marketSegmentXCostCenters,
                screenData: ms.length === 1
                    ? { ...state.screenData, marketSegment: ms[0] }
                    : state.screenData
            };
        case jobActions.SET_SUBMIT_ERROR:
            return { ...state, submitError: action.submitError };
        case jobActions.SET_INVENTORY_ADDRESS:
            return { ...state, inventoryAddresses: action.addresses };
        case jobActions.REMOVE_ALL_UPLOADED_ADDRESS:
            return {
                ...state,
                totalAddressCount: 0, addresses: [], addressCode: null,
                addressPagination: null,
                totalAddressCount: 0,
                addressFileIds: []
            };
        case jobActions.SET_LOADING_ADDRESS_PAGE:
            return { ...state, loadingAddressPage: action.loading };
        case jobActions.SET_ADDRESS_PAGINATION:
            return { ...state, addressPagination: action.addressPagination, totalAddressCount: action.addressPagination.totalCount };
        case jobActions.SET_SUBMITTING:
            return { ...state, submitting: action.submitting };
        case jobActions.SET_IM_INFO:
            return { ...state, imInfo: action.imInfo };
        case jobActions.SET_SPMLOADED:
            return { ...state, spmLoaded: action.spmLoaded };
        case jobActions.SET_SAVED_ID:
            return { ...state, savedId: action.savedId };
        case jobActions.SET_SAVED_NAME:
            return { ...state, savedName: action.savedName };
        case jobActions.SET_SAVED_ORDERNO:
            return { ...state, savedOrderNumber: action.savedOrderNumber };
        case jobActions.SET_PICK_PACK_FILE:
            earliest = new Date(action.data.deliveryInfo.deliveryDate);
            console.log('#ODS# ~ file: reducer.js ~ line 131 ~ earliest', earliest);
            return {
                ...state,
                pickPackFile: action.data,
                deliveryDate: action.data.deliveryInfo.deliveryDate,
                jobInfo: {
                    ...state.jobInfo,
                    dateReq: earliest.toLocaleDateString(),
                    earliest: earliest.toLocaleDateString()
                }
            };
        case jobActions.SET_NEXT_DOM_STEP_READY:
            return { ...state, isNextDomStepReady: action.isReady };
        case jobActions.SET_SHIPPING_NOTES:
            return { ...state, shippingNotes: action.shippingNotes };
        case jobActions.SET_UPS_SHIPPING_NOTES:
            return { ...state, upsShippingNotes: action.upsShippingNotes };

        case jobActions.SET_ITEM_TYPES:
            return { ...state, itemTypes: action.itemTypes };
        case jobActions.SET_WHS:
            return { ...state, whs: action.whs };
        case jobActions.SET_UPLOADING:
            return { ...state, uploading: action.uploading };
        case jobActions.SET_COPY_ORDER_DATA:
            return { ...state, copyOrderData: action.copyOrderData };

        case jobActions.SET_SHIPPING_METHOD:
            return { ...state, shippingMethod: action.shippingMethod };
        case jobActions.SET_SHIPPING_METHOD_SETTINGS:
            return { ...state, shippingMethodSettings: action.shippingMethodSettings };

        case jobActions.SET_CUSTOMERS:
            return { ...state, customers: action.customers };
        case jobActions.SET_DELIVERY_DATE:
            addrs = state.addresses;
            if (action.jobType === 'IM' || action.jobType === 'FJE') {
                dates = action.deliveryDate.map((d) => new Date(d.shipmentDate));
                earliest = new Date(Math.min.apply(null, dates));
            } else {
                dates = action.deliveryDate.map((d) => new Date(d.deliveryDate));
                earliest = new Date(Math.max.apply(null, dates));
            }

            for (let i = 0; i < addrs.length; i += 1) {
                addrs[i].deliveryDate = i >= action.deliveryDate.length
                    ? action.deliveryDate[0].shipmentDate
                    : action.deliveryDate[i].shipmentDate;

                addrs[i].deliveryDetail = i >= action.deliveryDate.length
                    ? action.deliveryDate[0]
                    : action.deliveryDate[i];
            }

            return {
                ...state,
                addresses: [...addrs],
                deliveryDate: action.deliveryDate,
                jobInfo: {
                    ...state.jobInfo,
                    dateReq: !state.jobInfo.dateReq || (new Date(state.jobInfo.dateReq)) < earliest
                        ? earliest.toLocaleDateString()
                        : state.jobInfo.dateReq,
                    earliest: earliest.toLocaleDateString()
                }
            };
        case jobActions.SELECT_CUSTOMER:
            return { ...state, customer: action.customer, isNextDomStepReady: true };
        case jobActions.SET_ADDRESS_FIELD:
            return { ...state, addressInfo: { ...state.addressInfo, [action.name]: action.value } };
        case jobActions.SET_SHIPPING_ADDRESS:
            return {
                ...state,
                addressInfo: { ...initialState.addressInfo },
                addresses: action.singleOnly
                    ? [action.selectedAddress] : [...state.addresses, action.selectedAddress],
                totalAddressCount: action.singleOnly ? 1 : state.totalAddressCount + 1
            };

        case jobActions.SET_SCREEN_FIELD:
            return {
                ...state,
                screenData: { ...state.screenData, [action.name]: action.value },
                jobInfo: {
                    ...state.jobInfo,
                    costCenter: action.name === 'costCenter'
                        ? action.value
                        : state.jobInfo.costCenter
                }
            };
        case jobActions.SET_PRODUCTS:
            return { ...state, products: action.products };
        case jobActions.SET_ATTACHMENT_SETTINGS:
            return { ...state, attachmentSettings: action.attachmentSettings };
        case jobActions.SET_COST_CENTERS:
            return { ...state, costCenters: action.costCenters };
        case jobActions.SET_STATE_MODELS:
            return { ...state, stateModels: action.stateModels };

        case jobActions.SET_KIT_ITEMS:
            return {
                ...state,
                jobItems: action.items
            };

        case jobActions.UPDATE_JOB_INFO:
            return { ...state, jobInfo: action.jobInfo };

        case jobActions.RESET_INPUT:
            return { ...initialState, ...(action.jobData || {}) };

        case jobActions.SET_VALIDATE:
            return { ...state, validates: { ...state.validates, [action.name]: action.value } };

        case jobActions.ADD_UPLOAD_ADDRESSES:
            return {
                ...state,
                addresses: action.addressData.code ? [] : [...state.addresses, ...(action.addressData.addresses || [])],
                addressCode: action.addressData.code,
                addressPagination: null,
                totalAddressCount: state.addresses.length + action.addressData.total,
                addressFileIds: [...state.addressFileIds, action.addressData.fileId].filter((i) => !!i)
            };
        case jobActions.UPDATE_ADDRESS_QTY:
            addrs = state.addresses;
            addrs[action.index].qty = action.qty;
            return { ...state, addresses: [...addrs] };

        case jobActions.REMOVE_ADDRESS:
            addrs = state.addresses;
            addrs.splice(action.index, 1);
            return {
                ...state,
                addresses: [...addrs],
                totalAddressCount: state.totalAddressCount - 1
            };

        case jobActions.REFRESH_ADDRESSES:
            return { ...state, addresses: [...action.addresses] };

        case jobActions.APPROVE_ATTACHMENT:
            addrs = state.attachments;
            addrs.find((a) => a.preview === action.preview).approved = true;
            return { ...state, attachments: [...addrs] };

        case jobActions.SET_ATTACHMENT_QUANTITY:
            addrs = state.attachments;
            addrs.find((a) => a.id === action.id).quantity = action.quantity;
            return { ...state, attachments: [...addrs] };

        case jobActions.SET_ATTACHMENT_FIELD:
            addrs = state.attachments;
            addrs.find((a) => a.id === action.id)[action.fieldName] = action.fieldValue;
            return { ...state, attachments: [...addrs] };

        case jobActions.ADD_ATTACHMENT:
            return { ...state, attachments: [...state.attachments, action.attachment] };

        case jobActions.REMOVE_ATTACHMENT:
            return { ...state, attachments: state.attachments.filter((a) => a.id !== action.id) };

        case jobActions.SET_ATTACHMENT_ERROR:
            return { ...state, attachmentError: action.attachmentError };

        case jobActions.GENERATING_FORM_PREVIEW:
            return { ...state, generating: action.generating };

        case jobActions.SET_FORM_PROOF_PREVIEW:
            return { ...state, formProofPreview: action.preview };

        case jobActions.SET_FORM_PROOF_PREVIEW_OPEN:
            return { ...state, openFormProofPreview: action.open };

        case jobActions.UPDATE_JOB_ITEM:
            return {
                ...state,
                jobItems: state.jobItems.map((i) => (i.id === action.jobItem.id
                    ? action.jobItem
                    : i))
            };

        case jobActions.SET_P1B_ITEMS:
            // eslint-disable-next-line no-case-declarations
            const newItems = [...state.jobItems];
            newItems.find((n) => n.id === action.id).items = action.items;
            return { ...state, jobItems: [...newItems] };

        case jobActions.ADD_TO_ADDRESSES:
            return {
                ...state,
                addresses: action.singleOnly
                    ? [{ ...state.addressInfo, ...(action.selected || {}) }]
                    : [...state.addresses, { ...state.addressInfo, ...(action.selected || {}) }],
                addressInfo: {
                    first1: '',
                    middle1: '',
                    last1: '',
                    first2: '',
                    middle2: '',
                    last2: '',
                    companyName: '',
                    address: '',
                    address2: '',
                    address3: '',
                    zip: '',
                    city: '',
                    state: '',
                    country: 'United States',
                    phone: '',
                    ext: '',
                    qty: 1,
                    deliveryPoint: ''
                },
                totalAddressCount: action.singleOnly ? 1 : (state.totalAddressCount + 1)
            };
        case jobActions.UPDATE_ADDRESS_DELIVERYPOINT:
            return {
                ...state, addressInfo: { ...state.addressInfo, deliveryPoint: action.deliveryPoint }
            };
        case jobActions.SET_COUNTIES:
            return {
                ...state, counties: action.counties
            };
        default:
            return state;
    }
};
