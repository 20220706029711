import orderActions from './action';

export default (
    state = {
        loading: false,
        downloadLink: '',
        pagedData: null
    },
    action
) => {
    switch (action.type) {
        case orderActions.LOADING:
            return { ...state, loading: action.loading };

        case orderActions.SET_DOWNLOAD_LINK:
            return { ...state, downloadLink: action.data };

        case orderActions.SET_PP_ADDRESSES:
            return { ...state, pagedData: action.data };

        default:
            return state;
    }
};
