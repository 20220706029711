import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import ListView from './ListView';

const mapStateToProps = ({ bannerMessageView }) => {
    return { ...bannerMessageView };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListView);
