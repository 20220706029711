// import createHistory from 'history/createBrowserHistory';
const createHistory = require('history').createHashHistory;

const history = createHistory();
export default history;


// import { createBrowserHistory } from 'history';

// const history = createBrowserHistory.createHistory();
// export default history;
