import { setUserInfo } from 'pages/login/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Content from './Content';

const mapStateToProps = ({ appView: { user } }) => {
    return { user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setUserInfo
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Content);
