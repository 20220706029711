import {
    Button,

    CircularProgress, Grid,

    IconButton, Paper, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Tooltip
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import { setShippingAddress } from '../action';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 1024,
        minHeight: 320,
        maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const ShippingAddressModal = (props) => {
    const { jobType } = useParams();
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, handleClose, setShippingAddress
    } = props;

    const [addresses, setAddresses] = useState([]);

    const classes = useStyles();

    const submitSearch = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('lastName', lastName || '');
        formData.append('company', company || '');
        const found = await http.post('store/find-existing-address', formData);

        setAddresses(found);
        setLoading(false);
    };

    const handleAdd = (addr) => {
        setShippingAddress({
            first1: addr.first1,
            last1: addr.last1,
            company: addr.company,
            address: addr.address,
            address2: addr.address2,
            address3: addr.address3,
            city: addr.city,
            zip: addr.zip,
            state: addr.state,
            country: addr.country,
            qty: 1
        }, jobType === 'FJE');
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>

                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <TextField
                            id="last-name-search"
                            fullWidth
                            size="small"
                            label="Name (last)"
                            type="search"
                            variant="outlined"
                            value={lastName}
                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(); } }}
                            onChange={(e) => { setLastName(e.target.value); setCompany(''); }}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.middleOr}>OR</Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="company-search"
                            fullWidth
                            size="small"
                            label="Company"
                            type="search"
                            variant="outlined"
                            value={company}
                            onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(); } }}
                            onChange={(e) => { setCompany(e.target.value); setLastName(''); }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="large"
                            fontWeight="fontWeightMedium"
                            className={classes.inlineSearchButton}
                            onClick={submitSearch}
                        >Search
                        </Button>
                    </Grid>
                </Grid>

                <TableContainer component={Paper} style={{ marginTop: '1rem', maxHeight: '30rem' }}>
                    <Table size="small" aria-label="address table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>NAME</TableCell>
                                <TableCell>COMPANY</TableCell>
                                <TableCell>Address 1</TableCell>
                                <TableCell>CITY</TableCell>
                                <TableCell>STATE</TableCell>
                                <TableCell>ZIP</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {loading && (
                                <TableRow>
                                    <TableCell
                                        colSpan={20}
                                        style={{ textAlign: 'center', padding: '1rem' }}
                                    >
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && addresses && addresses.map((row, i) => (
                                <TableRow key={`${row.first1}-${i}`}>
                                    <TableCell>{row.first1} {row.last1}</TableCell>
                                    <TableCell>{row.company}</TableCell>
                                    <TableCell>{row.address}</TableCell>
                                    <TableCell>{row.city}</TableCell>
                                    <TableCell>{row.state}</TableCell>
                                    <TableCell>{row.zip}</TableCell>
                                    <TableCell>
                                        {
                                            row.isPO && (jobType === 'AJEKT' || jobType === 'AJEPP')
                                                ? (
                                                    <Tooltip title="PO Box address is not allowed">
                                                        <AddBoxIcon />
                                                    </Tooltip>
                                                )
                                                : (
                                                    <IconButton aria-label="AddBoxIcon" color="primary" onClick={() => handleAdd(row)}>
                                                        <AddBoxIcon />
                                                    </IconButton>
                                                )
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </Modal>
    );
};

const mapStateToProps = () => ({

});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { setShippingAddress },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressModal);
