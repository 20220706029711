import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { updateJobItem } from '../../action';
import { loadFormSchema } from '../edit-form-modal/action';
import KitCrosswalk from './KitCrosswalk';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 960,
        minHeight: 150,
        maxHeight: 800,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

function KitContainer(props) {
    const {
        open, handleClose, kit, updateJobItem: saveJobItem, customer, loadFormSchema, lob, jobType, disableEF
    } = props;
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    let items = null;
    if (kit) {
        items = kit.items;
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <KitCrosswalk
                    customer={customer || {}}
                    kit={kit}
                    items={items}
                    disableEF={disableEF}
                    loadFormSchema={loadFormSchema}
                    saveJobItem={saveJobItem}
                    onSave={handleClose}
                    lob={lob}
                    jobType={jobType}
                />
            </div>
        </Modal>
    );
}

const mapStateToProps = ({ directOrderView: { stepView: { customer } } }) => ({
    customer
});

const mapDispatchToProps = {
    updateJobItem, loadFormSchema
};

export default connect(mapStateToProps, mapDispatchToProps)(KitContainer);
