import { Box, Button, CircularProgress, Container, CssBaseline, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import http from 'utils/http';
import ResetPasswordDialog from './ResetPasswordDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3rem'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    forgetPassword: {
        textTransform: 'none',
        textDecoration: 'underline'
    }
}));

const LoginForm = (props) => {
    const classes = useStyles();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [resetting, setResetting] = useState(false);
    const [NPNRequired, setNPNRequired] = useState(false);
    const [submittingReset, setSubmittingReset] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [resetNPN, setResetNPN] = useState('');
    const [info, setInfo] = useState(null);
    const [ssoInfo, setSsoInfo] = useState(null);
    const { message } = useParams();
    const { setErrorMsg } = props;
    useEffect(() => {
        if (message) {
            if (message === "sso-unauthorized") {
                setSsoInfo("<p>Thank you for your interest in accessing the Aetna Ordering Portal. According to our records a personal profile has not yet been created for you. To request a profile be created, \
                               please complete the <a href='https://aetnao365.sharepoint.com/:w:/r/sites/ProviderDirectories/_layouts/15/Doc.aspx?sourcedoc=%7B1D819B6D-0CF4-4855-991E-384CE78B8597%7D&file=ODS%20-%20Request%20User%20Access%20FOA_CSP%20-%208.15.2020.docx&action=default&mobileredirect=true&cid=415623e1-373f-4af9-b336-3e94a5c7e88b' target='_blank'>user access request form</a> \
                               and email it to the Aetna Fulfillment Team at <a href='mailto:AetnaFulfillmentTeam@Aetna.com'>AetnaFulfillmentTeam@Aetna.com</a>. You will be notified once a profile is created, and your access is enabled (within 48 hours of your submission request). </p>");
            }
            else {
                setErrorMsg(message);
            }
        }
        return () => {

        };
    }, [message, setErrorMsg]);

    const { isAuthUser, error, loading } = props;
    if (isAuthUser && !!window.localStorage.getItem('user')) return <Redirect to="/" />;

    const submitForm = () => {
        if (userName === '' || password === '') {
            props.setErrorMsg('User name or password are required');
        } else {
            props.login(userName, password);
        }
    };

    const dismissMessage = () => {
        props.setErrorMsg(null);
    };

    const dismissInfo = () => {
        setInfo('');
    };

    const submitting = loading && !!userName && !!password;

    const showForgotPassword = () => {
        setResetting(true);
    };
    const hideForgotPassword = () => {
        setResetting(false);
        setNPNRequired(false);
    };

    const submitResetEmail = () => {
        setSubmittingReset(true);
        const fd = new FormData();
        fd.append('email', resetEmail);
        if (NPNRequired) fd.append('npn', resetNPN);

        http.post('auth/reset', fd).then((t) => {
            if (t.isEmailSent) {
                setInfo('Instructions to reset your password have been sent to the email address. Please contact us if you require further assistance.');
                setResetting(false);
            } else if (t.npnRequired) {
                setNPNRequired(true);
                if (t.message) {
                    props.setErrorMsg(t.message);
                }
            } else {
                props.setErrorMsg(t.message || 'System failed to send password reset email. Please try again later or contact us if you require further assistance.');
            }
        }).finally(() => setSubmittingReset(false));
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper variant="outlined" className={classes.paper}>
                {ssoInfo && (
                    <div style={{ marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: ssoInfo }} />
                )}
                <Typography component="h1" variant="h4">Sign In</Typography>
                <TextField
                    label="User ID/NPN/Email"
                    required
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className="form-input"
                    value={userName}
                    autoFocus
                    onChange={(e) => setUserName(e.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter') { submitForm(); } }}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    className="form-input"
                    type="password"
                    value={password}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter') { submitForm(); } }}
                />
                <Grid container>
                    <Grid item xs>
                        <Button color="primary" onClick={showForgotPassword} className={classes.forgetPassword}>
                            Forgot password?
                        </Button>
                        <Box component="div">
                            <a href="https://cdndev.memberdoc.com/f/Ordering_Portal_FAQ.pdf" target="_blank">
                                <CloudDownloadIcon style={{ position: 'relative', top: '7px', marginRight: '5px' }} />
                                <Typography variant="span">
                                    Medicare FAQ
                                </Typography>
                            </a>
                            <br />
                            <a href="https://cdndev.memberdoc.com/f/ONEKit_FAQ_OE_v6.pdf" target="_blank">
                                <CloudDownloadIcon style={{ position: 'relative', top: '7px', marginRight: '5px' }} />
                                <Typography variant="span">
                                    Commercial FAQ
                                </Typography>
                            </a>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={submitting}
                            className={classes.submit}
                            onClick={submitForm}
                        >
                            {submitting && (
                                <CircularProgress
                                    color="secondary"
                                    size={12}
                                    className={classes.buttonProgress}
                                />
                            )} Login
                        </Button>

                    </Grid>
                </Grid>
                {error && !resetting && (
                    <MuiAlert elevation={6} severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={dismissMessage}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {error}
                    </MuiAlert>
                )}
                {info && (
                    <MuiAlert elevation={6} variant="filled" severity="info" onClick={dismissInfo}>
                        {info}
                    </MuiAlert>
                )}
            </Paper>
            <ResetPasswordDialog
                open={resetting}
                NPNRequired={NPNRequired}
                resetNPN={resetNPN}
                error={error}
                dismissMessage={dismissMessage}
                onClose={hideForgotPassword}
                resetEmail={resetEmail}
                setResetEmail={setResetEmail}
                setResetNPN={setResetNPN}
                submitResetEmail={submitResetEmail}
                submittingReset={submittingReset}
            />
        </Container>
    );
};

export default LoginForm;
