import React, { PureComponent } from 'react';

export default class PassthroughLogin extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { passthroughLogin, match: { params: { token } } } = this.props;

        if (token && token !== 'undefined') { passthroughLogin(token); } else { setTimeout(() => window.location = 'https://www.aetnaseniorproducts.com', 2000); }
    }

    render() {
        const { error, match: { params: { token } } } = this.props;
        const createMarkup = (cf) => { return { __html: cf }; };

        let msg = 'LOADING...';
        if (error) {
            msg = 'ACCESS DENIED';
        } else if (!token || token === 'undefined') {
            msg = 'You are being redirected to <b>www.aetnaseniorproducts.com</b>';
        }

        return (
            <div dangerouslySetInnerHTML={createMarkup(msg)} />
        );
    }
}
