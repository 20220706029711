import { Box, Breadcrumbs, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { showMessage } from 'actions/app';
import AddressModal from 'components/ShippingAddressModal';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAddresses } from './action';

const pageSize = 10;
class ListView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            pageNumber: 1,

            openAddress: false,
            address: null

        };
    }

    componentDidMount() {
        const { pageNumber } = this.state;
        const { getAddresses: loadData } = this.props;
        loadData(pageNumber, pageSize);
    }

    handleChangePage = (_event, pageNumber) => {
        const { getAddresses: loadData } = this.props;
        loadData(pageNumber + 1, pageSize);
    }

    closeAddressModal = () => {
        this.setState({ openAddress: false });
    }

    saveAddress = (addr) => {
        const { saveAddress, history } = this.props;
        saveAddress(addr, () => history.go());

        this.setState({ openAddress: false });
    }

    openAddressModal = (id) => {
        const { addresses } = this.props;
        this.setState({ openAddress: true, address: addresses.results.find((a) => a.id === id) });
    }

    render() {
        const { addresses, showMessage } = this.props;
        const { openAddress, address } = this.state;
        return (
            <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    <Box mb={2}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="#/account">
                                Account
                            </Link>
                                <Typography color="textPrimary">Your Addresses</Typography>
                        </Breadcrumbs>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Addresses</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => this.openAddressModal()}>
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addresses && addresses.results.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>{row.firstName} {row.lastName} </TableCell>
                                    <TableCell>{row.address} {row.address2} {row.city} {row.state} {row.zip}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" onClick={() => this.openAddressModal(row.id)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {addresses
                    && (
                        <TablePagination
                            component="div"
                            count={addresses.totalCount}
                            page={addresses.pageNumber - 1}
                            rowsPerPage={addresses.pageSize}
                            rowsPerPageOptions={[addresses.pageSize]}
                            onChangePage={this.handleChangePage}
                        />
                    )}
                {openAddress && (
                    <AddressModal
                        open={openAddress}
                        address={address}
                        closeAddress={this.closeAddressModal}
                        saveAddress={this.saveAddress}
                        showMessage={showMessage}
                    />
                )}
                </Grid>

            </Grid>
            </div>
        );
    }
}

const mapStateToProps = ({ ordersView: { orders } }) => ({
    orders
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getAddresses, showMessage
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
