import React, { Component } from 'react';
import {
    FormControl, Select, MenuItem, InputLabel, Box, Input, Chip
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

class MultiSelectCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
            errorMessage: '',
            pickList: props.args.options || props.pickList || []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value ? nextProps.value : [] });
        }
        if (nextProps.pickList !== this.props.pickList) {
            if (nextProps.pickList && nextProps.pickList.length > 0) {
                this.setState({ pickList: nextProps.pickList });
            }
        }
    }

    onChange = (e) => {
        const { row, col, updateRowData } = this.props;
        const { value } = e.target;
        this.setState({ value });
        updateRowData && updateRowData(row, col, value);
    }

    render() {
        const { isReadOnly, prefix, col } = this.props;
        const { value, pickList } = this.state;

        return (
            <Box component="div" className={`${prefix}-checkbox-field`}>
                <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>{col.title}</InputLabel>
                    <Select
                        multiple
                        label={col.title}
                        disabled={isReadOnly}
                        value={value}
                        onChange={this.onChange}
                        input={<Input />}
                        renderValue={(selected) => {
                            return (
                                <Box component="div" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            );
                        }}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {pickList && pickList.map((item, index) => {
                            return <MenuItem key={index} value={item[col.valueField]}>{item[col.textField]}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
            </Box>
        );
    }
}

MultiSelectCell.defaultProps = {
    prefix: 'editable-cell'
};

export default MultiSelectCell;
