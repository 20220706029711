import DateFnsUtils from '@date-io/date-fns';
import {
    Checkbox, FormControl,
    FormControlLabel, Grid,

    InputLabel, MenuItem, Select, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ConfirmShippingMethodChange from 'components/ConfirmShippingMethodChange';
import PhoneInput from 'components/PhoneInput';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    getPossibleDeliveryDate, refreshAddresses, setDomNextStepReady, setValidate, updateJobInfo
} from '../action';

const useStyles = makeStyles((theme) => ({
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const JobInformationStep = (props) => {
    const [init, setInit] = useState(false);
    const [openDDD, setOpenDDD] = useState(false);
    const [ddd, setDDD] = useState('');
    const [lateAddresses, setLateAddresses] = useState([]);
    const {
        pickPackFile,
        jobInfo, user, addresses, jobItems, marketSegmentXCostCenters,
        screenData, settings, shippingMethodSettings, isBackward, refreshAddresses
    } = props;
    const { lob, jobType, subCode } = useParams();

    const [showShippingMethodConfirm, setShowShippingMethodConfirm] = useState(false);
    const [newShippingMethodId, setNewShippingMethodId] = useState(null);

    const confirmShippingMethodChange = (e) => {
        if (e.target.value == 1) {
            handleInputChange(e);
        } else {
            if (jobInfo.shippingMethodId == 1) {
                setNewShippingMethodId(e.target.value);
                setShowShippingMethodConfirm(true);
            } else {
                handleInputChange(e);
            }
        }
    };

    let shippingMethodOptions = shippingMethodSettings
        ? shippingMethodSettings.filter((s) => s.lob === lob && s.jobType === jobType)
        : [];
    if (!(lob === 'cml' && jobType === 'AJEPP' && screenData.marketSegment === 'FEDS') && !(lob === 'p1b' && jobType === 'IM')) {
        shippingMethodOptions = shippingMethodOptions.filter((o) => o.shippingMethodId !== 9 && o.shippingMethodId !== 10);
    }

    useEffect(() => {
        if (!init) {
            let newInfo = null;
            if (user && lob === 'p1a') {
                newInfo = { ...jobInfo, costCenter: '19086' };
            }

            if (lob === 'p1b' && user && user.customFields.userType === 'fsr' && (jobType === 'AJEKT' || jobType === 'IM')) {
                let ccItem = jobItems[0];
                if (jobItems.length > 1) {
                    ccItem = jobItems.find(i => !!i.costCenter);
                }
                newInfo = {
                    ...(newInfo || jobInfo),
                    costCenter: ccItem.costCenter,
                    costCenterOptions: ccItem.costCenterOptions,
                    ccReadOnly: !ccItem.costCenterOptions || ccItem.costCenterOptions.length === 0
                };
            }
            if (lob === 'p1b' && user && user.customFields.userType === 'fsr' && jobType === 'AJEPP') {
                newInfo = { ...(newInfo || jobInfo), costCenter: '19224', costCenterOptions: ['19224', '700209'], ccReadOnly: false };
            }

            const defaultShippingMethod = shippingMethodOptions.find((m) => m.lob === lob && m.jobType === jobType && m.isDefault);
            if (defaultShippingMethod) {
                newInfo = { ...(newInfo || jobInfo), shippingMethodId: defaultShippingMethod.shippingMethodId };
            }
            if (newInfo != null && !pickPackFile) {
                props.updateJobInfo(newInfo);
            }

            if (!isBackward && !pickPackFile) props.getPossibleDeliveryDate(lob, jobType);
            setInit(true);
        }
        const isValid = !!(jobInfo.firstName && jobInfo.lastName
            && (
                (jobInfo.dateReq && (!jobInfo.earliest || new Date(jobInfo.dateReq) >= new Date(jobInfo.earliest)))
                // || (!jobInfo.dateReq && jobType === 'AJEPP' && lob === 'cml')
            )
            && (subCode || jobInfo.costCenter)
            && (!jobInfo.oboBroker || !!jobInfo.npn));
        props.setIsNextReady(isValid);
    }, [init, jobInfo, props, user, lob, jobItems, jobType, marketSegmentXCostCenters, screenData, shippingMethodOptions, isBackward, pickPackFile, subCode]);

    const handleInputChange = (e) => {
        let value = e.target.name === 'oboBroker' ? e.target.checked : e.target.value;
        if (e.target.name === 'dateReq') {
            const expected = new Date(e.target.value);
            value = expected.toLocaleDateString();
        }

        const info = { ...jobInfo };
        info[e.target.name] = value;
        props.updateJobInfo(info);

        if (e.target.name === 'dateReq') {
            setDDD(value);
            const reqDate = new Date(e.target.value);
            const late = addresses.filter((a) => a.deliveryDate > reqDate);
            setLateAddresses(late);
            if (late.length) {
                setOpenDDD(true);
            }

            for (const a of addresses) {
                if (a.deliveryDetail) {
                    if (jobType === 'IM' || jobType === 'FJE') {
                        a.deliveryDetail.shipmentDate = new Date(e.target.value).toLocaleDateString();
                        a.deliveryDetail.deliveryDate = calcDeliveryDate(new Date(e.target.value), a.deliveryDetail.daysInTransit);
                        a.deliveryDate = a.deliveryDetail.deliveryDate;
                    } else {
                        a.deliveryDetail.deliveryDate = new Date(e.target.value).toLocaleDateString();
                        a.deliveryDate = a.deliveryDetail.deliveryDate;
                        a.deliveryDetail.shipmentDate = calcShipmentDate(new Date(e.target.value), a.deliveryDetail.daysInTransit);
                    }
                }
            }
            refreshAddresses(addresses);
        } else if (e.target.name === 'shippingMethodId') {
            props.updateJobInfo({ ...info, dateReq: '' });
            props.getPossibleDeliveryDate(lob, jobType);
        }
    };

    const calcDeliveryDate = (date, daysInTransit) => {
        for (let n = 0; n < daysInTransit; n += 1) {
            do {
                date.setDate(date.getDate() + 1);
            } while (date.getDay() === 0 || date.getDay() === 6 || (settings && settings.holidays && settings.holidays.includes(formatDate(date))));
        }

        return date.toLocaleDateString();
    };
    const calcShipmentDate = (date, daysInTransit) => {
        for (let n = 0; n < daysInTransit; n += 1) {
            do {
                date.setDate(date.getDate() - 1);
            } while (date.getDay() === 0 || date.getDay() === 6 || (settings && settings.holidays && settings.holidays.includes(formatDate(date))));
        }

        return date.toLocaleDateString();
    };

    const classes = useStyles();
    const oboSupported = user && (user.customFields.userType === 'fsr' || user.customFields.userType === 'su') && lob === 'p1a';

    const formatDate = (date) => {
        const year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : `0${month}`;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : `0${day}`;

        return `${month}/${day}/${year}`;
    };

    const disableWeekends = (date) => {
        return date.getDay() === 0 || date.getDay() === 6 || (settings && settings.holidays && settings.holidays.includes(formatDate(date)));
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        required
                        label="First Name"
                        type="text"
                        variant="outlined"
                        name="firstName"
                        value={jobInfo.firstName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        size="small"
                        label="MI"
                        type="text"
                        variant="outlined"
                        name="middleName"
                        value={jobInfo.middleName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        required
                        label="Last Name"
                        type="text"
                        variant="outlined"
                        name="lastName"
                        value={jobInfo.lastName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Phone#"
                        size="small"
                        name="phone"
                        variant="outlined"
                        InputProps={{
                            inputComponent: PhoneInput,
                            value: jobInfo.phone || '',
                            onChange: handleInputChange
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Ext"
                        type="text"
                        variant="outlined"
                        name="ext"
                        value={jobInfo.ext}
                        onChange={handleInputChange}
                    />
                </Grid>
                {!subCode && (

                    <Grid item xs={2}>

                        {
                            jobInfo.costCenterOptions && jobInfo.costCenterOptions.length > 0
                                ? (
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        required
                                        variant="outlined"
                                    >
                                        <InputLabel id="country-select-outlined-label">Cost Center</InputLabel>
                                        <Select
                                            labelId="cost-center-select-outlined-label"
                                            id="cost-center-select-outlined"
                                            name="costCenter"
                                            value={jobInfo.costCenter}
                                            label="Cost Center"
                                            fullWidth
                                            onChange={handleInputChange}
                                        >
                                            {jobInfo.costCenterOptions.map((o) => {
                                                if (lob === 'cml' && ['AJEPP', 'AJEKT', 'IM'].includes(jobType)) {
                                                    return (
                                                        <MenuItem
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            {o.value} ({o.label})
                                                        </MenuItem>
                                                    );
                                                }

                                                return (
                                                    <MenuItem
                                                        key={o}
                                                        value={o}
                                                    >
                                                        {o}
                                                    </MenuItem>
                                                );
                                            })}

                                        </Select>
                                    </FormControl>
                                )
                                : (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        required
                                        disabled={(lob === 'p1a' || jobInfo.ccReadOnly || ((jobType === 'AJEPP' || jobType === 'AJEKT' || jobType === 'IM') && lob === 'cml') && !pickPackFile)}
                                        label="Cost Center"
                                        type="text"
                                        variant="outlined"
                                        name="costCenter"
                                        value={jobInfo.costCenter}
                                        onChange={handleInputChange}
                                    />
                                )
                        }

                    </Grid>

                )}
                <Grid item xs={2}>

                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        shouldDisableDate={disableWeekends}
                        id="date"
                        label={jobType === 'IM' ? 'Ship Date' : 'Date Req'}
                        name="dateReq"
                        minDate={jobInfo.earliest ? new Date(jobInfo.earliest) : new Date()}
                        value={jobInfo.dateReq ? new Date(jobInfo.dateReq) : new Date()}
                        inputVariant="outlined"
                        size="small"
                        disabled={!jobInfo.dateReq}
                        required
                        onChange={(d) => {
                            handleInputChange({ target: { name: 'dateReq', value: d } });
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'Date Req'
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                </Grid>
                {
                    !pickPackFile && shippingMethodOptions && shippingMethodOptions.length > 0 && (
                        <Grid item xs={3}>

                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <InputLabel id="shipping-method-select-outlined-label">Shipping Method</InputLabel>
                                <Select
                                    labelId="shipping-method-select-outlined-label"
                                    id="shipping-method-select-outlined"
                                    value={jobInfo.shippingMethodId}
                                    name="shippingMethodId"
                                    onChange={confirmShippingMethodChange}
                                    label="Shipping Method"
                                >
                                    {shippingMethodOptions.map((s) => <MenuItem key={s.shippingMethodId} value={s.shippingMethodId}>{s.shippingMethod}</MenuItem>)}

                                </Select>
                            </FormControl>

                        </Grid>
                    )
                }
                {oboSupported && (
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox checked={jobInfo.oboBroker || false} onChange={handleInputChange} name="oboBroker" />}
                            label="On Behalf of Broker"
                        />
                    </Grid>

                )}
                {
                    jobInfo.oboBroker && (
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                label="NPN"
                                type="text"
                                variant="outlined"
                                name="npn"
                                value={jobInfo.npn || ''}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )
                }
                {false && (
                    <>
                        <Grid item xs={2}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel>Office/Location</InputLabel>
                                <Select
                                    value={jobInfo.officeLocation}
                                    fullWidth
                                    onChange={handleInputChange}
                                    name="officeLocation"
                                    label="Office/Location"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="Aetna">Aetna</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel>Job Reason</InputLabel>
                                <Select
                                    value={jobInfo.jobReason}
                                    fullWidth
                                    onChange={handleInputChange}
                                    name="jobReason"
                                    label="Job Reason"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="Wrong Item">Wrong Item</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                InputProps={{
                                    readOnly: jobType !== 'AJEKT'
                                }}
                                label="Quantity"
                                type="text"
                                variant="outlined"
                                name="quantity"
                                value={jobInfo.quantity}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </>

                )}

                {/* <InvalidDeliveryDate open={openDDD} ddd={ddd} addresses={lateAddresses} onClose={() => setOpenDDD(false)} /> */}
                {lob === 'p1b' && ['fsr', 'su'].includes(user.customFields.userType) && (
                    <>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email 1"
                                type="email"
                                variant="outlined"
                                name="email1"
                                value={jobInfo.email1 || ''}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Email 2"
                                type="email"
                                variant="outlined"
                                name="email2"
                                value={jobInfo.email2 || ''}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </>
                )}
                <ConfirmShippingMethodChange
                    open={showShippingMethodConfirm}
                    onClose={() => {
                        setShowShippingMethodConfirm(false);
                        setNewShippingMethodId(null);
                    }}
                    onSave={() => {
                        setShowShippingMethodConfirm(false);
                        handleInputChange({ target: { name: 'shippingMethodId', value: newShippingMethodId } });
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

const mapStateToProps = ({
    appView: { user, settings },
    directOrderView: {
        stepView: {
            jobInfo,
            addresses,
            jobItems, marketSegmentXCostCenters, screenData,
            shippingMethodSettings,
            pickPackFile
        }
    }
}) => ({
    jobInfo,
    user,
    settings,
    addresses,
    jobItems,
    marketSegmentXCostCenters,
    screenData,
    shippingMethodSettings,
    pickPackFile
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateJobInfo, setDomNextStepReady, setValidate, getPossibleDeliveryDate, refreshAddresses
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobInformationStep);
