import {
    Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';

export default function EditBrokerSelect({
    saveBrokerSelection, meta, stateLang, selection, setCounties, countyList, selectCounty
}) {
    const yearOptions = meta.map((m) => m.Year);
    const [productOptions, setProductOptions] = useState(meta[0].Product.split(',').filter((s) => !!s));
    const [selectedProduct, setSelectedProduct] = useState((selection != null && selection.product != null) ? selection.product : productOptions[0]);
    const [stateOptions, setStateOptions] = useState(meta[0].States.split(',').filter((s) => !!s && (selectedProduct !== 'PARTD' ? s !== 'ALL' : s === 'ALL')));
    const [countyOptions, setCountyOptions] = useState(countyList ?? ['']);
    const [selectedYear, setSelectedYear] = useState((selection != null && selection.year != null) ? selection.year : yearOptions[0]);
    const [selectedState, setSelectedState] = useState((selection != null && selection.state != null) ? selection.state : stateOptions[0]);
    const [selectedCounty, setSelectedCounty] = useState((selection != null && selection.county != null ? selection.county : (countyOptions ? countyOptions[0] : '')));

    const [languageOptions, setLanguageOptions] = useState([]);
    const [partdProduct, setpartdProduct] = useState((selection != null && selection.state != null && selection.product != null && selection.product == 'PARTD') ? true : false);

    const llo = [...new Set(stateLang.filter((x) => x.State === selectedState)
        .map((x) => (x.Lang === 'E' ? 'English' : x.Lang === 'C' ? 'Chinese' : 'Spanish')))].sort((a, b) => {
            return a === 'English' && b !== a ? -1 : 1;
        });

    const [selectedLanguage, setSelectedLanguage] = useState((selection != null && selection.language != null) ? selection.language : llo[0]);


    const saveChange = () => {
        saveBrokerSelection({
            year: selectedYear,
            state: selectedState,
            language: selectedLanguage,
            product: selectedProduct,
            countyList: countyList.filter((f) => f.name === selectedCounty || '').map((f) => f.name)
        });
    };
    useEffect(() => {
        const lo = [...new Set(stateLang.filter((x) => x.State === selectedState)
            .map((x) => (x.Lang === 'E' ? 'English' : x.Lang === 'C' ? 'Chinese' : 'Spanish')))].sort((a, b) => {
                return a === 'English' && b !== a ? -1 : 1;
            });
        setLanguageOptions([...lo]);
        if (!selectedLanguage || !lo.includes(selectedLanguage)) {
            setSelectedLanguage(lo[0]);
        }
        return () => { };
    }, [selectedState, selectedLanguage, stateLang]);

    useEffect(() => {
        const fetch = async () => {
            http.get(`broker/county-list/${selectedState}`).then((result) => {
                // console.log('editbrokerselect: ', result);
                setCounties([...result.countyList]);
                setCountyOptions([...result.countyList]);
                if (!selectedCounty || !result.countyList.includes(selectedCounty)) {
                    setSelectedCounty('');
                    selectCounty('');
                }
            });
        };

        // setCountyOptions([]);
        // setSelectedCounty(null);
        fetch();
    }, [selectedState]);

    useEffect(() => {
        // console.log('selectedCounty: ', selectedCounty);
        selectCounty(selectedCounty);
    }, [selectedCounty]);

    const onPlanYearChange = (newYear) => {
        setSelectedYear(newYear);
        const m = meta.find((t) => t.Year === newYear);

        setProductOptions(m.Product.split(',').filter((s) => !!s));
        if (!productOptions.includes(selectedProduct)) {
            setSelectedProduct(productOptions[0]);
        }

        setStateOptions(m.States.split(',').filter((s) => !!s && (selectedProduct != 'PARTD' ? s != 'ALL' : s == 'ALL')));
        if (!stateOptions.includes(selectedState)) {
            setSelectedState(stateOptions[0]);
        }
    };

    const onPlanProductChange = (newPlan) => {
        setSelectedProduct(newPlan);
        if (newPlan == 'PARTD') {
            var newStateOptions = meta[0].States.split(',').filter((s) => !!s && s === 'ALL');
            setStateOptions(newStateOptions);
            setSelectedState('ALL');
            setpartdProduct(true);
        }
        else {
            var newStateOptions = meta[0].States.split(',').filter((s) => !!s && s != 'ALL');
            setStateOptions(newStateOptions);
            setpartdProduct(false);
            setSelectedState(newStateOptions[0]);
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Medicare Broker Materials
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="year-select-outlined-label">Year</InputLabel>
                    <Select
                        labelId="year-select-outlined-label"
                        id="year-select-outlined"
                        value={selectedYear}
                        onChange={(e) => onPlanYearChange(e.target.value)}
                        name="year"
                        label="Year"
                    >
                        {yearOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="product-select-outlined-label">Product</InputLabel>
                    <Select
                        labelId="product-select-outlined-label"
                        id="product-select-outlined"
                        value={selectedProduct}
                        onChange={(e) => onPlanProductChange(e.target.value)}
                        name="product"
                        label="Product"
                    >
                        {productOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="state-select-outlined-label">State</InputLabel>
                    <Select
                        disabled={partdProduct}
                        labelId="state-select-outlined-label"
                        id="state-select-outlined"
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        name="state"
                        label="State"
                    >
                        {stateOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="county-select-outlined-label">County</InputLabel>
                    <Select
                        disabled={partdProduct}
                        labelId="county-select-outlined-label"
                        id="county-select-outlined"
                        value={selectedCounty}
                        onChange={(e) => setSelectedCounty(e.target.value)}
                        name="county"
                        label="County"
                    >
                        {countyOptions.map((y) => <MenuItem key={y.name} value={y.name}>{y.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="language-select-outlined-label">Language</InputLabel>
                    <Select
                        labelId="language-select-outlined-label"
                        id="language-select-outlined"
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        name="language"
                        label="Language"
                    >
                        {languageOptions.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={3}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            float="right"
                            size="large"
                            onClick={saveChange}
                        >Next <NavigateNextIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
