import update from 'immutability-helper';
import { actionCreator, keyMirror } from '../../../exportUtil';

export const actions = keyMirror('PLAN_COMPARISON', {
    PRE_LOAD: null,
    LOAD_SUCCESS: null,
    ERROR: null,
    SET_ORDER_DATA: null,
    SET_PLAN_COMPARISON: null,
    UPDATE_PLAN_COMPARISON: null,
    UPDATE_PLAN_NAME: null,
    UPDATE_TABLE_DATA: null,
    SET_DATA_VALUE: null
});

export const error = actionCreator(actions.ERROR, 'errorMessages');
const preLoad = actionCreator(actions.PRE_LOAD);
const loaded = actionCreator(actions.LOAD_SUCCESS);

const initialState = {
    isLoading: false,
    errorMessages: [],
    planComparison: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.PRE_LOAD:
            return { ...state, isLoading: true };
        case actions.LOAD_SUCCESS:
            return { ...state, isLoading: false };
        case actions.ERROR:
            return { ...state, isLoading: false, errorMessages: action.errorMessages };

        case actions.SET_PLAN_COMPARISON:
            return { ...state, planComparison: action.data };
        case actions.UPDATE_PLAN_COMPARISON: {
            const newData = update(state.planComparison, {
                dataValue: {
                    [action.key]: { $set: action.value }
                }
            });
            return { ...state, planComparison: newData };
        }
        case actions.UPDATE_PLAN_NAME: {
            const newData = update(state.planComparison, {
                dataValue: {
                    planName: {
                        $merge: { [action.key]: action.value }
                    }
                }
            });
            return { ...state, planComparison: newData };
        }
        case actions.UPDATE_TABLE_DATA: {
            const {
                primary, secondary, colName, key, value
            } = action.data;
            const newPlanComparisonData = update(state.planComparison, {
                dataValue: {
                    rowData: {
                        $apply: (x) => {
                            return x && x.map((item) => {
                                if (item.primary === primary && item.secondary === secondary) {
                                    return {
                                        ...item,
                                        [colName]: {
                                            ...item[colName],
                                            [key]: value
                                        }
                                    };
                                } else {
                                    return item;
                                }
                            });
                        }
                    }
                }
            });
            return {
                ...state, planComparison: newPlanComparisonData
            };
        }
        case actions.SET_DATA_VALUE: {
            const newData = update(state.planComparison, {
                dataValue: { $set: action.data }
            });
            console.log('action.data', action.data);
            return { ...state, planComparison: newData };
        }
        default:
            return state;
    }
}

export const setPlanComparison = actionCreator(actions.SET_PLAN_COMPARISON, 'data');
export const getPlanComparison = (dv) => (dispatch) => {
    const data = {
        dataCell: [
            {
                id: 'planYear',
                title: 'Year',
                dType: 'string',
                type: 'radioSelect',
                defaultValue: '',
                args: {
                    options: [
                        {
                            text: '2024',
                            value: '2024'
                        },
                        {
                            text: '2024-2025',
                            value: '2024-2025'
                        },
                        {
                            text: '2025',
                            value: '2025'
                        }
                    ]
                },
                textField: 'text',
                valueField: 'value'
            },
            {
                id: 'startDate',
                title: 'Start Date',
                dType: 'string',
                type: 'date',
                defaultValue: ''
            },
            {
                id: 'endDate',
                title: 'End Date',
                dType: 'string',
                type: 'date',
                defaultValue: ''
            },
            {
                id: 'phoneNumber',
                title: 'Phone Number',
                dType: 'string',
                type: 'textField',
                mask: '999-999-9999',
                defaultValue: ''
            },
            {
                id: 'languageServiceNumber',
                title: 'Language Service Number',
                dType: 'string',
                mask: '999-999-9999',
                type: 'textField',
                defaultValue: ''
            },
            {
                id: 'spanishServiceNumber',
                title: 'Spanish Service Number',
                mask: '999-999-9999',
                dType: 'string',
                type: 'textField',
                defaultValue: ''
            },
            {
                id: 'apnDesc',
                title: 'APN Desc',
                dType: 'string',
                type: 'textField',
                defaultValue: '',
                maxLength: 20
            },
            {
                id: 'numberOfPlans',
                title: 'Number of Plans',
                dType: 'int',
                type: 'radioSelect',
                defaultValue: 4,
                args: {
                    options: [
                        {
                            text: '1',
                            value: 1
                        },
                        {
                            text: '2',
                            value: 2
                        },
                        {
                            text: '3',
                            value: 3
                        },
                        {
                            text: '4',
                            value: 4
                        }
                    ]
                },
                textField: 'text',
                valueField: 'value'
            },
            {
                id: 'employee',
                title: 'Employee',
                type: 'checkbox',
                dType: 'int',
                defaultValue: '0',
                isReadOnly: false
            },
            {
                id: 'employeeSpouse',
                title: 'Employee + Spouse',
                type: 'checkbox',
                dType: 'int',
                defaultValue: '0',
                isReadOnly: false
            },
            {
                id: 'family',
                title: 'Family',
                type: 'checkbox',
                dType: 'int',
                defaultValue: '0',
                isReadOnly: false
            },
            {
                id: 'employeeDependents',
                title: 'Employee + Dependents Only',
                type: 'checkbox',
                dType: 'int',
                defaultValue: '0',
                isReadOnly: false
            },
            {
                id: 'teladocAccess',
                title: 'Should Teladoc be included the list of covered services?',
                type: 'checkbox',
                dType: 'int',
                defaultValue: '0',
                isReadOnly: false
            },
            {
                id: 'deductibleWalved',
                title: 'Is the deductible waived after admission to the hospital after an ER visit for any of the plans included?',
                type: 'checkbox',
                dType: 'int',
                defaultValue: '0',
                isReadOnly: false
            }
        ],
        dataValue: {
            planYear: '',
            startDate: '',
            endDate: '',
            outputType: '',
            phoneNumber: '',
            languageServiceNumber: '',
            spanishServiceNumber: '',
            numberOfPlans: 4,
            employee: 1,
            employeeSpouse: 1,
            family: 1,
            employeeDependents: 1,
            teladocAccess: 1,
            deductibleWalved: 1,
            planName: {
                plan1: '',
                plan2: '',
                plan3: '',
                plan4: '',
                plan5: ''
            },
            rowData: [
                {
                    primary: 'Deductible',
                    secondary: 'Employee',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Deductible',
                    secondary: 'Employee + Spouse',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Deductible',
                    secondary: 'Employee + Dependents Only',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Deductible',
                    secondary: 'Family',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Out-Of-Pocket Maximum',
                    secondary: 'Employee',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Out-Of-Pocket Maximum',
                    secondary: 'Employee + Spouse',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Out-Of-Pocket Maximum',
                    secondary: 'Employee + Dependents Only',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Out-Of-Pocket Maximum',
                    secondary: 'Family',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Contribution to HSA',
                    secondary: 'Employee',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Contribution to HSA',
                    secondary: 'Employee + Spouse',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Contribution to HSA',
                    secondary: 'Employee + Dependents Only',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Contribution to HSA',
                    secondary: 'Family',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Preventive care',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Doctor Visit',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Specialist visit*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Teladoc® doctor access*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Walk-in clinic*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Urgent care*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Emergency room*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Hospital inpatient*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                },
                {
                    primary: 'Covered services',
                    secondary: 'Hospital outpatient*',
                    plan1: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan2: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan3: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan4: {
                        inNetwork: '',
                        outNetwork: ''
                    },
                    plan5: {
                        inNetwork: '',
                        outNetwork: ''
                    }
                }
            ],
            ...(dv || {})
        }
    };

    dispatch(setPlanComparison(data));
};

export const updatePlanComparison = actionCreator(actions.UPDATE_PLAN_COMPARISON, 'key', 'value');

export const updatePlanName = actionCreator(actions.UPDATE_PLAN_NAME, 'key', 'value');

export const updateTableData = actionCreator(actions.UPDATE_TABLE_DATA, 'data');

export const setDataValueForPlanComparison = actionCreator(actions.SET_DATA_VALUE, 'data');
