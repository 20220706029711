import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('PP_BATCH', {
    LOADING: null,
    SET_DOWNLOAD_LINK: null,
    SET_PP_ADDRESSES: null
});

export default actions;

const setPPAddresses = actionCreator(actions.SET_PP_ADDRESSES, 'data');
export const getPPAddresses = (pageNumber) => (dispatch) => {
    http.get(`SU/p7/pp-batch-addresses/${pageNumber}`)
        .then((data) => dispatch(setPPAddresses(data)));
};

const setDownloadLink = actionCreator(actions.SET_DOWNLOAD_LINK, 'data');
export const getDownloadLink = () => (dispatch) => {
    http.get('SU/p7/download-link')
        .then((data) => dispatch(setDownloadLink(data)));
};
