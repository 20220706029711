import {
    Dialog, DialogActions, DialogTitle
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { showMessage } from 'actions/app';
import SaveForLaterDialog from 'components/customize/SaveForLaterDialog';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from 'utils/history';
import http from 'utils/http';
import {
    getMarketSegmentXCostCenters, getPossibleDeliveryDate, refreshAddresses, resetInput,
    saveImInfo, setDomNextStepReady,

    setPickPackFile, setSavedId,
    setSavedName, setShippingMethod, setSubmitError,
    updateJobInfo, setSavedOrderNumber
} from './action';
import ImQuery from './ImQuery';
import JobInformationStep from './steps/JobInformationStep';
import ReviewStep from './steps/ReviewStep';
import SelectCustomerStep from './steps/SelectCustomerStep';
import SelectItemStep from './steps/SelectItemStep';
import SelectScreenStep from './steps/SelectScreenStep';
import ShippingAddressStep from './steps/ShippingAddressStep';
import BuildSummary from './steps/summary/BuildSummary';
import UploadPickPackModal from './steps/upload-pp';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    actionsContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    resetContainer: {
        padding: theme.spacing(3)
    }
}));

function getStepContent(stepName, setIsNextReady, isBackward, jumpTo) {
    switch (stepName) {
        case 'Select Customer':
            return <SelectCustomerStep setIsNextReady={setIsNextReady} />;
        case 'Shipping Address':
            return <ShippingAddressStep setIsNextReady={setIsNextReady} jumpTo={jumpTo} />;
        case 'Select Attributes':
            return <SelectScreenStep setIsNextReady={setIsNextReady} />;
        case 'Select Items':
            return (
                <SelectItemStep
                    isBackward={isBackward}
                    setIsNextReady={(v) => {
                        setIsNextReady(v);
                    }}
                />
            );
        case 'Job Information':
            return <JobInformationStep isBackward={isBackward} setIsNextReady={setIsNextReady} />;
        case 'Review & Submit':
            return <ReviewStep setIsNextReady={setIsNextReady} />;
        default:
            return 'Unknown step';
    }
}

const DirectOrder = (props) => {
    const { lob, jobType, subCode } = useParams();

    const {
        savedId, savedName, user, imInfo, saveImInfo, shippingMethodSettings, setShippingMethod, submitError, setSubmitError, pickPackFile, savedOrderNumber
    } = props;

    const [isNextReady, setIsNextReady] = useState(false);
    const [isBackward, setIsBackward] = useState(false);

    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openSaveTemplateDialog, setOpenSaveTemplateDialog] = useState(false);
    const [validatingAddress, setValidatingAddress] = useState(false);
    const [savingJob, setSavingJob] = useState(false);
    const [showUploadPickPack, setShowUploadPickPack] = useState(subCode === 'FEDS');

    const classes = useStyles();
    const {
        activeStep, setActiveStep, attachments, showMessage, steps,
        refreshAddresses, setSavedId, setSavedName, submitting, marketSegmentXCostCenters,
        setPickPackFile: savePickPackFile, setSavedOrderNumber
    } = props;

    const [showImQuery, setShowImQuery] = useState(false);
    useEffect(() => {
        setShowImQuery(lob === 'cml' && jobType === 'IM' && !imInfo);
    }, [lob, jobType, imInfo]);
    const saveImQuery = (data) => {
        saveImInfo(data);
        setShowImQuery(false);
    };
    const getJobOrderData = () => {
        let selected = props.jobItems;
        selected = selected
            ? (selected.some((j) => j.selected === true) ? selected.filter((j) => j.selected === true) : selected)
            : [];

        return jobType === 'FJE' ? {
            addresses: props.addresses,
            jobItems: selected,
            attachments,
            lob,
            jobType,
            shippingNotes: props.shippingNotes,
            upsShippingNotes: props.upsShippingNotes,
            savedId,
            marketSegment: props.screenData.marketSegment,
            language: props.screenData.language,
            product: props.screenData.product,
            state: props.screenData.state,
            model: props.screenData.model
        } : {
            customer: props.customer,
            addressCode: props.addressCode,
            addresses: props.addresses,
            jobItems: props.jobItems && selected,
            attachments,
            jobInfo: props.jobInfo,
            lob,
            jobType,
            shippingNotes: props.shippingNotes,
            upsShippingNotes: props.upsShippingNotes,
            savedId,
            marketSegment: subCode || props.screenData.marketSegment,
            language: props.screenData.language,
            product: props.screenData.product,
            state: props.screenData.state,
            model: props.screenData.model,
            addressFileIds: props.addressFileIds,
            imInfo,
            subCode,
            pickPackFile: props.pickPackFile
        };
    };

    const getStepIndex = (stepName) => {
        for (let index = 0; index < steps.length; index += 1) {
            if (stepName === steps[index]) {
                return index;
            }
        }

        return 0;
    };

    const handleNext = (stepName) => {
        setIsBackward(false);

        let loadingX = false;
        const isJump = typeof stepName === 'string';
        let nextStep = isJump
            ? getStepIndex(stepName)
            : activeStep + 1;
        if (steps[nextStep] === 'Select Items' && lob === 'cml' && jobType === 'AJEPP' && pickPackFile) {
            nextStep += 1;
        }

        const { addresses, marketSegmentXCostCenters: mscc, getMarketSegmentXCostCenters: loadX } = props;
        if (steps[nextStep] === 'Review & Submit' && addresses && addresses.length && !addresses[0].deliveryDate && jobType !== 'INVENTORY') {
            props.getPossibleDeliveryDate(lob, jobType);
        }

        if (steps[nextStep] === 'Select Attributes' && lob === 'cml' && mscc === null) {
            let ms = [];
            if (
                user && user.customFields
                && (user.customFields.userType !== 'su' && user.customFields.marketSegments && user.customFields.marketSegments.includes('FEDS'))
            ) {
                ms = ['FEDS'];
            }
            loadX(ms);
            loadingX = true;
        }
        if (lob === 'cml' && ['AJEPP', 'AJEKT', 'IM'].includes(jobType) && steps[activeStep] === 'Select Attributes') {
            const costCenterOptions = mscc[props.screenData.marketSegment];
            const info = { ...props.jobInfo, costCenterOptions }; // , costCenter: costCenterOptions[0]
            if (user.customFields.costCenters && user.customFields.costCenters.length > 0) {
                const costCenter = user.customFields.costCenters.find((cc) => costCenterOptions.some((o) => o.value === cc)) || '';
                info.costCenter = costCenter;
            }
            if (costCenterOptions.length === 1) {
                info.costCenter = costCenterOptions[0].value;
            }
            props.updateJobInfo(info);
        }

        if (steps[nextStep] === 'Select Attributes' && lob === 'cml' && mscc === null && !loadingX) {
            loadX();
        }

        if (steps[nextStep] === 'Job Information' && !props.jobInfo.shippingMethodId && !isJump && !pickPackFile) {
            const defaultShippingMethod = shippingMethodSettings.find((m) => m.lob === lob && m.jobType === jobType && m.isDefault);
            if (defaultShippingMethod) {
                props.updateJobInfo({
                    ...props.jobInfo,
                    shippingMethodId: defaultShippingMethod.shippingMethodId,
                    firstName: user.customFields.firstName || '',
                    middleName: user.customFields.middleName || '',
                    lastName: user.customFields.lastName || ''
                });
            } else {
                props.updateJobInfo({
                    ...props.jobInfo,
                    firstName: user.customFields.firstName || '',
                    middleName: user.customFields.middleName || '',
                    lastName: user.customFields.lastName || ''
                });
            }
        }
        if (steps[activeStep] === 'Job Information' && props.jobInfo.shippingMethodId && !isJump && !pickPackFile) {
            setShippingMethod(shippingMethodSettings.find((m) => m.lob === lob && m.jobType === jobType && m.shippingMethodId === props.jobInfo.shippingMethodId).shippingMethod);
        }
        if (steps[activeStep] === 'Job Information' && jobType === 'IM' && props.jobInfo.dateReq) {
            const selectedDate = new Date(props.jobInfo.dateReq);
            for (const a of addresses) {
                if (new Date(a.deliveryDate) < selectedDate) {
                    a.deliveryDate = props.jobInfo.dateReq;
                }
            }
            refreshAddresses(addresses);
        }

        if (activeStep === steps.length - 1) {
            const jobOrderData = getJobOrderData();
            props.submitJobOrder(jobOrderData);
        } else {
            setActiveStep(nextStep);
        }
    };

    const handleBack = () => {
        setIsBackward(true);
        setActiveStep(activeStep - 1);
        if (steps[activeStep - 1] === 'Select Attributes' && lob === 'cml') {
            const { marketSegmentXCostCenters: mscc, getMarketSegmentXCostCenters: loadX } = props;
            if (mscc === null) {
                loadX();
            }
        }
    };

    const handleReset = () => {
        setIsBackward(false);
        setActiveStep(0);
    };

    const handleEditOrder = () => {
        setIsBackward(true);
        setActiveStep(1);
    };

    const onSaveJobClicked = () => {
        setOpenSaveDialog(true);
    };

    const onSaveTemplateClicked = () => {
        setOpenSaveTemplateDialog(true);
    };

    const saveJobForLater = (savedJobName) => {
        setSavingJob(true);
        setOpenSaveDialog(false);
        const fd = new FormData();
        fd.append('name', savedJobName);
        fd.append('savedId', savedId || 0);
        const savedData = { jobData: { ...props.currentJob, activeStep, submitting: false }, hash: window.location.hash };
        fd.append('data', JSON.stringify(savedData));
        http.post('catalog/save-job-order', fd)
            .then((r) => {
                setSavedId(r.savedId);
                setSavedOrderNumber(r.orderNumber);
                setSavedName(savedJobName);
            })
            .finally(() => setSavingJob(false));
    };

    const saveTemplate = (savedJobName) => {
        setSavingJob(true);
        setOpenSaveTemplateDialog(false);
        const fd = new FormData();
        fd.append('name', savedJobName);
        fd.append('savedId', savedId || 0);
        const savedData = { jobData: { ...props.currentJob, activeStep, submitting: false }, hash: window.location.hash };
        fd.append('data', JSON.stringify(savedData));
        http.post('catalog/save-job-template', fd)
            .then((r) => {
                setSavedId(r.savedId);
                setSavedOrderNumber(r.orderNumber);
                setSavedName(savedJobName);
            })
            .finally(() => setSavingJob(false));
    };

    const getJobName = () => {
        switch (jobType) {
            case 'FJE':
                return 'Add Fast Job';
            case 'AJEPP':
                return 'Add Pick&Pack Job';
            case 'AJEKT':
                return 'Add KIT Job';
            case 'INVENTORY':
                return 'Add INVENTORY Job';
            default:
                return 'Add IM Job';
        }
    };

    const okToSave = (lob === 'cml' || (lob === 'p1b' && jobType !== 'FJE') || activeStep === steps.length - 1) && !pickPackFile;

    const { jobItems } = props;
    const selected = jobItems
        ? (jobItems.some((j) => j.selected === true) ? jobItems.filter((j) => j.selected === true) : jobItems)
        : [];
    const hasMissingItem = selected.some((s) => s.missing === true);

    // const nextDisabled = (user && user.customFields && user.customFields.orderDisabled && activeStep === steps.length - 1)
    //     || !isNextReady || submitting || (hasMissingItem && activeStep === steps.length - 1);
    // console.log('#ODS# ~ file: DirectOrder.js ~ line 308 ~ DirectOrder ~  (user && user.customFields && user.customFields.orderDisabled && activeStep === steps.length - 1) || !isNextReady || submitting || (hasMissingItem && activeStep === steps.length - 1)',
    //     user,
    //     (activeStep === steps.length - 1 ? 'true' : 'false'),
    //     !isNextReady,
    //     submitting,
    //     hasMissingItem,
    //     activeStep === steps.length - 1);

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h6">
                        {getJobName()}
                    </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {savedOrderNumber && (
                        <span>
                            Saved Order Number: <span style={{ color: '#7d3f98' }}><b>{savedOrderNumber}</b></span>
                        </span>
                    )}
                </Grid>

            </Grid>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => {
                    const SummaryComp = BuildSummary(label, index >= activeStep);
                    return (
                        <Step key={label}>
                            <StepLabel>
                                <Grid
                                    container
                                    alignItems="center"
                                >
                                    <Grid item xs={2}>
                                        {label}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <Box>
                                                <SummaryComp />
                                            </Box>
                                        </Suspense>
                                    </Grid>
                                </Grid>
                            </StepLabel>
                            <StepContent>
                                {getStepContent(steps[index], setIsNextReady, isBackward, handleNext)}
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        {lob === 'p1a' && jobType === 'AJEPP' && activeStep === 3 && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleEditOrder}
                                                className={classes.button}
                                            >
                                                Edit Order
                                            </Button>
                                        )}
                                        <Button
                                            disabled={
                                                (user && user.customFields && user.customFields.orderDisabled && activeStep === steps.length - 1)
                                                || !isNextReady || submitting || (hasMissingItem && activeStep === steps.length - 1)
                                            }
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Submit Job Order' : 'Next'}
                                        </Button>
                                        {okToSave && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={onSaveJobClicked}
                                                    className={classes.button}
                                                    disabled={savingJob}
                                                >
                                                    Save Job
                                                </Button>

                                                {openSaveDialog && (
                                                    <SaveForLaterDialog
                                                        open={openSaveDialog}
                                                        onClose={() => setOpenSaveDialog(false)}
                                                        initName={savedName || `${jobType} drafted ${new Date().toLocaleDateString()}`}
                                                        onSave={saveJobForLater}
                                                        savedId={savedId}
                                                    />
                                                )}

                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={onSaveTemplateClicked}
                                                    className={classes.button}
                                                    disabled={savingJob}
                                                >
                                                    Save Template
                                                </Button>

                                                {openSaveTemplateDialog && (
                                                    <SaveForLaterDialog
                                                        open={openSaveTemplateDialog}
                                                        onClose={() => setOpenSaveTemplateDialog(false)}
                                                        initName={savedName || `${jobType} drafted ${new Date().toLocaleDateString()}`}
                                                        onSave={saveTemplate}
                                                        savedId={savedId}
                                                        isTemplate={true}
                                                    />
                                                )}
                                            </>
                                        )}
                                        {validatingAddress && (
                                            <span>
                                                <CircularProgress size={12} color="primary" /> validating address...
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
            <Backdrop className={classes.backdrop} open={savingJob} onClick={() => setSavingJob(false)}>
                <CircularProgress color="inherit" /> Saving...
            </Backdrop>
            <ImQuery
                open={showImQuery}
                onClose={() => { }}
                onSave={saveImQuery}
            />

            {
                subCode === 'FEDS' && (
                    <UploadPickPackModal
                        open={!pickPackFile}
                        jobType={jobType}
                        subCode={subCode}
                        onClose={(a, data) => {
                            if (data && data.totalCount) {
                                savePickPackFile(data);
                            } else {
                                history.goBack();
                            }
                        }}
                    />
                )
            }

            <Dialog
                open={submitError != null}
                onClose={() => setSubmitError(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{submitError}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setSubmitError(null)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = ({
    appView: { user },
    directOrderView: {
        stepView: {
            isNextDomStepReady,
            customer, addresses, addressFileIds,
            totalAddressCount,
            screenData,
            jobItems,
            jobInfo,
            attachments,
            shippingNotes,
            upsShippingNotes,
            savedId,
            submitting,
            savedName,
            marketSegmentXCostCenters, imInfo,
            shippingMethodSettings,
            submitError,
            addressCode, pickPackFile,
            savedOrderNumber
        }
    }

}) => ({
    isNextDomStepReady,
    customer,

    addresses,
    addressFileIds,
    totalAddressCount,

    screenData,

    jobItems,
    jobInfo,
    attachments,
    shippingNotes,
    upsShippingNotes,
    savedId,
    submitting,
    savedName,
    marketSegmentXCostCenters,
    user,
    imInfo,
    shippingMethodSettings,
    submitError,
    addressCode,
    pickPackFile,
    savedOrderNumber
});
export default connect(mapStateToProps, {
    setDomNextStepReady,
    resetInput,
    getPossibleDeliveryDate,
    showMessage,
    refreshAddresses,
    setSavedId,
    setSavedName,
    getMarketSegmentXCostCenters,
    updateJobInfo,
    saveImInfo,
    setShippingMethod,
    setPickPackFile,
    setSubmitError,
    setSavedOrderNumber
})(DirectOrder);
