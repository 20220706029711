import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React from 'react';

const filter = createFilterOptions();

export default function UsStateSelect({
    value, onChange, size, label, states, disabled, disableCustom
}) {
    const stateCodes = states || ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
        'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
        'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const [currentValue, setCurrentValue] = React.useState(value);

    React.useEffect(() => {
        if (value !== currentValue) {
            setCurrentValue(value);
        }
        return () => {

        };
    }, [value, currentValue, setCurrentValue]);

    const checkLength = (s) => {
        const maxLength = 30;
        if (s.length > maxLength) {
            return s.substring(0, maxLength);
        }
        return s;
    };

    return (

        <Autocomplete
            fullWidth
            size={size || 'small'}
            value={currentValue}
            disabled={disabled}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    newValue = checkLength(newValue);
                    setCurrentValue({
                        title: newValue
                    });
                    onChange({ target: { name: 'state', value: newValue } });
                } else if (newValue && newValue.inputValue) {
                    newValue.inputValue = checkLength(newValue.inputValue);
                    setCurrentValue({
                        title: newValue.inputValue
                    });
                    onChange({ target: { name: 'state', value: newValue.inputValue } });
                } else {
                    setCurrentValue(newValue);
                    onChange({ target: { name: 'state', value: newValue } });
                }
            }}
            onBlur={
                (e, v) => {
                    if (e.target.value) {
                        const uv = e.target.value.toUpperCase();
                        if (stateCodes.includes(uv)) {
                            setCurrentValue(uv);
                            onChange({ target: { name: 'state', value: uv } });
                        }
                    }
                }
            }
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '' && !disableCustom) {
                    params.inputValue = checkLength(params.inputValue);
                    filtered.push({
                        inputValue: params.inputValue,
                        title: `Use "${params.inputValue}"`
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={stateCodes}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.title;
            }}
            renderOption={(option) => option.title || option}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={label || 'State/Province'} variant="outlined" />
            )}
        />
    );
}
