import {
    Button, Dialog, DialogActions,
    DialogContent, DialogTitle, Grid, TextField, Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import history from 'utils/history';

export default function ImQuery({
    open, onClose, onSave
}) {
    const [underwriter, setUnderwriter] = useState('');
    const [accountManager, setAccountManager] = useState('');
    const [initials, setInitials] = useState('');
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">In order to continue, please complete all of the following. By continuing, you are confirming that you have obtained all necessary ECS approvals before submitting this job.</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography component="h6">
                            Name of underwriter approver:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={underwriter}
                            autoFocus
                            onChange={(e) => setUnderwriter(e.target.value)}
                            onKeyPress={(e) => { if (e.key === 'Enter' && (initials && accountManager && underwriter)) { onSave({ initials, accountManager, underwriter }); } }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography component="h6">
                            Name of account manager approver:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={accountManager}
                            onChange={(e) => setAccountManager(e.target.value)}
                            onKeyPress={(e) => { if (e.key === 'Enter' && (initials && accountManager && underwriter)) { onSave({ initials, accountManager, underwriter }); } }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography component="h6">
                            Your initials indicating you have obtained ECS approval:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={initials}
                            onChange={(e) => setInitials(e.target.value)}
                            onKeyPress={(e) => { if (e.key === 'Enter' && (initials && accountManager && underwriter)) { onSave({ initials, accountManager, underwriter }); } }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onSave({ initials, accountManager, underwriter })}
                    disabled={!initials || !accountManager || !underwriter}
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
