import { showMessage } from 'actions/app';
import actionCreator from '../../utils/actionCreator';
import history from '../../utils/history';
import http from '../../utils/http';
import keyMirror from '../../utils/keyMirror';

export const actions = keyMirror('DOM_VIEW', {
    SET_NEXT_DOM_STEP_READY: null,

    SET_UPLOADING: null,
    SET_CUSTOMERS: null,
    SELECT_CUSTOMER: null,

    SET_ADDRESS_FIELD: null,
    SET_FOUND_ADDRESSES: null,
    ADD_UPLOAD_ADDRESSES: null,
    REMOVE_ALL_UPLOADED_ADDRESS: null,
    SET_SHIPPING_ADDRESS: null,
    ADD_TO_ADDRESSES: null,
    UPDATE_ADDRESS_QTY: null,
    REMOVE_ADDRESS: null,
    REFRESH_ADDRESSES: null,
    SET_COPY_ORDER_DATA: null,
    UPDATE_ADDRESS_DELIVERYPOINT: null,

    SET_SCREEN_FIELD: null,
    SET_PICK_PACK_FILE: null,

    SET_MARKET_SEGMENT: null,
    SET_COST_CENTER: null,
    SET_COST_CENTERS: null,
    SET_LANGUAGE: null,
    SET_PRODUCT: null,
    SET_STATE_MODEL: null,
    SET_PRODUCTS: null,
    SET_STATE_MODELS: null,

    SET_KIT_ITEMS: null,
    SET_SHIPPING_METHOD: null,
    SET_SHIPPING_METHOD_SETTINGS: null,

    GET_KIT_ITEMS_SUCCESS: null,
    FIND_ITEMS_SUCCESS: null,
    APPEND_ITEMS: null,
    UPDATE_ITEMS: null,

    UPDATE_JOB_INFO: null,
    RESET_INPUT: null,
    SET_VALIDATE: null,

    SET_DELIVERY_DATE: null,
    ADD_ATTACHMENT: null,
    REMOVE_ATTACHMENT: null,
    APPROVE_ATTACHMENT: null,
    SET_ATTACHMENT_QUANTITY: null,
    SET_ATTACHMENT_FIELD: null,
    SET_SHIPPING_NOTES: null,
    SET_UPS_SHIPPING_NOTES: null,
    SET_ATTACHMENT_SETTINGS: null,

    GENERATING_FORM_PREVIEW: null,
    SET_ATTACHMENT_ERROR: null,
    SET_FORM_PROOF_PREVIEW_OPEN: false,
    SET_FORM_PROOF_PREVIEW: null,
    SET_ITEM_TYPES: null,
    SET_P1B_ITEMS: null,
    UPDATE_JOB_ITEM: null,
    SET_SAVED_ID: null,
    SET_WHS: null,
    SET_SUBMITTING: null,
    SET_SUBMIT_ERROR: null,
    SET_IM_INFO: null,
    SET_SPMLOADED: null,
    SET_MARKET_SEGMENT_COST_CENTERS: null,
    SET_LOADING_ADDRESS_PAGE: null,
    SET_ADDRESS_PAGINATION: null,
    SET_INVENTORY_ADDRESS: null,
    SET_SAVED_NAME: null,
    SET_SAVED_ORDERNO: null,
    SET_COUNTIES: null
});

export default actions;

export const setUploading = actionCreator(actions.SET_UPLOADING, 'uploading');
export const setSubmitError = actionCreator(actions.SET_SUBMIT_ERROR, 'submitError');
export const setSavedId = actionCreator(actions.SET_SAVED_ID, 'savedId');
export const setSavedOrderNumber = actionCreator(actions.SET_SAVED_ORDERNO, 'savedOrderNumber');
export const saveImInfo = actionCreator(actions.SET_IM_INFO, 'imInfo');
export const setMarketSegmentXCostCenters = actionCreator(actions.SET_MARKET_SEGMENT_COST_CENTERS, 'marketSegmentXCostCenters');
export const getMarketSegmentXCostCenters = (marketSegments = []) => (dispatch) => {
    const fd = new FormData();
    fd.append('marketSegments', marketSegments.join(','));
    http.post('direct-order/market-segments-cost-centers', fd)
        .then((x) => {
            const u = {};
            for (const k in x) {
                u[k.toUpperCase()] = x[k];
            }

            dispatch(setMarketSegmentXCostCenters(u));
        });
};
export const setShippingMethodSettings = actionCreator(actions.SET_SHIPPING_METHOD_SETTINGS, 'shippingMethodSettings');
export const getShippingMethodSettings = (lob) => (dispatch) => {
    http.get(`direct-order/shipping-methods/${lob}`)
        .then((s) => dispatch(setShippingMethodSettings(s)));
};
export const setShippingMethod = actionCreator(actions.SET_SHIPPING_METHOD, 'shippingMethod');
export const getShippingMethod = (lob, jobType) => (dispatch) => {
    http.get(`store/get-shipping-method/${lob}/${jobType}`)
        .then((s) => dispatch(setShippingMethod(s)));
};
export const setSavedName = actionCreator(actions.SET_SAVED_NAME, 'savedName');

export const resetInput = actionCreator(actions.RESET_INPUT, 'jobData');
export const setValidate = actionCreator(actions.SET_VALIDATE, 'name', 'value');

const setCustomers = actionCreator(actions.SET_CUSTOMERS, 'customers');

export const searchCustomer = (criteria) => (dispatch) => {
    http.post('store/search-customer', criteria)
        .then((customers) => dispatch(setCustomers(customers)));
};

export const selectCustomer = actionCreator(actions.SELECT_CUSTOMER, 'customer');
export const setDomNextStepReady = actionCreator(actions.SET_NEXT_DOM_STEP_READY, 'isReady');

export const setKitItems = actionCreator(actions.SET_KIT_ITEMS, 'items');
export const setSubmitting = actionCreator(actions.SET_SUBMITTING, 'submitting');
export const setSPMLoaded = actionCreator(actions.SET_SPMLOADED, 'spmLoaded');
export const getKitItems = (defaultToOptional) => (dispatch, getState) => {
    const currentState = getState();
    const formData = new FormData();
    formData.append('marketSegment', currentState.directOrderView.stepView.screenData.marketSegment);
    formData.append('language', currentState.directOrderView.stepView.screenData.language);
    formData.append('product', currentState.directOrderView.stepView.screenData.product);
    formData.append('state', currentState.directOrderView.stepView.screenData.state);
    formData.append('model', currentState.directOrderView.stepView.screenData.model);
    http.post('store/get-commercial-kit-items', formData)
        .then((found) => {
            for (let i = 0; i < found.length; i += 1) {
                const item = found[i];
                if (defaultToOptional) {
                    item.requiredCode = 'N';
                    item.selected = false;
                } else {
                    item.selected = item.requiredCode !== 'N';
                }
                item.sequence = i;
                item.local = true;
            }
            dispatch(setKitItems(found));
            dispatch(setSPMLoaded(true));
        });
};
export const setP1BItems = actionCreator(actions.SET_P1B_ITEMS, 'id', 'items');

export const getP1BItems = (id) => (dispatch, getState) => {
    const currentState = getState();
    const formData = new FormData();
    formData.append('marketSegment', currentState.directOrderView.stepView.screenData.marketSegment);
    formData.append('language', currentState.directOrderView.stepView.screenData.language);
    formData.append('product', currentState.directOrderView.stepView.screenData.product);
    formData.append('state', currentState.directOrderView.stepView.screenData.state);
    formData.append('model', currentState.directOrderView.stepView.screenData.model);
    http.post(`direct-order/get-p1b-kits-items/${id}`)
        .then((ii) => {
            dispatch(setP1BItems(id, ii));
        });
};

export const appendItems = actionCreator(actions.APPEND_ITEMS, 'items');

export const updateItems = actionCreator(actions.UPDATE_ITEMS, 'items');

export const setCostCenterField = actionCreator(actions.SET_COST_CENTER_FIELD, 'name', 'value');
export const setMarketSegment = actionCreator(actions.SET_MARKET_SEGMENT, 'marketSegment');
export const setCostCenter = actionCreator(actions.SET_COST_CENTER, 'costCenter');
export const setLanguage = actionCreator(actions.SET_LANGUAGE, 'language');
export const setProduct = actionCreator(actions.SET_PRODUCT, 'product');
export const setStateModel = actionCreator(actions.SET_STATE_MODEL, 'stateModel');

const setProducts = actionCreator(actions.SET_PRODUCTS, 'products');
export const getProducts = (marketSegment) => (dispatch) => {
    http.get(`store/get-products/${marketSegment}`)
        .then((products) => dispatch(setProducts(products)));
};

const setInventoryAddress = actionCreator(actions.SET_INVENTORY_ADDRESS, 'addresses');
export const getInventoryAddress = () => (dispatch) => {
    http.get(`direct-order/get-inventory-addresses`)
        .then((a) => dispatch(setInventoryAddress(a)));
};

const setCostCenters = actionCreator(actions.SET_COST_CENTERS, 'costCenters');
export const getCostCenters = (marketSegment) => (dispatch) => {
    http.get(`store/get-cost-centers/${marketSegment}`)
        .then((costCenters) => dispatch(setCostCenters(costCenters)));
};

const setStateModels = actionCreator(actions.SET_STATE_MODELS, 'stateModels');
export const getStateModels = (marketSegment, language, product) => (dispatch) => {
    const formData = new FormData();
    formData.append('marketSegment', marketSegment);
    formData.append('language', language);
    formData.append('product', product);
    http.post('store/get-state-models', formData)
        .then((stateModels) => dispatch(setStateModels(stateModels)));
};
const setAttachmentSettings = actionCreator(actions.SET_ATTACHMENT_SETTINGS, 'attachmentSettings');
export const loadAttachmentSettings = () => (dispatch) => {
    http.get('store/attachment-settings')
        .then((settings) => dispatch(setAttachmentSettings(settings)));
};

export const updateJobInfo = actionCreator(actions.UPDATE_JOB_INFO, 'jobInfo');

export const submitJobOrder = (jobOrderData) => (dispatch, getState) => {
    dispatch(setSubmitting(true));
    const current = getState().directOrderView.stepView;
    http.post('store/submit-job-order', jobOrderData)
        .then((result) => {
            if (result.count) {
                history.push(`/orders/${result.orderNo}`);
            } else if (result.orderId) {
                history.push(`/order/${result.orderId}`);
            } else {
                dispatch(setDeliveryDate(result.deliveryDates));
                dispatch(setSubmitError(result.message));
            }
        })
        .finally(() => {
            dispatch(setSubmitting(false));
        });
};

// ////////////////////
// address
// ////////////////////

export const addToAddresses = actionCreator(actions.ADD_TO_ADDRESSES, 'singleOnly', 'selected');
export const setScreenField = actionCreator(actions.SET_SCREEN_FIELD, 'name', 'value');
export const setAddressField = actionCreator(actions.SET_ADDRESS_FIELD, 'name', 'value');
export const setPickPackFile = actionCreator(actions.SET_PICK_PACK_FILE, 'data');

export const setShippingAddress = actionCreator(actions.SET_SHIPPING_ADDRESS, 'selectedAddress', 'singleOnly');

export const setCopyOrderData = actionCreator(actions.SET_COPY_ORDER_DATA, 'copyOrderData');
export const addUploadedAddresses = actionCreator(actions.ADD_UPLOAD_ADDRESSES, 'addressData');
export const setAddressPagination = actionCreator(actions.SET_ADDRESS_PAGINATION, 'addressPagination');
export const uploadUserAddressFile = (fileData, lob, jobType) => (dispatch) => {
    const fd = new FormData();
    fd.append('fileData', fileData);
    dispatch(setUploading(true));
    return http.post(`direct-order/upload-address/${lob}/${jobType}`, fd)
        .then((addressData) => {
            if (addressData.message) {
                dispatch(showMessage(addressData.message, 'error'));
            } else {
                if (addressData.code) {
                    dispatch(loadAddressesByPage(1, addressData.code));
                }
                dispatch(addUploadedAddresses(addressData));
            }
        }).finally(() => dispatch(setUploading(false)));
};

export const removeAllAddress = actionCreator(actions.REMOVE_ALL_UPLOADED_ADDRESS);

const setLoadingAddressPage = actionCreator(actions.SET_LOADING_ADDRESS_PAGE, 'loading');
export const loadAddressesByPage = (pageNumber, code, thenValidate, lob, jobType) => (dispatch) => {
    dispatch(setLoadingAddressPage(true));
    return http.get(`direct-order/load-addresses/${pageNumber}/${code}`)
        .then((pagination) => {
            dispatch(setAddressPagination(pagination));
            if (thenValidate) {
                dispatch(getPossibleDeliveryDate(lob, jobType));
            }
        }).finally(() => dispatch(setLoadingAddressPage(false)));
};

export const refreshAddresses = actionCreator(actions.REFRESH_ADDRESSES, 'addresses');

export const removeAttachment = actionCreator(actions.REMOVE_ATTACHMENT, 'id');

export const setUpsShippingNotes = actionCreator(actions.SET_UPS_SHIPPING_NOTES, 'upsShippingNotes');
export const setShippingNotes = actionCreator(actions.SET_SHIPPING_NOTES, 'shippingNotes');
export const approveAttachment = actionCreator(actions.APPROVE_ATTACHMENT, 'preview');
export const setAttachmentQty = actionCreator(actions.SET_ATTACHMENT_QUANTITY, 'id', 'quantity');
export const setAttachmentField = actionCreator(actions.SET_ATTACHMENT_FIELD, 'id', 'fieldName', 'fieldValue');
export const addAttachment = actionCreator(actions.ADD_ATTACHMENT, 'attachment');
export const setAttachmentError = actionCreator(actions.SET_ATTACHMENT_ERROR, 'attachmentError');
export const uploadAttachment = (fileName, fileData, itemType, skipValidation = false) => (dispatch) => {
    const fd = new FormData();
    fd.append('fileName', fileName);
    fd.append('fileData', fileData);
    fd.append('skipValidation', skipValidation);
    dispatch(setUploading(true));
    return http.post('store/upload-item-file', fd)
        .then((attachment) => {
            if (attachment.displayMessage) {
                dispatch(setAttachmentError(attachment.displayMessage));
            } else {
                dispatch(addAttachment({ ...attachment, itemType }));
            }
        }).finally(() => dispatch(setUploading(false)));
};

export const removeUploadedAddress = (id) => (dispatch, getState) => {
    const currentState = getState();
    return http.post('store/remove-uploaded-address/' + id)
        .then(() => {
            const ap = currentState.directOrderView.stepView.addressPagination;
            ap.results = ap.results.filter(r => r.id !== id);
            ap.totalCount -= 1;
            dispatch(setAddressPagination(ap));
        }).finally(() => dispatch(setUploading(false)));
};

export const updateAddressQty = actionCreator(actions.UPDATE_ADDRESS_QTY, 'index', 'qty');
export const removeAddressAt = actionCreator(actions.REMOVE_ADDRESS, 'index');


export const setDeliveryDate = actionCreator(actions.SET_DELIVERY_DATE, 'deliveryDate', 'jobType');
export const getPossibleDeliveryDate = (lob, jobType) => (dispatch, getState) => {
    const currentState = getState();
    const {
        directOrderView: {
            stepView: {
                jobItems, addresses, attachments, jobInfo, addressCode, addressPagination
            }
        }
    } = currentState;
    const selected = jobItems
        ? (jobItems.some((j) => j.selected === true) ? jobItems.filter((j) => j.selected === true) : jobItems)
        : null;
    if (!addressCode || addressPagination) {
        http.post('store/get-possible-delivery-date', {
            lob,
            jobType,
            addresses: addressCode ? addressPagination.results : addresses,
            jobItems: selected,
            attachments,
            shippingMethodId: jobInfo.shippingMethodId,
            addressCount: addressCode ? addressPagination.totalCount : addresses.length
        })
            .then((deliveryDate) => {
                dispatch(setDeliveryDate(deliveryDate, jobType));
            }).catch(err => {
                console.log('#ODS# ~ file: action.js ~ line 336 ~ .then ~ err', err);
            });
    }
};

const setItemTypes = actionCreator(actions.SET_ITEM_TYPES, 'itemTypes');
export const getItemTypes = (lob, kitOnly = false) => (dispatch) => {
    const api = kitOnly ? `direct-order/kit-types/${lob}` : `direct-order/item-types/${lob}`;
    http.get(api)
        .then((itemTypes) => {
            dispatch(setItemTypes(itemTypes));
        });
};
const setWHS = actionCreator(actions.SET_WHS, 'whs');
export const getWHS = (lob) => (dispatch) => {
    http.get(`direct-order/whs/${lob}`)
        .then((whs) => {
            dispatch(setWHS(whs));
        });
};

export const updateJobItem = actionCreator(actions.UPDATE_JOB_ITEM, 'jobItem');

export const validateJobItems = (lob, jobItems) => (dispatch, getState) => {
    const currentState = getState();
    const {
        directOrderView: { stepView: { jobItems: items } }
    } = currentState;
    http.post(`direct-order/validate-job-items/${lob}`, jobItems)
        .then((result) => {
            if (result && result.length > 0) {
                for (const item of result) {
                    const missingItem = items.find((i) => i.code === item.code && i.version === item.version && i.whs === item.whs);
                    missingItem.missing = true;
                }
                dispatch(setKitItems([...items]));
            }

            if (lob === 'cml') {
                http.post('direct-order/latest-cml-inventory-qty', jobItems)
                    .then((qtyResult) => {
                        if (qtyResult && qtyResult.length > 0) {
                            const s = getState();
                            const {
                                directOrderView: { stepView: { jobItems: cmlItems } }
                            } = s;

                            const sss = JSON.stringify(cmlItems);
                            console.log('#ODS# ~ file: action.js ~ line 385 ~ .then ~ cmlItems', sss);

                            for (const item of cmlItems) {
                                const qtyItem = qtyResult.find((i) => i.code === item.code && i.version === item.version && i.whs === item.whs);

                                if (qtyItem) {
                                    item.inventoryQuantity = qtyItem.qty;
                                } else {
                                    delete item.inventoryQuantity;
                                }
                            }

                            console.log('#ODS# ~ file: action.js ~ line 385 ~ .then ~ cmlItems', cmlItems);
                            dispatch(setKitItems([...items]));
                        }

                    });

            }

        });

};

export const updateAddressDeliveryPoint = actionCreator(actions.UPDATE_ADDRESS_DELIVERYPOINT, 'deliveryPoint');

export const getAJEPPItemTypes = (lob) => (dispatch) => {
    http.get(`direct-order/ajepp-item-types/${lob}`)
        .then((itemTypes) => {
            dispatch(setItemTypes(itemTypes));
        });
};

export const getInventoryItemTypes = (lob) => (dispatch) => {
    http.get(`direct-order/inventory-item-types/${lob}`)
        .then((itemTypes) => {
            dispatch(setItemTypes(itemTypes));
        });
};

const setCounties = actionCreator(actions.SET_COUNTIES, 'counties');
export const getCounties = (itemState) => (dispatch) => {
    http.get(`direct-order/counties/${itemState}`)
        .then((counties) => {
            dispatch(setCounties(counties));
        });
};
