import {
    Button,
    Checkbox, Chip,
    CircularProgress,
    FormControl, Grid,
    InputLabel, MenuItem, Paper,
    Select, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Tooltip,
    Link
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import http from 'utils/http';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import { setKitItems } from '../../action';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '90%',
        minHeight: 320,
        maxHeight: 900,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    // table: {
    //     '& td.MuiTableCell-sizeSmall': {
    //         padding: '6px'

    //     }
    // },
    smallChip: {
        height: '1rem',
        marginLeft: '.25rem',
        fontSize: '.75rem'
    }
}));

const stateCodes = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

const languages = ['English', 'Spanish', 'Chinese'];

const additionalKitItemType = 'TL';

const FindAJEPPItemModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const [whs, setWHS] = useState('');
    const [itemType, setItemType] = useState('');
    const [itemTypeName, setItemTypeName] = useState('');
    const [foundResult, setFoundResult] = useState(null);
    const [modalStyle] = React.useState(getModalStyle);
    const [itemState, setItemState] = useState('');
    const [itemCounty, setItemCounty] = useState('');
    const [itemLanguage, setItemLanguage] = useState('English');
    const {
        open, handleClose, needQty, jobItems, customer, orderingOnHoldItem, userType, whs: whsItems,
        marketSegment, itemTypes, getCounties, counties, allowDownload, user, confirmDownload
    } = props;

    const { lob: lobQuery, jobType: jobTypeQuery } = useParams();
    let { lob, jobType } = props;
    lob = lob || lobQuery;
    jobType = jobType || jobTypeQuery;
    const singleKitOnly = lobQuery === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM'); // 1 kit only
    const allowMultipleSingle = (userType === 'fsr' || userType === 'su')
        && (lob === 'p1b' && (jobType === 'AJEKT' || jobType === 'IM'));

    const keepSingleKit = lobQuery === 'p1b' && jobType === 'AJEPP'; // 1 kit + x items

    let onlyWHS = props.onlyWhs || '';
    if (lob === 'cml' // && (jobType === 'AJEKT' || jobType === 'IM')
        && ((jobItems && jobItems.length > 0) || selected.length > 0)) {
        const w = (jobItems || []).find((j) => !!j.whs);
        if (w) {
            onlyWHS = w.whs;
        } else if (selected && foundResult && foundResult.results) {
            const s = foundResult.results.find((f) => selected.includes(f.cvw));
            if (s) {
                onlyWHS = s.whs;
            }
        }
    }

    const clearSearch = () => {
        setItemCode('');
        setItemName('');
        setItemType('');
        setItemTypeName('');
        setItemState('');
        setItemCounty('');
        setItemLanguage('English');
    }

    useEffect(() => {
        setItemCounty('');
        if (itemState !== '') {
            getCounties(itemState);
            // alert(`new state: ${itemState}`);
        }
    }, [itemState, getCounties]);

    const classes = useStyles();

    const buildDownloadLink = (j) => {
        return `${process.env.REACT_APP_SERVER_URL}/download/p1a/${(j.isKit ? 'kit' : 'item')}/${j.id}?Authorization=${user.token}`.replace('/api/', '/file/');
    };

    const handleSearch = async (p = 1) => {
        if (!open) return;
        setLoading(true);
        setSelected([]);
        const formData = new FormData();
        formData.append('lob', lob);
        formData.append('jobType', jobType);
        formData.append('itemCode', itemCode);
        formData.append('itemName', itemName);
        if (itemType && ((itemType === additionalKitItemType || itemTypes.some((t) => t === itemType || t.code === itemType)) || jobType === 'OEJ')) {
            formData.append('itemType', itemType);
        }
        formData.append('userType', userType);
        formData.append('pageNumber', p);
        formData.append('marketSegment', marketSegment);
        formData.append('whs', onlyWHS || whs);
        formData.append('state', props.onlyState || props.state);
        formData.append('orderingOnHoldItem', orderingOnHoldItem ? 'Y' : 'N');

        if (lobQuery === 'p1b') {
            if (customer) formData.append('sponsor', customer.customerName);
            if ((jobType === 'AJEKT' || jobType === 'IM') && itemType !== additionalKitItemType) {
                formData.append('kitOnly', 'yes');
            }
            if ((jobType === 'AJEKT' || jobType === 'IM') && !itemType && (jobItems && jobItems.some((r) => r.kitTypeName === 'STANDARD') && jobItems.some((r) => r.kitTypeName === 'SILVERSCRIPT'))) {
                const its = itemTypes.map((t) => t.code || t);
                its.push(additionalKitItemType);
                formData.append('itemTypes', its.join(','));
                formData.delete('kitOnly');
            }
        }
        if (lobQuery === 'p1a' && jobType === 'AJEPP') {
            formData.append('itemState', itemState);
            formData.append('itemCounty', itemCounty);
            formData.append('itemLanguage', itemLanguage);
        }

        const found = await http.post('direct-order/find-items', formData);

        if (jobType !== 'AJEPP' && lob === 'cml') { // qty limit only works in cml/pp (at least for now)
            for (const item of found.results) {
                item.qtyLimit = null;
            }
        }

        if (props.p1afsrLimit && lobQuery === 'p1a' && userType === 'fsr') {
            for (const item of found.results) {
                let limit = props.p1afsrLimit.others;
                if (item.isKit) {
                    limit = props.p1afsrLimit.kits;
                } else if (props.p1afsrLimit[item.type.toLowerCase()]) {
                    limit = props.p1afsrLimit[item.type.toLowerCase()];
                }
                item.max = limit;
            }
        }
        if (orderingOnHoldItem) {
            for (const item of found.results) {
                item.held = true;
            }
        }

        setFoundResult(found);
        setLoading(false);
    };

    const handleAdd = (close) => {
        const items = foundResult.results
            .filter((i) => selected.includes(i.cvw)
                && (!jobItems || !jobItems.some((ij) => ij.cvw === i.cvw)));

        console.log('#ODS# ~ file: SelectAJEPPItemModal.js ~ line 205 ~ handleAdd ~ items: ', items);
        for (const fi of items) {
            fi.selected = true;
            if (needQty) fi.quantity = 1;

            if (fi.relatedItems && fi.relatedItems.length > 0) {
                for (const item of fi.relatedItems) {
                    item.selected = false;
                }
            }
        }
        if (singleKitOnly) {
            let uniqItems = items;
            if (allowMultipleSingle && jobItems && jobItems.length) {
                const prevSelected = jobItems.filter((j) => j.selected === true);
                const whole = [...prevSelected, ...items];
                const singleCVWs = getUniqKitTypeItems(whole.map((w) => w.cvw), whole);
                uniqItems = whole.filter((w) => singleCVWs.includes(w.cvw));
            }
            for (let i = 0; i < uniqItems.length; i += 1) {
                uniqItems[i].sequence = i;
            }

            props.setKitItems(uniqItems);

            if (close) {
                clearSearch();
                setFoundResult(null);
                setSelected([]);
                handleClose();
            }
            return;
        }

        if (jobItems && jobItems.length) {
            let lastSelectedIndex = 0;
            for (let i = 0; i < jobItems.length; i += 1) {
                if (jobItems[i].selected) { lastSelectedIndex = i; } else { break; }
            }
            jobItems.splice(lastSelectedIndex + 1, 0, ...items);

            let newItems = [...jobItems];
            if (keepSingleKit) {
                const firstK = newItems.find((k) => k.isKit);
                if (firstK) {
                    newItems = newItems.filter((n) => !n.isKit || n.cvw === firstK.cvw);
                }
            }

            if (jobType === 'FJE' && props.msoFJEJobItemLimit
                && newItems.length > props.msoFJEJobItemLimit) {
                newItems = newItems.slice(0, props.msoFJEJobItemLimit);
            }

            newItems = sortItems(newItems);

            props.setKitItems(newItems);
        } else {
            for (let i = 0; i < items.length; i += 1) {
                items[i].sequence = i;
            }

            let newItems = [...items];
            if (keepSingleKit) {
                const firstK = newItems.find((k) => k.isKit);
                if (firstK) {
                    newItems = newItems.filter((n) => !n.isKit || n.cvw === firstK.cvw);
                }
            }

            if (jobType === 'FJE' && props.msoFJEJobItemLimit
                && newItems.length > props.msoFJEJobItemLimit) {
                newItems = newItems.slice(0, props.msoFJEJobItemLimit);
            }

            newItems = sortItems(newItems);

            props.setKitItems(newItems);
        }

        if (close) {
            clearSearch();
            setFoundResult(null);
            setSelected([]);
            handleClose();
        }
    };

    const sortItems = (items) => {
        const sortedItems = items.sort((a, b) => {
            if (a.isKit > b.isKit) {
                return 1;
            } else if (a.isKit < b.isKit) {
                return -1;
            } else {
                if (a.type > b.type) {
                    return 1;
                } else if (a.type < b.type) {
                    return -1;
                } else {
                    return 0;
                }
            }
        });
        for (let i = 0; i < sortedItems.length; i += 1) {
            sortedItems[i].sequence = i;
        }

        return sortedItems;
    };

    const getUniqKitTypeItems = (newSelected, items = null) => {
        let uniq = ['STANDARD', 'SILVERSCRIPT', additionalKitItemType];
        const selectedItems = items || foundResult.results.filter((r) => newSelected.includes(r.cvw));

        const otherTypeItem = selectedItems.find((i) => !uniq.includes(i.kitTypeName || i.type));
        if (otherTypeItem) {
            return [otherTypeItem.cvw];
        }

        const values = [];
        for (let index = newSelected.length - 1; index >= 0; index--) {
            const tid = newSelected[index];
            const item = selectedItems.find((r) => r.cvw === tid);
            if (uniq.includes(item.kitTypeName || item.type)) {
                values.push(item.cvw);
                uniq = uniq.filter((u) => u !== (item.kitTypeName || item.type));
                if (!uniq.length) {
                    break;
                }
            }
        }

        return values;
    };

    const showTLMenu = jobItems && jobItems.some((r) => r.kitTypeName === 'STANDARD') && jobItems.some((r) => r.kitTypeName === 'SILVERSCRIPT');

    const handleToggleCheck = (cvw) => {
        if (selected.includes(cvw)) {
            const rest = selected.filter((s) => s !== cvw);
            setSelected([...rest]);
        } else if (singleKitOnly) {
            if (allowMultipleSingle && selected.length > 0
            ) {
                const selections = getUniqKitTypeItems([...selected, cvw]);
                setSelected(selections);
            } else {
                setSelected([cvw]);
            }
        } else {
            selected.push(cvw);
            setSelected([...selected]);
        }
    };

    let itemTypeOptions;
    if (itemTypes && itemTypes.length) {
        itemTypeOptions = itemTypes;
    } else {
        itemTypeOptions = [{ code: 'BS', name: 'Benefit Summary' }, { code: 'EF', name: 'Enrollment Form' }];
    }

    let maxItemCountReached = false;
    const { msoFJEJobItemLimit } = props;
    if (jobType === 'FJE' && msoFJEJobItemLimit && ((jobItems || []).length + selected.length) === msoFJEJobItemLimit) {
        maxItemCountReached = true;
    }

    const handleCancel = () => {
        clearSearch();
        setFoundResult(null);
        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={() => handleCancel()}
            disableBackdropClick
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <FormControl style={{ width: '150px' }}>
                            <InputLabel id="item-type-label">Item Type</InputLabel>
                            <Select
                                labelId="item-type-label"
                                id="item-type"
                                value={itemType}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setItemType(e.target.value);
                                        setItemTypeName('');
                                    } else {
                                        let name = itemTypeOptions.find(x => x.code === e.target.value).name;
                                        if (name.startsWith('PDP') || name === 'Member Forms') {
                                            setItemState('');
                                            // setItemCounty('');
                                        }
                                        setItemType(e.target.value);
                                        setItemTypeName(name);
                                    }                                    
                                }}
                                fullWidth
                                label="Item Type"
                            >
                                <MenuItem value="">Select</MenuItem>
                                {itemTypeOptions.map((t) => <MenuItem key={t.code || t} value={t.code || t}>{t.name || t}</MenuItem>)}
                                {
                                    itemTypeOptions.every((o) => o !== additionalKitItemType) && showTLMenu && <MenuItem value={additionalKitItemType}>{additionalKitItemType}</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl style={{ width: '150px', paddingRight: '5px' }}>
                            <InputLabel id="state-label">State</InputLabel>
                            <Select
                                labelId="state-label"
                                value={itemState}
                                onChange={(e) => {
                                    // setItemCounty('');
                                    setItemState(e.target.value);
                                }}
                                disabled={itemType === '' || itemTypeName.startsWith('PDP') || itemTypeName === 'Member Forms'}
                            >
                                <MenuItem value="">Select</MenuItem>
                                {stateCodes.map((t) => <MenuItem key={t.code || t} value={t.code || t}>{t.name || t}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: '150px' }}>
                            <InputLabel id="county-label">County</InputLabel>
                            <Select
                                labelId="county-label"
                                value={itemCounty}
                                onChange={(e) => setItemCounty(e.target.value)}
                                disabled={!(itemTypeName === 'Sales Kits' && itemState !== '')}
                            >
                                <MenuItem value="">Select</MenuItem>
                                {(counties || []).map((t) => <MenuItem key={t.code || t} value={t.code || t}>{t.name || t}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl style={{ width: '150px' }}>
                            <InputLabel id="language-label">Language</InputLabel>
                            <Select
                                labelId="language-label"
                                value={itemLanguage}
                                onChange={(e) => setItemLanguage(e.target.value)}
                            >
                                {languages.map((t) => <MenuItem key={t.code || t} value={t.code || t}>{t.name || t}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Item Code"
                            autoFocus
                            value={itemCode}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                            onChange={(e) => setItemCode(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Item Name"
                            value={itemName}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch(); } }}
                            onChange={(e) => setItemName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => {
                                handleSearch();
                            }}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ maxHeight: '650px' }}>
                    <Table size="small" stickyHeader className={classes.table}>
                        <TableHead style={{ backgroundColor: '#fafafa' }}>
                            <TableRow hover>
                                <TableCell />
                                <TableCell>Item Type</TableCell>
                                <TableCell>Plans</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell>Item Code</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>Item Name</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ minHeight: '50rem' }}>
                            {loading && (
                                <TableRow>
                                    <TableCell
                                        colSpan={20}
                                        style={{ textAlign: 'center', padding: '1rem' }}
                                    >
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!loading && foundResult && foundResult.totalCount === 0 && (
                                <TableRow>
                                    <TableCell colSpan={20}>
                                        <span style={{ color: '#999', fontWeight: 'bold', fontSize: '1.2rem' }}> No Items</span>
                                    </TableCell>

                                </TableRow>
                            )}
                            {!loading && foundResult && foundResult.results.length > 0 && foundResult.results.map((row) => {
                                const checked = selected.includes(row.cvw);
                                const itemDisabled = (maxItemCountReached && !checked)
                                    || (jobItems && jobItems.some((ji) => ji.code === row.code && ji.version === row.version))
                                    || (onlyWHS && row.whs !== onlyWHS);
                                return (
                                    <TableRow
                                        key={row.cvw}
                                    >
                                        <TableCell scope="row">
                                            <Checkbox
                                                color="primary"
                                                disabled={itemDisabled}
                                                onClick={() => handleToggleCheck(row.cvw)}
                                                checked={checked}
                                            />
                                        </TableCell>
                                        <TableCell scope="row" style={{ width: '100px' }}>{row.typeCategory}</TableCell>
                                        <TableCell scope="row" style={{ width: '400px' }}>{row.contractPBP}</TableCell>
                                        <TableCell scope="row" align="center">{row.stateCode}</TableCell>
                                        <TableCell scope="row">{row.language}</TableCell>
                                        <TableCell scope="row">
                                            {row.code}
                                            {
                                                row.kitTypeName && (
                                                    <Tooltip title={`Kit Type: ${row.kitTypeName}`} placement="right">
                                                        <Chip
                                                            size="small"
                                                            className={classes.smallChip}
                                                            label={row.kitTypeName}
                                                        />
                                                    </Tooltip>
                                                )
                                            }
                                        </TableCell>
                                        <TableCell scope="row" align="center" style={{ width: '25px' }}>
                                            {row.version}
                                        </TableCell>
                                        <TableCell scope="row">
                                            {row.name}
                                        </TableCell>
                                        {allowDownload && row.isDownload && (
                                            <TableCell style={{ padding: '.5rem' }}>
                                                <Link
                                                    href={buildDownloadLink(row)}
                                                    target="_blank"
                                                    color="primary"
                                                    onClick={(e) => confirmDownload(e, row.type, buildDownloadLink(row))}
                                                >
                                                    <CloudDownloadIcon color="primary" size="small" />
                                                </Link>
                                            </TableCell>
                                    )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!foundResult && (
                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => handleCancel()}
                                style={{ float: 'right' }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {foundResult && foundResult.results && foundResult.results.length > 0 && (

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        {!singleKitOnly && (
                            <Grid item xs={3}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleAdd(false)}
                                >
                                    Add Item
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAdd(true)}
                                className={classes.button}
                            >
                                Add Item/Close
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                foundResult && !loading && foundResult.pageCount > 1 && (
                                    <Pagination
                                        component="div"
                                        count={foundResult.pageCount}
                                        page={foundResult.pageNumber}
                                        style={{ float: 'right' }}
                                        onChange={(e, p) => {
                                            handleSearch(p);
                                        }}
                                    />
                                )
                            }
                            <Button
                                variant="contained"
                                onClick={() => handleCancel()}
                                style={{ float: 'right' }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>

                )}
            </div>
        </Modal>
    );
};

const mapStateToProps = ({
    directOrderView: {
        stepView: {
            jobItems, itemTypes, customer, whs, counties
        }
    }
}) => ({
    jobItems, itemTypes, customer, whs, counties
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { setKitItems },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FindAJEPPItemModal);
