import {
    Button, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import React from 'react';

export default function InvalidAddressDialog({
    open, onClose, onSave
}) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
            <DialogTitle id="alert-dialog-title">Invalid Address</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This address failed USPS validation. Do you still want to use it?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    No
                </Button>
                <Button onClick={onSave} color="primary" variant="contained" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>

    );
}
