import { Box, TextField } from '@material-ui/core';
import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';

class TextFieldCell extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            errorMessage: '',
            hasDiff: false,
            canEdit: !props.needTrigger
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value || '' });
        }
    }

    componentDidMount() {
        const { needTrigger } = this.props;
        if (needTrigger) {
            this.wrapper.addEventListener('dblclick', () => this.triggerEditMode(true));
        }
    }

    componentWillUnmount() {
        this.wrapper.removeEventListener('dblclick', () => this.triggerEditMode(true));
    }


    onChange = (e) => {
        let { value } = e.target;
        const { maxLength } = this.props;
        if (maxLength && e.target.value.length > maxLength) {
            value = value.substr(0, maxLength);
        }
        this.setState({ value });
    }

    onBlur = () => {
        const {
            row, col, updateRowData, needTrigger, isReadOnly
        } = this.props;
        const { value, canEdit } = this.state;
        if (needTrigger) {
            this.triggerEditMode(false);
        }
        const isChangeVal = row && col && row[col.id] ? row[col.id] !== value : true;
        if (!isReadOnly && updateRowData && canEdit && isChangeVal) {
            updateRowData(row, col, value);
        }
    }

    triggerEditMode = (canEdit) => {
        const { isReadOnly } = this.props;
        if (isReadOnly) return;
        this.setState({
            canEdit
        });
    }

    render() {
        const {
            isReadOnly, prefix, col, mask, characterLimit
        } = this.props;
        const { value, canEdit } = this.state;
        if (mask) {
            return (
                <Box component="div" className={`${prefix}-text-field`} ref={(ref) => this.wrapper = ref}>
                    <InputMask
                        fullWidth
                        size="small"
                        variant="outlined"
                        mask={mask}
                        value={value || ''}
                        name={col.id}
                        disabled={isReadOnly || !canEdit}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                    >
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            variant="outlined"
                            label={col.title}
                            name={col.id}
                            value={value || ''}
                            onChange={this.onChange}
                            inputProps={{
                                maxLength: characterLimit || 1000
                            }}
                        />
                    </InputMask>
                </Box>
            );
        }

        return (
            <Box component="div" className={`${prefix}-text-field`} ref={(ref) => this.wrapper = ref}>
                <TextField
                    fullWidth
                    size="small"
                    type="text"
                    variant="outlined"
                    label={col.title}
                    required={col.isRequired}
                    name={col.id}
                    disabled={isReadOnly || !canEdit}
                    value={value || ''}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    inputProps={{
                        maxLength: characterLimit || 1000
                    }}
                />
            </Box>
        );
    }
}

TextFieldCell.defaultProps = {
    prefix: 'editable-cell'
};
export default TextFieldCell;
