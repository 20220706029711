import basketActions from './action';

export default (
    state = {
        loading: false,
        basketItems: []
    },
    action
) => {
    switch (action.type) {
        case basketActions.ADD_TO_BASKET:
            return { ...state, basketItems: [...state.basketItems, action.item] };

        case basketActions.REMOVE_FROM_BASKET:
            return {
                ...state,
                basketItems: state.basketItems.filter((b) => b.lineId !== action.lineId)
            };

        case basketActions.UPDATE_BASKET_ITEM:
            return {
                ...state,
                basketItems: state.basketItems.map((b) => (
                    b.lineId === action.item.lineId
                        ? action.item
                        : b
                ))
            };

        default:
            return state;
    }
};
