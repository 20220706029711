import {
 Box, Button, CircularProgress, Container, CssBaseline, Divider, Grid, Typography
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import MuiAlert from '@material-ui/lab/Alert';
import React, { PureComponent } from 'react';
import http from 'utils/http';
import { RadioSelectCell, TextAreaCell, TextFieldCell } from '../../components/cells-control';

export default class HelpView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            missingRequiredField: true,
            submitting: false,
            info: null,

            helpType: null
        };
    }

    componentDidMount() {
        const { match: { params: { type } }, loadHelpInfo } = this.props;
        loadHelpInfo(type);
        this.setState({ helpType: type });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match && nextProps.match.params && nextProps.match.params.type) {
            const { helpType } = this.state;
            const { match: { params: { type } }, loadHelpInfo } = nextProps;
            if (helpType !== type) {
                loadHelpInfo(type);
                this.setState({ helpType: type });
            }
        }
    }

    updateRowData = (row, col, newValue) => {
        const { updateHelpInfo, helpInfo: { dataValue: v } } = this.props;
        updateHelpInfo(row, newValue);

        v[row] = newValue;
        this.setState({
            missingRequiredField: !v.name || !v.email || !v.phone || !v.support || !v.comments
        });
    }

    handleResetEvent = () => {
        const { resetEvent } = this.props;
        resetEvent();
    }

    handleSubmitEvent = () => {
        const { helpInfo: { dataValue } } = this.props;
        this.setState({ submitting: true });
        const { helpType } = this.state;
        dataValue.helpType = helpType;
        http.post('store/help', dataValue).then((r) => {
            if (r) {
                this.setState({ info: 'Your request has been submitted.' });
            } else {
                this.setState({ submitting: false });
            }
        });
    }

    renderField = (key, cell, value) => {
        switch (cell.type) {
            case 'textField':
                return (
                    <TextFieldCell
                        row={key}
                        col={cell}
                        prefix="help-form"
                        value={value}
                        {...cell}
                        updateRowData={this.updateRowData}
                    />
                );
            case 'textArea':
                return (
                    <TextAreaCell
                        row={key}
                        col={cell}
                        prefix="help-form"
                        value={value}
                        {...cell}
                        updateRowData={this.updateRowData}
                    />
                );
            case 'radioSelect':
                return (
                    <RadioSelectCell
                        row={key}
                        col={cell}
                        prefix="help-form"
                        value={value}
                        {...cell}
                        updateRowData={this.updateRowData}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const {
            user,
            helpInfo: {
                dataCell, dataValue, title, description, downloadTitle, downloadLink, tollFreeNumber
            }
        } = this.props;
        const { missingRequiredField, submitting, info } = this.state;
        return (
            <Container fixed className="help-view">
                <CssBaseline />
                <Box component="div" mb={4}>
                    <Typography variant="h3">
                        Need help now? {tollFreeNumber && (
                            <span style={{ fontSize: '1.1rem', float: 'right' }}>Toll free Number:  {tollFreeNumber}</span>
                        )}
                    </Typography>
                    <Divider style={{ height: '3px', marginBottom: '50px' }} />
                </Box>
                <Box component="div">
                    <Box component="div">
                        <Typography variant="h6">
                            {title}
                        </Typography>
                        <Typography variant="span">
                            {description}
                        </Typography>
                    </Box>
                    {downloadLink && (
                        <Box component="div">
                            <a href={downloadLink} target="_blank">
                                <CloudDownloadIcon style={{ position: 'relative', top: '5px', marginRight: '5px' }} />
                                <Typography variant="span">
                                    {downloadTitle}
                                </Typography>
                            </a>
                        </Box>
                    )}
                    {user && user.customFields && !user.customFields.p2_Catalog && (
                    <Grid
                        container
                        spacing={3}
                        className="help-content-part"
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ marginTop: '1.5rem' }}
                    >
                        <Grid container xs={12} sm={5}>
                            {dataCell && dataCell.map((item) => {
                                return (
                                    <Grid item xs={12} sm={12} key={item.title} style={{ marginBottom: '10px' }}>
                                        <Box component="div" mb={1}>
                                            {item.title}: {item.isRequired ? <sup style={{ color: 'red' }}>*</sup> : ''}
                                        </Box>
                                        {this.renderField(item.id, item, dataValue[item.id])}
                                    </Grid>
                                );
                            })}
                            <Grid container item xs={12} sm={12} spacing={2}>
                                {info && (
                                    <Grid item xs={12}>
                                        <MuiAlert elevation={6} variant="filled" severity="info">
                                            {info}
                                        </MuiAlert>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                                    <Button
                                        className="btn reset-btn"
                                        variant="contained"
                                        onClick={this.handleResetEvent}
                                        color="default"
                                    >Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                                    <Button
                                        className="btn submit-btn"
                                        variant="contained"
                                        onClick={this.handleSubmitEvent}
                                        color="primary"
                                        disabled={missingRequiredField || submitting}
                                    >
                                        {submitting && !info && (
                                            <CircularProgress
                                                size={12}
                                                style={{ marginRight: '.5rem' }}
                                            />
                                        )}
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    )}
                </Box>
            </Container>
        );
    }
}
