import { resetInput } from 'pages/direct-order/action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import CartView from './CartView';

const mapStateToProps = ({
    cartView: {
        isLoading, errorMessages, basketItems, used, pricing, refreshed
    },
    appView: { user },
    brokerView: { limits },
    directOrderView: { stepView: { jobItems, attachments } }
}) => {
    return {
        isLoading, errorMessages, basketItems, used, user, jobItems, attachments, limits, pricing, refreshed
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...actions, resetInput
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartView);
