import {
    Box,
    Button,

    FormControlLabel, Grid,
    Switch,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import LoadingTableRow from 'components/LoadingTableRow';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';

export default function PasswordSettingView({
    getSettings, getUserGroups, settings, userGroups
}) {
    const [localSettings, setLocalSettings] = useState(false);
    const [saving, setSaving] = useState(false);
    useEffect(() => {
        getUserGroups();
        getSettings();
    }, [getSettings, getUserGroups]);

    const onToggle = (sid, gid, checked) => {
        const updating = (localSettings || settings);
        const setting = updating.find((s) => s.id === sid);
        if (checked) {
            setting.userGroupXpasswordRequirement.push({ userGroupId: gid });
        } else {
            setting.userGroupXpasswordRequirement = setting.userGroupXpasswordRequirement.filter((x) => x.userGroupId !== gid);
        }

        setLocalSettings([...updating]);
    };

    const onSubmit = () => {
        setSaving(true);
        http.post('customer/password-settings', localSettings).then((d) => {
        }).finally((_) => setSaving(false));
    };

    const latest = (localSettings || settings);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/account">
                            Account
                        </Link>
                        <Typography color="textPrimary">Password Settings</Typography>
                    </Breadcrumbs>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small" aria-label="order table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Settings</TableCell>
                                {userGroups && userGroups.filter((g) => g.name !== 'Broker' && g.name !== 'MSO').map((g) => (
                                    <TableCell>{g.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <LoadingTableRow loading={!settings} />
                            {latest && latest.length > 0 && latest.map((s) => (
                                <TableRow>
                                    <TableCell>{s.description}</TableCell>
                                    {userGroups && userGroups.filter((g) => g.name !== 'Broker' && g.name !== 'MSO').map((g) => {
                                        const isOn = s.userGroupXpasswordRequirement.some((x) => x.userGroupId === g.id);
                                        return (
                                            <TableCell
                                                key={g.id}
                                            >
                                                <FormControlLabel
                                                    control={(
                                                        <Switch
                                                            checked={isOn}
                                                            onChange={(e) => onToggle(s.id, g.id, e.target.checked)}
                                                            color="primary"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Button
                    className="btn submit-btn"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={saving}
                    color="primary"
                    style={{ float: 'right' }}
                >{saving ? 'Saving' : 'Save'}
                </Button>
            </Grid>
        </Grid>
    );
}
