import {
    Box,
    IconButton, List,
    ListItem,
    ListItemSecondaryAction, ListItemText, Typography
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';

const SuggestedItemPanel = (props) => {
    const {
        jobType, lob, suggestedItems, addSuggestedItem, parentId, disabled
    } = props;

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (disabled && expanded) {
            setExpanded(false);
        }
    }, [expanded, disabled]);

    return (
        <ExpansionPanel disabled={disabled} expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <b>Suggested Items</b>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <List style={{ width: '100%' }} dense>
                    <ListItem>
                        <ListItemText
                            primary={(
                                <Box width="90%" display="inline-block">
                                    <Box display="inline-block" width="35%">Item Code</Box>
                                    <Box display="inline-block" width="5%">V </Box>
                                    <Box display="inline-block" width="35%">NAME</Box>
                                    <Box display="inline-block" width="5%">TYPE</Box>
                                </Box>
                            )}
                        />
                        <ListItemSecondaryAction />
                    </ListItem>
                    {suggestedItems.filter((item) => !item.selected).map((item) => (
                        <ListItem
                            key={item.id}
                            ContainerComponent="li"
                            divider
                        >
                            <ListItemText
                                primary={(

                                    <Box width="90%" display="inline-block">
                                        <Box display="inline-block" width="35%">
                                            {item.code}
                                        </Box>
                                        <Box display="inline-block" width="5%">
                                            {item.version}
                                        </Box>
                                        <Box display="inline-block" width="35%">
                                            {item.name}
                                        </Box>
                                        <Box display="inline-block" width="5%">
                                            {item.type}
                                        </Box>
                                    </Box>
                                )}
                            />
                            <ListItemSecondaryAction style={{ textAlign: 'right' }}>
                                <IconButton
                                    aria-label="delete"
                                    display="inline"
                                    size="small"
                                    m={1}
                                    onClick={() => {
                                        addSuggestedItem(item.id, parentId);
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default SuggestedItemPanel;
