import {
    Button, CircularProgress, ClickAwayListener, Divider, Grid, IconButton, Link, Popper, TableFooter, TextField, Typography
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import { addProductToCart } from 'components/basket/action';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadProducts } from './action';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
        zIndex: 10
    },
    paper: {
        padding: theme.spacing(1),
        zIndex: 10000
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

export const ProductTableView = ({
    listResult, loadProducts, openProduct, addProductToCart, limits, loading, token
}) => {
    const classes = useStyles();
    const handleChangePage = (_event, pageNumber) => {
        loadProducts(pageNumber + 1);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [qty, setQty] = React.useState(1);
    const [openId, setOpenId] = React.useState(null);

    const handlePopoverOpen = (event, id) => {
        if (id) {
            setQty(1);
            setAnchorEl(event.currentTarget);
            setOpenId(id);
        }
    };

    const closePopover = () => {
        setQty(1);
        setAnchorEl(null);
        setOpenId(null);
    };

    const onAddClicked = (id, isKit, code, name, quantity, customFields) => {
        customFields.limit = limits.monthlyItemLimits[code.toLowerCase()] || limits.monthlyLimits[`${customFields.itemType.toLowerCase()}_${customFields.marketName ?? 'Default'}`];
        customFields.upperLimit = limits.upperLimits[`${customFields.itemType.toLowerCase()}_${customFields.marketName ?? 'Default'}`] || limits.upperLimits[`${customFields.itemType.toLowerCase()}_Default`];
        addProductToCart({
            id, isKit, code, name, qty: quantity, customFields
        }, false);
    };
    const fallbackSrc = (e) => {
        e.target.src = '//cdndev.memberdoc.com/f/img_no_thumb.jpg';
        e.target.style.width = '100%';
    };

    return (
        <TableContainer component={Paper}>
            <Table size="medium" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Item Description</StyledTableCell>
                        <StyledTableCell>Language</StyledTableCell>
                        <StyledTableCell>Item Code</StyledTableCell>
                        <StyledTableCell>Contract PBP</StyledTableCell>
                        <StyledTableCell align="right">Order</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={20}
                                style={{ textAlign: 'center', padding: '1rem' }}
                            >
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {listResult && !loading && listResult.results.map((row) => {
                        const popoverOpen = openId === row.id;
                        return (
                            <TableRow key={row.id + row.code}>
                                <StyledTableCell component="th" scope="row">
                                    <Typography
                                        color="primary"
                                        style={{ fontWeight: 'bold' }}
                                    // aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                                    // aria-haspopup="true"
                                    // onMouseEnter={(e) => handlePopoverOpen(e, row.id)}
                                    >
                                        {row.name}
                                    </Typography>
                                    <br />
                                    <Button

                                        onClick={() => openProduct(row)}
                                    >VIEW LEAVE BEHINDS, PLAN GUIDES, OR FORMS
                                    </Button>
                                    <ClickAwayListener onClickAway={closePopover}>
                                        <Popper id={`pop-${row.id}`} open={popoverOpen} anchorEl={anchorEl}>
                                            <Grid container style={{ width: '36rem', height: '10rem' }} spacing={2} component={Paper}>
                                                <Grid item xs={3}>
                                                    <Grid container spacing={3}>
                                                        {
                                                            !row.downloadOnly && (
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        id="outlined-qty"
                                                                        label="Qty"
                                                                        size="small"
                                                                        type="number"
                                                                        min={1}
                                                                        autoFocus
                                                                        style={{ width: '6rem' }}
                                                                        value={qty}
                                                                        InputLabelProps={{
                                                                            shrink: true
                                                                        }}
                                                                        variant="outlined"
                                                                        onChange={(e) => setQty(parseInt(e.target.value, 10))}
                                                                    />
                                                                </Grid>
                                                            )
                                                        }
                                                        <Grid item xs={12}>
                                                            {
                                                                row.downloadOnly
                                                                    ? (
                                                                        <Link href={`${row.downloadRoot}/${row.itemType}/${row.id}?Authorization=${token}`} target="_blank">
                                                                            <CloudDownloadIcon color="primary" />
                                                                        </Link>
                                                                    )
                                                                    : (
                                                                        <Button
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            onClick={() => onAddClicked(row.id, row.isKit, row.code, row.name, qty, {
                                                                                itemType: row.customFields.materialType,
                                                                                thumbnail: row.thumbnail,
                                                                                whs: row.whs,
                                                                                version: row.version,
                                                                                marketName: row.marketName,
                                                                                dp: row.dp,
                                                                                year: row.year,
                                                                                type: row.type,
                                                                                hold_IND: row.hold_IND
                                                                            })}
                                                                        >
                                                                            <AddShoppingCartIcon
                                                                                color="primary"
                                                                            />
                                                                        </Button>
                                                                    )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={9}>
                                                    <Grid item xs={4}>
                                                        <img
                                                            src={row.thumbnail}
                                                            onError={fallbackSrc}
                                                            style={{ maxWidth: '8rem', maxHeight: '9rem' }}
                                                            alt={row.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Typography
                                                            color="primary"
                                                            style={{ fontWeight: 'bold' }}
                                                        >
                                                            {row.name}
                                                        </Typography>
                                                        <Divider />
                                                        {row.code}
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Popper>
                                    </ClickAwayListener>
                                </StyledTableCell>
                                <StyledTableCell>{row.language}</StyledTableCell>
                                <StyledTableCell>{row.code}</StyledTableCell>
                                <StyledTableCell>{row.contractPBP}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {
                                        row.downloadOnly
                                            ? (
                                                <Link href={`${row.downloadRoot}/${row.itemType}/${row.id}?Authorization=${token}`} target="_blank">
                                                    <CloudDownloadIcon color="primary" />
                                                </Link>
                                            )
                                            : (
                                                <IconButton
                                                    onClick={() => onAddClicked(row.id, row.isKit, row.code, row.name, 1, {
                                                        itemType: row.customFields.materialType, thumbnail: row.thumbnail, whs: row.whs, version: row.version, marketName: row.marketName,
                                                        dp: row.dp, year: row.year,
                                                        type: row.type,
                                                        hold_IND: row.hold_IND
                                                    })}
                                                >
                                                    <AddShoppingCartIcon
                                                        color="primary"
                                                    />
                                                </IconButton>
                                            )
                                    }
                                </StyledTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={20}>
                            {listResult && (
                                <TablePagination
                                    component="div"
                                    count={listResult.totalCount}
                                    page={listResult.pageNumber - 1}
                                    rowsPerPage={listResult.pageSize}
                                    rowsPerPageOptions={[listResult.pageSize]}
                                    onChangePage={handleChangePage}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
};

const mapStateToProps = (state) => ({
    listResult: state.brokerView.listResult,
    loading: state.brokerView.loading,
    limits: state.brokerView.limits,
    token: state.appView.user.token
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addProductToCart,
            loadProducts
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTableView);
