import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './action';
import PasswordSettingView from './PasswordSettingView';

const mapStateToProps = ({ passwordSettingsView, appView: { user } }) => {
    return { ...passwordSettingsView, user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { ...actions },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PasswordSettingView);
