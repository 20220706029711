import React from 'react';
import {
    FormControl, Select, InputLabel, MenuItem
} from '@material-ui/core';

export default function OEOutputType({
    outputType, onOutputTypeChange, disableDigital, disablePrint, disabled, enableDigitalAndPrint
}) {
    return (
        <FormControl
            variant="outlined"
            style={{ marginRight: '1rem' }}
            size="small"
        >
            <InputLabel>Output Type</InputLabel>
            <Select
                value={outputType}
                label="Output Type"
                disabled={disabled}
                onChange={onOutputTypeChange}
            >
                {!disableDigital && <MenuItem value="Digital Only">Digital Only</MenuItem>}
                {!disablePrint && <MenuItem value="Print Only">Print Only</MenuItem>}
                {enableDigitalAndPrint && !disablePrint && !disableDigital && <MenuItem value="Digital and Print">Digital and Print</MenuItem>}
            </Select>
        </FormControl>
    );
}
