import {
    Box,
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import React from 'react';

export default function JobInfo({ job, jobType }) {
    if (!job) return null;
    return (
        <Box mb={2}>
            <Typography variant="h6" color="primary">
                Job Information
            </Typography>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="Job Info">
                    <TableHead>
                        <TableRow>
                            <TableCell>NAME</TableCell>
                            <TableCell>{jobType === 'IM' ? 'SHIP DATE' : 'DATE REQ'}</TableCell>
                            <TableCell>COST CENTER</TableCell>
                            <TableCell>PHONE</TableCell>
                            {job.oboBroker && <TableCell>NPN</TableCell>}
                            {job.email1 && <TableCell>Email 1</TableCell>}
                            {job.email2 && <TableCell>Email 2</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{job.firstName} {job.lastName}</TableCell>
                            <TableCell>{job.dateReq}</TableCell>
                            <TableCell>{job.costCenter}</TableCell>
                            <TableCell>{job.phone} {job.ext}</TableCell>
                            {job.oboBroker && <TableCell>{job.npn}</TableCell>}
                            {job.email1 && <TableCell>{job.email1}</TableCell>}
                            {job.email2 && <TableCell>{job.email2}</TableCell>}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
