import DateFnsUtils from '@date-io/date-fns';
import {
    Button, FormControl, InputLabel, MenuItem, Select,
    Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Pagination from '@material-ui/lab/Pagination';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LoadingTableRow from 'components/LoadingTableRow';
import OrderRow from 'components/OrderRow';
import React from 'react';
import http from 'utils/http';

export default function HistoricalOrders({
    loading, orders, onChangePage, submitSearch, history, criteria, setCriteria
}) {
    const getCriteria = () => {
        return {
            ...criteria,
            startDate: criteria.startDate ? criteria.startDate.toLocaleDateString() : '',
            endDate: criteria.endDate ? criteria.endDate.toLocaleDateString() : ''
        };
    };

    const toFullName = (s) => {
        switch (s) {
            case 'FJE':
                return 'Fast Job';
            case 'AJEPP':
                return 'Pick & Pack';
            case 'AJEKT':
                return 'Kit Job';
            case 'IM':
                return 'IM';
            case 'Broker':
                return 'Broker';
            default:
                return '';
        }
    };

    const goToDetail = (id) => {
        history.push({
            pathname: `/order/${id}`,
            state: {
                criteria: getCriteria(),
                pageCount: orders.pageCount,
                pageNumber: orders.pageNumber
            }
        });
    };

    const onCopyOrder = (orderId) => {
        const fd = new FormData();
        fd.append('orderId', orderId);
        http.post('store/copy-order', fd).then((data) => {
            if (data.lob && data.jobType) {
                if (data.jobItems) {
                    for (const ji of data.jobItems) {
                        if (ji.components && ji.components.length) {
                            for (const c of ji.components) {
                                if (c.formData) {
                                    c.formData.approved = true;
                                    c.approved = true;
                                    if (c.component) {
                                        c.component.approved = true;
                                    }
                                }
                            }
                        }

                        if (ji.items && ji.items.length) {
                            for (const c of ji.items) {
                                if (c.formData) {
                                    c.approved = true;
                                    c.formData.approved = true;
                                    if (c.component) {
                                        c.component.approved = true;
                                    }
                                }
                            }
                        }
                    }
                }


                history.push({
                    pathname: `/direct-order/${data.lob}/${data.jobType}`,
                    state: { orderData: data }
                });
            } else {
                history.push('/cart');
            }
        });
    };

    const hasCustomerName = orders && orders.results && orders.results.some((o) => o.customerName);

    return (

        <TableContainer>
            <Table size="small" aria-label="order table">
                <TableHead>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TableRow>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="ORDER #"
                                    autoFocus
                                    value={criteria.orderNumber}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('orderNumber', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="USER ID"
                                    value={criteria.userId}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('userId', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="First Name"
                                    value={criteria.recipientFirstName}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('recipientFirstName', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="Middle Name"
                                    value={criteria.recipientMiddleName}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('recipientMiddleName', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="Last Name"
                                    value={criteria.recipientLastName}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('recipientLastName', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="ship_by_date_picker"
                                    label="Ship By Date"
                                    name="shipByDate"
                                    value={criteria.shipByDate}
                                    onChange={(e) => setCriteria('shipByDate', e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change ship by date'
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="Item Code"
                                    autoFocus
                                    value={criteria.itemCode}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('itemCode', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <Button
                                    color="primary"
                                    onClick={() => submitSearch(getCriteria())}
                                >
                                    <SearchIcon /> Search
                                </Button>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="Customer"
                                    autoFocus
                                    value={criteria.customerName}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('customerName', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                >
                                    <InputLabel id="country-select-outlined-label">Program</InputLabel>
                                    <Select
                                        value={criteria.program}
                                        onChange={(e) => setCriteria('program', e.target.value)}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        fullWidth
                                        label="Program"
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Medicare Individual">Medicare Individual</MenuItem>
                                        <MenuItem value="Medicare Group">Medicare Group</MenuItem>
                                        <MenuItem value="Traditional Commercial">Traditional Commercial</MenuItem>
                                        <MenuItem value="Reimagination">Dynamic OE</MenuItem>
                                        <MenuItem value="Small Group">Small Group</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                >
                                    <InputLabel id="country-select-outlined-label">Job Type</InputLabel>
                                    <Select
                                        value={criteria.jobType}
                                        onChange={(e) => setCriteria('jobType', e.target.value)}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        fullWidth
                                        label="Job Type"
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="FJE">Fast Job</MenuItem>
                                        <MenuItem value="AJEPP">Pick & Pack</MenuItem>
                                        <MenuItem value="AJEKT">Kit Job</MenuItem>
                                        <MenuItem value="IM">IM</MenuItem>
                                        <MenuItem value="Broker">Broker</MenuItem>
                                        <MenuItem value="telesales">Telesales</MenuItem>
                                        <MenuItem value="OE">OE</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                >
                                    <InputLabel id="country-select-outlined-label">Order Status</InputLabel>
                                    <Select
                                        value={criteria.orderStatus}
                                        onChange={(e) => setCriteria('orderStatus', e.target.value)}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                        fullWidth
                                        label="Order Status"
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        <MenuItem value="Shipped">Shipped</MenuItem>
                                        <MenuItem value="Partially Shipped">Partially Shipped</MenuItem>
                                        <MenuItem value="Pending">Pending</MenuItem>
                                        <MenuItem value="Approved">Approved</MenuItem>
                                        <MenuItem value="Held">Held</MenuItem>
                                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="start_date_picker"
                                    label="Start Date"
                                    name="startDate"
                                    value={criteria.startDate}
                                    onChange={(e) => setCriteria('startDate', e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change start date'
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="end_date_picker"
                                    label="End Date"
                                    name="endDate"
                                    value={criteria.endDate}
                                    onChange={(e) => setCriteria('endDate', e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change end date'
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="SO #"
                                    autoFocus
                                    value={criteria.soNumber}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('soNumber', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="Batch File #"
                                    autoFocus
                                    value={criteria.fileNumber}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('fileNumber', e.target.value)}
                                />
                            </TableCell>
                            <TableCell style={{ padding: '6px' }}>
                                <TextField
                                    label="NPN"
                                    autoFocus
                                    value={criteria.npn}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { submitSearch(getCriteria()); } }}
                                    onChange={(e) => setCriteria('npn', e.target.value)}
                                />
                            </TableCell>

                        </TableRow>
                    </MuiPickersUtilsProvider>
                </TableHead>
            </Table>
            <Table aria-label="order table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ padding: '.5rem' }} />
                        <TableCell style={{ padding: '.5rem' }}>ORDER NUMBER</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>ORDERED BY</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>RECIPIENTS</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>PROGRAM</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>JOB TYPE</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>MARKET SEGMENT</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>COST CENTER</TableCell>
                        {
                            hasCustomerName && (<TableCell style={{ padding: '.5rem' }}>CUSTOMER</TableCell>)
                        }

                        <TableCell style={{ padding: '.5rem' }}>KIT QTY</TableCell>
                        <TableCell style={{ padding: '.5rem', maxWidth: '100px' }}>ORDER STATUS</TableCell>
                        <TableCell style={{ padding: '.5rem' }}>WHS</TableCell>
                        <TableCell style={{ padding: '.5rem' }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <LoadingTableRow loading={loading} />
                    {!loading && orders && orders.results.map((row) => (
                        <OrderRow
                            key={row.id}
                            row={row}
                            hasCustomerName={hasCustomerName}
                            onViewClick={goToDetail}
                            onCopyClick={onCopyOrder}
                            showKitCount
                            showRecipient
                        />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={20}>
                            {orders
                                && (
                                    <Pagination
                                        component="div"
                                        count={orders.pageCount}
                                        page={orders.pageNumber}
                                        onChange={onChangePage}
                                    />
                                )}

                        </TableCell>
                    </TableRow>

                </TableFooter>
            </Table>
        </TableContainer>
    );
}
