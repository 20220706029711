
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { passthroughLogin, setErrorMsg } from './action';
import PassthroughLogin from './PassthroughLogin';

const mapStateToProps = ({ passthroughLoginView, appView }) => {
    return {
        error: passthroughLoginView.error,
        loading: passthroughLoginView.loading,
        isAuthUser: appView.isAuthUser
    };
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            passthroughLogin, setErrorMsg
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PassthroughLogin);
