import actionCreator from 'utils/actionCreator';
import http from 'utils/http';
import keyMirror from 'utils/keyMirror';

export const actions = keyMirror('ADDRESSES_VIEW', {
    LOADING: null,

    SET_PROFILE: null
});

export default actions;

const setLoading = actionCreator(actions.LOADING, 'loading');

const setProfile = actionCreator(actions.SET_PROFILE, 'profile');
export const getProfile = () => (dispatch) => {
    dispatch(setLoading(true));
    http.get('customer/profile')
        .then((data) => dispatch(setProfile(data)))
        .finally(() => dispatch(setLoading(false)));
};

// export const saveAddress = (address) => (dispatch) => {
//     dispatch(setLoading(true));
//     http.post('customer/save-address', address);
// };
