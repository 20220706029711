import {
    Box, Button,
    Checkbox, FormHelperText,

    Grid,
    Modal, TextField
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import InvalidAddressDialog from 'components/InvalidAddressDialog';
import message from 'components/Message';
import PhoneInput from 'components/PhoneInput';
import RecommendedAddress from 'components/RecommendedAddress';
import StateSelect from 'components/UsStateSelect';
import countries from 'pages/direct-order/steps/CountryData.json';
import React, { useEffect, useState } from 'react';
import http from 'utils/http';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        height: 480,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        padding: '2rem',
        boxShadow: theme.shadows[5]
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    }
}));

const AddressModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const {
        open, closeAddress, address, saveAddress, showMessage, allowSpouse, isOE
    } = props;
    const [addr, setAddr] = React.useState(address || { country: 'United States' });
    const [requiredFilled, setRequiredFilled] = useState(false);
    const [validating, setValidating] = useState(false);

    const [includeSpouse, setIncludeSpouse] = useState(false);
    const [promptTitle, setPromptTitle] = useState(false);
    const [title, setTitle] = useState(false);

    const [openInvalid, setOpenInvalid] = useState(false);
    const [isPOBox, setIsPOBox] = useState(false);
    const [openRecommended, setOpenRecommended] = useState(false);
    const [recommended, setRecommended] = useState(null);

    useEffect(() => {
        setRequiredFilled(!!addr.lastName
            && !!addr.address
            && !!addr.zip);
    }, [addr, props]);

    const classes = useStyles();

    const onFieldChange = (e) => {
        setAddr({ ...addr, [e.target.name]: e.target.value });
    };

    const hasPOBox = (s) => {
        if (s) {
            const ps = s.replace(/\./g, '').toLowerCase().trim();

            return ps.startsWith('po ');
        }
        return false;
    };

    const saveChange = () => {
        if ((hasPOBox(addr.address) || hasPOBox(addr.address2))) {
            setIsPOBox(true);
            return;
        }

        setValidating(true);
        setIsPOBox(false);
        http.post('store/validate-address', {
            Address1: addr.address,
            Address2: addr.address2,
            City: addr.city,
            State: addr.state,
            Zipcode: addr.zip,
            Country: addr.country || 'United States'
        }).then((r) => {
            if (r.POBox) {
                setIsPOBox(true);
            } else if (!r.Success) {
                if (r.StatusMessage === 'Insufficient Data') {
                    let msg = 'Your address input is insufficient.';
                    if (!addr.city) {
                        msg += ' Please populate City';
                    } else if (!addr.state) {
                        msg += ' Please populate State';
                    }
                    message.error(msg);
                } else {
                    setOpenInvalid(true);
                }
            } else {
                const addressR = {
                    ...addr,
                    address: r.Address1,
                    address2: r.Address2,
                    city: r.City,
                    state: r.State,
                    zip: r.Zipcode
                };
                if (
                    addr.address !== r.Address1 || (addr.address2 && addr.address2 !== r.Address2) || addr.city !== r.City || addr.state !== r.State || addr.zip !== r.Zipcode
                ) {
                    const ia = { ...addr };
                    if (!addr.city && r.City) {
                        ia.city = r.City;
                    }
                    if (!addr.state && r.State) {
                        ia.state = r.State;
                    }
                    setAddr(ia);

                    setRecommended(addressR);
                    setOpenRecommended(true);
                } else {
                    saveAddress(addr);
                }
            }
        }).finally(() => setValidating(false));
    };

    const clearAll = () => {
        const a = {};
        for (const k in addr) { a[k] = ''; }
        a.country = 'United States';
        setAddr(a);
    };

    return (
        <Modal
            open={open}
            onClose={closeAddress}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container spacing={1} className="row-item">
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            name="name"
                            fullWidth
                            value={addr.name || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={addr.firstName || ''}
                            onChange={onFieldChange}
                            required
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="MI"
                            name="middleName"
                            value={addr.middleName || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    {allowSpouse
                        ? (
                            <>
                                <Grid item xs={3}>
                                    <TextField

                                        label="Last Name"
                                        name="lastName"
                                        required
                                        value={addr.lastName || ''}
                                        onChange={onFieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        checked={includeSpouse}
                                        onChange={() => setIncludeSpouse(!includeSpouse)}
                                        color="primary"
                                    />
                                    Including Spouse

                                </Grid>
                            </>
                        )
                        : (
                            <Grid item xs={6}>
                                <TextField

                                    label="Last Name"
                                    name="lastName"
                                    required
                                    value={addr.lastName || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>

                        )}
                    {includeSpouse ? (
                        <>
                            <Grid item xs={3}>
                                <TextField
                                    label="Spouse First Name"
                                    name="spouseFirstName"
                                    value={addr.spouseFirstName || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Spouse First Name"
                                    name="spouseLastName"
                                    value={addr.spouseLastName || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField

                                    label="Phone#"
                                    name="phone"
                                    value={addr.phone || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField

                                    label="Email"
                                    name="email"
                                    value={addr.email || ''}
                                    onChange={onFieldChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>

                        </>
                    ) : (
                        <>
                            <Grid item xs={3}>
                                <TextField
                                    label="Phone#"
                                    name="phone"
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: PhoneInput,
                                        value: addr.phone || '',
                                        onChange: onFieldChange
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {/* <TextField

                                        label="Phone#"
                                        name="phone"
                                        value={addr.phone || ''}
                                        onChange={onFieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    /> */}
                            </Grid>
                            <Grid item xs={9}>
                                    {
                                        isOE
                                            ? null
                                            : (
                                                <TextField

                                                    label="Email"
                                                    name="email"
                                                    value={addr.email || ''}
                                                    onChange={onFieldChange}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    variant="outlined"
                                                />)
                                    }
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Address Line 1"
                            name="address"
                            value={addr.address || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 50
                            }}
                            required
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Address Line 2"
                            name="address2"
                            value={addr.address2 || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 50
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="City"
                            name="city"
                            value={addr.city || ''}
                            onChange={onFieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 30
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StateSelect
                            size="medium"
                            name="state"
                            value={addr.state || ''}
                            onChange={onFieldChange}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <TextField

                            label="Zip/Postal Code"
                            name="zip"
                            value={addr.zip || ''}
                            onChange={onFieldChange}
                            required
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            required
                            variant="outlined"
                        >
                            <InputLabel id="country-select-outlined-label">Country</InputLabel>
                            <Select
                                labelId="country-select-outlined-label"
                                id="country-select-outlined"
                                fullWidth
                                name="country"
                                required
                                value={addr.country || ''}
                                onChange={onFieldChange}
                                label="Country"
                            >
                                <MenuItem value="">Select</MenuItem>
                                {countries.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHelperText>
                            * is for required fields
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button
                        disabled={!requiredFilled || validating || isPOBox}
                        variant="contained"
                        onClick={saveChange}
                        color="primary"
                    >Save
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        fontWeight="fontWeightMedium"
                        style={{ marginLeft: '1rem' }}
                        onClick={clearAll}
                    >
                        <ClearAllIcon /> Clear
                    </Button>
                    {isPOBox && <span style={{ color: 'red', fontWeight: 'bold', marginLeft: '2rem' }}>PO Box address is not allowed.</span>}
                </Box>

                {
                    openRecommended && (
                        <RecommendedAddress
                            open={openRecommended}
                            onClose={() => { setRecommended(null); setOpenRecommended(false); }}
                            recommendedAddress={recommended}
                            originalAddress={addr}
                            onSave={saveAddress}
                        />
                    )
                }
                {
                    openInvalid && (
                        <InvalidAddressDialog
                            open={openInvalid}
                            onClose={() => setOpenInvalid(false)}
                            onSave={() => saveAddress(addr)}
                        />
                    )
                }
            </div>
        </Modal>
    );
};

export default AddressModal;
