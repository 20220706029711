import {
    Checkbox, Divider, FormControl,
    FormHelperText, Grid,
    InputLabel, MenuItem, Select, TextField,
    Typography
} from '@material-ui/core';
import PhoneInput from 'components/PhoneInput';
import StateSelect from 'components/UsStateSelect';
import countries from 'pages/direct-order/steps/CountryData.json';
import React, { PureComponent } from 'react';
import http from 'utils/http';

export default class SingleAddressView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            includingSpouse: false,
            validating: false,
            validated: false,
            canValidate: false,

            savedAddresses: [],
            selectedAddressId: ''
        };
    }

    componentDidMount() {
        http.get('store/addresses/1/100')
            .then((d) => {
                this.setState({ savedAddresses: d.results });
            });
    }

    onFieldChange = (e) => {
        const { addr, onAddressChange } = this.props;
        const newAddr = { ...addr, [e.target.name]: e.target.value };
        onAddressChange(newAddr);
        this.setState({
            canValidate: !!newAddr.lastName
                && !!newAddr.address
                && !!newAddr.zip
                && !!newAddr.country,
            validated: false
        });
    }

    setIncludingSpouse = () => {
        const { includingSpouse } = this.state;
        this.setState({ includingSpouse: !includingSpouse });
    }

    selectAddress = (selectedAddressId) => {
        if (!selectedAddressId) return;
        const { savedAddresses } = this.state;
        this.setState({ selectedAddressId });
        const address = savedAddresses.find((a) => a.id == selectedAddressId);
        if (!address.shippingMethodCode) {
            address.shippingMethodCode = 'Standard';
        }
        const { onAddressChange } = this.props;
        onAddressChange(address, true);
    }

    render() {
        const {
            addr, spouseSupported, disableShippingMethod, phoneRequired, shippingMethods, user, emailRequired, isPOBox
        } = this.props;
        const {
            includingSpouse, validated, validating, canValidate, savedAddresses, selectedAddressId
        } = this.state;

        const isP2 = !!(user !== null && user && user.customFields && user.customFields.p2_Catalog);
        const isP2Admin = user !== null && user && user.customFields && user.customFields.isAdminUser;

        return (
            <Grid container spacing={1}>
                <Grid item xs={!spouseSupported ? 9 : 12}>
                    <Typography variant="h5">
                        Shipping Address
                        {spouseSupported && (
                            <label style={{ paddingleft: '1.5rem', float: 'right', fontSize: '1.2rem' }}>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    checked={includingSpouse}
                                    onChange={this.setIncludingSpouse}
                                    color="primary"
                                />
                                Including Spouse
                            </label>
                        )}
                    </Typography>

                    <Divider style={{
                        height: '1px', width: '100%', marginBottom: '1rem', marginTop: '.5rem'
                    }}
                    />
                </Grid>
                {!spouseSupported && (
                    <Grid item xs={3}>
                        <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                        >
                            <InputLabel>Saved Addresses</InputLabel>
                            <Select
                                value={selectedAddressId}
                                fullWidth

                                label="Saved Addresses"
                                onChange={(e) => this.selectAddress(e.target.value)}
                            >
                                <MenuItem value="">Select</MenuItem>
                                {
                                    savedAddresses.map((s) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12}>

                    <Grid container spacing={2} className="row-item">
                        <Grid item xs={2}>
                            <TextField
                                required
                                label="First Name"
                                name="firstName"
                                size="small"
                                value={addr.firstName || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"

                                label="MI"
                                name="middleName"
                                value={addr.middleName || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                required

                                label="Last Name"
                                name="lastName"
                                value={addr.lastName || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label="Phone#"
                                size="small"
                                name="phone"
                                required={phoneRequired}
                                variant="outlined"
                                InputProps={{
                                    inputComponent: PhoneInput,
                                    value: addr.phone || '',
                                    onChange: this.onFieldChange
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                required={emailRequired}
                                label="Email"
                                name="email"
                                value={addr.email || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        {spouseSupported && includingSpouse ? (
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={2}>
                                    <TextField
                                        size="small"
                                        label="Spouse First Name"
                                        name="spouseFirstName"
                                        value={addr.spouseFirstName || ''}
                                        onChange={this.onFieldChange}
                                        InputLabelProps={{
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        size="small"
                                        label="Spouse Last Name"
                                        name="spouseLastName"
                                        value={addr.spouseLastName || ''}
                                        onChange={this.onFieldChange}
                                        InputLabelProps={{
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>

                            </Grid>
                        ) : null}

                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    size="small"
                                    required
                                    fullWidth
                                    label="Address Line 1"
                                    error={isPOBox}
                                    name="address"
                                    value={addr.address || ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                    }}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Address Line 2"
                                    name="address2"
                                    error={isPOBox}
                                    value={addr.address2 || ''}
                                    onChange={this.onFieldChange}
                                    InputLabelProps={{
                                    }}
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {isPOBox && <span style={{ color: 'red' }}>PO Box address is not allowed.</span>}
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                label="City"
                                name="city"
                                value={addr.city || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                inputProps={{
                                    maxLength: 30
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <StateSelect
                                required
                                value={addr.state || ''}
                                onChange={this.onFieldChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size="small"
                                required
                                label="Zip/Postal Code"
                                name="zip"
                                value={addr.zip || ''}
                                onChange={this.onFieldChange}
                                InputLabelProps={{
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>

                            <FormControl
                                fullWidth
                                size="small"
                                required
                                variant="outlined"
                            >
                                <InputLabel id="country-select-outlined-label">Country</InputLabel>
                                <Select
                                    labelId="country-select-outlined-label"
                                    id="country-select-outlined"
                                    fullWidth
                                    name="country"
                                    value={addr.country || 'United States'}
                                    onChange={this.onFieldChange}
                                    label="Country"
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    {countries.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormHelperText>
                                * is for required fields
                            </FormHelperText>
                        </Grid>
                        {/* <Grid item xs={2}>
                            <Button
                                size="large"
                                color="primary"
                                variant="contained"
                                fontWeight="fontWeightMedium"
                                style={{ marginRight: '1rem' }}
                                disabled={!canValidate || validating}
                                onClick={this.validateInputAddress}
                            >
                                <VerifiedUserIcon /> {validated ? 'Validated' : 'Validate'}
                            </Button>
                        </Grid> */}
                    </Grid>

                </Grid>
                {
                    !disableShippingMethod && (

                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <InputLabel>Shipping Method</InputLabel>
                                <Select
                                    value={addr.shippingMethodCode}
                                    fullWidth
                                    onChange={this.onFieldChange}
                                    name="shippingMethodCode"
                                    label="Shipping Method"
                                >
                                    {
                                        shippingMethods === undefined || shippingMethods === null
                                            ? <MenuItem value="Standard">Standard</MenuItem>
                                            : isP2 && isP2Admin ? shippingMethods.map((m) => <MenuItem key={m.code} value={m.code}>{m.code}</MenuItem>)
                                                : isP2 && !isP2Admin ? <MenuItem value="Standard">Standard</MenuItem>
                                                    : shippingMethods.map((m) => <MenuItem key={m.code} value={m.code}>{m.code}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                        </Grid>

                    )
                }
            </Grid>
        );
    }
}
