import addressActions from './action';

export default (
    state = {
        loading: false,
        addresses: null
    },
    action
) => {
    switch (action.type) {
        case addressActions.LOADING:
            return { ...state, loading: action.loading };


        case addressActions.SET_ADDRESSES:
            return { ...state, addresses: action.addresses };

        default:
            return state;
    }
};
