import psActions from './action';

export default (
    state = {
        loading: false,
        saving: false,
        meta: false,
        traks: null
    },
    action
) => {
    switch (action.type) {
        case psActions.LOADING:
            return { ...state, loading: action.loading };
        case psActions.SAVING:
            return { ...state, loading: action.saving };

        case psActions.SET_TRAKS:
            return { ...state, traks: action.traks };

        case psActions.SET_META:
            return { ...state, meta: action.meta };

        default:
            return state;
    }
};
