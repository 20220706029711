import { combineReducers } from 'redux';
import appView from './AppReducer';
import basketView from './components/basket/reducer';
import planComparisonView from './components/customize/PlanComparison/reducer';
import accountView from './pages/account/reducer';
import addressesView from './pages/addresses/reducer';
import bannerMessageView from './pages/banner-message/reducer';
import brokerView from './pages/broker/reducer';
import cartView from './pages/cart/reducer';
import catalogView from './pages/catalog/reducer';
import checkoutView from './pages/checkout/reducer';
import demo from './pages/demo/reducer';
import directOrderView from './pages/direct-order/reducerExport';
import helpView from './pages/help/reducer';
import imBatchView from './pages/im-batch/reducer';
import loginView from './pages/login/reducer';
import orderManagementView from './pages/order-management/reducer';
import ordersView from './pages/orders/reducer';
import passthroughLoginView from './pages/passthrough-login/reducer';
import passthroughView from './pages/passthrough/reducer';
import passwordSettingsView from './pages/password-settings/reducer';
import passwordView from './pages/password/reducer';
import pingOneLoginView from './pages/pingone-login/reducer';
import planSponsorView from './pages/plan-sponsor/reducer';
import ppBatchView from './pages/pp-batch/reducer';
import profileView from './pages/profile/reducer';
import savedJobView from './pages/saved-job/reducer';
import savedOrderView from './pages/saved-order/reducer';
import smallGroupView from './pages/small-group/reducer';
import telesalesView from './pages/telesales/reducer';
import trakView from './pages/trak/reducer';

export default combineReducers({
    demo,
    accountView,
    appView,
    addressesView,
    bannerMessageView,
    basketView,
    brokerView,
    cartView,
    catalogView,
    checkoutView,
    directOrderView,
    helpView,
    imBatchView,
    loginView,
    passthroughView,
    passthroughLoginView,
    pingOneLoginView,
    orderManagementView,
    ordersView,
    passwordView,
    passwordSettingsView,
    planComparisonView,
    planSponsorView,
    ppBatchView,
    profileView,
    savedOrderView,
    savedJobView,
    smallGroupView,
    telesalesView,
    trakView
});
