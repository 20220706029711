import {
    Grid, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core';
import React, { useState } from 'react';

export default function IMBatchItemInfo({ orderData, onAddressChanged }) {
    const [address, setAddress] = useState(orderData.addressItem);
    const updateAddress = (kv) => {
        const a = { ...address, ...kv };
        setAddress(a);
        onAddressChanged(a);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    label="First Name"
                    fullWidth
                    margin="normal"
                    name="firstName"
                    variant="outlined"
                    size="small"
                    onChange={((e) => updateAddress({ firstName: e.target.value }))}
                    value={address.firstName || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Last Name"
                    fullWidth
                    margin="normal"
                    name="lastName"
                    variant="outlined"
                    size="small"
                    onChange={((e) => updateAddress({ lastName: e.target.value }))}
                    value={address.lastName || ''}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Street Address"
                    name="streetAddress"
                    value={address.streetAddress || ''}
                    onChange={((e) => updateAddress({ streetAddress: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 50
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Street Address 2"
                    name="streetAddress2"
                    value={address.streetAddress2 || ''}
                    onChange={((e) => updateAddress({ streetAddress2: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 50
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Street Address3"
                    name="streetAddress3"
                    value={address.streetAddress3 || ''}
                    onChange={((e) => updateAddress({ streetAddress3: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 50
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="City"
                    name="city"
                    value={address.city || ''}
                    onChange={((e) => updateAddress({ city: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        maxLength: 30
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="State"
                    name="state"
                    value={address.state || ''}
                    onChange={((e) => updateAddress({ state: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Zip Code"
                    name="zipCode"
                    value={address.zipCode || ''}
                    onChange={((e) => updateAddress({ zipCode: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Country"
                    name="country"
                    value={address.country || ''}
                    onChange={((e) => updateAddress({ country: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Telephone Number"
                    name="telephoneNumber"
                    value={address.telephoneNumber || ''}
                    onChange={((e) => updateAddress({ telephoneNumber: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Product Name"
                    name="productName"
                    value={address.productName || ''}
                    onChange={((e) => updateAddress({ productName: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="member"
                    name="Member"
                    value={address.member || ''}
                    onChange={((e) => updateAddress({ member: e.target.value }))}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Item Code</TableCell>
                                <TableCell>
                                    Qty
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderData.jobItems.map((j, i) => (
                                <TableRow key={j.id}>
                                    <TableCell>{j.id ? <span>{j.code}</span> : <span style={{ color: 'red' }}>{j.code}</span>}</TableCell>
                                    <TableCell>1</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
