import actionTypes from './action';

export default (
    state = {
        loading: false,
        found: []
    },
    action
) => {
    switch (action.type) {
        case actionTypes.LOADING:
            return { ...state, loading: action.loading };


        case actionTypes.SET_FOUND:
            return { ...state, found: action.found };

        default:
            return state;
    }
};
